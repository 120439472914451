/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateDossierRequest } from '../model/createDossierRequest';
// @ts-ignore
import { Dossier } from '../model/dossier';
// @ts-ignore
import { DossierDocument } from '../model/dossierDocument';
// @ts-ignore
import { DossierFile } from '../model/dossierFile';
// @ts-ignore
import { DossierImage } from '../model/dossierImage';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DossiersService {

    protected basePath = 'https://api.dev.twinspot.com/dossiers';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Endpoint to retrieve dossiers based on organization ID.
     * This endpoint retrieves a list of dossiers belonging to the specified organization.  # Parameters  - &#x60;organization_id&#x60;: The unique ID of the organization whose dossiers are to be retrieved. - &#x60;building_id&#x60;: The unique ID of the building whose dossiers are to be retrieved.  # Returns  A list of dossiers belonging to the specified organization.  ## HTTP Status Codes  - 200 OK: The list of dossiers is successfully retrieved. - 400 Bad Request: Please specify organization_id or building_id or both, but one of these must be given. - 500 Internal Server Error: There was a server error while retrieving the dossiers.
     * @param organizationId
     * @param buildingId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersGet(organizationId: string, buildingId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<Array<Dossier>>;
    public dossiersGet(organizationId: string, buildingId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<Array<Dossier>>>;
    public dossiersGet(organizationId: string, buildingId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<Array<Dossier>>>;
    public dossiersGet(organizationId: string, buildingId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (organizationId === null || organizationId === undefined) {
            throw new Error('Required parameter organizationId was null or undefined when calling dossiersGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (organizationId !== undefined && organizationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>organizationId, 'organization_id');
        }
        if (buildingId !== undefined && buildingId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buildingId, 'building_id');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers`;
        return this.httpClient.request<Array<Dossier>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to delete a dossier by ID.
     * This endpoint deletes a dossier based on the specified ID.  # Parameters  - &#x60;id&#x60;: The unique ID of the dossier to delete.  # Returns  No content.  ## HTTP Status Codes  - 204 No Content: The dossier is successfully deleted. - 404 Not Found: The dossier with the specified ID does not exist. - 500 Internal Server Error: There was a server error while deleting the dossier.
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdDelete(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public dossiersIdDelete(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public dossiersIdDelete(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public dossiersIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GET endpoint to retrieve a document by its ID.
     * # Parameters  - &#x60;id&#x60;: The unique identifier of the dossier containing the document. - &#x60;document_id&#x60;: The unique identifier of the document to retrieve.  # Returns  Returns the binary data of the document if found, or a 404 Not Found error if the document does not exist.  # Errors  - If the requester does not have permission to access the dossier. - If there is an internal server error during the retrieval process.  # Example  &#x60;&#x60;&#x60;http GET /v1/123/document/456 HTTP/1.1 Authorization: Bearer &lt;token&gt; &#x60;&#x60;&#x60;
     * @param id
     * @param documentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdDocumentDocumentIdGet(id: string, documentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream', context?: HttpContext}): Observable<Blob>;
    public dossiersIdDocumentDocumentIdGet(id: string, documentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public dossiersIdDocumentDocumentIdGet(id: string, documentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public dossiersIdDocumentDocumentIdGet(id: string, documentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdDocumentDocumentIdGet.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling dossiersIdDocumentDocumentIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/document/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This endpoint fetches metadata for a specific document tied to a dossier.
     * Parameters: - &#x60;id&#x60;: The unique identifier for the dossier. - &#x60;document_id&#x60;: The unique identifier for the document. - &#x60;auth&#x60;: Authentication info to check if the user has the necessary permissions.  Process: - First, we check if the user has permission to get the dossier info. - Then, we try to get the document metadata from the repository. - If we find the document, we return its metadata in JSON format. - If the document isn\&#39;t found, we return a \&quot;not found\&quot; error. - If there\&#39;s any other error, we return an internal server error.
     * @param id
     * @param documentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdDocumentDocumentIdMetaGet(id: string, documentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<DossierDocument>;
    public dossiersIdDocumentDocumentIdMetaGet(id: string, documentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<DossierDocument>>;
    public dossiersIdDocumentDocumentIdMetaGet(id: string, documentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<DossierDocument>>;
    public dossiersIdDocumentDocumentIdMetaGet(id: string, documentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdDocumentDocumentIdMetaGet.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling dossiersIdDocumentDocumentIdMetaGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/document/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/meta`;
        return this.httpClient.request<DossierDocument>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates a presigned URL for retrieving document metadata.
     * # Parameters - &#x60;id&#x60;: The unique identifier for the resource. - &#x60;document_id&#x60;: The unique identifier for the document. - &#x60;auth&#x60;: The authentication object that validates the user\&#39;s permissions.  # Returns - &#x60;Result&lt;Json&lt;String&gt;&gt;&#x60;: A JSON containing the presigned URL string if successful, or an error.  # Errors - Returns an error if the user does not have the required permissions. - Returns an error if the document is not found. - Returns an error if there is an issue with the document repository or bucket repository.  # Permissions - The user must have the \&quot;dossiers\&quot; permission with &#x60;Scope::GET&#x60; access.
     * @param id
     * @param documentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdDocumentDocumentIdMetaPresignedlinkGet(id: string, documentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<string>;
    public dossiersIdDocumentDocumentIdMetaPresignedlinkGet(id: string, documentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<string>>;
    public dossiersIdDocumentDocumentIdMetaPresignedlinkGet(id: string, documentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<string>>;
    public dossiersIdDocumentDocumentIdMetaPresignedlinkGet(id: string, documentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdDocumentDocumentIdMetaPresignedlinkGet.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling dossiersIdDocumentDocumentIdMetaPresignedlinkGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/document/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/meta/presignedlink`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to upload a document to a dossier.
     * This endpoint allows users to upload a document to a dossier identified by its ID.  # Parameters  - &#x60;id&#x60;: The unique identifier of the dossier. - &#x60;image_post&#x60;: Data structure containing information about the document being uploaded. - &#x60;auth&#x60;: Authentication token to authorize the request.  # Returns  A JSON response containing information about the uploaded document.  ## HTTP Status Codes  - 201 Created: The document is successfully uploaded. - 401 Unauthorized: The user is not authorized to perform the operation. - 500 Internal Server Error: There was a server error during the upload process.
     * @param id
     * @param documentFile
     * @param description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdDocumentPost(id: string, documentFile: Blob, description?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<DossierDocument>;
    public dossiersIdDocumentPost(id: string, documentFile: Blob, description?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<DossierDocument>>;
    public dossiersIdDocumentPost(id: string, documentFile: Blob, description?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<DossierDocument>>;
    public dossiersIdDocumentPost(id: string, documentFile: Blob, description?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdDocumentPost.');
        }
        if (documentFile === null || documentFile === undefined) {
            throw new Error('Required parameter documentFile was null or undefined when calling dossiersIdDocumentPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (description !== undefined) {
            localVarFormParams = localVarFormParams.append('description', <any>description) as any || localVarFormParams;
        }
        if (documentFile !== undefined) {
            localVarFormParams = localVarFormParams.append('document_file', <any>documentFile) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/document`;
        return this.httpClient.request<DossierDocument>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a list of documents associated with a specific dossier.
     * # Parameters  - &#x60;id&#x60;: Path parameter representing the unique identifier of the dossier. - &#x60;content_type&#x60;: Optional query parameter specifying the content type of the documents to filter. - &#x60;auth&#x60;: Authentication information containing the user\&#39;s authorization details.  # Returns  A JSON response containing a list of dossier documents.  # Errors  This function may return an error if: - The user does not have permission to access dossier documents. - An internal server error occurs during the retrieval process.
     * @param id
     * @param contentType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdDocumentsGet(id: string, contentType?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<Array<DossierDocument>>;
    public dossiersIdDocumentsGet(id: string, contentType?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<Array<DossierDocument>>>;
    public dossiersIdDocumentsGet(id: string, contentType?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<Array<DossierDocument>>>;
    public dossiersIdDocumentsGet(id: string, contentType?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdDocumentsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (contentType !== undefined && contentType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contentType, 'content_type');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/documents`;
        return this.httpClient.request<Array<DossierDocument>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GET endpoint to retrieve a file by its ID.
     * # Parameters  - &#x60;id&#x60;: The unique identifier of the dossier containing the file. - &#x60;file_id&#x60;: The unique identifier of the file to retrieve.  # Returns  Returns the binary data of the file if found, or a 404 Not Found error if the file does not exist.  # Errors  - If the requester does not have permission to access the dossier. - If there is an internal server error during the retrieval process.  # Example  &#x60;&#x60;&#x60;http GET /v1/123/file/456 HTTP/1.1 Authorization: Bearer &lt;token&gt; &#x60;&#x60;&#x60;
     * @param id
     * @param fileId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdFileFileIdGet(id: string, fileId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream', context?: HttpContext}): Observable<Blob>;
    public dossiersIdFileFileIdGet(id: string, fileId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public dossiersIdFileFileIdGet(id: string, fileId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public dossiersIdFileFileIdGet(id: string, fileId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdFileFileIdGet.');
        }
        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling dossiersIdFileFileIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/file/${this.configuration.encodeParam({name: "fileId", value: fileId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This endpoint fetches metadata for a specific file tied to a dossier.
     * Parameters: - &#x60;id&#x60;: The unique identifier for the dossier. - &#x60;file_id&#x60;: The unique identifier for the file. - &#x60;auth&#x60;: Authentication info to check if the user has the necessary permissions.  Process: - First, we check if the user has permission to get the dossier info. - Then, we try to get the file metadata from the repository. - If we find the file, we return its metadata in JSON format. - If the file isn\&#39;t found, we return a \&quot;not found\&quot; error. - If there\&#39;s any other error, we return an internal server error.
     * @param id
     * @param fileId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdFileFileIdMetaGet(id: string, fileId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<DossierFile>;
    public dossiersIdFileFileIdMetaGet(id: string, fileId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<DossierFile>>;
    public dossiersIdFileFileIdMetaGet(id: string, fileId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<DossierFile>>;
    public dossiersIdFileFileIdMetaGet(id: string, fileId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdFileFileIdMetaGet.');
        }
        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling dossiersIdFileFileIdMetaGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/file/${this.configuration.encodeParam({name: "fileId", value: fileId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/meta`;
        return this.httpClient.request<DossierFile>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates a presigned URL for retrieving file metadata.
     * # Parameters - &#x60;id&#x60;: The unique identifier for the resource. - &#x60;file_id&#x60;: The unique identifier for the file. - &#x60;auth&#x60;: The authentication object that validates the user\&#39;s permissions.  # Returns - &#x60;Result&lt;Json&lt;String&gt;&gt;&#x60;: A JSON containing the presigned URL string if successful, or an error.  # Errors - Returns an error if the user does not have the required permissions. - Returns an error if the file is not found. - Returns an error if there is an issue with the file repository or bucket repository.  # Permissions - The user must have the \&quot;dossiers\&quot; permission with &#x60;Scope::GET&#x60; access.
     * @param id
     * @param fileId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdFileFileIdMetaPresignedlinkGet(id: string, fileId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<string>;
    public dossiersIdFileFileIdMetaPresignedlinkGet(id: string, fileId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<string>>;
    public dossiersIdFileFileIdMetaPresignedlinkGet(id: string, fileId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<string>>;
    public dossiersIdFileFileIdMetaPresignedlinkGet(id: string, fileId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdFileFileIdMetaPresignedlinkGet.');
        }
        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling dossiersIdFileFileIdMetaPresignedlinkGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/file/${this.configuration.encodeParam({name: "fileId", value: fileId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/meta/presignedlink`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to upload a file to a dossier.
     * This endpoint allows users to upload a file to a dossier identified by its ID.  # Parameters  - &#x60;id&#x60;: The unique identifier of the dossier. - &#x60;file_post&#x60;: Data structure containing information about the file being uploaded. - &#x60;auth&#x60;: Authentication token to authorize the request.  # Returns  A JSON response containing information about the uploaded file.  ## HTTP Status Codes  - 201 Created: The file is successfully uploaded. - 401 Unauthorized: The user is not authorized to perform the operation. - 500 Internal Server Error: There was a server error during the upload process.
     * @param id
     * @param file
     * @param description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdFilePost(id: string, file: Blob, description?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<DossierFile>;
    public dossiersIdFilePost(id: string, file: Blob, description?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<DossierFile>>;
    public dossiersIdFilePost(id: string, file: Blob, description?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<DossierFile>>;
    public dossiersIdFilePost(id: string, file: Blob, description?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdFilePost.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling dossiersIdFilePost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (description !== undefined) {
            localVarFormParams = localVarFormParams.append('description', <any>description) as any || localVarFormParams;
        }
        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/file`;
        return this.httpClient.request<DossierFile>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a list of files associated with a specific dossier.
     * # Parameters  - &#x60;id&#x60;: Path parameter representing the unique identifier of the dossier. - &#x60;content_type&#x60;: Optional query parameter specifying the content type of the files to filter. - &#x60;auth&#x60;: Authentication information containing the user\&#39;s authorization details.  # Returns  A JSON response containing a list of dossier files.  # Errors  This function may return an error if: - The user does not have permission to access dossier files. - An internal server error occurs during the retrieval process.
     * @param id
     * @param contentType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdFilesGet(id: string, contentType?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<Array<DossierFile>>;
    public dossiersIdFilesGet(id: string, contentType?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<Array<DossierFile>>>;
    public dossiersIdFilesGet(id: string, contentType?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<Array<DossierFile>>>;
    public dossiersIdFilesGet(id: string, contentType?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdFilesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (contentType !== undefined && contentType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contentType, 'content_type');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/files`;
        return this.httpClient.request<Array<DossierFile>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to retrieve a single dossier by ID.
     * This endpoint retrieves a single dossier based on the specified ID.  # Parameters  - &#x60;id&#x60;: The unique ID of the dossier to retrieve.  # Returns  The dossier object corresponding to the specified ID.  ## HTTP Status Codes  - 200 OK: The dossier is successfully retrieved. - 404 Not Found: The dossier with the specified ID does not exist. - 500 Internal Server Error: There was a server error while retrieving the dossier.
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdGet(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<Dossier>;
    public dossiersIdGet(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<Dossier>>;
    public dossiersIdGet(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<Dossier>>;
    public dossiersIdGet(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<Dossier>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GET endpoint to retrieve an image by its ID.
     * # Parameters  - &#x60;id&#x60;: The unique identifier of the dossier to which the image belongs. - &#x60;image_id&#x60;: The unique identifier of the image to retrieve.  # Returns  Returns the binary data of the image if found, or a 404 Not Found error if the image does not exist.  # Errors  - If the requester does not have permission to access the dossier. - If there is an internal server error during the retrieval process.  # Example  &#x60;&#x60;&#x60;http GET /v1/123/image/456 HTTP/1.1 Authorization: Bearer &lt;token&gt; &#x60;&#x60;&#x60;
     * @param id
     * @param imageId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdImageImageIdGet(id: string, imageId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream', context?: HttpContext}): Observable<Blob>;
    public dossiersIdImageImageIdGet(id: string, imageId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream', context?: HttpContext}): Observable<HttpResponse<Blob>>;
    public dossiersIdImageImageIdGet(id: string, imageId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream', context?: HttpContext}): Observable<HttpEvent<Blob>>;
    public dossiersIdImageImageIdGet(id: string, imageId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdImageImageIdGet.');
        }
        if (imageId === null || imageId === undefined) {
            throw new Error('Required parameter imageId was null or undefined when calling dossiersIdImageImageIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/image/${this.configuration.encodeParam({name: "imageId", value: imageId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This endpoint fetches metadata for a specific image tied to a dossier.
     * Parameters: - &#x60;id&#x60;: The unique identifier for the dossier. - &#x60;image_id&#x60;: The unique identifier for the image. - &#x60;auth&#x60;: Authentication info to check if the user has the necessary permissions.  Process: - First, we check if the user has permission to get the dossier info. - Then, we try to get the image metadata from the repository. - If we find the image, we return its metadata in JSON format. - If the image isn\&#39;t found, we return a \&quot;not found\&quot; error. - If there\&#39;s any other error, we return an internal server error.
     * @param id
     * @param imageId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdImageImageIdMetaGet(id: string, imageId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<DossierImage>;
    public dossiersIdImageImageIdMetaGet(id: string, imageId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<DossierImage>>;
    public dossiersIdImageImageIdMetaGet(id: string, imageId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<DossierImage>>;
    public dossiersIdImageImageIdMetaGet(id: string, imageId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdImageImageIdMetaGet.');
        }
        if (imageId === null || imageId === undefined) {
            throw new Error('Required parameter imageId was null or undefined when calling dossiersIdImageImageIdMetaGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/image/${this.configuration.encodeParam({name: "imageId", value: imageId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/meta`;
        return this.httpClient.request<DossierImage>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates a presigned URL for retrieving image metadata.
     * # Parameters - &#x60;id&#x60;: The unique identifier for the resource. - &#x60;image_id&#x60;: The unique identifier for the image. - &#x60;auth&#x60;: The authentication object that validates the user\&#39;s permissions.  # Returns - &#x60;Result&lt;Json&lt;String&gt;&gt;&#x60;: A JSON containing the presigned URL string if successful, or an error.  # Errors - Returns an error if the user does not have the required permissions. - Returns an error if the image is not found. - Returns an error if there is an issue with the image repository or bucket repository.  # Permissions - The user must have the \&quot;dossiers\&quot; permission with &#x60;Scope::GET&#x60; access.
     * @param id
     * @param imageId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdImageImageIdMetaPresignedlinkGet(id: string, imageId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<string>;
    public dossiersIdImageImageIdMetaPresignedlinkGet(id: string, imageId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<string>>;
    public dossiersIdImageImageIdMetaPresignedlinkGet(id: string, imageId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<string>>;
    public dossiersIdImageImageIdMetaPresignedlinkGet(id: string, imageId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdImageImageIdMetaPresignedlinkGet.');
        }
        if (imageId === null || imageId === undefined) {
            throw new Error('Required parameter imageId was null or undefined when calling dossiersIdImageImageIdMetaPresignedlinkGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/image/${this.configuration.encodeParam({name: "imageId", value: imageId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/meta/presignedlink`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to upload an image to a dossier.
     * This endpoint allows users to upload an image to a dossier identified by its ID.  # Parameters  - &#x60;id&#x60;: The unique identifier of the dossier. - &#x60;image_post&#x60;: Data structure containing information about the image being uploaded. - &#x60;auth&#x60;: Authentication token to authorize the request.  # Returns  A JSON response containing information about the uploaded image.  ## HTTP Status Codes  - 201 Created: The image is successfully uploaded. - 401 Unauthorized: The user is not authorized to perform the operation. - 500 Internal Server Error: There was a server error during the upload process.
     * @param id
     * @param imageFile
     * @param description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdImagePost(id: string, imageFile: Blob, description?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<DossierImage>;
    public dossiersIdImagePost(id: string, imageFile: Blob, description?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<DossierImage>>;
    public dossiersIdImagePost(id: string, imageFile: Blob, description?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<DossierImage>>;
    public dossiersIdImagePost(id: string, imageFile: Blob, description?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdImagePost.');
        }
        if (imageFile === null || imageFile === undefined) {
            throw new Error('Required parameter imageFile was null or undefined when calling dossiersIdImagePost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (description !== undefined) {
            localVarFormParams = localVarFormParams.append('description', <any>description) as any || localVarFormParams;
        }
        if (imageFile !== undefined) {
            localVarFormParams = localVarFormParams.append('image_file', <any>imageFile) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/image`;
        return this.httpClient.request<DossierImage>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a list of images associated with a specific dossier.
     * # Parameters  - &#x60;id&#x60;: Path parameter representing the unique identifier of the dossier. - &#x60;content_type&#x60;: Optional query parameter specifying the content type of the images to filter. - &#x60;auth&#x60;: Authentication information containing the user\&#39;s authorization details.  # Returns  A JSON response containing a list of dossier images.  # Errors  This function may return an error if: - The user does not have permission to access dossier images. - An internal server error occurs during the retrieval process.
     * @param id
     * @param contentType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersIdImagesGet(id: string, contentType?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<Array<DossierImage>>;
    public dossiersIdImagesGet(id: string, contentType?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<Array<DossierImage>>>;
    public dossiersIdImagesGet(id: string, contentType?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<Array<DossierImage>>>;
    public dossiersIdImagesGet(id: string, contentType?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dossiersIdImagesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (contentType !== undefined && contentType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contentType, 'content_type');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/images`;
        return this.httpClient.request<Array<DossierImage>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to create a new dossier.
     * This endpoint creates a new dossier based on the provided request payload.  # Parameters  - &#x60;dossier&#x60;: The JSON payload containing information to create the dossier.  # Returns  The newly created dossier object.  ## HTTP Status Codes  - 201 Created: The dossier is successfully created. - 400 Bad Request: A dossier for the specified building already exists. - 500 Internal Server Error: There was a server error while creating the dossier.
     * @param createDossierRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersPost(createDossierRequest: CreateDossierRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<Dossier>;
    public dossiersPost(createDossierRequest: CreateDossierRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<Dossier>>;
    public dossiersPost(createDossierRequest: CreateDossierRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<Dossier>>;
    public dossiersPost(createDossierRequest: CreateDossierRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (createDossierRequest === null || createDossierRequest === undefined) {
            throw new Error('Required parameter createDossierRequest was null or undefined when calling dossiersPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dossiers`;
        return this.httpClient.request<Dossier>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createDossierRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
