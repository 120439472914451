import { Component } from '@angular/core';
import {
  MeasureTemplateListItem,
  MeasureTemplateSortColumn,
  MeasureTemplatesService,
  ProjectSortColumn,
  SortOrder,
} from '@api-clients/project';
import { Router } from '@angular/router';
import { Scope } from '../../../scopes';
import { Resource } from '../../../resources';
import { getEnumValue } from '../../../building-module/utils';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { AuthorizedViewComponent } from '../../../auth/authorized-view/authorized-view.component';
import { FormsModule } from '@angular/forms';
import { TableFilterComponent } from '@shared/components/table-filter/table-filter.component';
import { NgFor } from '@angular/common';
import { TablePaginationComponent } from '@shared/components/table-pagination/table-pagination.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-measure-template-overview',
  templateUrl: './measure-template-overview.component.html',
  styleUrl: './measure-template-overview.component.scss',
  imports: [
    BreadcrumbComponent,
    AuthorizedViewComponent,
    FormsModule,
    TableFilterComponent,
    NgFor,
    TablePaginationComponent,
    ConfirmationDialogComponent,
    TranslateModule,
  ],
})
export class MeasureTemplateOverviewComponent {
  protected defaultPageSize: number = 10;
  protected sortBy: MeasureTemplateSortColumn = MeasureTemplateSortColumn.Name;
  protected sortOrder: SortOrder = SortOrder.Asc;
  protected searchText: string = '';
  protected filteredData: MeasureTemplateListItem[] = [];
  protected totalCount: number = 0;
  protected page: number = 1;
  protected pageSize: number = this.defaultPageSize;
  private selectedMeasureTemplateId?: string;

  // column data is added in html through a loop.
  protected columns: { key: MeasureTemplateSortColumn | undefined; label: string }[] = [
    { key: MeasureTemplateSortColumn.Name, label: 'name' },
    { key: MeasureTemplateSortColumn.Description, label: 'description' },
    { key: MeasureTemplateSortColumn.CreationTimestampUtc, label: 'creation-timestamp' },
    { key: MeasureTemplateSortColumn.LastModifiedTimestampUtc, label: 'last-modified-timestamp' },
    { key: undefined, label: 'remove' },
  ];

  protected readonly SortOrder = SortOrder;

  constructor(
    private readonly router: Router,
    private readonly measureTemplatesService: MeasureTemplatesService,
    private readonly confirmationDialogService: ConfirmationDialogService
  ) {
    this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
    this.page = 1;
    this.pageSize = this.defaultPageSize;

    const sort_by = getEnumValue(
      ProjectSortColumn,
      localStorage.getItem('measure-template-overview-sort-by')
    );
    this.sortBy = sort_by ?? MeasureTemplateSortColumn.Name;
    const sort_order = getEnumValue(
      SortOrder,
      localStorage.getItem('measure-template-overview-sort-order')
    );
    this.sortOrder = sort_order || SortOrder.Asc;

    this.load();
  }

  load(): void {
    this.measureTemplatesService
      .templatesGet(this.page - 1, this.pageSize, this.sortBy, this.sortOrder, this.searchText)
      .subscribe((data) => {
        this.filteredData = data.items;
        this.totalCount = data.count;
      });
  }

  onPageChange(event: { page: number; pageSize: number }): void {
    this.page = event.page;
    this.pageSize = event.pageSize;
    this.load();
  }

  toggleSort(property: MeasureTemplateSortColumn | undefined): void {
    if (this.sortBy === property) {
      this.sortOrder = this.sortOrder == SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
    } else {
      // reset to ascending if a different header is clicked
      this.sortBy = property ?? MeasureTemplateSortColumn.Name;
      this.sortOrder = SortOrder.Asc;
    }
    localStorage.setItem('measure-template-overview-sort-by', this.sortBy);
    localStorage.setItem('measure-template-overview-sort-order', this.sortOrder);
    this.load();
  }

  openDetail(item: MeasureTemplateListItem): void {
    void this.router.navigate(['measure-template', item.id]);
  }

  search(): void {
    this.load();
  }

  createMeasureTemplate(): void {
    void this.router.navigate(['measure-template', 'new']);
  }

  measureTemplateToRemovePopup(event: MouseEvent, measureTemplateId?: string): void {
    event.preventDefault();
    event.stopPropagation();
    if (!measureTemplateId) return;
    this.selectedMeasureTemplateId = measureTemplateId;
    this.confirmationDialogService.open();
  }

  removeMeasureTemplate(): void {
    if (!this.selectedMeasureTemplateId) return;
    this.measureTemplatesService.templatesIdDelete(this.selectedMeasureTemplateId).subscribe(() => {
      this.load();
    });
  }

  protected readonly Scope = Scope;
  protected readonly Resource = Resource;
}
