export enum Resource {
  Dossiers = 'dossiers',
  TimelineEvents = 'timeline_events',
  ConstructionReports = 'construction_reports',
  Inspections = 'inspections',
  InspectionTemplates = 'inspection_templates',
  Notes = 'notes',
  RoomPlans = 'room_plans',
  BuildingInformationModels = 'building_information_models',
  Buildings = 'buildings',
  AllOrganizations = 'all_organizations',
  AllUsers = 'all_users',
  OwnOrganization = 'own_organization',
  OwnOrganizationUsers = 'own_organization_users',
  Projects = 'projects',
  MeasureTemplates = 'measure_templates',
}
