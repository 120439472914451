import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from '@api-clients/user';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { lastValueFrom } from 'rxjs';
import { PermissionService } from '../../../auth/permission-service';
import { ToastrService } from '@shared/services/toastr.service';
import { AddressAutocompleteService } from '@services/address-autocomplete-service';
import { AddressGroup } from '../../components/address-detail/address-detail.component';
import { Resource } from '../../../resources';
import { Scope } from '../../../scopes';

export interface OrganizationForm {
  id: FormControl<string | undefined>;
  name: FormControl<string>;
  phoneNumber: FormControl<string | undefined | null>;
  emailAddress: FormControl<string | undefined | null>;
  address: FormGroup<AddressGroup | null>;
}

@Component({
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.scss'],
})
export class OrganizationDetailComponent implements OnInit {
  public organizationDetailForm: FormGroup<OrganizationForm>;
  public addressDetailForm: FormGroup<AddressGroup>;

  breadcrumbTree = [
    {
      translate: 'organization-overview-page',
      link: '/organizations',
    },
    { translate: 'organization-detail-page', link: this.router.url },
  ];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly organizationService: OrganizationService,
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly permissionService: PermissionService,
    private readonly router: Router,
    private readonly toastrService: ToastrService,
    private readonly zipcodeService: AddressAutocompleteService,
    private readonly formBuilder: FormBuilder
  ) {
    this.addressDetailForm = this.formBuilder.group<AddressGroup>(<AddressGroup>{
      streetName: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      postalCode: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      number: new FormControl('', Validators.required),
    });

    this.organizationDetailForm = this.formBuilder.group<OrganizationForm>(<OrganizationForm>{
      id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
      name: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      phoneNumber: new FormControl('', [Validators.pattern(/^\+?\d+$/)]),
      emailAddress: new FormControl('', { validators: [Validators.email, Validators.required] }),
      address: this.addressDetailForm as FormGroup<AddressGroup | null>,
    });
  }

  public canDelete: boolean = false;
  public canUpdate: boolean = false;

  async ngOnInit(): Promise<void> {
    this.canDelete = this.permissionService.userHasPermissionWithScope(
      Resource.AllOrganizations,
      Scope.DELETE
    );
    this.canUpdate =
      this.permissionService.userHasPermissionWithScope(Resource.AllOrganizations, Scope.UPDATE) ||
      this.permissionService.userHasPermissionWithScope(Resource.OwnOrganization, Scope.UPDATE);

    if (!this.canUpdate) {
      this.organizationDetailForm.disable();
    }

    const id = this.route.snapshot.paramMap.get('id');
    if (!id) return; // Creating new organization, don't fetch organization data

    const organization = await lastValueFrom(this.organizationService.get(id));
    // Ugly hack. Can be fixed by improving Users OpenAPI spec.
    // Specifically: address in OrganizationDtoBase should be nonNullable{
    const { address, ...org } = organization;
    org['address'] = address ?? undefined;
    // }
    this.organizationDetailForm.patchValue(org);
  }

  organizationToRemovePopup(): void {
    this.confirmationDialogService.open();
  }

  removeOrganization(): void {
    if (this.organizationDetailForm.value.id === undefined)
      throw new Error('OrganizationId is undefined');
    this.organizationService
      ._delete(this.organizationDetailForm.value.id as string)
      .subscribe(() => this.finished());
  }

  private finished(): Promise<boolean> {
    this.toastrService.showSuccess(
      'users-module.organization.save-success',
      'users-module.organization.save-title'
    );
    if (this.permissionService.userHasPermissionWithScope(Resource.AllOrganizations, Scope.READ)) {
      return this.router.navigate(['organizations']);
    } else {
      // stay on this page, do nothing
      return Promise.resolve(true);
    }
  }

  isSaving: boolean = false;

  async updateOrganization(): Promise<void> {
    if (!this.organizationDetailForm.valid) return;
    if (this.isSaving) return;

    this.isSaving = true;

    const organization = this.organizationDetailForm.getRawValue();

    if (organization.phoneNumber === '') organization.phoneNumber = null;
    if (organization.emailAddress === '') organization.emailAddress = null;

    const next = (): Promise<boolean> => {
      this.isSaving = false;
      return this.finished();
    };

    // If we have an ID, we are updating an organization. If we have no ID, we are creating a new one.
    if (organization.id) {
      this.organizationService.put(organization.id, organization).subscribe(next);
    } else {
      this.organizationService.post(organization).subscribe(next);
    }
  }
}
