import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { ViewerComponent } from '@shared/components/viewer/viewer.component';
import { ListViewComponent } from './list-view/list-view.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss'],
  imports: [NgIf, ViewerComponent, ListViewComponent, TranslateModule],
})
export class HomeComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  get listView(): boolean {
    return this.route.snapshot.queryParamMap.get('list') === 'true';
  }

  changeView(): void {
    void this.router.navigate([], {
      queryParams: { list: !this.listView },
      queryParamsHandling: 'merge',
    });
  }
}
