import * as clipperLib from 'js-angusj-clipper/web';
import { Feature } from './features';
import { BoundingBox } from './bounding-box';
import {
  IndicesBuildingObject,
  MeshBuildingObject,
  NoGeomBuildingObject,
  Room,
} from '@shared/services/assets/building-elements';
import { Buffers } from '@shared/components/floor-plan/floorplan/buffers';
import { ObjectCategory, RoomCategory } from '@api-clients/bim';

export type bbox = { x1: number; y1: number; x2: number; y2: number };
export type BatchIdToFaceIndices = Map<number, number[]>[];

/**
 * Set the polygons to a string that can be read by svg
 * @param polygons
 */
export function toSvgPoints(polygons: { x: number; y: number }[]): string {
  let result = '';
  for (const polygon in polygons) {
    result += polygons[polygon].x + ',' + polygons[polygon].y + ' ';
  }
  return result;
}

export function triangleFrontFacing(polygon: { x: number; y: number }[]): boolean {
  const a = polygon[0];
  const b = polygon[1];
  const c = polygon[2];

  return (c.x - a.x) * (b.y - a.y) - (b.x - a.x) * (c.y - a.y) > 10;
}

export function stairsToPolygons(
  object: ObjectBuffer,
  clipper: clipperLib.ClipperLibWrapper,
  scale: number
): Feature[] {
  const stairSteps = new Array<{ height: number; triangles: { x: number; y: number }[][] }>();

  for (let faceIndex = 0; faceIndex * 3 < object.faceIndexBuffer.length; faceIndex++) {
    const v1 = (object.faceIndexBuffer[faceIndex * 3] - object.positionBufferOffset) * 3;
    const v2 = (object.faceIndexBuffer[faceIndex * 3 + 1] - object.positionBufferOffset) * 3;
    const v3 = (object.faceIndexBuffer[faceIndex * 3 + 2] - object.positionBufferOffset) * 3;

    const h1 = object.positionBuffer[v1 + 1]; // v1 height
    const h2 = object.positionBuffer[v2 + 1]; // v2 height
    const h3 = object.positionBuffer[v3 + 1]; // v3 height

    let height: number;
    if (Math.abs(h1 - h2) > 3e-3 || Math.abs(h2 - h3) > 3e-3 || Math.abs(h1 - h3) > 3e-3)
      height = -1; // If triangle is not flat, it's not part of a stair step. Handle in 'rest' group
    else height = (h1 + h2 + h3) / 3; // average height of the triangle

    // Create polygons in micrometers
    const triangle = triangleFromBuffer(object.positionBuffer, v1, v2, v3, scale);
    if (!triangleFrontFacing(triangle)) continue;

    // Find a stair step that is close to the height of the triangle
    const stairStep = stairSteps.find((t) => Math.abs(t.height - height) < 0.1 /*10cm*/);
    if (stairStep) {
      stairStep.triangles.push(triangle);
    } else {
      // Make a new step
      stairSteps.push({ height, triangles: [triangle] });
    }
  }
  if (stairSteps.length == 0) return [];

  const mergedPolygons = stairSteps.flatMap(({ triangles }) =>
    clipper.clipToPaths({
      clipType: clipperLib.ClipType.Union,
      subjectInputs: [{ data: triangles[0], closed: true }],
      clipInputs: [{ data: triangles.slice(1) }],
      subjectFillType: clipperLib.PolyFillType.NonZero,
      clipFillType: clipperLib.PolyFillType.NonZero,
      cleanDistance: 2,
    })
  );

  // Scale the vertices to millimeters
  for (const vertex of mergedPolygons.flat(2)) {
    vertex.x = (vertex.x * 1000) / scale;
    vertex.y = (vertex.y * 1000) / scale;
  }

  const boundingBox = BoundingBox.fromPoly(mergedPolygons.flat());
  const width = boundingBox.x2 - boundingBox.x1;
  const height = boundingBox.y2 - boundingBox.y1;
  return mergedPolygons.map((polygon) => ({
    polygon: polygon,
    points: toSvgPoints(polygon),
    bimId: object.bimId,
    bbox: boundingBox,
    width,
    height,
  }));
}

// Todo: running this in a webworker can improve performance
export function objectToPolygons(
  object: ObjectBuffer,
  clipper: clipperLib.ClipperLibWrapper,
  scale: number
): Feature[] {
  const triangles: { x: number; y: number }[][] = [];
  for (let faceIndex = 0; faceIndex * 3 < object.faceIndexBuffer.length; faceIndex++) {
    const v1 = (object.faceIndexBuffer[faceIndex * 3] - object.positionBufferOffset) * 3;
    const v2 = (object.faceIndexBuffer[faceIndex * 3 + 1] - object.positionBufferOffset) * 3;
    const v3 = (object.faceIndexBuffer[faceIndex * 3 + 2] - object.positionBufferOffset) * 3;
    const triangle = triangleFromBuffer(object.positionBuffer, v1, v2, v3, scale);
    if (triangleFrontFacing(triangle)) triangles.push(triangle);
  }
  if (triangles.length == 0) {
    return [];
  }
  const mergedPolygons = clipper.clipToPaths({
    clipType: clipperLib.ClipType.Union,
    subjectInputs: [{ data: triangles[0], closed: true }],
    clipInputs: [{ data: triangles.slice(1) }],
    subjectFillType: clipperLib.PolyFillType.NonZero,
    cleanDistance: 2,
  });

  // Scale the vertices to millimeters
  for (const vertex of mergedPolygons.flat(2)) {
    vertex.x = (vertex.x / scale) * 1000;
    vertex.y = (vertex.y / scale) * 1000;
  }

  const bbox = BoundingBox.fromPoly(mergedPolygons.flat(2));
  const width = bbox.x2 - bbox.x1;
  const height = bbox.y2 - bbox.y1;

  return mergedPolygons
    .filter((polygon) => polygon.length)
    .map((polygon) => ({
      polygon,
      points: toSvgPoints(polygon),
      bimId: object.bimId,
      bbox,
      width,
      height,
    }));
}

export function calculateParametersForSimpleFeature(feature: Feature): {
  angle: number;
  width: number;
  height: number;
} {
  let maxDistance = 0;
  let maxDistancePoint = 0;
  for (let i = 0; i < feature.polygon.length; i++) {
    const p1 = feature.polygon[i];
    const p2 = feature.polygon[(i + 1) % feature.polygon.length];
    const distance = Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
    if (distance < Number.MIN_VALUE) {
      continue;
    }
    if (distance > maxDistance) {
      maxDistance = distance;
      maxDistancePoint = i;
    }
  }
  const p1 = feature.polygon[maxDistancePoint];
  const p2 = feature.polygon[(maxDistancePoint + 1) % feature.polygon.length];
  const angle = (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
  const width = maxDistance;
  const directionVector = { x: p1.x - p2.x, y: p1.y - p2.y };
  const orthogonalVector = {
    x: -directionVector.y / maxDistance,
    y: directionVector.x / maxDistance,
  };
  const lengthInOrthogonal = feature.polygon.map(
    (point) => point.x * orthogonalVector.x + point.y * orthogonalVector.y
  );
  const minOrthogonal = Math.min(...lengthInOrthogonal);
  const maxOrthogonal = Math.max(...lengthInOrthogonal);
  const height = maxOrthogonal - minOrthogonal;
  return { angle, width, height };
}

function triangleFromBuffer(
  positionBuffer: Float32Array,
  v1: number,
  v2: number,
  v3: number,
  scale: number
): { x: number; y: number }[] {
  return [
    { x: Math.round(positionBuffer[v1] * scale), y: Math.round(positionBuffer[v1 + 2] * scale) }, // vertex 1 x, z
    { x: Math.round(positionBuffer[v2] * scale), y: Math.round(positionBuffer[v2 + 2] * scale) }, // vertex 2 x, z
    { x: Math.round(positionBuffer[v3] * scale), y: Math.round(positionBuffer[v3 + 2] * scale) }, // vertex 3 x, z
  ];
}

export interface ObjectBuffer {
  category?: ObjectCategory | RoomCategory;
  bimId: string;
  faceIndexBuffer: Uint32Array;
  positionBuffer: Float32Array;
  positionBufferOffset: number;
}

export function objectOrRoomToBuffer(
  object: NoGeomBuildingObject | Room,
  buffers: Buffers[],
  batchIdToFaceIndices: BatchIdToFaceIndices
): ObjectBuffer | undefined {
  if (
    (object instanceof IndicesBuildingObject || object instanceof Room) &&
    object.indices &&
    object.batchId
  ) {
    const meshIndex = object.indices[0];
    const faceIndices = batchIdToFaceIndices[meshIndex].get(object.batchId);
    if (!faceIndices) {
      throw new Error(
        'Unreachable. If indices are set we should have faceIndices for object with batchId: ' +
          object.batchId
      );
    }
    const buffer = buffers[meshIndex];
    const faceIndexBuffer = buffer.indexBuffer.slice(
      faceIndices[0] * 3,
      faceIndices.at(-1)! * 3 + 3
    );
    const positionBuffer = buffer.positionBuffer.slice(
      object.indices[1][0] * 3,
      object.indices[1][1] * 3 + 3
    );
    const category = object instanceof Room ? object.category : object.category?.type;
    return <ObjectBuffer>{
      category,
      bimId: object.bimId,
      faceIndexBuffer,
      positionBuffer,
      positionBufferOffset: object.indices[1][0],
    };
  }
  if (object instanceof MeshBuildingObject) {
    console.warn('Parsing meshBuildingObject not implemented');
    return undefined;
  } /* object with no geometry */ else {
    return undefined;
  }
}
