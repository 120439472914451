<div class="inspection-detail">
  <div class="inspection-detail__container" *ngIf="inspection">
    <app-breadcrumb></app-breadcrumb>
    <div class="intro">
      <h1 *ngIf="!edit">{{ inspection.description }}</h1>
      <p [innerHTML]="address"></p>

      <input
        class="h1 title-input"
        type="text"
        name="description"
        autocomplete="off"
        [(ngModel)]="inspection.description"
        placeholder="{{ 'dossier-module.inspection-template-title' | translate }}"
        *ngIf="edit" />

      <!--suppress JSIncompatibleTypesComparison -->
      <app-lightswitch
        [label]="'edit' | translate"
        [checked]="edit"
        *ngIf="inspection.finished_time_utc === null"
        (changedState)="changeSwitch(edit)"></app-lightswitch>

      <div class="inspection-detail__container__buttons">
        <button
          (click)="saveAndFinish()"
          *ngIf="!inspection.finished_time_utc"
          [disabled]="isSaving"
          class="button-primary">
          <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
          {{ 'save-and-finish' | translate }}
        </button>
        <button
          (click)="save()"
          *ngIf="!inspection.finished_time_utc"
          [disabled]="isSaving"
          class="button-primary">
          <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
          {{ 'save' | translate }}
        </button>
      </div>
    </div>
    <div cdkDropList cdkDropListGroup (cdkDropListDropped)="itemDropped($event)" class="item-list">
      <!--suppress JSIncompatibleTypesComparison -->
      <app-inspection-section
        *ngFor="let section of inspection.content.sections; let i = index"
        (click)="activateSection(section)"
        [section]="section"
        [edit]="edit"
        [active]="inspectionService.getCurrentSection() === section"
        [disabled]="inspection.finished_time_utc !== null"
        [inspection]="inspection"
        [sectionIndex]="i"
        (openImage)="openImage($event)"
        (deleteSection)="deleteSection(section)"></app-inspection-section>
    </div>

    <app-inspection-toolbox
      [edit]="edit"
      [currentSection]="inspectionService.getCurrentSection()"></app-inspection-toolbox>
  </div>
</div>

<app-file-preview-popup
  [fileUrl]="selectedFileUrl"
  [active]="selectedFile !== undefined"
  [fileSize]="0"
  [fileName]="selectedFile?.file_name"
  (close)="selectedFile = undefined"></app-file-preview-popup>
