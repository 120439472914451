/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateUpdateScenarioRequest } from '../model/createUpdateScenarioRequest';
// @ts-ignore
import { ScenarioDto } from '../model/scenarioDto';
// @ts-ignore
import { ScenarioListResponse } from '../model/scenarioListResponse';
// @ts-ignore
import { ScenarioSortColumn } from '../model/scenarioSortColumn';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ScenariosService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scenariosDefaultGet(projectId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<ScenarioDto>;
    public scenariosDefaultGet(projectId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<ScenarioDto>>;
    public scenariosDefaultGet(projectId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<ScenarioDto>>;
    public scenariosDefaultGet(projectId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling scenariosDefaultGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (projectId !== undefined && projectId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>projectId, 'project_id');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/scenarios/default`;
        return this.httpClient.request<ScenarioDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a list of scenarios based on the provided query parameters.
     * # Query Parameters - &#x60;project_id&#x60; (optional): The unique identifier of the project containing the scenarios. - &#x60;filter_text&#x60; (optional): A string to filter scenarios by name or other searchable fields. - &#x60;page_index&#x60;: The index of the page to retrieve (starting from 0). - &#x60;page_size&#x60;: The number of scenarios to include per page. - &#x60;sort_column&#x60;: The column to sort the scenarios by. Possible values are defined by &#x60;ScenarioSortColumn&#x60;. - &#x60;sort_order&#x60;: The order in which to sort the scenarios. Possible values are &#x60;Asc&#x60; or &#x60;Desc&#x60;. # Responses: - **200 OK:** Returns a list of scenarios based on the provided query parameters. # Error Responses: - **500 Internal Server Error:** If there is a server error during the retrieval process. # Example Response Body (200 OK): &#x60;&#x60;&#x60;json [{ \&quot;id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;project_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;creation_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;last_modified_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;name\&quot;: \&quot;Project Name\&quot;, \&quot;description\&quot;: \&quot;Project Description\&quot; }] &#x60;&#x60;&#x60;
     * @param pageIndex 
     * @param pageSize 
     * @param sortColumn 
     * @param sortOrder 
     * @param projectId 
     * @param filterText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scenariosGet(pageIndex: number, pageSize: number, sortColumn: ScenarioSortColumn, sortOrder: SortOrder, projectId?: string, filterText?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<ScenarioListResponse>;
    public scenariosGet(pageIndex: number, pageSize: number, sortColumn: ScenarioSortColumn, sortOrder: SortOrder, projectId?: string, filterText?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<ScenarioListResponse>>;
    public scenariosGet(pageIndex: number, pageSize: number, sortColumn: ScenarioSortColumn, sortOrder: SortOrder, projectId?: string, filterText?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<ScenarioListResponse>>;
    public scenariosGet(pageIndex: number, pageSize: number, sortColumn: ScenarioSortColumn, sortOrder: SortOrder, projectId?: string, filterText?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (pageIndex === null || pageIndex === undefined) {
            throw new Error('Required parameter pageIndex was null or undefined when calling scenariosGet.');
        }
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling scenariosGet.');
        }
        if (sortColumn === null || sortColumn === undefined) {
            throw new Error('Required parameter sortColumn was null or undefined when calling scenariosGet.');
        }
        if (sortOrder === null || sortOrder === undefined) {
            throw new Error('Required parameter sortOrder was null or undefined when calling scenariosGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (projectId !== undefined && projectId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>projectId, 'project_id');
        }
        if (filterText !== undefined && filterText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterText, 'filter_text');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'page_index');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (sortColumn !== undefined && sortColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortColumn, 'sort_column');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sort_order');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/scenarios`;
        return this.httpClient.request<ScenarioListResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a scenario.
     * # Path Parameters - &#x60;id&#x60;: Unique identifier of the scenario to delete. # Responses: - **204 No Content:** If the scenario was successfully deleted. # Error Responses: - **500 Internal Server Error:** If there is a server error during the deletion process.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scenariosIdDelete(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public scenariosIdDelete(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public scenariosIdDelete(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public scenariosIdDelete(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/scenarios/`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a specific scenario by ID.
     * # Path Parameters - &#x60;id&#x60;: Unique identifier of the scenario to retrieve. # Responses: - **200 OK:** Returns the requested scenario. # Error Responses: - **404 Not Found:** If the specified scenario cannot be found. - **500 Internal Server Error:** If there is a server error during the retrieval process. # Example Response Body (200 OK): &#x60;&#x60;&#x60;json { \&quot;id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;project_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;creation_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;last_modified_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;name\&quot;: \&quot;Scenario Name\&quot;, \&quot;description\&quot;: \&quot;Scenario Description\&quot; } &#x60;&#x60;&#x60;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scenariosIdGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<ScenarioDto>;
    public scenariosIdGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<ScenarioDto>>;
    public scenariosIdGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<ScenarioDto>>;
    public scenariosIdGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/scenarios/`;
        return this.httpClient.request<ScenarioDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing scenario.
     * # Path Parameters - &#x60;id&#x60;: Unique identifier of the scenario to update. # Responses: - **200 OK:** Returns the updated scenario. # Error Responses: - **500 Internal Server Error:** If there is a server error during the update process. # Example Request Body: &#x60;&#x60;&#x60;json { \&quot;project_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;name\&quot;: \&quot;Scenario Name\&quot;, \&quot;description\&quot;: \&quot;Scenario Description\&quot; } &#x60;&#x60;&#x60; # Example Response Body (200 OK): &#x60;&#x60;&#x60;json { \&quot;id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;project_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;creation_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;last_modified_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;name\&quot;: \&quot;Scenario Name\&quot;, \&quot;description\&quot;: \&quot;Scenario Description\&quot; } &#x60;&#x60;&#x60;
     * @param createUpdateScenarioRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scenariosIdPut(createUpdateScenarioRequest: CreateUpdateScenarioRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<ScenarioDto>;
    public scenariosIdPut(createUpdateScenarioRequest: CreateUpdateScenarioRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<ScenarioDto>>;
    public scenariosIdPut(createUpdateScenarioRequest: CreateUpdateScenarioRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<ScenarioDto>>;
    public scenariosIdPut(createUpdateScenarioRequest: CreateUpdateScenarioRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (createUpdateScenarioRequest === null || createUpdateScenarioRequest === undefined) {
            throw new Error('Required parameter createUpdateScenarioRequest was null or undefined when calling scenariosIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/scenarios/`;
        return this.httpClient.request<ScenarioDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createUpdateScenarioRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new scenario for a project.
     * # Responses: - **201 Created:** Returns the created scenario. # Error Responses: - **500 Internal Server Error:** If there is a server error during the creation process. # Example Request Body: &#x60;&#x60;&#x60;json { \&quot;project_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;name\&quot;: \&quot;Scenario Name\&quot;, \&quot;description\&quot;: \&quot;Scenario Description\&quot; } &#x60;&#x60;&#x60; # Example Response Body (201 Created): &#x60;&#x60;&#x60;json { \&quot;id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;project_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;creation_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;last_modified_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;name\&quot;: \&quot;Scenario Name\&quot;, \&quot;description\&quot;: \&quot;Scenario Description\&quot; } &#x60;&#x60;&#x60;
     * @param createUpdateScenarioRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scenariosPost(createUpdateScenarioRequest: CreateUpdateScenarioRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<ScenarioDto>;
    public scenariosPost(createUpdateScenarioRequest: CreateUpdateScenarioRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<ScenarioDto>>;
    public scenariosPost(createUpdateScenarioRequest: CreateUpdateScenarioRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<ScenarioDto>>;
    public scenariosPost(createUpdateScenarioRequest: CreateUpdateScenarioRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (createUpdateScenarioRequest === null || createUpdateScenarioRequest === undefined) {
            throw new Error('Required parameter createUpdateScenarioRequest was null or undefined when calling scenariosPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/scenarios`;
        return this.httpClient.request<ScenarioDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createUpdateScenarioRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
