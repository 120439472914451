<div class="map-management">
  <div class="map-management__select">
    <input
      type="file"
      #fileInput
      (click)="$event.stopPropagation()"
      style="display: none"
      accept=".pmtiles,.geojson"
      (change)="fileInputChanged($event)" />

    <button #upload (click)="uploadFile()" class="button-primary button--has-icon">
      <span class="material-symbols-rounded">map</span>
      {{ 'add' | translate }}
    </button>

    <mat-selection-list
      [multiple]="false"
      [disabled]="mapForm.dirty"
      (selectionChange)="select($event.options[0].value)">
      @for (layer of layers; track layer) {
        <mat-list-option [value]="layer" [selected]="layer === selectedLayer" [color]="'primary'">
          {{ layer.description }}
        </mat-list-option>
      }
    </mat-selection-list>
  </div>
  <div class="map-management__details">
    <form [formGroup]="mapForm" (submit)="save()" class="form" *ngIf="selectedLayer">
      <div class="form-group">
        <label for="description">{{ 'description' | translate }}</label>
        <input id="description" type="text" formControlName="description" />
        <div class="error" *ngIf="mapForm.controls.description.errors?.['required']">
          {{ 'description-required-error' | translate }}
        </div>
      </div>

      <div class="form-group">
        <label for="layer_type">{{ 'layer_type' | translate }}</label>
        <select id="layer_type" type="text" formControlName="layer_type">
          <option [value]="undefined">{{ 'unknown' | translate }}</option>
          <option value="line">{{ 'line' | translate }}</option>
          <option value="circle">{{ 'circle' | translate }}</option>
          <option value="fill">{{ 'fill' | translate }}</option>
        </select>
        <div class="error" *ngIf="mapForm.controls.layer_type.errors?.['required']">
          {{ 'layer_type-required-error' | translate }}
        </div>
      </div>

      <div class="form-group">
        <label for="color">{{ 'color' | translate }}</label>

        <app-color-picker
          id="color"
          [color]="mapForm.controls.color.value ?? '#ff0000'"
          (colorChange)="setColor($event)"
          [description]="'color' | translate"></app-color-picker>

        <div class="error" *ngIf="mapForm.controls.color.errors?.['required']">
          {{ 'color-required-error' | translate }}
        </div>
      </div>

      <button
        *ngIf="selectedLayer"
        type="button"
        class="button-danger button--has-icon"
        (click)="remove(selectedLayer)">
        <span class="material-symbols-rounded">delete</span>
        {{ 'remove' | translate }}
      </button>

      <button
        [disabled]="!mapForm.dirty"
        type="button"
        class="button cancel-button"
        (click)="cancel()">
        {{ 'cancel' | translate }}
      </button>

      <button type="submit" class="button-primary">
        <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
        {{ 'save' | translate }}
      </button>
    </form>
  </div>
</div>
