<div class="geometry-edit">
  <div
    class="big-input"
    [class.has-change]="originalBox !== undefined && originalHeight !== height">
    <label for="length">{{ 'length' | translate }}</label>
    <input
      (change)="fireBoxChange()"
      [disabled]="disabled"
      [(ngModel)]="height"
      id="height"
      step="0.01"
      type="number" />

    <label class="units" for="height">m</label>
  </div>
  <div class="big-input" [class.has-change]="originalBox !== undefined && originalWidth !== width">
    <label for="width">{{ 'width' | translate }}</label>
    <input
      (change)="fireBoxChange()"
      [(ngModel)]="width"
      [disabled]="disabled"
      id="width"
      step="0.01"
      type="number" />

    <label class="units" for="width">m</label>
  </div>
  <div
    class="big-input"
    [class.has-change]="originalBox !== undefined && originalLength !== length">
    <label for="height">{{ 'height' | translate }}</label>
    <input
      (change)="fireBoxChange()"
      [(ngModel)]="length"
      [disabled]="disabled"
      id="length"
      step="0.01"
      type="number" />

    <label class="units" for="length">m</label>
  </div>
</div>
