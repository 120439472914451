import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgIf } from '@angular/common';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@Component({
    selector: 'app-date-input',
    templateUrl: './date-input.component.html',
    styleUrl: './date-input.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DateInputComponent,
            multi: true,
        },
    ],
    imports: [NgIf, OwlDateTimeModule],
})
export class DateInputComponent implements ControlValueAccessor {
  @Input({ required: true }) id: string = '';

  private onTouched = (): void => {};
  protected value: string | null = null;
  protected disabled = true;

  writeValue(value: string | null): void {
    this.value = value;
  }

  // Called when form control is changed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Called when form control is touched
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Called when component should be enabled/disabled
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Function that will be called when value changes
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected onChange = (value: any): void => {
    this.value = value;
  };

  protected dateSelected(date: Date): void {
    this.onChange(date);
    this.writeValue(toDateOnly(date));
  }

  protected clearValue(): void {
    this.onChange(null);
    this.writeValue(null);
    this.onTouched();
  }
}

export function toDateOnly(date: Date | null): string | null {
  if (!date) return null;
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits
  const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits
  return `${date.getFullYear()}-${month}-${day}`;
}

export function fromDateOnly(date: string | undefined): Date | null {
  if (!date) return null;
  const [year, month, day] = date.split('-').map((part) => parseInt(part, 10));
  return new Date(year, month - 1, day);
}
