import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildingRoutingModule } from './building-routing.module';
import { DossierDetailComponent } from './views/dossier-detail/dossier-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { CdkDrag, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { ThreedComponent } from '@shared/components/threed/threed.component';
import { ProductsAddPopupComponent } from './components/products-add-popup/products-add-popup.component';
import { FloorPlanViewerComponent } from './views/floor-plan-viewer/floor-plan-viewer.component';
import { ChangesSummaryComponent } from './components/changes-summary/changes-summary.component';
import { ProductDetailsPopupComponent } from './components/product-details-popup/product-details-popup.component';
import { ElementPopupComponent } from './components/element-popup/element-popup.component';
import { ModelViewerComponent } from './views/model-viewer/model-viewer.component';
import { AuthConfigModule } from '../auth/auth-config.module';
import { DossierTimelineComponent } from './components/dossier-timeline/dossier-timeline.component';
import { BuildingWrapperComponent } from './views/building-wrapper/building-wrapper.component';
import { NotePopupComponent } from './components/note-popup/note-popup.component';
import { TimelineEventComponent } from './components/timeline-event/timeline-event.component';
import { AddNoteComponent } from './components/add-note/add-note.component';
import { InspectionModule } from '../inspection-module/inspection.module';
import { GeometryEditComponent } from './components/geometry-edit/geometry-edit.component';
import { GeometrySummaryComponent } from './components/changes-summary/geometry-summary/geometry-summary.component';
import { ElementCategoryComponent } from './components/element-popup/element-category/element-category.component';
import { DegradationEditComponent } from './components/degradation-edit/degradation-edit.component';
import { DegradationSummaryComponent } from './components/changes-summary/degradation-summary/degradation-summary.component';

@NgModule({
  declarations: [
    DossierTimelineComponent,
    TimelineEventComponent,
    AddNoteComponent,
    ThreedComponent,
    ProductsAddPopupComponent,
    FloorPlanViewerComponent,
    ChangesSummaryComponent,
    ProductDetailsPopupComponent,
    ElementPopupComponent,
    ElementCategoryComponent,
    BuildingWrapperComponent,
    ModelViewerComponent,
    DossierDetailComponent,
    NotePopupComponent,
    GeometryEditComponent,
    DegradationEditComponent,
    GeometrySummaryComponent,
    DegradationSummaryComponent
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    BuildingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule.forChild(),
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    AuthConfigModule,
    InspectionModule,
  ],
  providers: [{ provide: OWL_DATE_TIME_LOCALE, useValue: 'nl' }],
  exports: [],
})
export class BuildingModule { }
