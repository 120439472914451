@if (tree) {
  <div class="breadcrumb">
    <a [routerLink]="['/']" class="home">
      <span class="material-symbols-rounded">home</span>
    </a>

    @for (treeItem of tree; track treeItem?.link) {
      @if (treeItem) {
        <button
          class="link"
          (click)="changeRouteSection(treeItem.link, treeItem.queryParams)">
        <span *ngIf="treeItem.translate">
          {{ treeItem.translate | translate }}
        </span>
          <span *ngIf="treeItem.name">
          {{ treeItem.name | translate }}
        </span>
        </button>
      }
    }
  </div>
}
