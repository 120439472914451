import { Component, HostListener } from '@angular/core';
import { VariablesPopupService } from './variables-popup.service';
import {
  GenericSubject,
  MeasureValueDto,
  MeasureValueService,
  MeasureValueVariable,
} from '@api-clients/project';
import { lastValueFrom } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UnitPinComponent } from '../../../components/unit-pin/unit-pin.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-variables-popup',
  templateUrl: './variables-popup.component.html',
  styleUrl: './variables-popup.component.scss',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, UnitPinComponent, NgIf],
})
export class VariablesPopupComponent {
  protected activeUnit: number = 0;
  protected pendingChanges: {
    measureValue: MeasureValueDto;
    insert: boolean;
    value: number;
    variableName: string;
  }[] = [];

  protected variablesForm?: FormGroup;

  constructor(
    protected readonly variablesPopupService: VariablesPopupService,
    protected readonly measureValueServices: MeasureValueService,
    private readonly formBuilder: FormBuilder
  ) {
    this.variablesPopupService.shown.subscribe(() => {
      this.initializeForm();
    });
  }

  close(): void {
    this.variablesForm = undefined; // Clear the form
    this.variablesPopupService.hide();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
    this.close();
  }

  initializeForm(): void {
    this.variablesForm = undefined;
    const group = this.formBuilder.group({});

    this.variablesPopupService.enrichedSubjects.forEach((subject) => {
      this.variablesPopupService.projectMeasures.forEach((projectMeasure) => {
        const subjectKey = JSON.stringify(subject.subject);
        if (projectMeasure.subjects.includes(subjectKey)) {
          projectMeasure.variables.forEach((variable) => {
            const controlName = this.hashCode(
              `field_${projectMeasure.measure_id}_${variable.name}_${subjectKey}`
            ).toString();
            const value = this.getValue(
              projectMeasure.measureValues,
              variable.name,
              subject.subject
            );
            group.addControl(controlName, this.formBuilder.control(value, []));
          });
        }
      });
    });
    this.variablesForm = group;
  }

  public async updateVariableValue(
    variable: MeasureValueVariable,
    measureValues: MeasureValueDto[] | undefined,
    subject: GenericSubject,
    e: Event
  ): Promise<void> {
    if (!measureValues) return;
    const value = (e.target as HTMLInputElement).value;

    const measureValue = measureValues.find(
      (mv) => JSON.stringify(mv.subject) === JSON.stringify(subject)
    );
    if (!measureValue) return;

    this.pendingChanges.push({
      measureValue,
      insert: false,
      value: parseFloat(value),
      variableName: variable.name,
    });
  }

  getValue(
    measureValues: Array<MeasureValueDto> | undefined,
    variable_name: string,
    subject: GenericSubject
  ): string {
    if (!measureValues) return '';
    const measureValue = measureValues.find(
      (mv) => JSON.stringify(mv.subject) === JSON.stringify(subject)
    );
    return (
      measureValue?.values.find((value) => value.name === variable_name)?.value?.toString() ?? ''
    );
  }

  async save(): Promise<void> {
    for (const pendingChange of this.pendingChanges) {
      const measureValue = pendingChange.measureValue;
      if (!pendingChange.insert) {
        const existing = measureValue.values.find((v) => v.name === pendingChange.variableName);
        if (existing) {
          existing.value = pendingChange.value;
        } else {
          measureValue.values.push({
            name: pendingChange.variableName,
            value: pendingChange.value,
          });
        }
        await lastValueFrom(
          this.measureValueServices.measuresMeasureIdMeasureValuesIdPut(
            pendingChange.measureValue.measure_id,
            pendingChange.measureValue.id,
            pendingChange.measureValue
          )
        );
      }
    }
    this.pendingChanges = [];
    this.variablesPopupService.saved.emit();
    this.close();
  }

  hashCode(input: string): number {
    let hash = 0,
      i: number,
      chr: number;
    if (input.length === 0) return hash;
    for (i = 0; i < input.length; i++) {
      chr = input.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  protected readonly JSON = JSON;
}
