export interface Layer {
  id: string;
  type?: 'fill' | 'line' | 'circle';
  disabled?: boolean;
  subLayers?: string[];
  postGISColumn?: string;
  isMapLayer?: boolean;
}

const allLayers: Layer[] = [
  {
    id: 'beheerobjecten_ut',
    type: 'fill',
    subLayers: [
      'BB',
      'BC',
      'BEB',
      'BEB CAPITOOL',
      'BEB THERMEN',
      'BEREGENINGSPUT',
      'BERM',
      'BINNENTUIN',
      'BLOEMBOLLEN',
      'BOOMSPIEGEL',
      'BOPH',
      'BOS',
      'BOSPL',
      'CON',
      'DAKVEGETATIE',
      'DREMPEL',
      'DUG OUT',
      'EXT',
      'EXT-HE',
      'EXT-T',
      'FIETS',
      'FIETS-UT-AF',
      'GOPH',
      'GREEN TO COLOUR',
      'GREPPEL',
      'GROEN CAPITOOL',
      'GROEN THERMEN',
      'GR-W',
      'GZA',
      'GZA-H',
      'GZA-T',
      'GZA-Z',
      'GZBT',
      'HEEM',
      'HEI',
      'HELOFYTENZONE',
      'HG-15',
      'HG-20',
      'HTR',
      'HTR-B',
      'IN MUTATIE',
      'KLIM',
      'KUNSTGRAS',
      'KUNSTGRAS ZAND INGESTROOID',
      'KUNSTOBJECT',
      'LED-WALL',
      'LIJNGOOT',
      'LUIK',
      'MOES',
      'MUUR',
      'MUUR CAPITOOL',
      'MUUR THERMEN',
      'OPH',
      'PACHT',
      'PLANTENBAK',
      'PRIVE',
      'PUTDEKSEL',
      'RHODO',
      'ROOSTER',
      'SCHK',
      'SCHK-WA',
      'SCHOONLOOPROOSTER',
      'SOL',
      'SPEL',
      'SPEL BOOT',
      'STAPSTENEN',
      'SV-A',
      'SV-BOOTCAMP',
      'SV-GRAVEL HOCKEY EN SOFTBAL',
      'SV-GRAVEL TENNIS',
      'SV-K',
      'SV-KW',
      'SV-N',
      'SV-N BASTILLE',
      'SV-N BASTILLE UITLOOP',
      'SV-N DOEL',
      'SV-N UTRACK',
      'SV-N UTRACK DOEL',
      'SV-N UTRACK UITLOOP',
      'SV-N VERREVELD',
      'SV-N VERREVELD DOEL',
      'SV-PANNAKOOI',
      'SV-S',
      'SV-T',
      'SV-Z BEACH',
      'SV-Z VERSPRINGEN',
      'SV-Z WITBREUK',
      'TRAFO',
      'TRAP',
      'UT VLINDER',
      'VBAK CARRE',
      'VBAK KOELVIJVER',
      'VBAK O&O plein',
      'VBOUW',
      'VH-B',
      'VH-BETON',
      'VH-BETON STELCON',
      'VH-BETON THEATER',
      'VH-E',
      'VH-E CAPITOOL',
      'VH-E DERDEN',
      'VH-E ONDER BEBOUWING',
      'VH-E ONDER TRAP',
      'VH-E THERMEN',
      'VH-E TR',
      'VH-E Z',
      'VH-G',
      'VH-H',
      'VH-H CAPITOOL',
      'VH-H THERMEN',
      'VH-MOLGOOT',
      'VH-MOLGOOT CAPITOOL',
      'VH-PUIN',
      'VH-S',
      'VH-ZA',
      'VIJVER',
      'VIJVER BOUWKUNDIG FOLIE MATENWEG PATIO',
      'VIJVER BOUWKUNDIG HELOFYTENFILTER HORST',
      'VIJVER BOUWKUNDIG HOGEKAMP',
      'VIJVER BOUWKUNDIG HOGEKAMP FOLIE',
      'VIJVER BOUWKUNDIG HOGEKAMP HELOFYTEN',
      'VLND',
      'VP',
      'WAT',
      'WAT DERDEN',
      'WERK',
      'ZAND DEPOT',
      'ZB',
      'ZITBANK THEATER',
      'ZITSTENEN',
      'ZUIL',
      'ZWEMBAD',
      'GZA-INTENSIEF',
      'GZA THE GALLERY',
      'CON TOT 2 METER',
      'BLOEMBOLLEN INTENSIEF GAZON THE GALLERY',
      'VH-E ONDER BEBOUWING THE GALLERY',
      'VH-E THE GALLERY',
      'BEB THE GALLERY',
      'CON HOGER DAN 2 METER',
      'VH-E FIETS',
      'GZA-BT',
      'BLOEMBOLLEN EXTENSIEF',
      'BLOEMBOLLEN INTENSIEF GAZON',
      'GZA-T THE GALLERY',
      'EXT-T THE GALLERY',
      'VH-B THE GALLERY',
      'CON THE GALLERY',
      'HG-15 THE GALLERY',
      'EXT-T THE GALLERY',
      'VH-E TR THE GALLERY',
      'MUUR THE GALLERY',
      'VH-G FIETS',
      'VH-S FIETS',
    ],
    postGISColumn: 'bgt_type',
  },

  {
    id: 'utnet_view_kabel',
    type: 'line',
    subLayers: ['middenspanning', 'laagspanning', 'datatransport'],
  },
  {
    id: 'utnet_view_leiding',
    type: 'line',
    subLayers: [
      'gasHogeDruk',
      'warmte',
      'gasLageDruk',
      'rioolVrijverval',
      'water',
      'rioolOnderOverOfOnderdruk',
    ],
  },
  {
    id: 'utnet_view_duct',
    subLayers: ['gasHogeDruk', 'middenspanning', 'laagspanning', 'datatransport'],
    disabled: true,
  },
  {
    id: 'utnet_view_hoogte',
    subLayers: ['gasHogeDruk', 'middenspanning', 'laagspanning', 'datatransport'],
    disabled: true,
  },
  {
    id: 'utnet_view_mantelbuis',
    subLayers: [
      'warmte',
      'rioolVrijverval',
      'laagspanning',
      'overig',
      'gasHogeDruk',
      'gasLageDruk',
      'water',
      'middenspanning',
      'datatransport',
    ],
  },
  {
    id: 'utnet_view_puntobject',
    subLayers: [
      'warmte',
      'rioolVrijverval',
      'laagspanning',
      'overig',
      'gasHogeDruk',
      'gasLageDruk',
      'water',
      'middenspanning',
      'rioolOnderOverOfOnderdruk',
      'datatransport',
    ],
    disabled: true,
  },
  {
    id: 'imkl_elektriciteitskabel_e_lv_map_cable_zuid_ligging',
    type: 'line',
    disabled: true,
  },
  {
    id: 'imkl_appurtenance_g_multistory_connection_noord_ligging',
    type: 'circle',
  },
  {
    id: 'dataset_afvalbakken_assen',
    type: 'circle',
  },
  /*  {
    id: 'dataset_bomen_assen',
    type: 'circle',
  },*/
  {
    id: 'dataset_parkeervoorzieningen_assen',
    type: 'circle',
  },
  {
    id: 'dataset_prullenbakken_hondenpoep_assen',
    type: 'circle',
  },
  /*  {
    id: 'dataset_riolering_assen',
    type: 'circle',
  },*/
  {
    id: 'assen bgt',
    subLayers: [
      'BTD_groenvoorziening_bosplantsoenV',
      'BTD_groenvoorziening_gras__en_kruidachtigenV',
      'BTN_groenvoorziening_heestersV',
      'BTD_groenvoorziening_plantenV',
      'BTD_groenvoorzieningV',
      'GBI_luifelV',
      'KWD_duikerV',
      'KWD_strekdamV',
      'OBD_dekV',
      'OBT_erfV',
      'OBT_gesloten_verharding_cementbetonV',
      'OBT_onverhard_zandV',
      'OBT_open_verharding_betonstraatstenenV',
      'OBT_open_verharding_sierbestratingV',
      'OBT_open_verharding_tegelsV',
      'OBW_lage_trafoV',
      'OBW_overkappingV',
      'OWG_berm_begroeidV',
      'OWG_berm_groenV',
      'OWG_berm_verhardV',
      'OWT_oeverV',
      'PND_gebouw_grondvlakV',
      'SHD_muurV',
      'WGD_fietspadV',
      'WGD_inritV',
      'WGD_parkeervlakV',
      'WGD_rijbaan_lokale_wegV',
      'WGD_voetpadV',
      'WTD_waterloop_griend_en_hakhoutV',
      'WTD_waterloop_slootV',
    ],
    postGISColumn: 'layer',
  },
  { id: 'bag-3d-models', isMapLayer: true },
  { id: 'custom-3d-model', isMapLayer: true },
];

export const layers = allLayers.filter((layer) => !layer.disabled);
