/**
 * BIM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DegradationManualDegradation { 
    estimated_expiry: string;
    description?: string;
    source: DegradationManualDegradation.SourceEnum;
}
export namespace DegradationManualDegradation {
    export type SourceEnum = 'ManuallyObserved';
    export const SourceEnum = {
        ManuallyObserved: 'ManuallyObserved' as SourceEnum
    };
}


