import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { DossierDetailComponent } from './views/dossier-detail/dossier-detail.component';
import { shoppingCartHasUnsavedChanges } from './building.guards';
import { FloorPlanViewerComponent } from './views/floor-plan-viewer/floor-plan-viewer.component';
import { ModelViewerComponent } from './views/model-viewer/model-viewer.component';
import { BuildingWrapperComponent } from './views/building-wrapper/building-wrapper.component';
import { InspectionOverviewComponent } from '../inspection-module/views/overview-page/inspection-overview.component';
import { InspectionDetailComponent } from '../inspection-module/views/inspection-detail/inspection-detail.component';
import { BimOverviewComponent } from './views/bim-overview/bim-overview.component';
import { BimDetailComponent } from './views/bim-detail/bim-detail.component';

const routes: Routes = [
  //This routing is only for building/id routes for the rest please other routing modules
  {
    path: 'buildings/:real_estate_building_id',
    canActivate: [AutoLoginPartialRoutesGuard],
    canDeactivate: [shoppingCartHasUnsavedChanges],
    component: BuildingWrapperComponent,
    children: [
      {
        path: 'dossier',
        component: DossierDetailComponent,
        data: {
          breadcrumb_segments: [
            {
              translate: 'buildingInfo',
              link: '/buildings/:real_estate_building_id/model/overview',
            },
            { translate: 'dossier-page', link: '/buildings/:real_estate_building_id' },
          ],
        },
      },
      {
        path: 'model/:state',
        component: ModelViewerComponent,
        data: {
          breadcrumb_segments: [
            {
              translate: 'buildingInfo',
              link: '/buildings/:real_estate_building_id/model/overview',
            },
            {
              constraint: '":state"==="details" || ":state"==="changes"',
              translate: ':state',
              link: '/buildings/:real_estate_building_id/model/:state',
            },
          ],
        },
      },
      {
        path: 'floor-plan/:state',
        component: FloorPlanViewerComponent,
        data: {
          breadcrumb_segments: [
            {
              translate: 'buildingInfo',
              link: '/buildings/:real_estate_building_id/floor-plan/overview',
            },
            {
              constraint: '":state"==="details" || ":state"==="changes"',
              translate: ':state',
              link: '/buildings/:real_estate_building_id/floor-plan/:state',
            },
          ],
        },
      },
      {
        path: 'inspections',
        component: InspectionOverviewComponent,
        data: {
          breadcrumb_segments: [
            {
              translate: 'buildingInfo',
              link: '/buildings/:real_estate_building_id/dossier',
            },
            {
              translate: 'inspections-overview-page',
              link: '/buildings/:real_estate_building_id/inspections',
            },
          ],
        },
      },
      {
        path: 'inspections/:id',
        component: InspectionDetailComponent,
        data: {
          breadcrumb_segments: [
            {
              translate: 'buildingInfo',
              link: '/buildings/:real_estate_building_id/dossier',
            },
            {
              translate: 'inspections-overview-page',
              link: '/buildings/:real_estate_building_id/inspections',
            },
            {
              translate: 'edit',
              link: '/buildings/:real_estate_building_id/inspections/:id',
            },
          ],
        },
      },
    ],
    data: {
      breadcrumb_segments: [],
    },
  },
  {
    path: 'bim',
    component: BimOverviewComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        {
          translate: 'bim-overview-page',
          link: '/bim',
        },
      ],
    },
  },
  {
    path: 'bim/:bim_id',
    component: BimDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: 'model/:state',
        component: ModelViewerComponent,
        canActivate: [AutoLoginPartialRoutesGuard],
        data: {
          breadcrumb_segments: [
            {
              constraint: '":state"==="details" || ":state"==="changes"',
              translate: ':state',
              link: '/bim/:bim_id/model/:state',
            },
          ],
        },
      },
      {
        path: 'floor-plan/:state',
        component: FloorPlanViewerComponent,
        canActivate: [AutoLoginPartialRoutesGuard],
        data: {
          breadcrumb_segments: [
            {
              constraint: '":state"==="details" || ":state"==="changes"',
              translate: ':state',
              link: '/bim/:bim_id/floor-plan/:state',
            },
          ],
        },
      },
    ],
    data: {
      breadcrumb_segments: [
        {
          translate: 'bim-overview-page',
          link: '/bim',
        },
        {
          translate: 'building_information',
          link: '/bim/:bim_id/model/overview',
        },
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BuildingRoutingModule {}
