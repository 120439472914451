<div class="building-info" *ngIf="selectedBuilding">
  <div class="header">
    <h2 class="h5" *ngIf="selectedBuilding.buildingMetadata?.address as name">
      {{ name }}
    </h2>
    <h2 class="h5" *ngIf="!selectedBuilding.buildingMetadata.address">
      {{ 'created-dossier' | translate }}
    </h2>
    <button (click)="closeSidebar()" class="material-symbols-rounded">
      {{ 'close' | translate }}
    </button>
  </div>
  <div class="specs">
    <div *ngIf="selectedBuilding.buildingMetadata as metadata">
      <h4 class="addres">{{ metadata.postalCode }}</h4>
      <h4 class="addres">{{ metadata.city }}</h4>
    </div>
    <ul>
      <li *ngIf="selectedBuilding.external_id as id">
        <span class="property">{{ 'cadastralIds' | translate }}</span>
        <span class="value">{{ id }}</span>
      </li>
      <!--suppress TypeScriptValidateTypes -->
      <li *ngFor="let item of selectedBuilding.buildingMetadata | additionalProperties | keyvalue">
        <span class="property">{{ item.key | translate }}</span>
        <span class="value">{{ item.value }}</span>
      </li>
      <li *ngIf="selectedBuilding.buildingMetadata?.surface">
        <span class="property">{{ 'surface' | translate }}</span>
        <span class="value">{{ selectedBuilding.buildingMetadata.surface }} m²</span>
      </li>
      <li *ngIf="selectedBuilding.buildingMetadata?.usages">
        <span class="property">{{ 'usages' | translate }}</span>
        <span
          class="value right"
          [innerHTML]="selectedBuilding.buildingMetadata.usages?.join('<br/>')"></span>
      </li>
<!--      <li>
        <span class="property">{{ 'Empty rooftop area' | translate }}</span>
        <app-info-bubble class="bubble-left"
                         [message]="'This area represents the estimated space suitable for installations like solar panels.' | translate">
        </app-info-bubble>
        <span class="value right">
                    <ng-container
                      *ngIf="selectedBuilding.buildingMetadata.estimated_empty_rooftop_space !== null && selectedBuilding.buildingMetadata.estimated_empty_rooftop_space !== undefined; else loading">
                      {{ selectedBuilding.buildingMetadata.estimated_empty_rooftop_space }} m²
                    </ng-container>
                    <ng-template #loading>
                      <div class="inline-spinner"></div>
                    </ng-template>
            </span>
      </li>
      <li>
        <span class="property">{{ 'Current solar panel area' | translate }}</span>
        <span class="value right">
                    <ng-container
                      *ngIf="selectedBuilding.buildingMetadata.current_coverage_of_solar_panels !== null && selectedBuilding.buildingMetadata.current_coverage_of_solar_panels !== undefined; else loading">
                      {{ selectedBuilding.buildingMetadata.current_coverage_of_solar_panels }} m²
                    </ng-container>
                    <ng-template #loading>
                      <div class="inline-spinner"></div>
                    </ng-template>
            </span>
      </li>-->
    </ul>
  </div>

  <div class="bottom-bar">
    <app-authorized-view [permission]="Resource.Dossiers" [scope]="Scope.READ">
      <button
        (click)="openDossier()"
        class="button-primary"
        *ngIf="selectedBuilding.real_estate_id">
        {{ 'dossier' | translate }}
      </button>
    </app-authorized-view>
    <button class="button" (click)="addBuilding()" *ngIf="!selectedBuilding.real_estate_id">
      <span class="material-symbols-rounded">add</span>
      {{ 'add_to_account' | translate }}
    </button>
  </div>
</div>
