import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Feature, FeatureCollection, Point } from 'geojson';
import {
  AddLayerObject,
  addProtocol,
  Evented,
  GeoJSONSource,
  LayerSpecification,
  LngLat,
  Map,
  MapMouseEvent,
  VectorSourceSpecification,
} from 'maplibre-gl';

import { environment } from 'environments/environment';
import { Map3DLayerComponent } from '../map-3d/map-3d-layer';

import { colorRuleMap } from './color-map';
import {
  brkKadastraal,
  brkKadastraalLayer,
  buildings,
  clusterCountLayer,
  clustersLayer,
  layers,
  mapboxSatellite,
  mapboxSatelliteLayer,
  pdokAhn,
  pdokAhn4Layer,
  unclusteredPointLayer,
} from './layers';
import { combineLatestWith, lastValueFrom, Observable, skip, take } from 'rxjs';
import { LatLngZoom } from '@shared/components/viewer/viewer.component';
import { ContextService } from '@services/context.service';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { RealEstateId } from '@shared/components/viewer/map-3d/assets/hd-building-manager';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { generateUUID } from 'three/src/math/MathUtils.js';
import { PMTiles, Protocol } from 'pmtiles';
import { MapFilesService, MapLayerDto } from '@api-clients/map';
import { SourceSpecification } from '@maplibre/maplibre-gl-style-spec';
import { Map2DLegendComponent } from '@shared/components/viewer/map-2d/custom-layers-legend/custom-layers-legend.component';
import { NgIf } from '@angular/common';

export interface LayerInfo {
  name: string;
}

export interface MapLayerExtendedDto extends MapLayerDto {
  subLayers: MapLayerExtendedDto[];
}

@Component({
  selector: 'map-2d',
  templateUrl: './map-2d.component.html',
  styleUrls: ['./map-2d.component.scss'],
  imports: [TranslateModule, Map2DLegendComponent, NgIf],
})
export class Map2DComponent implements AfterViewInit, OnDestroy {
  @Input({ required: true }) currentPose!: Observable<LatLngZoom>;
  @Input({ required: true }) map3dLayer!: Map3DLayerComponent;
  @Input({ required: true }) ownedBuildings$!: Observable<BuildingOverviewEntry[]>;
  @Input({ required: true }) selectedBuilding$!: Observable<BuildingOverviewEntry | undefined>;
  @Input() sidebarActive: boolean = false;

  @Output() newLatLngZoom = new EventEmitter<LatLngZoom>();
  @Output() dossierAtLatLngRequested = new EventEmitter<LngLat>();
  @Output() selectBuildingRequested = new EventEmitter<RealEstateId>();

  @ViewChild('legendControl') private readonly legendComponent!: Map2DLegendComponent;

  private map!: Map;
  protected gisLayers: Evented[] = [];
  protected buildings: FeatureCollection<Point> = {
    type: 'FeatureCollection',
    features: [],
  };

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  private mapLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  private sourceLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected activeRealEstateID: string | undefined;

  protected activeLayer: 'normal' | 'satellite' | 'ahn4' = 'normal';
  protected showBag3DLayer: boolean = true;
  protected showCadastralLayer: boolean = false;
  private protocol!: Protocol;
  protected customLayers: MapLayerExtendedDto[] = [];

  protected legendActive: boolean = false;

  constructor(
    public readonly contextService: ContextService,
    private readonly translateService: TranslateService,
    private readonly mapFilesService: MapFilesService
  ) {
    this.activeLayer =
      (localStorage.getItem('activeLayer') as 'normal' | 'satellite' | 'ahn4') || 'normal';
    this.showCadastralLayer = JSON.parse(localStorage.getItem('cadastral-layer') || 'false');
    this.showBag3DLayer = JSON.parse(localStorage.getItem('3d-layer') || 'true');
  }

  ngAfterViewInit(): void {
    this.currentPose.pipe(take(1)).subscribe(this.initMap.bind(this));
    this.currentPose.pipe(skip(1)).subscribe(this.navigateToLocation.bind(this));

    this.ownedBuildings$.pipe(combineLatestWith(this.mapLoaded)).subscribe((next) => {
      this.handleOwnedBuildingsChanged(next[0]);
    });
    this.sourceLoaded.subscribe(() =>
      this.selectedBuilding$.subscribe((building) => {
        this.activeRealEstateID = building?.real_estate_id;
        this.handleBuildingSelected();
      })
    );
    this.mapLoaded.subscribe(this.check3DLayerVisibility.bind(this));
  }

  async initMap(latLngZoom: LatLngZoom): Promise<void> {
    const { lat, lng, zoom } = latLngZoom;

    this.map = new Map({
      container: this.mapContainer.nativeElement,
      style: `https://api.maptiler.com/maps/57feab4f-e013-4079-b901-0ac7b678f6e9/style.json?key=XCli4HbSVDG3WCLxU24r`,
      center: [lng, lat],
      maxPitch: 45,
      pitch: 45,
      zoom,
      boxZoom: false,
    });

    // Prevent race condition: if style is already loaded, load layers. Elsewise, wait for styledata event and then load layers.
    if (this.map.isStyleLoaded()) {
      await this.loadLayers();
    } else {
      this.map.once('styledata', () => this.loadLayers());
    }

    // On click cluster and unclustered points start
    this.map.on('click', 'unclustered-point', (e) => {
      const feature = e.features?.[0];
      if (!feature) {
        return;
      }

      this.selectBuildingRequested.emit(feature.properties['real_estate_id']);
    });

    this.map.on('click', 'clusters', (e) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      });

      if (!features.length) {
        return;
      }

      const clusterId = features[0].properties['cluster_id'];
      const source = this.map.getSource('buildings') as GeoJSONSource;

      source.getClusterExpansionZoom(clusterId).then((zoom) =>
        this.map.easeTo({
          center: features[0].geometry['coordinates'],
          zoom: zoom,
        })
      );
    });
    // On click cluster and unclustered points end

    // hover states start
    let hoveredFeatureId: number | string | undefined;
    const setPointerAndHover = (layerId): void => {
      this.map.on('mouseenter', layerId, (e) => {
        for (const feature of e.features || []) {
          hoveredFeatureId = feature.id;
          this.map.setFeatureState({ source: 'buildings', id: hoveredFeatureId }, { hover: true });
        }

        if (hoveredFeatureId) {
          this.map.getCanvas().style.cursor = 'pointer';
        }
      });
      this.map.on('mouseleave', layerId, () => {
        if (hoveredFeatureId) {
          this.map.setFeatureState({ source: 'buildings', id: hoveredFeatureId }, { hover: false });
          this.map.getCanvas().style.cursor = '';
          hoveredFeatureId = undefined;
        }
      });
    };

    setPointerAndHover('clusters');
    setPointerAndHover('unclustered-point');
    // hover states end

    // Also give the map3dLayer the chance to update mouse cursor
    this.map.on('mousemove', (event) => {
      if (hoveredFeatureId) return; // If map points are already hovered, don't change the cursor
      // Else, check if the mouse is over a building to change the cursor anyway
      if (this.map3dLayer.isMouseOverBuilding(event)) {
        this.map.getCanvas().style.cursor = 'pointer';
      } else {
        this.map.getCanvas().style.cursor = '';
      }
    });

    let sourceLoaded = false;

    this.map.on('resize', () => this.map3dLayer.updateProjectionMatrix(this.map));
    this.map.on('click', (event) => this.handleMouseClick(event));
    this.map.on('contextmenu', (event) => this.handleRightClick(event));
    this.map.on('error', (e) => console.warn('MapLibre error', e));
    this.map.on('zoomstart', () => this.contextService.hide());
    this.map.on('zoomend', () => this.updateZoom());
    this.map.on('dragstart', () => this.contextService.hide());
    this.map.on('dragend', () => this.updateLatLngZoom());
    this.map.on('sourcedata', (e) => {
      if (!sourceLoaded && e.sourceId === 'buildings' && e.isSourceLoaded) {
        sourceLoaded = true;
        this.sourceLoaded.emit(true);
      }
    });

    // add the PMTiles plugin to the maplibregl global.
    this.protocol = new Protocol();
    addProtocol('pmtiles', this.protocol.tile);
  }

  async loadLayers(): Promise<void> {
    layers
      .filter((layer) => !this.mapLayers.includes(layer.id))
      .forEach(({ id, type }) => {
        this.map.addSource(id, {
          url: `${environment.TREX_TILESERVER}/${id}.json`,
          type: 'vector',
        } as VectorSourceSpecification);

        const colorProp = type === 'fill' ? 'fill-color' : 'line-color';
        const layer = {
          id,
          source: id,
          'source-layer': id,
          paint: {
            [colorProp]: ['case', ...colorRuleMap, 'transparent'],
          },
          type: type || 'line',
          visibility: 'visible',
        } as LayerSpecification;

        this.map.addLayer(layer);
        const styleLayer = this.map.getLayer(layer.id)!;
        this.gisLayers.push(styleLayer);
      });

    const newLayers = await lastValueFrom(this.mapFilesService.layersGet());
    const addLayer = (layer: MapLayerExtendedDto, source, source_layer: string): void => {
      const layer_type = layer.layer_type || 'line';
      const layer_type_color = layer_type + '-color';

      const pm_tiles_layer = <AddLayerObject>{
        id: layer.id,
        source: source,
        'source-layer': source_layer,
        type: layer_type,
        paint: {
          [layer_type_color]: layer.color || 'red',
        },
        layout: {
          visibility: selectedLayers.includes(layer.id) ? 'visible' : 'none',
        },
      };

      this.map.addLayer(pm_tiles_layer);
    };

    newLayers.sort((a, b) => (a.description ?? '').localeCompare(b.description ?? ''));
    const customLayers = newLayers.map(
      (layer) =>
        <MapLayerExtendedDto>{
          ...layer,
          subLayers: [],
        }
    );

    const selectedLayers =
      JSON.parse(localStorage.getItem('map2d.selectedLayers') || 'null') ||
      customLayers.map((s) => s.id);

    for (const layer of customLayers) {
      try {
        if (layer.s3_url) {
          const pm_tiles = new PMTiles(layer.s3_url!);
          this.protocol.add(pm_tiles);

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const metadata: any = await pm_tiles.getMetadata();

          const source = <SourceSpecification>{
            type: 'vector',
            url: `pmtiles://${layer.s3_url}`,
          };

          this.map.addSource(layer.id, source);

          addLayer(layer, layer.id, metadata.vector_layers[0].id);

          if (metadata.vector_layers.length > 1) {
            // we have more than one layer, so treat them as sublayers
            layer.subLayers = [
              ...layer.subLayers,
              ...metadata.vector_layers.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (vector_layer: any) =>
                  <MapLayerExtendedDto>{
                    id: `${layer.id}_${vector_layer.id}`,
                    description: vector_layer.id,
                    color: this.generateColorFromName(vector_layer.id),
                    layer_type: layer.layer_type,
                  }
              ),
            ];

            for (const subLayer of layer.subLayers) {
              addLayer(subLayer, layer.id, subLayer.description ?? '');
            }
          }
        }
      } catch (error) {
        console.error('Failed to load layer:', layer, error);
      }
    }

    this.customLayers = customLayers;

    this.map.addSource('buildings', buildings(this.buildings));

    this.map.addSource('mapbox-satellite', mapboxSatellite);
    this.map.addLayer(mapboxSatelliteLayer(this.activeLayer === 'satellite'));

    this.map.addSource('pdok-ahn4', pdokAhn);
    this.map.addLayer(pdokAhn4Layer(this.activeLayer === 'ahn4'));

    this.map.addSource('brk-kadastraal', brkKadastraal);
    this.map.addLayer(brkKadastraalLayer(this.showCadastralLayer));

    this.map.addLayer(this.map3dLayer);
    this.map.setLayoutProperty(
      this.map3dLayer.id,
      'visibility',
      this.showBag3DLayer ? 'visible' : 'none'
    );
    this.map.addLayer(clustersLayer);
    this.map.addLayer(clusterCountLayer);
    this.map.addLayer(unclusteredPointLayer);

    this.mapLoaded.emit(true);
  }

  // Function to generate a color based on a string (consistent per sublayer)
  generateColorFromName(name: string): string {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Convert hash to a HEX color
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += value.toString(16).padStart(2, '0');
    }
    return color;
  }

  get mapLayers(): string[] {
    return layers.filter((layer) => layer.isMapLayer).map((layer) => layer.id);
  }

  handleOwnedBuildingsChanged(entries: BuildingOverviewEntry[]): void {
    this.buildings = {
      type: 'FeatureCollection',
      features: entries.map<Feature<Point>>((building) => ({
        type: 'Feature',
        id: generateUUID(),
        properties: {
          real_estate_id: building.real_estate_id,
          has3d: !!building.external_id || !!building.bim_id,
          active: false,
        },
        geometry: {
          type: 'Point',
          coordinates: [
            building.buildingMetadata.location.lng,
            building.buildingMetadata.location.lat,
          ],
        },
      })),
    };

    if (this.map.getSource('buildings')) {
      // Update the GeoJSON data if the source already exists
      const source = this.map.getSource('buildings') as GeoJSONSource;
      source.setData(this.buildings);
      this.setUnclusteredFilter();
    }

    this.handleBuildingSelected();
  }

  handleBuildingSelected(): void {
    const buildingsFeatures = this.map.querySourceFeatures('buildings');
    buildingsFeatures.forEach((feature) => {
      this.map.setFeatureState(
        { source: 'buildings', id: feature.id },
        { active: feature.properties?.['real_estate_id'] === this.activeRealEstateID }
      );
    });
  }

  handleMouseClick(mapMouseEvent: MapMouseEvent): void {
    this.contextService.hide();
    if (mapMouseEvent.type === 'click' && mapMouseEvent.originalEvent.button === 0)
      // left click
      this.map3dLayer.handleMouseClick(mapMouseEvent);
  }

  handleRightClick(mapMouseEvent: MapMouseEvent): void {
    this.contextService.set(
      mapMouseEvent.originalEvent.clientX,
      mapMouseEvent.originalEvent.clientY,
      [
        {
          name: this.translateService.instant('add-dossier-to-location'),
          icon: 'house',
          action: (): void => {
            this.dossierAtLatLngRequested.emit(mapMouseEvent.lngLat);
          },
        },
      ]
    );
  }

  updateZoom(): void {
    this.check3DLayerVisibility();
    this.setUnclusteredFilter();
    this.updateLatLngZoom();
  }

  check3DLayerVisibility(): void {
    const zoom = this.map.getZoom();
    this.map3dLayer.setVisibility(this.showBag3DLayer && zoom > 15);
  }

  updateLatLngZoom(): void {
    const { lat, lng } = this.map.getCenter();
    const zoom = this.map.getZoom();
    this.newLatLngZoom.emit({ lat, lng, zoom });
  }

  navigateToLocation(latLngZoom: LatLngZoom): void {
    const { lat, lng, zoom } = latLngZoom;
    const [mapLat, mapLng] = this.map.getCenter().toArray();
    if (lat && lng && (Math.abs(lat - mapLat) > 1e-5 || Math.abs(lng - mapLng) > 1e-5))
      this.map.setCenter([lng, lat]);
    if (zoom && Math.abs(zoom - this.map.getZoom()) > 0.2) this.map.setZoom(zoom);
    this.map.triggerRepaint();
  }

  changeLayer(layer: 'normal' | 'satellite' | 'ahn4'): void {
    this.activeLayer = layer;
    localStorage.setItem('activeLayer', layer);
    this.map.setLayoutProperty(
      'satellite',
      'visibility',
      layer === 'satellite' ? 'visible' : 'none'
    );
    this.map.setLayoutProperty('pdok-ahn4', 'visibility', layer === 'ahn4' ? 'visible' : 'none');
  }

  changeCadastralLayer = (): void => {
    this.showCadastralLayer = !this.showCadastralLayer;
    localStorage.setItem('cadastral-layer', this.showCadastralLayer.toString());

    this.map.setLayoutProperty(
      'brk-kadastraal',
      'visibility',
      this.showCadastralLayer ? 'visible' : 'none'
    );
  };

  change3DLayer = (): void => {
    this.showBag3DLayer = !this.showBag3DLayer;
    localStorage.setItem('3d-layer', this.showBag3DLayer.toString());

    this.map.setLayoutProperty(
      this.map3dLayer.id,
      'visibility',
      this.showBag3DLayer ? 'visible' : 'none'
    );

    this.check3DLayerVisibility();
    this.setUnclusteredFilter();
    this.handleBuildingSelected();
  };

  setUnclusteredFilter = (): void => {
    const zoom = this.map.getZoom();
    if (this.showBag3DLayer && zoom > 15) {
      // If showBag3DLayer is true, hide the unclustered points that has a 3D model
      this.map.setFilter('unclustered-point', ['==', ['get', 'has3d'], false]);
    } else {
      // If showBag3DLayer is false, show as normal
      this.map.setFilter('unclustered-point', ['all', ['!', ['has', 'point_count']]]);
    }
  };

  setSelected(selected: MapLayerDto[]): void {
    const selectedIds = selected.map((node) => node.id);
    for (const layer of this.customLayers) {
      const visible = selectedIds.includes(layer.id);
      this.map.setLayoutProperty(layer.id, 'visibility', visible ? 'visible' : 'none');

      for (const subLayer of layer.subLayers) {
        const visible = selectedIds.includes(subLayer.id);
        this.map.setLayoutProperty(subLayer.id, 'visibility', visible ? 'visible' : 'none');
      }
    }
  }

  ngOnDestroy(): void {
    this.map?.remove();
  }
}
