import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-information-filter',
    templateUrl: './information-filter.component.html',
    styleUrls: ['./information-filter.component.scss'],
    imports: [FormsModule]
})
export class InformationFilterComponent {
  @Input() filters = [];
}
