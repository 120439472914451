import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TimeLineDto, TimeLineType } from '@api-clients/dossier';
import { DossierService } from '@services/dossier.service';
import { TranslateService } from '@ngx-translate/core';
import { BimSource, BimStatus, BuildingInformationModelDto, BimService } from '@api-clients/bim';
import { EnrichedTimeLineDto } from '../../views/dossier-detail/EnrichedTimeLineDto';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-timeline-event',
  templateUrl: './timeline-event.component.html',
  styleUrl: './timeline-event.component.scss',
})
export class TimelineEventComponent implements OnChanges {
  @Input() item: EnrichedTimeLineDto = {} as EnrichedTimeLineDto;
  @Input() lastItem: boolean = false;
  @Input() smallVersion: boolean = false;
  @Output() imageFunction = new EventEmitter<string>();

  protected readonly TimeLineType = TimeLineType;

  protected buildingInfo: BuildingInformationModelDto | undefined = undefined;

  constructor(
    private readonly dossierService: DossierService,
    protected readonly translateService: TranslateService,
    private readonly bimService: BimService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item'] && changes['item'].currentValue) {
      void this.enrichTimelineItem(changes['item'].currentValue);
    }
  }

  async enrichTimelineItem(item: TimeLineDto): Promise<void> {
    if (item.item_type == TimeLineType.BimLink) {
      const bimLink = await this.dossierService.getBimLinkForDossier(item);
      this.buildingInfo = await lastValueFrom(this.bimService.bimBimIdGet(bimLink.linked_bim_id));
    }
  }

  async sendImage(image_id: string): Promise<void> {
    this.imageFunction.emit(image_id);
  }

  openDocument(dossier_id: string, document_id: string): void {
    console.warn('open right pane image', dossier_id, document_id);
  }

  protected readonly BimStatus = BimStatus;
  protected readonly BimSource = BimSource;
}
