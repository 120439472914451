import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map, Observable, skip, switchMap, take } from 'rxjs';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { Dossier } from '@api-clients/dossier';
import {
  AddressableUnitsService as AddressableUnitsService,
  BuildingModel,
  BuildingsService,
} from '@api-clients/real-estate';
import { BuildingMetadata } from '@core/models/building-model';
import { DossierService } from '@services/dossier.service';
import {
  AdresService,
  PandIdentificatieRequestParams,
  PandIOHal,
  PandService,
  VerblijfsobjectService,
} from '@api-clients/kadaster';
import { LngLat } from 'maplibre-gl';
import Proj4 from 'proj4';
//import { EstimationService } from '@api-clients/solar';

export type CadastralId = string;

@Injectable({
  providedIn: 'root',
})
export class BuildingOverviewService {
  private updatingBuildings = false;
  private ownedBuildings = new BehaviorSubject<BuildingOverviewEntry[]>([]);

  public get ownedBuildings$(): Observable<BuildingOverviewEntry[]> {
    if (this.updatingBuildings) {
      // If we're currently updating ownedBuildings, don't take the current value, but wait for the updated one.
      return this.ownedBuildings.pipe(skip(1));
    } else {
      return this.ownedBuildings.asObservable();
    }
  }

  constructor(
    private readonly dossierService: DossierService,
    private readonly buildingsService: BuildingsService,
    private readonly addressableUnitsService: AddressableUnitsService,
    private readonly pandService: PandService,
    private readonly adresService: AdresService,
    private readonly verblijfsObjectService: VerblijfsobjectService
    //private readonly estimationService: EstimationService
  ) {
    void this.init_buildings();
  }

  private async init_buildings(): Promise<void> {
    this.updatingBuildings = true;
    const entries = await this.getBuildingOverviewEntries();
    this.updatingBuildings = false;
    this.ownedBuildings.next(entries);
  }

  private async getBuildingOverviewEntries(): Promise<BuildingOverviewEntry[]> {
    const dossiers: Dossier[] = await this.dossierService.getDossiersForCurrentOrganization();
    if (dossiers.length === 0) return [];
    const buildings: BuildingModel[] = await lastValueFrom(
      this.buildingsService.includingAddressesGet(dossiers.map((d) => d.building_id))
    );

    const entries: BuildingOverviewEntry[] = [];
    for (const building of buildings) {
      const coordinatesLngLat = new LngLat(building.coordinates.lng, building.coordinates.lat);
      const metadata: BuildingMetadata = new BuildingMetadata(coordinatesLngLat, undefined);

      metadata.addresses = building.addresses;
      const firstAddress = building.addresses[0];
      if (firstAddress) {
        metadata.address = firstAddress.address + ' ' + firstAddress.house_number;
        metadata.city = firstAddress.place;
        metadata.postalCode = firstAddress.zip_code;
      }

      const dossier = dossiers.find((d) => d.building_id === building.id);
      if (!dossier) throw new Error('Dossier not found');

      entries.push({
        real_estate_id: building.id,
        external_id: building.external_id,
        buildingMetadata: metadata,
        dossier_id: dossier.id,
        bim_id: dossier.linked_bim_ids[0],
      });
    }
    return entries;
  }

  private async generateBuildingMetadata(cadastralId: string): Promise<BuildingMetadata> {
    const [pandInfo, bevraagAdressen, bevraagVerblijfsObjecten] = await Promise.all([
      this.getPandInfo(cadastralId),
      lastValueFrom(this.adresService.bevraagAdressen({ pandIdentificatie: cadastralId })),
      lastValueFrom(
        this.verblijfsObjectService.zoekVerblijfsobjecten({
          pandIdentificatie: cadastralId,
          huidig: true,
          acceptCrs: 'epsg:28992',
        })
      ),
    ]);

    const lngLat = getLatLngFromPandInfo(pandInfo);
    const metaData = new BuildingMetadata(
      lngLat,
      bevraagAdressen?._embedded?.adressen,
      pandInfo,
      bevraagVerblijfsObjecten?._embedded?.verblijfsobjecten?.at(0)
    );

    return metaData;
  }

  private async getPandInfo(cadastralId: string): Promise<PandIOHal> {
    const query = {
      identificatie: cadastralId,
      acceptCrs: 'epsg:28992',
    } as PandIdentificatieRequestParams;

    const pandInfo = this.pandService.pandIdentificatie(query);
    return await lastValueFrom(pandInfo);
  }

  getBuildingByCadId(cadastralId: CadastralId): Observable<BuildingOverviewEntry> {
    return this.ownedBuildings$.pipe(
      take(1),
      switchMap(async (buildings) => {
        const building = buildings.find((b) => b.external_id === cadastralId);
        const buildingMetadata = await this.generateBuildingMetadata(cadastralId);

        const metaData = { ...building?.buildingMetadata, ...buildingMetadata };

        /*        this.estimationService.estimationBuildingIdGet(cadastralId).subscribe((coverage) => {
          setEstimatedCoverage(metaData, coverage);
        });*/
        return {
          ...building,
          buildingMetadata: metaData,
          external_id: cadastralId,
        };
      })
    );
  }

  public getBuildingByRealEstateId(
    realEstateId: string
  ): Observable<BuildingOverviewEntry | undefined> {
    return this.ownedBuildings$.pipe(
      take(1),
      switchMap(async (buildings) => {
        const building = buildings.find((b) => b.real_estate_id === realEstateId);
        if (!building) return undefined;
        if (building.external_id) {
          building.buildingMetadata = {
            ...building.buildingMetadata,
            ...(await this.generateBuildingMetadata(building.external_id)),
          };
        }
        return building;
      })
    );
  }

  public async requestAddBuildingToOwnedBuildings(
    building: BuildingOverviewEntry
  ): Promise<BuildingOverviewEntry> {
    this.updatingBuildings = true;
    // Begin with creating a real estate api building
    const real_estate_building = await lastValueFrom(
      this.buildingsService.buildingsPost({
        external_id: building.external_id,
        coordinates: building.buildingMetadata.location,
      })
    );
    const newOwnedBuilding = { ...building, real_estate_id: real_estate_building.id };

    // create all addresses
    (building.buildingMetadata?.addresses ?? []).map((item) => {
      this.addressableUnitsService
        .unitsPost({
          building_id: real_estate_building.id,
          address: item.address ?? '',
          zip_code: item.zip_code ?? '',
          place: item.place ?? '',
          house_number: item.house_number ?? '',
        })
        .subscribe();
    });

    // then create a dossier for this building
    const dossier = await this.dossierService.postDossier(real_estate_building.id);
    newOwnedBuilding.dossier_id = dossier.id;
    newOwnedBuilding.real_estate_id = real_estate_building.id;

    // update the buildings
    this.updatingBuildings = false;
    this.ownedBuildings.next([...this.ownedBuildings.value, newOwnedBuilding]);
    return newOwnedBuilding;
  }

  public getLatLonFromCadastralId(cadastralId: CadastralId): Observable<LngLat> {
    const query = {
      identificatie: cadastralId,
      acceptCrs: 'epsg:28992',
    } as PandIdentificatieRequestParams;
    return this.pandService.pandIdentificatie(query).pipe(map(getLatLngFromPandInfo));
  }
}

function getLatLngFromPandInfo(pandInfo: PandIOHal): LngLat {
  // Calculates the coordinates to the average of the cadastral points.
  const coordinates = pandInfo.pand.geometrie.coordinates[0];
  const length = coordinates.length;
  const summedCoords = coordinates.reduce((acc: number[], coords: number[]) => [
    acc[0] + coords[0],
    acc[1] + coords[1],
  ]);
  const averageCoords = [summedCoords[0] / length, summedCoords[1] / length];
  const [lng, lat] = Proj4('EPSG:28992', 'EPSG:4326', [averageCoords[0], averageCoords[1]]);
  return new LngLat(lng, lat);
}
