import { Component, Input } from '@angular/core';
import { ManualDegradation } from '@api-clients/bim';

@Component({
  selector: 'app-degradation-summary',
  templateUrl: './degradation-summary.component.html',
  styleUrls: [],
})
export class DegradationSummaryComponent {
  @Input() set old(degradation: ManualDegradation | unknown) {
    this.oldDegradation = this.normalizeDegradation(degradation);
  }

  @Input() set new(degradation: ManualDegradation | unknown) {
    this.newDegradation = this.normalizeDegradation(degradation);
  }

  protected oldDegradation: ManualDegradation = this.createEmptyDegradation();
  protected newDegradation: ManualDegradation = this.createEmptyDegradation();

  get hasChanges(): boolean {
    return (
      this.oldDegradation.estimated_expiry !== this.newDegradation.estimated_expiry ||
      (this.oldDegradation.description || null) !== (this.newDegradation.description || null)
    );
  }

  private createEmptyDegradation(): ManualDegradation {
    return {
      estimated_expiry: '',
      description: undefined,
    };
  }

  private normalizeDegradation(value: unknown): ManualDegradation {
    if (
      value &&
      typeof value === 'object' &&
      'estimated_expiry' in value
    ) {
      return {
        estimated_expiry: (value as ManualDegradation).estimated_expiry,
        description: (value as ManualDegradation).description || undefined,
      };
    }
    return this.createEmptyDegradation();
  }
}
