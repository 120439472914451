<div class="model-viewer">
  <div class="big-spinner" *ngIf="threed.modelState === 'loading' || isSaving"></div>
  <div
    class="model-viewer__overlay"
    [class.show]="threed.modelState === 'loading' || isSaving"></div>

  <div class="model-viewer-no-model" *ngIf="threed.modelState === 'error'">
    {{ 'no-3d-model-available' | translate }}
  </div>

  <div class="model-viewer__top-bar" #topBar>
    <app-breadcrumb></app-breadcrumb>

    <button
      class="button-primary cta-button"
      *ngIf="visibleMenu !== 'changes'"
      [disabled]="shoppingCartCount === 0"
      [class.animate]="bounceShoppingCartButton"
      (click)="showShoppingCart()">
      ({{ shoppingCartCount }}) {{ 'changes' | translate }}
    </button>
    <button
      class="button-primary cta-button"
      *ngIf="visibleMenu === 'changes'"
      [disabled]="shoppingCartCount === 0 || isSaving"
      (click)="saveShoppingCart()">
      <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
      {{ 'save' | translate }}
    </button>
  </div>

  <app-threed #threed></app-threed>

  <div class="sidebar" *ngIf="threed.modelState === 'loaded'">
    <div class="sidebar__content" [class.hide]="visibleMenu !== 'overview'">
      <app-residence-sidebar
        [availableHeight]="availableHeight"
        (toggleCategoryVisibility)="threed.toggleCategoryVisibility($event)"
        [categories]="threed.objectCategories"
        (toggleLevelVisibility)="threed.toggleLevelVisibility($event)"
        [levels]="threed.levels" />
    </div>
    <div class="sidebar__content" [class.hide]="visibleMenu !== 'details'">
      <app-element-popup [availableHeight]="availableHeight" [bimId]="bimId"></app-element-popup>
    </div>
    <div class="sidebar__content" [class.hide]="visibleMenu !== 'changes'">
      <app-changes-summary [availableHeight]="availableHeight"></app-changes-summary>
    </div>
  </div>
</div>
