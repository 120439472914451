import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from '@shared/services/toastr.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  public constructor(
    private readonly router: Router,
    private readonly toastrService: ToastrService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((e) => {
        const errorResponse = e as HttpErrorResponse;
        if (errorResponse.status === 401) {
          // this should never happen when token refresh does its work correctly.
          void this.router.navigate(['/']);
        }
        if (errorResponse.status === 403) {
          // Forbidden
          this.toastrService.showFailure(
            'errors.code.NO_RIGHTS.message',
            'errors.code.NO_RIGHTS.title'
          );
        }
        return throwError(e);
      })
    );
  }
}
