import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { UserService } from '@services/user.service';
import { lastValueFrom } from 'rxjs';
import { MeasureTemplateDto, MeasureTemplatesService } from '@api-clients/project';
import { ToastrService } from '@shared/services/toastr.service';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { NgIf, NgFor } from '@angular/common';
import { CalculationPanelComponent } from '../../components/calculation-pane/calculation-panel.component';
import { TranslateModule } from '@ngx-translate/core';

export interface MeasureDetailForm {
  id: FormControl<string | undefined>;
  organizationId: FormControl<string>;
  name: FormControl<string>;
  description: FormControl<string>;
  variables: FormArray;
  calculations: FormArray;
}

const required = Validators.required;

@Component({
  selector: 'app-measure-template-detail',
  templateUrl: './measure-template-detail.component.html',
  styleUrl: './measure-template-detail.component.scss',
  imports: [
    BreadcrumbComponent,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgFor,
    CalculationPanelComponent,
    TranslateModule,
  ],
})
export class MeasureTemplateDetailComponent implements OnInit {
  protected formGroup!: FormGroup<MeasureDetailForm>;
  protected isSaving: boolean = false;
  protected isNew: boolean = false;

  protected measureTemplate!: MeasureTemplateDto;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly measureTemplatesService: MeasureTemplatesService,
    private readonly toastrService: ToastrService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.buildForm();
  }

  protected buildForm(): void {
    this.formGroup = this.formBuilder.group<MeasureDetailForm>(
      {
        id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
        organizationId: new FormControl<string>(this.userService.organizationId, {
          nonNullable: true,
        }),
        name: new FormControl('', {
          nonNullable: true,
          validators: required,
        }),
        description: new FormControl('', {
          nonNullable: true,
          validators: required,
        }),
        variables: this.formBuilder.array([]),
        calculations: this.formBuilder.array([]),
      },
      { updateOn: 'change' }
    );
  }

  async ngOnInit(): Promise<void> {
    this.formGroup.valueChanges.subscribe(() => {
      this.cdRef.detectChanges(); // Ensures Angular updates the panel when values change
    });

    this.route.paramMap.subscribe(async (next) => {
      const id = next.get('id');

      this.isNew = id === null;

      if (id) {
        // If the id is set, we are updating a user
        this.formGroup.get('organizationId')!.disable();
        this.measureTemplate = await lastValueFrom(this.measureTemplatesService.templatesIdGet(id));

        // this is necessary for the initial creation of controls
        for (let i = 0; i < this.measureTemplate.variables.length; i++) {
          this.addVariable();
        }

        // this is necessary for the initial creation of controls
        for (let i = 0; i < this.measureTemplate.calculations.length; i++) {
          this.addCalculation();
        }

        this.formGroup.patchValue({
          name: this.measureTemplate.name,
          description: this.measureTemplate.description,
          variables: this.measureTemplate.variables,
          calculations: this.measureTemplate.calculations,
          organizationId: this.measureTemplate.organization_id,
          id: this.measureTemplate.id,
        });
      }
    });
  }

  protected addVariable(): void {
    const fa = this.formGroup.get('variables') as FormArray;
    fa.push(
      this.formBuilder.group({
        name: ['', [Validators.required, this.noSpacesValidator]],
        description: ['', Validators.required],
        unit: [''],
      })
    );
  }

  protected deleteVariable(index: number): void {
    const fa = this.formGroup.get('variables') as FormArray;
    fa.removeAt(index);
  }

  protected addCalculation(): void {
    const fa = this.formGroup.get('calculations') as FormArray;
    fa.push(
      this.formBuilder.group({
        name: ['', Validators.required],
        unit: ['', Validators.required],
        formula: ['', Validators.required],
      })
    );
  }

  protected deleteCalculation(index: number): void {
    const fa = this.formGroup.get('calculations') as FormArray;
    fa.removeAt(index);
  }

  getVariables(index: number): Record<string, number> {
    const measure_detail = this.formGroup.getRawValue();

    return Object.fromEntries(measure_detail.variables.map((item) => [item.name, index + 1]));
  }

  noSpacesValidator(control: AbstractControl): ValidationErrors | null {
    return control.value && control.value.includes(' ') ? { noSpaces: true } : null;
  }

  async save(): Promise<void> {
    if (!this.formGroup.valid) return;
    if (this.isSaving) return;

    this.isSaving = true;

    const measure_detail = this.formGroup.getRawValue();

    // If we have an ID, we are updating a user. If we have no ID, we are creating a new one.
    const putOrPost = measure_detail.id
      ? this.measureTemplatesService.templatesIdPut(measure_detail.id, {
          name: measure_detail.name,
          description: measure_detail.description,
          variables: measure_detail.variables,
          calculations: measure_detail.calculations,
        })
      : this.measureTemplatesService.templatesPost({
          name: measure_detail.name,
          description: measure_detail.description,
          variables: measure_detail.variables,
          calculations: measure_detail.calculations,
        });
    putOrPost.subscribe(() => {
      this.toastrService.showSuccess(
        'project-module.measure-template.save-success',
        'project-module.measure-template.save-title'
      );
      this.isSaving = false;
      this.router.navigate(['measure_template']);
    });
  }
}
