import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { UsersRoutingModule } from './users-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { UserDetailComponent } from './views/user-detail/user-detail.component';
import { UsersPageComponent } from './views/user-overview/user-overview.component';
import { OrganizationOverviewComponent } from './views/organization-overview/organization-overview.component';
import { OrganizationDetailComponent } from './views/organization-detail/organization-detail.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthConfigModule } from '../auth/auth-config.module';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressDetailComponent } from './components/address-detail/address-detail.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        UsersRoutingModule,
        MatFormFieldModule,
        MatInputModule,
        AuthConfigModule,
        TranslateModule.forChild(),
        UserDetailComponent,
        UsersPageComponent,
        OrganizationOverviewComponent,
        OrganizationDetailComponent,
        AddressDetailComponent,
    ],
})
export class UsersModule {}
