<div class="color-picker">
  <button type="button" [style.background]="color" (click)="primaryColorPicker.click()">
    <span class="color-picker__description" [style.color]="contrast" *ngIf="description">
      {{ description }}
    </span>
    <input
      #primaryColorPicker
      class="color-picker__input"
      type="color"
      [(ngModel)]="color"
      (change)="colorChange.emit(color)"
      (input)="updateContrastColor()" />
  </button>
</div>
