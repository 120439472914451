import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuItem } from '@services/context.service';
import {
  InspectionFinding,
  InspectionTemplateQuestion,
  InspectionValueValueBoolean,
} from '@api-clients/dossier';
import { ContextMenuDirective } from '@shared/directives/context-menu.directive';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LightswitchComponent } from '@shared/components/lightswitch/lightswitch.component';
import { ThreeDotComponent } from '@shared/components/three-dot/three-dot.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-inspection-boolean-question',
  templateUrl: './boolean-question.component.html',
  styleUrl: './boolean-question.component.scss',
  imports: [
    ContextMenuDirective,
    NgIf,
    FormsModule,
    LightswitchComponent,
    ThreeDotComponent,
    TranslateModule,
  ],
})
export class BooleanQuestionComponent implements OnInit {
  @Input() question!: InspectionTemplateQuestion | InspectionFinding;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Input() disabled = false;
  @Output() delete = new EventEmitter();
  @Output() move = new EventEmitter<number>();

  expanded = false;
  menu: ContextMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: (): boolean => !this.first,
        action: (): void => this.moveItem(-1),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: (): boolean => !this.last,
        action: (): void => this.moveItem(1),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: (): void => this.delete.emit(),
      },
    ];
  }

  get value(): boolean {
    const finding = this.question as InspectionFinding;
    if (finding.value === null || finding.value === undefined) {
      return false;
    }
    return (finding.value as InspectionValueValueBoolean).boolean;
  }

  set value(newValue: boolean) {
    const finding = this.question as InspectionFinding;
    finding.value = { boolean: newValue, type: 'Boolean' };
  }

  moveItem(movement): void {
    this.move.emit(movement);
    this.buildMenu();
  }

  isFinding(): boolean {
    return 'value' in this.question;
  }
}
