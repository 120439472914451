import { Injectable } from '@angular/core';
import {
  BimLink,
  BimLinksService as BimLinkApi,
  ConstructionReport,
  ConstructionReportsService as ConstructionReportApi,
  CreateBimLinkRequest,
  CreateNoteRequest,
  Dossier,
  DossierFile,
  DossierImage,
  DossiersService as DossierApi,
  EventsService as EventApi,
  Location,
  LocationsService as LocationApi,
  Note,
  NotesService as NoteApi,
  TimeLineDto,
  TimeLineResponse,
  TimeLineType,
} from '@api-clients/dossier';
import { UserService } from '@services/user.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DossierService {
  constructor(
    private userService: UserService,
    private dossierApi: DossierApi,
    private eventApi: EventApi,
    private locationApi: LocationApi,
    private noteApi: NoteApi,
    private constructionReportApi: ConstructionReportApi,
    private bimLinkApi: BimLinkApi
  ) {}

  async getDossier(buildingId: string): Promise<Dossier> {
    const organizationId = this.userService.organizationId;
    const dossiers: Dossier[] = await lastValueFrom(
      this.dossierApi.dossiersGet(organizationId, buildingId)
    );
    if (dossiers.length === 0) {
      throw new Error('Dossier not found');
    }
    return dossiers[0];
  }

  async postDossier(buildingId: string): Promise<Dossier> {
    const organizationId = this.userService.organizationId;
    return await lastValueFrom(
      this.dossierApi.dossiersPost({
        organization_id: organizationId,
        building_id: buildingId,
      })
    );
  }

  async getDossiersForCurrentOrganization(): Promise<Dossier[]> {
    const organizationId = this.userService.organizationId;
    return await lastValueFrom(this.dossierApi.dossiersGet(organizationId));
  }

  async getDossierEvents(
    dossierId: string,
    pageIndex: number,
    pageSize: number,
    timeLineType?: TimeLineType,
    from?: string,
    until?: string
  ): Promise<TimeLineResponse> {
    return await lastValueFrom(
      this.eventApi.dossiersDossierIdTimelineGet(
        dossierId,
        pageIndex,
        pageSize,
        timeLineType,
        from,
        until
      )
    );
  }

  async postNoteEvent(dossierId: string | undefined, event: CreateNoteRequest): Promise<Note> {
    return await lastValueFrom(this.noteApi.notePost(event, dossierId));
  }

  async postIfcEvent(dossierId: string, event: CreateBimLinkRequest): Promise<BimLink> {
    return await lastValueFrom(this.bimLinkApi.dossiersDossierIdBimLinksPost(dossierId, event));
  }

  async getLocations(): Promise<Array<Location>> {
    return await lastValueFrom(this.locationApi.locationsGet());
  }

  async getNote(item: TimeLineDto): Promise<Note> {
    return await lastValueFrom(this.noteApi.noteIdGet(item.item_id));
  }

  async getImage(id: string): Promise<DossierImage> {
    return await lastValueFrom(this.dossierApi.imageImageIdMetaGet(id));
  }

  async getFile(id: string): Promise<DossierImage> {
    return await lastValueFrom(this.dossierApi.fileFileIdMetaGet(id));
  }

  async postImage(image: Blob, description: string, dossier_id?: string): Promise<DossierImage> {
    return await lastValueFrom(this.dossierApi.imagePost(image, dossier_id, description));
  }

  async postFile(file: Blob, description: string, dossier_id?: string): Promise<DossierFile> {
    return await lastValueFrom(this.dossierApi.filePost(file, dossier_id, description));
  }

  async getImagePresignedUrl(id: string): Promise<string> {
    return await lastValueFrom(this.dossierApi.imageImageIdMetaPresignedlinkGet(id));
  }

  async getFilePresignedUrl(id: string): Promise<string> {
    return await lastValueFrom(this.dossierApi.fileFileIdMetaPresignedlinkGet(id));
  }

  async getConstructionReport(item: TimeLineDto): Promise<ConstructionReport> {
    return await lastValueFrom(
      this.constructionReportApi.dossiersDossierIdConstructionReportIdGet(
        item.dossier_id,
        item.item_id
      )
    );
  }

  async getBimLinkForDossier(item: TimeLineDto): Promise<BimLink> {
    return await lastValueFrom(
      this.bimLinkApi.dossiersDossierIdBimLinksIdGet(item.dossier_id, item.item_id)
    );
  }

  async getBimLinksForDossier(dossierId: string): Promise<BimLink[]> {
    return await lastValueFrom(this.bimLinkApi.dossiersDossierIdBimLinksGet(dossierId));
  }
}
