import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface Segment {
  translate?: string;
  constraint?: string;
  link: string;
}

export interface TreeItem {
  name?: string;
  translate?: string;
  link: string;
  queryParams?: Record<string, string>;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public tree$: BehaviorSubject<TreeItem[]> = new BehaviorSubject<TreeItem[]>([]);

  constructor(private readonly router: Router) {
    this.router.events
      .pipe(
        filter((e): e is ActivationEnd => e instanceof ActivationEnd),
        takeUntilDestroyed()
      )
      .subscribe((e) => {
        const snapshot = e.snapshot;
        const firstChild = snapshot.firstChild;
        const firstChildSegments: Segment[] = firstChild?.data['breadcrumb_segments'] ?? [];

        const showBreadCrumb = snapshot.data['breadcrumb_segments'] ?? false;

        if (showBreadCrumb) {
          const newTree = [...(snapshot.data['breadcrumb_segments'] ?? []), ...firstChildSegments]
            .map((segment: Segment) => {
              let { link, constraint, translate } = segment;

              for (const param in snapshot.params) {
                translate = translate?.replaceAll(`:${param}`, snapshot.params[param]);
                link = link?.replaceAll(`:${param}`, snapshot.params[param]);
                constraint = constraint?.replaceAll(`:${param}`, snapshot.params[param]);
              }

              if (firstChild) {
                for (const param in firstChild.params) {
                  translate = translate?.replaceAll(`:${param}`, firstChild.params[param]);
                  link = link?.replaceAll(`:${param}`, firstChild.params[param]);
                  constraint = constraint?.replaceAll(`:${param}`, firstChild.params[param]);
                }
              }

              if (!window.eval(constraint ?? 'true')) {
                // a constraint is something that is evaluated to a boolean. See building routing module for example
                return undefined;
              }

              return {
                translate,
                link,
              } as TreeItem;
            })
            .filter((item): item is TreeItem => item !== undefined);

          this.tree$.next(newTree);
        }
      });
  }
}
