<div class="calculation-panel" [class.show]="error || min || max">
  <span *ngIf="!error">
    {{ 'project-module.calculation-panel.calculation' | translate }}
    {{ variables | json }} {{ 'project-module.calculation-panel.output' | translate }}: ({{
      'project-module.calculation-panel.min' | translate
    }}
    : {{ min }}, {{ 'project-module.calculation-panel.max' | translate }}: {{ max }} )
  </span>
  <span *ngIf="error">{{ error | translate }}</span>
</div>
