import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({ selector: 'img[imgFallback]' })
export class ImgFallbackDirective {
  @Input() imgFallback?: string;

  constructor(private readonly elementRef: ElementRef) {}

  @HostListener('error')
  private loadFallbackOnError(): void {
    const element: HTMLImageElement = this.elementRef.nativeElement;
    element.src = this.imgFallback || 'https://via.placeholder.com/200';
  }
}
