/**
 * BIM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BimElement } from '../model/bimElement';
// @ts-ignore
import { BimElementDto } from '../model/bimElementDto';
// @ts-ignore
import { BuildingInformationModelDetailedDto } from '../model/buildingInformationModelDetailedDto';
// @ts-ignore
import { BuildingInformationModelDto } from '../model/buildingInformationModelDto';
// @ts-ignore
import { FusedModelDto } from '../model/fusedModelDto';
// @ts-ignore
import { Pose } from '../model/pose';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class BimService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Delete a specific Building Information Model (BIM) by its ID.
     * This endpoint allows authenticated users to delete a BIM, including its associated IFC and 3D model files from the storage bucket.  # Parameters  - &#x60;Path(bim_id)&#x60;: The UUID of the BIM to be deleted. - &#x60;auth&#x60;: The authentication information of the user making the request.  # Responses  - &#x60;204 No Content&#x60;: Successfully deleted the BIM and its associated files. - &#x60;401 Unauthorized&#x60;: If the user does not have the necessary permissions or is not authenticated. - &#x60;404 Not Found&#x60;: If the BIM with the specified ID does not exist. - &#x60;500 Internal Server Error&#x60;: If there was an error during the deletion process.  # Authorization  The user must have the &#x60;CREATE&#x60; scope for the &#x60;building_information_models&#x60; permission and must belong to the organization associated with the BIM.  # Errors  Returns a JSON error response if there is an issue with deleting the BIM or its associated files.
     * @param bimId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bimBimIdDelete(bimId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public bimBimIdDelete(bimId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public bimBimIdDelete(bimId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public bimBimIdDelete(bimId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (bimId === null || bimId === undefined) {
            throw new Error('Required parameter bimId was null or undefined when calling bimBimIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bim/${this.configuration.encodeParam({name: "bimId", value: bimId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve detailed information of a specific Building Information Model (BIM) by its ID, including 3D model URL.
     * This endpoint allows authenticated users to fetch detailed information of a BIM, including properties, levels, rooms, objects, and a presigned URL for the composite 3D model.  # Parameters  - &#x60;Path(bim_id)&#x60;: The UUID of the BIM to be retrieved. - &#x60;auth&#x60;: The authentication information of the user making the request.  # Responses  - &#x60;200 OK&#x60;: Successfully retrieved the detailed BIM information. Returns a &#x60;BuildingInformationModelDetailedDto&#x60; with the BIM data. - &#x60;401 Unauthorized&#x60;: If the user does not have the necessary permissions or is not authenticated. - &#x60;404 Not Found&#x60;: If the detailed BIM information with the specified ID does not exist. - &#x60;500 Internal Server Error&#x60;: If there was an error retrieving the BIM or generating the URL.  # Authorization  The user must have the &#x60;CREATE&#x60; scope for the &#x60;building_information_models&#x60; permission and must belong to the organization associated with the BIM.  # Errors  Returns a JSON error response if there is an issue with retrieving the BIM or generating the presigned URL.
     * @param bimId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bimBimIdDetailedModelViewGet(bimId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<BuildingInformationModelDetailedDto>;
    public bimBimIdDetailedModelViewGet(bimId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<BuildingInformationModelDetailedDto>>;
    public bimBimIdDetailedModelViewGet(bimId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<BuildingInformationModelDetailedDto>>;
    public bimBimIdDetailedModelViewGet(bimId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (bimId === null || bimId === undefined) {
            throw new Error('Required parameter bimId was null or undefined when calling bimBimIdDetailedModelViewGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bim/${this.configuration.encodeParam({name: "bimId", value: bimId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/detailed-model-view`;
        return this.httpClient.request<BuildingInformationModelDetailedDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an element from a Building Information Model (BIM).
     * This endpoint allows authenticated users to delete an element (e.g., room, level, or object) within a specified BIM by providing its unique identifiers.  # Parameters  - &#x60;Path(bim_id)&#x60;: The UUID of the BIM from which the element will be deleted. - &#x60;Path(element_id)&#x60;: The UUID of the element to be deleted. - &#x60;auth&#x60;: The authentication information of the user making the request.  # Responses  - &#x60;204 No Content&#x60;: Successfully deleted the element. No content is returned. - &#x60;401 Unauthorized&#x60;: If the user does not have the necessary permissions or is not authenticated. - &#x60;404 Not Found&#x60;: If the element does not exist in the specified BIM. - &#x60;500 Internal Server Error&#x60;: If there was an error deleting the element.  # Authorization  The user must have the &#x60;CREATE&#x60; scope for the &#x60;building_information_models&#x60; permission and must belong to the organization associated with the BIM.  # Errors  Returns a JSON error response if there is an issue with deleting the element, such as the element not being found in the BIM.
     * @param bimId
     * @param elementId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bimBimIdElementsElementIdDelete(bimId: string, elementId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public bimBimIdElementsElementIdDelete(bimId: string, elementId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public bimBimIdElementsElementIdDelete(bimId: string, elementId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public bimBimIdElementsElementIdDelete(bimId: string, elementId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (bimId === null || bimId === undefined) {
            throw new Error('Required parameter bimId was null or undefined when calling bimBimIdElementsElementIdDelete.');
        }
        if (elementId === null || elementId === undefined) {
            throw new Error('Required parameter elementId was null or undefined when calling bimBimIdElementsElementIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bim/${this.configuration.encodeParam({name: "bimId", value: bimId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/elements/${this.configuration.encodeParam({name: "elementId", value: elementId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve details of a specific element within a Building Information Model (BIM) by its ID.
     * This endpoint allows authenticated users to fetch details of a specific element within a BIM, identified by the element\&#39;s ID.  # Parameters  - &#x60;Path(bim_id)&#x60;: The UUID of the BIM containing the element. - &#x60;Path(element_id)&#x60;: The UUID of the element to be retrieved. - &#x60;auth&#x60;: The authentication information of the user making the request.  # Responses  - &#x60;200 OK&#x60;: Successfully retrieved the element details. Returns a &#x60;BimElementDto&#x60; with the element data. - &#x60;401 Unauthorized&#x60;: If the user does not have the necessary permissions or is not authenticated. - &#x60;404 Not Found&#x60;: If the element with the specified ID does not exist within the specified BIM. - &#x60;500 Internal Server Error&#x60;: If there was an error retrieving the element.  # Authorization  The user must have the &#x60;CREATE&#x60; scope for the &#x60;building_information_models&#x60; permission and must belong to the organization associated with the BIM.  # Errors  Returns a JSON error response if there is an issue with retrieving the element.
     * @param bimId
     * @param elementId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bimBimIdElementsElementIdGet(bimId: string, elementId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<BimElementDto>;
    public bimBimIdElementsElementIdGet(bimId: string, elementId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<BimElementDto>>;
    public bimBimIdElementsElementIdGet(bimId: string, elementId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<BimElementDto>>;
    public bimBimIdElementsElementIdGet(bimId: string, elementId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (bimId === null || bimId === undefined) {
            throw new Error('Required parameter bimId was null or undefined when calling bimBimIdElementsElementIdGet.');
        }
        if (elementId === null || elementId === undefined) {
            throw new Error('Required parameter elementId was null or undefined when calling bimBimIdElementsElementIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bim/${this.configuration.encodeParam({name: "bimId", value: bimId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/elements/${this.configuration.encodeParam({name: "elementId", value: elementId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<BimElementDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update details of a specific element within a Building Information Model (BIM) by its ID.
     * This endpoint allows authenticated users to update details of a specific element within a BIM, identified by the element\&#39;s ID. The element can be a room, level, or object.  # Parameters  - &#x60;Path(bim_id)&#x60;: The UUID of the BIM containing the element. - &#x60;Path(element_id)&#x60;: The UUID of the element to be updated. - &#x60;Json(element)&#x60;: The new details of the element to be updated. - &#x60;auth&#x60;: The authentication information of the user making the request.  # Responses  - &#x60;200 OK&#x60;: Successfully updated the element details. Returns a &#x60;BimElementDto&#x60; with the updated element data. - &#x60;401 Unauthorized&#x60;: If the user does not have the necessary permissions or is not authenticated. - &#x60;404 Not Found&#x60;: If the element with the specified ID does not exist within the specified BIM. - &#x60;500 Internal Server Error&#x60;: If there was an error updating the element.  # Authorization  The user must have the &#x60;CREATE&#x60; scope for the &#x60;building_information_models&#x60; permission and must belong to the organization associated with the BIM.  # Errors  Returns a JSON error response if there is an issue with updating the element.
     * @param bimId
     * @param elementId
     * @param bimElement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bimBimIdElementsElementIdPut(bimId: string, elementId: string, bimElement: BimElement, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<BimElementDto>;
    public bimBimIdElementsElementIdPut(bimId: string, elementId: string, bimElement: BimElement, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<BimElementDto>>;
    public bimBimIdElementsElementIdPut(bimId: string, elementId: string, bimElement: BimElement, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<BimElementDto>>;
    public bimBimIdElementsElementIdPut(bimId: string, elementId: string, bimElement: BimElement, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (bimId === null || bimId === undefined) {
            throw new Error('Required parameter bimId was null or undefined when calling bimBimIdElementsElementIdPut.');
        }
        if (elementId === null || elementId === undefined) {
            throw new Error('Required parameter elementId was null or undefined when calling bimBimIdElementsElementIdPut.');
        }
        if (bimElement === null || bimElement === undefined) {
            throw new Error('Required parameter bimElement was null or undefined when calling bimBimIdElementsElementIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bim/${this.configuration.encodeParam({name: "bimId", value: bimId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/elements/${this.configuration.encodeParam({name: "elementId", value: elementId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<BimElementDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: bimElement,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new element within a Building Information Model (BIM).
     * This endpoint allows authenticated users to create a new element (room, level, or object) within a specified BIM.  # Parameters  - &#x60;Path(bim_id)&#x60;: The UUID of the BIM where the new element will be created. - &#x60;Json(element)&#x60;: The details of the element to be created. - &#x60;auth&#x60;: The authentication information of the user making the request.  # Responses  - &#x60;201 Created&#x60;: Successfully created the new element. Returns a &#x60;BimElementDto&#x60; with the created element data. - &#x60;401 Unauthorized&#x60;: If the user does not have the necessary permissions or is not authenticated. - &#x60;404 Not Found&#x60;: If required levels or rooms for the element do not exist. - &#x60;500 Internal Server Error&#x60;: If there was an error creating the element.  # Authorization  The user must have the &#x60;CREATE&#x60; scope for the &#x60;building_information_models&#x60; permission and must belong to the organization associated with the BIM.  # Errors  Returns a JSON error response if there is an issue with creating the element, such as missing required levels or rooms.
     * @param bimId
     * @param bimElement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bimBimIdElementsPost(bimId: string, bimElement: BimElement, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<BimElementDto>;
    public bimBimIdElementsPost(bimId: string, bimElement: BimElement, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<BimElementDto>>;
    public bimBimIdElementsPost(bimId: string, bimElement: BimElement, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<BimElementDto>>;
    public bimBimIdElementsPost(bimId: string, bimElement: BimElement, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (bimId === null || bimId === undefined) {
            throw new Error('Required parameter bimId was null or undefined when calling bimBimIdElementsPost.');
        }
        if (bimElement === null || bimElement === undefined) {
            throw new Error('Required parameter bimElement was null or undefined when calling bimBimIdElementsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bim/${this.configuration.encodeParam({name: "bimId", value: bimId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/elements`;
        return this.httpClient.request<BimElementDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: bimElement,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve details of a specific Building Information Model (BIM) by its ID.
     * This endpoint allows authenticated users to fetch details of a BIM, including its properties and presigned URLs for 3D models if available.  # Parameters  - &#x60;Path(bim_id)&#x60;: The UUID of the BIM to be retrieved. - &#x60;auth&#x60;: The authentication information of the user making the request.  # Responses  - &#x60;200 OK&#x60;: Successfully retrieved the BIM details. Returns a &#x60;BuildingInformationModelDto&#x60; with the BIM data. - &#x60;401 Unauthorized&#x60;: If the user does not have the necessary permissions or is not authenticated. - &#x60;404 Not Found&#x60;: If the BIM with the specified ID does not exist. - &#x60;500 Internal Server Error&#x60;: If there was an error retrieving the BIM or generating the URLs.  # Authorization  The user must have the &#x60;GET&#x60; scope for the &#x60;building_information_models&#x60; permission and must belong to the organization associated with the BIM.  # Errors  Returns a JSON error response if there is an issue with retrieving the BIM or generating presigned URLs.
     * @param bimId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bimBimIdGet(bimId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<BuildingInformationModelDto>;
    public bimBimIdGet(bimId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<BuildingInformationModelDto>>;
    public bimBimIdGet(bimId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<BuildingInformationModelDto>>;
    public bimBimIdGet(bimId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (bimId === null || bimId === undefined) {
            throw new Error('Required parameter bimId was null or undefined when calling bimBimIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bim/${this.configuration.encodeParam({name: "bimId", value: bimId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<BuildingInformationModelDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the pose of a Building Information Model (BIM) by its ID. # Parameters
     * - &#x60;Path(bim_id)&#x60;: The UUID of the BIM that will have its pose updated. - &#x60;Json(pose)&#x60;: JSON body containing new lat, lng and rotations that will determine the position of the BIM on the map  # Authorization  The user must have the &#x60;CREATE&#x60; scope for the &#x60;building_information_models&#x60; permission and must belong to the organization associated with the BIM.
     * @param bimId
     * @param pose
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bimBimIdPosePut(bimId: string, pose: Pose, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public bimBimIdPosePut(bimId: string, pose: Pose, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public bimBimIdPosePut(bimId: string, pose: Pose, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public bimBimIdPosePut(bimId: string, pose: Pose, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (bimId === null || bimId === undefined) {
            throw new Error('Required parameter bimId was null or undefined when calling bimBimIdPosePut.');
        }
        if (pose === null || pose === undefined) {
            throw new Error('Required parameter pose was null or undefined when calling bimBimIdPosePut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bim/${this.configuration.encodeParam({name: "bimId", value: bimId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/pose`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: pose,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rework a Building Information Model (BIM).
     * This endpoint triggers the rework process for a specified Building Information Model (BIM) by its ID. The user must have the appropriate permission to update building information models, and the BIM must belong to the organization the user is authorized to manage.  # Parameters - &#x60;bim_id&#x60;: The UUID of the Building Information Model to rework. - &#x60;auth&#x60;: Authorization information, including permissions and organizational context.  # Responses - &#x60;200 OK&#x60;: The rework process was successfully triggered. - &#x60;403 Forbidden&#x60;: The user does not have the required permissions to perform this action. - &#x60;404 Not Found&#x60;: The specified BIM does not exist within the user\&#39;s organization. - &#x60;500 Internal Server Error&#x60;: An error occurred while trying to process the rework request.  # Errors - Returns a 403 error if the user lacks permission to update the BIM. - Returns a 404 error if the BIM ID is not found in the user\&#39;s organization. - Returns a 500 error for any other server-side issues during the rework process.  # Security This endpoint requires the user to be authenticated and have the &#x60;UPDATE&#x60; scope on &#x60;building_information_models&#x60;.
     * @param bimId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bimBimIdReworkPut(bimId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public bimBimIdReworkPut(bimId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public bimBimIdReworkPut(bimId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public bimBimIdReworkPut(bimId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (bimId === null || bimId === undefined) {
            throw new Error('Required parameter bimId was null or undefined when calling bimBimIdReworkPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bim/${this.configuration.encodeParam({name: "bimId", value: bimId, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}/rework`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve fused 3D models for specified Building Information Models (BIMs).
     * This endpoint allows authenticated users to fetch the URLs of fused 3D models associated with given BIM IDs. Only BIMs with available 3D models will be included in the response.  # Parameters  - &#x60;Query(bim_ids)&#x60;: A list of UUIDs of the BIMs for which fused 3D models are requested. - &#x60;auth&#x60;: The authentication information of the user making the request.  # Responses  - &#x60;200 OK&#x60;: Successfully retrieved the fused 3D models. Returns a list of &#x60;FusedModelDto&#x60; objects with details of the models. - &#x60;401 Unauthorized&#x60;: If the user does not have the necessary permissions or is not authenticated. - &#x60;500 Internal Server Error&#x60;: If there was an error fetching the models or generating the URLs.  # Authorization  The user must have the &#x60;CREATE&#x60; scope for the &#x60;building_information_models&#x60; permission and must belong to the organization associated with the BIMs.  # Errors  Returns a JSON error response if there is an issue with fetching the models or generating URLs.
     * @param bimIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bimFused3dModelsGet(bimIds: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<Array<FusedModelDto>>;
    public bimFused3dModelsGet(bimIds: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<Array<FusedModelDto>>>;
    public bimFused3dModelsGet(bimIds: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<Array<FusedModelDto>>>;
    public bimFused3dModelsGet(bimIds: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (bimIds === null || bimIds === undefined) {
            throw new Error('Required parameter bimIds was null or undefined when calling bimFused3dModelsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (bimIds) {
            bimIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'bim_ids');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bim/fused-3d-models`;
        return this.httpClient.request<Array<FusedModelDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload an IFC (Industry Foundation Classes) file to the system.
     * We expect a zip file with the IFC file inside. The IFC file will be extracted when processed.  This endpoint allows authenticated users to upload an IFC file associated with a specific organization. The uploaded file is stored in the bucket, and relevant entries are created in the database to manage the Building Information Model (BIM) data.  # Parameters  - &#x60;Binary(ifc)&#x60;: The binary body of the IFC file being uploaded. - &#x60;Query(organization_id)&#x60;: The UUID of the organization the IFC file is associated with. - &#x60;auth&#x60;: The authentication information of the user making the request.  # Responses  - &#x60;201 Created&#x60;: Successfully uploaded the IFC file and created the BIM entity. Returns the BIM entity details. - &#x60;401 Unauthorized&#x60;: If the user does not have the necessary permissions or is not authenticated. - &#x60;500 Internal Server Error&#x60;: If there was an error processing the file or interacting with the database.  # Authorization  The user must have the &#x60;CREATE&#x60; scope for the &#x60;building_information_models&#x60; permission and must belong to the specified organization.  # Errors  Returns a JSON error response if there is an issue with the upload or database operations.
     * @param organizationId
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bimIfcPost(organizationId: string, body: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<BuildingInformationModelDto>;
    public bimIfcPost(organizationId: string, body: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<BuildingInformationModelDto>>;
    public bimIfcPost(organizationId: string, body: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<BuildingInformationModelDto>>;
    public bimIfcPost(organizationId: string, body: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (organizationId === null || organizationId === undefined) {
            throw new Error('Required parameter organizationId was null or undefined when calling bimIfcPost.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling bimIfcPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (organizationId !== undefined && organizationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>organizationId, 'organization_id');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/octet-stream'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bim/ifc`;
        return this.httpClient.request<BuildingInformationModelDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload an USDZ (Universal Scene Description Zip) file to the system.
     * We expect a zip file with two files inside: - .usdz file - .json file The USDZ file will be extracted when processed.  This endpoint allows authenticated users to upload an USDZ file associated with a specific organization. The uploaded file is stored in the bucket, and relevant entries are created in the database to manage the Building Information Model (BIM) data.  # Parameters  * &#x60;Binary(usdz)&#x60; - The USDZ file to be uploaded, provided in binary format. * &#x60;Query(organization_id)&#x60; - The UUID of the organization to which the file belongs, provided as a query parameter. * &#x60;auth&#x60; - Authentication information for verifying the requester\&#39;s permissions and organization membership.  # Responses  - &#x60;201 Created&#x60;: Successfully uploaded the USDZ file and created the BIM entity. Returns the BIM entity details. - &#x60;401 Unauthorized&#x60;: If the user does not have the necessary permissions or is not authenticated. - &#x60;500 Internal Server Error&#x60;: If there was an error processing the file or interacting with the database.  # Authorization  The user must have the &#x60;CREATE&#x60; scope for the &#x60;building_information_models&#x60; permission and must belong to the specified organization.  # Errors  Returns a JSON error response if there is an issue with the upload or database operations.
     * @param organizationId
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bimUsdzPost(organizationId: string, body: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<BuildingInformationModelDto>;
    public bimUsdzPost(organizationId: string, body: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<BuildingInformationModelDto>>;
    public bimUsdzPost(organizationId: string, body: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<BuildingInformationModelDto>>;
    public bimUsdzPost(organizationId: string, body: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (organizationId === null || organizationId === undefined) {
            throw new Error('Required parameter organizationId was null or undefined when calling bimUsdzPost.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling bimUsdzPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (organizationId !== undefined && organizationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>organizationId, 'organization_id');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/octet-stream'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bim/usdz`;
        return this.httpClient.request<BuildingInformationModelDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
