<div class="main-menu">
  <a class="logo" href="/">
    <img alt="TwinTopics" src="/assets/logo.png" />
  </a>

<ul *ngIf="oidcSecurityService.isAuthenticated$ | async as authInfo">
  <!--      <li>
        <a
          [routerLinkActive]="['active']"
          [routerLink]="['/']"
          [routerLinkActiveOptions]="{ exact: true }">
          <span class="material-symbols-rounded">home</span>
          <span class="link">{{ 'dashboard' | translate }}</span>
        </a>
      </li>-->

    <li>
      <a [routerLinkActive]="['active']" [routerLink]="['/buildings']">
        <span class="material-symbols-rounded">deployed_code</span>
        <span class="link">{{ 'buildings' | translate }}</span>
      </a>
    </li>

    <li>
      <a
        [routerLinkActive]="['active']"
        [routerLink]="['/users/', userId]"
        [routerLinkActiveOptions]="{ exact: true }">
        <span class="material-symbols-rounded">person</span>
        <span class="link">{{ 'profile' | translate }}</span>
      </a>
    </li>
    <app-authorized-view [permission]="['all-users', 'own-organization-users']" scope="GET">
      <li>
        <a
          [routerLinkActive]="['active']"
          [routerLink]="['/users']"
          [routerLinkActiveOptions]="{ exact: true }">
          <span class="material-symbols-rounded">groups</span>
          <span class="link">{{ 'users' | translate }}</span>
        </a>
      </li>
    </app-authorized-view>
    <app-authorized-view permission="all-organizations" scope="GET">
      <li>
        <a [routerLinkActive]="['active']" [routerLink]="['/organizations']">
          <span class="material-symbols-rounded">settings</span>
          <span class="link">{{ 'organizations' | translate }}</span>
        </a>
      </li>
    </app-authorized-view>
    <app-authorized-view permission="own-organization" scope="GET">
      <li>
        <a [routerLinkActive]="['active']" [routerLink]="['/organizations/'+ userOrganizationId ]">
          <span class="material-symbols-rounded">settings</span>
          <span class="link">{{ 'organization' | translate }}</span>
        </a>
      </li>
    </app-authorized-view>
  <app-authorized-view permission="inspection-templates" scope="GET">
    <li>
      <a [routerLinkActive]="['active']" [routerLink]="['/inspection_templates']">
        <span class="material-symbols-rounded">article</span>
        <span class="link">{{ 'inspection_templates' | translate }}</span>
      </a>
    </li>
  </app-authorized-view>
    <li>
      <button (click)="logoff()">
        <span class="material-symbols-rounded">logout</span>
        <span class="link">{{ 'log-off' | translate }}</span>
      </button>
    </li>
  </ul>
</div>
