import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { InspectionTemplateDetailComponent } from './views/inspection-template-detail/inspection-template-detail.component';
import { InspectionOverviewComponent } from './views/overview-page/inspection-overview.component';
import { OverviewWrapperComponent } from './views/overview-wrapper/overview-wrapper.component';
import { InspectionTemplatesComponent } from './views/inspection-templates/inspection-templates.component';
import { InspectionDetailComponent } from './views/inspection-detail/inspection-detail.component';

const routes: Routes = [
  {
    path: 'inspections',
    canActivate: [AutoLoginPartialRoutesGuard],
    component: OverviewWrapperComponent,
    children: [
      {
        path: 'templates',
        component: InspectionTemplatesComponent,
        data: {
          breadcrumb_segments: [
            {
              translate: 'inspection-templates',
              link: '/inspections/templates',
            },
          ],
        },
      },
      {
        path: '',
        component: InspectionOverviewComponent,
        data: {
          breadcrumb_segments: [
            {
              translate: 'inspections-overview-page',
              link: '/inspections/overview',
            },
          ],
        },
      },
    ],
    data: {
      breadcrumb_segments: [],
    },
  },
  {
    path: 'inspections/templates/:id',
    component: InspectionTemplateDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        {
          translate: 'inspection-templates',
          link: '/inspections/templates',
        },
        {
          translate: 'edit',
          link: '/inspections/templates/:id',
        },
      ],
    },
  },
  {
    path: 'inspections/templates/new',
    component: InspectionTemplateDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        {
          translate: 'inspections-overview-page',
          link: '/inspections',
        },
      ],
    },
  },
  {
    path: 'inspections/:id',
    component: InspectionDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        {
          translate: 'inspections-overview-page',
          link: '/inspections',
        },
        {
          translate: 'edit',
          link: '/inspections/:id',
        },
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InspectionRoutingModule {}
