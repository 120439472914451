"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nativePathsToPaths = exports.doubleArrayToPaths = exports.nativePathsToDoubleArray = exports.pathsToNativePaths = exports.doubleArrayToNativePaths = exports.pathsToDoubleArray = void 0;
var mem_1 = require("./mem");
var PathToNativePath_1 = require("./PathToNativePath");
// js to c++
function pathsToDoubleArray(nativeClipperLib, myPaths) {
  var nofPaths = myPaths.length;
  // first calculate nof items required
  var nofItems = 1; // for path count
  for (var i = 0; i < nofPaths; i++) {
    nofItems += (0, PathToNativePath_1.getNofItemsForPath)(myPaths[i]);
  }
  var heapBytes = (0, mem_1.mallocDoubleArray)(nativeClipperLib, nofItems);
  heapBytes[0] = nofPaths;
  var ptr = 1;
  for (var i = 0; i < nofPaths; i++) {
    var path = myPaths[i];
    ptr = (0, PathToNativePath_1.writePathToDoubleArray)(path, heapBytes, ptr);
  }
  return heapBytes;
}
exports.pathsToDoubleArray = pathsToDoubleArray;
function doubleArrayToNativePaths(nativeClipperLib, array, freeArray) {
  var p = new nativeClipperLib.Paths();
  nativeClipperLib.toPaths(p, array.byteOffset);
  if (freeArray) {
    (0, mem_1.freeTypedArray)(nativeClipperLib, array);
  }
  return p;
}
exports.doubleArrayToNativePaths = doubleArrayToNativePaths;
function pathsToNativePaths(nativeClipperLib, paths) {
  var array = pathsToDoubleArray(nativeClipperLib, paths);
  return doubleArrayToNativePaths(nativeClipperLib, array, true);
}
exports.pathsToNativePaths = pathsToNativePaths;
// c++ to js
function nativePathsToDoubleArray(nativeClipperLib, nativePaths, freeNativePaths) {
  var array = nativeClipperLib.fromPaths(nativePaths);
  if (freeNativePaths) {
    nativePaths.delete();
  }
  return array;
}
exports.nativePathsToDoubleArray = nativePathsToDoubleArray;
function doubleArrayToPaths(nativeClipperLib, array, _freeDoubleArray) {
  var len = array[0];
  var paths = [];
  paths.length = len;
  var arrayI = 1;
  for (var i = 0; i < len; i++) {
    var result = (0, PathToNativePath_1.doubleArrayToPath)(nativeClipperLib, array, false, arrayI);
    paths[i] = result.path;
    arrayI = result.ptrEnd;
  }
  if (_freeDoubleArray) {
    (0, mem_1.freeTypedArray)(nativeClipperLib, array);
  }
  return paths;
}
exports.doubleArrayToPaths = doubleArrayToPaths;
function nativePathsToPaths(nativeClipperLib, nativePaths, freeNativePaths) {
  var array = nativePathsToDoubleArray(nativeClipperLib, nativePaths, freeNativePaths);
  return doubleArrayToPaths(nativeClipperLib, array, true);
}
exports.nativePathsToPaths = nativePathsToPaths;
