import { Injectable } from '@angular/core';
import Formula from 'fparser';

@Injectable({
  providedIn: 'root',
})
export class FormulaService {
  constructor() {}

  public expandFormula(formula: string): string[] {
    // Match numbers inside `{}` brackets
    const numSetRegex = /\{([\d,\s]+)}/g;

    // Extract all sets of numbers from curly braces and convert them to arrays
    const sets: number[][] = [];
    formula = formula.replace(numSetRegex, (_, numbers: string): string => {
      const values = numbers.split(',').map((num) => parseInt(num.trim(), 10));
      sets.push(values);
      return '%s'; // Placeholder for later replacement
    });

    // Generate all combinations of number sets
    function cartesianProduct(arrays: number[][]): number[][] {
      return arrays.reduce<number[][]>(
        (acc, curr) => acc.flatMap((a) => curr.map((b) => [...a, b])),
        [[]]
      );
    }

    const combinations: number[][] = cartesianProduct(sets);

    // Replace placeholders with values from each combination
    return combinations.map((combo) => {
      let replacedFormula = formula;
      let index = 0;
      replacedFormula = replacedFormula.replace(/%s/g, () => combo[index++].toString());
      return replacedFormula;
    });
  }

  public evaluateFormula(
    formula: string,
    variables: Record<string, number>
  ): { max: number; min: number; error: string | undefined } {
    const expandedFormulas = this.expandFormula(formula);
    let minValue = Infinity;
    let maxValue = -Infinity;
    let error: string | undefined = undefined;
    try {
      for (const expandedFormula of expandedFormulas) {
        const f = new Formula(expandedFormula);
        const result = f.evaluate(variables);

        if (typeof result == 'number') {
          // Track min and max

          minValue = Math.min(minValue, result);
          maxValue = Math.max(maxValue, result);
        }
      }
    } catch (e) {
      error = (e as Error).message;
      console.error(e);
    }

    return {
      min: minValue,
      max: maxValue,
      error: error,
    };
  }
}
