import {
  GenericSubject,
  GenericSubjectRealEstateBuildingReference,
  Inspection,
  InspectionFinding,
  InspectionSection,
  InspectionTemplate,
  InspectionTemplateQuestion,
  InspectionTemplateSection,
  InspectionValue,
  InspectionValueCheckListValues,
  InspectionValueRadioListValue,
  InspectionValueValueBoolean,
  InspectionValueValueImages,
  InspectionValueValueNumber,
  InspectionValueValueText,
} from '@api-clients/dossier';
import { BimSubject, BimSubjectRealEstateBuildingReference } from '@api-clients/bim';

export function isImagesAnswer(answer: InspectionValue): answer is InspectionValueValueImages {
  return answer.type === InspectionValueValueImages.TypeEnum.ImageList;
}

export function isTextAnswer(answer: InspectionValue): answer is InspectionValueValueText {
  return answer.type === InspectionValueValueText.TypeEnum.Text;
}

export function isNumberAnswer(answer: InspectionValue): answer is InspectionValueValueNumber {
  return answer.type === InspectionValueValueNumber.TypeEnum.Number;
}

export function isBooleanAnswer(answer: InspectionValue): answer is InspectionValueValueBoolean {
  return answer.type === InspectionValueValueBoolean.TypeEnum.Boolean;
}

export function isCheckListAnswer(
  answer: InspectionValue
): answer is InspectionValueCheckListValues {
  return answer.type === InspectionValueCheckListValues.TypeEnum.CheckList;
}

export function isRadioListAnswer(
  answer: InspectionValue
): answer is InspectionValueRadioListValue {
  return answer.type === InspectionValueRadioListValue.TypeEnum.RadioList;
}

export function isInspectionFinding(
  value: InspectionTemplateQuestion | InspectionFinding
): value is InspectionFinding {
  return (value as InspectionFinding).value !== undefined;
}

export function isInspectionSection(
  value: InspectionTemplateSection | InspectionSection
): value is InspectionSection {
  return (value as InspectionSection).findings !== undefined;
}

export function isInspection(value: InspectionTemplate | Inspection): value is Inspection {
  return (value as Inspection).content !== undefined;
}

export function isRealEstateBuildingIdBimSubject(
  input: BimSubject
): input is BimSubjectRealEstateBuildingReference {
  return input.type === BimSubjectRealEstateBuildingReference.TypeEnum.RealEstateBuilding;
}

export function isRealEstateBuildingIdGenericSubject(
  input: GenericSubject
): input is GenericSubjectRealEstateBuildingReference {
  return input.type === GenericSubjectRealEstateBuildingReference.TypeEnum.RealEstateBuilding;
}

export function getEnumValue<T extends Record<string, string>>(
  enumObj: T,
  value: string | null
): T[keyof T] | undefined {
  return Object.values(enumObj).find((v): v is T[keyof T] => v === value);
}
