/**
 * IMBAG API - van de LVBAG
 * Dit is de [BAG API](https://zakelijk.kadaster.nl/-/bag-api) Individuele Bevragingen van de Landelijke Voorziening Basisregistratie Adressen en Gebouwen (LVBAG).  Meer informatie over de Basisregistratie Adressen en Gebouwen is te vinden op de website van het [Ministerie van Binnenlandse Zaken en Koninkrijksrelaties](https://www.geobasisregistraties.nl/basisregistraties/adressen-en-gebouwen) en [Kadaster](https://zakelijk.kadaster.nl/bag).  De BAG API levert informatie conform de [BAG Catalogus 2018](https://www.geobasisregistraties.nl/documenten/publicatie/2018/03/12/catalogus-2018) en het informatiemodel IMBAG 2.0. De API specificatie volgt de [Nederlandse API-Strategie](https://docs.geostandaarden.nl/api/API-Strategie) specificatie versie van 20200204 en is opgesteld in [OpenAPI Specificatie](https://www.forumstandaardisatie.nl/standaard/openapi-specification) (OAS) v3.  Het standaard mediatype HAL (`application/hal+json`) wordt gebruikt. Dit is een mediatype voor het weergeven van resources en hun relaties via hyperlinks.  Deze API is vooral gericht op individuele bevragingen (op basis van de identificerende gegevens van een object). Om gebruik te kunnen maken van de BAG API is een API key nodig, deze kan verkregen worden door het [aanvraagformulier](https://formulieren.kadaster.nl/aanvraag_bag_api_individuele_bevragingen_productie) in te vullen.  Voor vragen, neem contact op met de LVBAG beheerder o.v.v. BAG API 2.0. We zijn aan het kijken naar een geschikt medium hiervoor, mede ook om de API iteratief te kunnen opstellen of doorontwikkelen samen met de community. Als de API iets (nog) niet kan, wat u wel graag wilt, neem dan contact op.
 *
 * The version of the OpenAPI document: 2.9.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent, HttpParameterCodec, HttpContext,
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BronhouderHal } from '../model/bronhouderHal';
// @ts-ignore
import { BronhouderHalCollection } from '../model/bronhouderHalCollection';
// @ts-ignore
import { ExtendedProblemDetails } from '../model/extendedProblemDetails';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface BevragenBronhouderRequestParams {
    /** Een officiële code die aan een gemeente is toegekend en ontleend is  aan tabel 33.  */
    identificatie: string;
    /** Filtert op objecten die geldig zijn op de opgegeven datum &#x60;JJJJ-MM-DD&#x60; Deze parameter komt uit de API strategie, het hoofdstuk  [Temporal](https://docs.geostandaarden.nl/api/API-Strategie-ext/#temporal).  */
    geldigOp?: string;
    /** Filtert op objecten die beschikbaar zijn op de opgegeven datum en tijd  &#x60;YYYY-MM-DDThh:mm:ss.sss\&#39;. Hierin kunnen de seconden \&#39;:ss\&#39; en milliseconden  \&#39;.sss\&#39; of alleen de milliseconden conform ISO 8601 specificatie worden  weggelaten, in dat geval krijgen deze automatisch de waarde \&#39;:00\&#39;  respectievelijk \&#39;.000\&#39;. Indien er voor de milliseconden \&#39;.sss\&#39; meer dan 3  cijfers worden opgegeven, bv. \&#39;.12345\&#39;, dan wordt dit afgekapt tot \&#39;.123\&#39;. Deze parameter komt uit de API strategie, het hoofdstuk  [Temporal](https://docs.geostandaarden.nl/api/API-Strategie-ext/#temporal).  */
    beschikbaarOp?: string;
}

export interface BronhouderIdentificatieVoorkomenRequestParams {
    /** Een officiële code die aan een gemeente is toegekend en ontleend is  aan tabel 33.  */
    identificatie: string;
    /** De versie van een voorkomen van een object. */
    versie: number;
    /** Een timestamp van het tijdstip waarop een voorkomen is geregistreerd in de LV BAG. */
    timestampRegistratieLv: string;
}

export interface ZoekBronhouderRequestParams {
    /** De identificatie van een woonplaats uit de BAG. */
    woonplaatsIdentificatie?: string;
    /** De identificatie van een object uit de BAG. Deze is 4 lang bij een  woonplaats en 16 lang bij de andere objecten.  */
    objectIdentificatie?: string;
    /** Filtert op objecten die geldig zijn op de opgegeven datum &#x60;JJJJ-MM-DD&#x60; Deze parameter komt uit de API strategie, het hoofdstuk  [Temporal](https://docs.geostandaarden.nl/api/API-Strategie-ext/#temporal).  */
    geldigOp?: string;
    /** Filtert op objecten die beschikbaar zijn op de opgegeven datum en tijd  &#x60;YYYY-MM-DDThh:mm:ss.sss\&#39;. Hierin kunnen de seconden \&#39;:ss\&#39; en milliseconden  \&#39;.sss\&#39; of alleen de milliseconden conform ISO 8601 specificatie worden  weggelaten, in dat geval krijgen deze automatisch de waarde \&#39;:00\&#39;  respectievelijk \&#39;.000\&#39;. Indien er voor de milliseconden \&#39;.sss\&#39; meer dan 3  cijfers worden opgegeven, bv. \&#39;.12345\&#39;, dan wordt dit afgekapt tot \&#39;.123\&#39;. Deze parameter komt uit de API strategie, het hoofdstuk  [Temporal](https://docs.geostandaarden.nl/api/API-Strategie-ext/#temporal).  */
    beschikbaarOp?: string;
}


@Injectable({
  providedIn: 'root'
})
export class BronhouderService {

  protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Bevragen bronhouder met de bronhouder identificatie (gemeentecode).
     * Bevragen/raadplegen van een bronhouder met de identificatie van een bronhouder (gemeentecode). De geldigOp en beschikbaarOp parameters kunnen gebruikt worden voor  tijdreis vragen, zie  [functionele specificatie tijdreizen](https://github.com/lvbag/BAG-API/blob/master/Features/tijdreizen.feature).
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bevragenBronhouder(requestParameters: BevragenBronhouderRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<BronhouderHal>;
    public bevragenBronhouder(requestParameters: BevragenBronhouderRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpResponse<BronhouderHal>>;
    public bevragenBronhouder(requestParameters: BevragenBronhouderRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpEvent<BronhouderHal>>;
    public bevragenBronhouder(requestParameters: BevragenBronhouderRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<any> {
        const identificatie = requestParameters.identificatie;
        if (identificatie === null || identificatie === undefined) {
            throw new Error('Required parameter identificatie was null or undefined when calling bevragenBronhouder.');
        }
        const geldigOp = requestParameters.geldigOp;
        const beschikbaarOp = requestParameters.beschikbaarOp;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (geldigOp !== undefined && geldigOp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>geldigOp, 'geldigOp');
        }
        if (beschikbaarOp !== undefined && beschikbaarOp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>beschikbaarOp, 'beschikbaarOp');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyBAG) required
        localVarCredential = this.configuration.lookupCredential('apiKeyBAG');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bronhouders/${this.configuration.encodeParam({name: "identificatie", value: identificatie, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<BronhouderHal>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * bevragen voorkomen van een bronhouder met de identificatie van een bronhouder en de identificatie van een voorkomen, bestaande uit een versie en een timestamp van het tijdstip van registratie in de LV BAG.
     * Bevragen/raadplegen van een voorkomen van een bronhouder met de identificatie van een bronhouder en de identificatie van een voorkomen, bestaande uit een versie en een timestamp van het tijdstip van registratie in de LV BAG.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bronhouderIdentificatieVoorkomen(requestParameters: BronhouderIdentificatieVoorkomenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<BronhouderHal>;
    public bronhouderIdentificatieVoorkomen(requestParameters: BronhouderIdentificatieVoorkomenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpResponse<BronhouderHal>>;
    public bronhouderIdentificatieVoorkomen(requestParameters: BronhouderIdentificatieVoorkomenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpEvent<BronhouderHal>>;
    public bronhouderIdentificatieVoorkomen(requestParameters: BronhouderIdentificatieVoorkomenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<any> {
        const identificatie = requestParameters.identificatie;
        if (identificatie === null || identificatie === undefined) {
            throw new Error('Required parameter identificatie was null or undefined when calling bronhouderIdentificatieVoorkomen.');
        }
        const versie = requestParameters.versie;
        if (versie === null || versie === undefined) {
            throw new Error('Required parameter versie was null or undefined when calling bronhouderIdentificatieVoorkomen.');
        }
        const timestampRegistratieLv = requestParameters.timestampRegistratieLv;
        if (timestampRegistratieLv === null || timestampRegistratieLv === undefined) {
            throw new Error('Required parameter timestampRegistratieLv was null or undefined when calling bronhouderIdentificatieVoorkomen.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyBAG) required
        localVarCredential = this.configuration.lookupCredential('apiKeyBAG');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bronhouders/${this.configuration.encodeParam({name: "identificatie", value: identificatie, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "versie", value: versie, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/${this.configuration.encodeParam({name: "timestampRegistratieLv", value: timestampRegistratieLv, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<BronhouderHal>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Bevragen bronhouder met een object identificatie.
     * Bevragen/raadplegen van een bronhouder met de identificatie van een object. De identificatie van één van de objecten: woonplaats, openbare ruimte, nummeraanduiding, ligplaats, standplaats, verblijfsobject of pand dient gespecificeerd te worden:&lt;br/&gt; De geldigOp en beschikbaarOp parameters kunnen gebruikt worden voor  tijdreis vragen, zie  [functionele specificatie tijdreizen](https://github.com/lvbag/BAG-API/blob/master/Features/tijdreizen.feature).
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoekBronhouder(requestParameters: ZoekBronhouderRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<BronhouderHalCollection>;
    public zoekBronhouder(requestParameters: ZoekBronhouderRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpResponse<BronhouderHalCollection>>;
    public zoekBronhouder(requestParameters: ZoekBronhouderRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpEvent<BronhouderHalCollection>>;
    public zoekBronhouder(requestParameters: ZoekBronhouderRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<any> {
        const woonplaatsIdentificatie = requestParameters.woonplaatsIdentificatie;
        const objectIdentificatie = requestParameters.objectIdentificatie;
        const geldigOp = requestParameters.geldigOp;
        const beschikbaarOp = requestParameters.beschikbaarOp;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (woonplaatsIdentificatie !== undefined && woonplaatsIdentificatie !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>woonplaatsIdentificatie, 'woonplaatsIdentificatie');
        }
        if (objectIdentificatie !== undefined && objectIdentificatie !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>objectIdentificatie, 'objectIdentificatie');
        }
        if (geldigOp !== undefined && geldigOp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>geldigOp, 'geldigOp');
        }
        if (beschikbaarOp !== undefined && beschikbaarOp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>beschikbaarOp, 'beschikbaarOp');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyBAG) required
        localVarCredential = this.configuration.lookupCredential('apiKeyBAG');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bronhouders`;
        return this.httpClient.request<BronhouderHalCollection>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
