import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Inspection,
  InspectionTemplatePropertyType,
  InspectionTemplateQuestion,
} from '@api-clients/dossier';
import { QuestionType } from '@api-clients/workspace';
import { NgIf } from '@angular/common';
import { OpenQuestionComponent } from '../open-question/open-question.component';
import { BooleanQuestionComponent } from '../boolean-question/boolean-question.component';
import { NumberQuestionComponent } from '../number-question/number-question.component';
import { ImagesQuestionComponent } from '../images-question/images-question.component';
import { ChecklistComponent } from '../checklist/checklist.component';

@Component({
    selector: 'app-inspection-section-item',
    templateUrl: './section-item.component.html',
    styleUrl: './section-item.component.scss',
    imports: [
        NgIf,
        OpenQuestionComponent,
        BooleanQuestionComponent,
        NumberQuestionComponent,
        ImagesQuestionComponent,
        ChecklistComponent,
    ],
})
export class SectionItemComponent {
  @Input() item!: InspectionTemplateQuestion;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Input() inspection?: Inspection;
  @Input() disabled = false;
  @Output() move = new EventEmitter<number>();
  @Output() openImage = new EventEmitter<string>();
  @Output() deleteChild = new EventEmitter<InspectionTemplateQuestion>();
  protected readonly QuestionType = QuestionType;
  protected readonly InspectionTemplatePropertyType = InspectionTemplatePropertyType;
}
