import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionComponent } from './components/section/section.component';
import { SectionItemComponent } from './components/section-item/section-item.component';
import { OpenQuestionComponent } from './components/open-question/open-question.component';
import { ImagesQuestionComponent } from './components/images-question/images-question.component';
import { ToolboxComponent } from './components/toolbox/toolbox.component';
import { ChecklistComponent } from './components/checklist/checklist.component';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkDrag, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { InspectionRoutingModule } from './inspection-routing.module';
import { InspectionTemplatesComponent } from './views/inspection-templates/inspection-templates.component';
import { InspectionTemplateDetailComponent } from './views/inspection-template-detail/inspection-template-detail.component';
import { AuthConfigModule } from '../auth/auth-config.module';
import { InspectionListComponent } from './components/inspection-list/inspection-list.component';
import { WorkspaceSelectComponent } from './components/workspace-select-dialog/workspace-select-dialog.component';
import { WorkspaceTopBarComponent } from './components/top-bar/top-bar.component';
import { WorkspaceProgressComponent } from './components/progress/progress.component';
import { WorkspaceFileUploadComponent } from './components/file-upload/file-upload.component';
import { FileSizePipe } from '@shared/pipes/file-size.pipe';
import { InspectionPopupComponent } from './components/inspection-popup/inspection-popup.component';
import { InspectionOverviewComponent } from './views/overview-page/inspection-overview.component';
import { InspectionDetailComponent } from './views/inspection-detail/inspection-detail.component';
import { InspectionResultComponent } from './components/inspection-result/inspection-result.component';
import { SecurePipe } from './pipes/secure-pipe';
import { ChooseTemplatePopupComponent } from './components/toolbox/choose-template/choose-template-popup.component';
import { BooleanQuestionComponent } from './components/boolean-question/boolean-question.component';
import { NumberQuestionComponent } from './components/number-question/number-question.component';
import { OverviewWrapperComponent } from './views/overview-wrapper/overview-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        AuthConfigModule,
        SharedModule,
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule,
        CdkDrag,
        CdkDropList,
        CdkDropListGroup,
        InspectionRoutingModule,
        SectionComponent,
        SectionItemComponent,
        OpenQuestionComponent,
        ImagesQuestionComponent,
        ToolboxComponent,
        ChecklistComponent,
        InspectionTemplatesComponent,
        InspectionTemplateDetailComponent,
        InspectionPopupComponent,
        InspectionOverviewComponent,
        InspectionListComponent,
        InspectionDetailComponent,
        InspectionResultComponent,
        BooleanQuestionComponent,
        NumberQuestionComponent,
        WorkspaceSelectComponent,
        WorkspaceTopBarComponent,
        WorkspaceProgressComponent,
        WorkspaceFileUploadComponent,
        ChooseTemplatePopupComponent,
        FileSizePipe,
        SecurePipe,
        OverviewWrapperComponent,
    ],
    exports: [
        SectionComponent,
        SectionItemComponent,
        OpenQuestionComponent,
        ImagesQuestionComponent,
        ToolboxComponent,
        ChecklistComponent,
        InspectionResultComponent,
    ],
})
export class InspectionModule {}
