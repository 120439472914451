/**
 * Product API
 * This api is used to query for products in both the 2ba and madaster data.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Dimensions { 
    width: number;
    height: number;
    depth: number;
}

