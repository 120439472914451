<div class="element-popup">
  <div class="element-popup__wrapper" [class.active]="visible">
    <div class="element-popup__header">
      <h5>
        {{ '3d-model-enrichment' | translate }}
      </h5>
      <div *ngIf="selectedElement">
        <button
          (click)="delete()"
          *ngIf="selectedElement.element['category'] === 'Installation'"
          (keydown)="delete()"
          class="material-symbols-rounded undo">
          delete
        </button>
        <button
          (click)="undo()"
          [disabled]="
            !(
              selectedElement.originalValues.size > 0 ||
              selectedElement.originalCategory !== undefined
            )
          "
          (keydown)="undo()"
          class="material-symbols-rounded undo">
          undo
        </button>
        <button
          (click)="bimPropertyService.selectBimElement(undefined)"
          (keydown)="bimPropertyService.selectBimElement(undefined)"
          class="material-symbols-rounded">
          close
        </button>
      </div>
    </div>
    <div class="element-popup__tabs">
      <button
        (click)="currentTab = elementTab.properties"
        [class.active]="currentTab === elementTab.properties">
        {{ 'add-data' | translate }}
      </button>
      <button
        (click)="dossierTimeline()"
        [class.active]="currentTab === elementTab.dossier || currentTab === elementTab.addNote">
        {{ 'notes' | translate }}
      </button>
      <button
        (click)="currentTab = elementTab.products"
        [class.active]="currentTab === elementTab.products">
        <span *ngIf="amountOfProducts">({{ amountOfProducts }})</span>
        {{ elementTab.products | translate }}
      </button>
    </div>

    <div
      class="element-popup__content"
      *ngIf="selectedElement && currentTab === elementTab.properties">
      <div class="big-input" [class.has-change]="selectedElement.originalCategory">
        <label class="title" for="category">
          {{ 'category' | translate }}
        </label>

        <select
          (ngModelChange)="selectCategory($event)"
          [disabled]="hasOriginalValue(selectedElement)"
          [ngModel]="selectedElement.element['category']"
          (change)="updateShoppingCart($event)"
          [class.has-change]="selectedElement.originalCategory"
          id="category">
          <option *ngFor="let item of categories" [value]="item">
            {{ item | lowercase | translate }}
          </option>
        </select>
      </div>
      <div *ngIf="selectedElement.propertyDefinition">
        <div *ngFor="let property of selectedElement.propertyDefinition | keyvalue">
          <div
            *ngIf="isFloatProperty(property.value)"
            class="big-input"
            [class.has-change]="selectedElement.originalValues.has(property.key)">
            <label class="title" for="{{ property.key }}">{{ property.key | translate }}</label>
            <input
              (ngModelChange)="propertyChanged(property.key, $event)"
              [disabled]="categoryIsDisabled(selectedElement)"
              [ngModel]="selectedElement.element.properties[property.key]"
              (change)="updateShoppingCart($event)"
              id="{{ property.key }}"
              type="number" />

            <label class="units" for="{{ property.key }}">
              {{ property.value.unit }}
            </label>
          </div>
          <label
            *ngIf="isBooleanProperty(property.value)"
            [class.disabled]="categoryIsDisabled(selectedElement)"
            [class.has-change]="selectedElement.originalValues.has(property.key)"
            class="checkboxcontainer big-checkbox">
            {{ property.key || '' | translate }}
            <input
              (ngModelChange)="propertyChanged(property.key, $event)"
              [disabled]="categoryIsDisabled(selectedElement)"
              [ngModel]="selectedElement.element.properties[property.key]"
              (change)="updateShoppingCart($event)"
              id="{{ property.key }}"
              type="checkbox" />
            <span class="checkmark"></span>
          </label>

          <div
            *ngIf="isEnumProperty(property.value)"
            class="big-input"
            [class.has-change]="selectedElement.originalValues.has(property.key)">
            <label class="title" for="{{ property.key }}">
              {{ property.key | translate }}
            </label>

            <select
              (ngModelChange)="propertyChanged(property.key, $event)"
              [disabled]="categoryIsDisabled(selectedElement)"
              [ngModel]="selectedElement.element.properties[property.key]"
              (change)="updateShoppingCart($event)"
              id="{{ property.key }}">
              <option [ngValue]="undefined">{{ 'unset' | translate }}</option>
              <option *ngFor="let item of property.value.values" [value]="item">
                {{ item | translate }}
              </option>
            </select>
          </div>

          <div
            *ngIf="
              isGeometryProperty(property.value) && selectedElement.element.properties[property.key]
            "
            class="big-input">
            <span class="geometry-title">
              {{ property.key | translate }}
            </span>

            <app-geometry-edit
              [box]="selectedElement.element.properties[property.key].value"
              [disabled]="categoryIsDisabled(selectedElement)"
              [originalBox]="
                $any(selectedElement.originalValues.get(property.key))?.value ?? undefined
              "
              (boxChange)="propertyChanged(property.key, $event)"
              (change)="updateShoppingCart($event)"
              id="{{ property.key }}"></app-geometry-edit>
          </div>

          <div *ngIf="isColorProperty(property.value)" class="big-input">
            <span class="title">
              {{ property.key | translate }}
            </span>

            <app-color-picker
              [class.has-change]="selectedElement.originalValues.has('color')"
              [color]="selectedElement.element.properties[property.key] ?? '#c6ed3b'"
              (colorChange)="updateColor(selectedElement, $event)"
              (change)="updateShoppingCart($event)"
              id="{{ property.key }}"></app-color-picker>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="currentTab === elementTab.dossier"
      cdkVirtualScrollingElement
      class="element-popup__content element-popup__content--timeline">
      <app-dossier-timeline
        (imageClick)="console.log('TODO goto dossier page and scroll to event and select image')"
        (timelineEventClick)="console.log('TODO goto dossier page and scroll to event')"
        [smallVersion]="true"></app-dossier-timeline>
    </div>

    <div *ngIf="currentTab === elementTab.addNote" class="element-popup__content">
      <app-add-note
        (canceled)="currentTab = elementTab.dossier"
        (noteAdded)="currentTab = elementTab.dossier"></app-add-note>
    </div>
    <div
      *ngIf="selectedElement && currentTab === elementTab.products"
      class="element-popup__content">
      <div class="element-popup__products">
        <h4 class="element-popup__products__title">
          {{ 'added-products-title' | translate }}
        </h4>
        <span *ngIf="amountOfProducts === 0" class="element-popup__products__no-result">
          {{ 'no-products-added' | translate }}
        </span>
        <ng-container *ngIf="selectedElement.getProducts() as products">
          <ul
            class="element-popup__products__list"
            [class.has-change]="selectedElement.originalValues.has('products')">
            <li *ngFor="let product of products; let i = index">
              <a (click)="openProductDetail(product); $event.preventDefault()" href="#">
                <span class="description">{{ product.description }}</span>
              </a>
              <button (click)="removeProduct(product)">
                <span class="material-symbols-rounded">close</span>
              </button>
            </li>
          </ul>
        </ng-container>
        <button (click)="openProductsAdd()" class="add-button">
          {{ 'add-products' | translate }}
          <span class="material-symbols-rounded">chevron_right</span>
        </button>
      </div>
    </div>

    <div *ngIf="currentTab !== elementTab.addNote" class="element-popup__buttons">
      <button
        (click)="currentTab = elementTab.addNote"
        *ngIf="currentTab === elementTab.dossier"
        class="secondary-button">
        {{ 'add-note' | translate }}
      </button>
    </div>
    <div
      [ngClass]="[animationParams.active ? 'animate' : '', animationParams.type]"
      [style.top]="animationParams.position"
      class="element-popup__added"></div>
  </div>

  <app-products-add-popup
    #addProductsPopup
    [active]="productsMenuState === 'add' && selectedElement !== undefined"
    (productsAddedChange)="addProducts($event)"
    (productRemovedChange)="removeProduct($event)"
    [currentProducts]="selectedElement?.getProducts()"
    (requestClose)="closeProductPopup()"></app-products-add-popup>
  <app-product-details-popup
    [active]="productsMenuState === 'detail' && selectedElement !== undefined"
    [showProduct]="detailedProduct"
    (requestClose)="closeProductPopup()"></app-product-details-popup>
  <app-confirmation-dialog
    [title]="'take-dimension-from-product-title' | translate"
    [description]="'take-dimension-from-product-description' | translate"
    [confirmText]="'take-dimension-from-product-confirm' | translate"
    (confirmOutput)="confirmDimensionChange()"></app-confirmation-dialog>

</div>
