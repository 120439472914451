/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OptionalGenericSubjectQueryGenericSubjectAddressableUnitReference { 
    optional_GenericSubject_addressable_unit_id: string;
    optional_GenericSubject_type: OptionalGenericSubjectQueryGenericSubjectAddressableUnitReference.OptionalGenericSubjectTypeEnum;
}
export namespace OptionalGenericSubjectQueryGenericSubjectAddressableUnitReference {
    export type OptionalGenericSubjectTypeEnum = 'AddressableUnit';
    export const OptionalGenericSubjectTypeEnum = {
        AddressableUnit: 'AddressableUnit' as OptionalGenericSubjectTypeEnum
    };
}


