<div class="dashboard-page">
  <app-breadcrumb></app-breadcrumb>

  <div class="dashboard-page__header">
    <div class="dashboard-page__header__info">
      <h1>{{ 'project' | translate }}: {{ project?.description ?? project?.name }}</h1>
      <h2>
        {{ 'start_date' | translate }} :
        {{ this.project?.start_date_utc ?? ('unknown' | translate) }} {{ 'end_date' | translate }} :
        {{ this.project?.end_date_utc ?? ('unknown' | translate) }}
      </h2>
    </div>

    <div class="dashboard-page__header__share">
      <div class="share"></div>
      <div class="share"></div>
      <div class="share"></div>
      <div class="share"></div>

      <div class="add"><span class="material-symbols-rounded">add</span></div>
    </div>

    <button class="button-secondary button--has-icon" (click)="openDetail()">
      <span class="material-symbols-rounded">settings</span>
      {{ 'settings' | translate }}
    </button>
  </div>

  <div
    class="dashboard-page__tiles"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    cdkDropListOrientation="mixed"
    cdkDragRootElement="app-project-dashboard-tile">
    @for (tile of tiles; track tile) {
      <app-project-dashboard-tile
        #tileComponent
        [width]="tile.width ? 'full' : 'third'"
        [title]="tile.title"
        [buttonTitle]="tile.buttonTitle ? tile.buttonTitle : ''"
        [buttonLink]="tile.buttonLink ? tile.buttonLink : ''">
        <ng-container *ngComponentOutlet="tile.content; injector: tileInjector"></ng-container>
      </app-project-dashboard-tile>
    }
  </div>
</div>
