/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OptionalMeasureSubjectQueryMeasureSubjectBimReference { 
    optional_MeasureSubject_bim_id: string;
    optional_MeasureSubject_type: OptionalMeasureSubjectQueryMeasureSubjectBimReference.OptionalMeasureSubjectTypeEnum;
}
export namespace OptionalMeasureSubjectQueryMeasureSubjectBimReference {
    export type OptionalMeasureSubjectTypeEnum = 'Bim';
    export const OptionalMeasureSubjectTypeEnum = {
        Bim: 'Bim' as OptionalMeasureSubjectTypeEnum
    };
}


