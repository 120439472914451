import { Component } from '@angular/core';
import { OrganizationDto, OrganizationsService } from '@api-clients/user';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { FormsModule } from '@angular/forms';
import { TableFilterComponent } from '@shared/components/table-filter/table-filter.component';
import { NgFor, NgIf } from '@angular/common';
import { TablePaginationComponent } from '@shared/components/table-pagination/table-pagination.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  templateUrl: './organization-overview.component.html',
  styleUrls: ['./organization-overview.component.scss'],
  imports: [
    BreadcrumbComponent,
    FormsModule,
    TableFilterComponent,
    NgFor,
    NgIf,
    TablePaginationComponent,
    ConfirmationDialogComponent,
    TranslateModule,
  ],
})
export class OrganizationOverviewComponent {
  //TO FIX eventually this needs to be done at the backend so getting only the filtered organizations instead of getting all organizations and filtering them here
  organizations: OrganizationDto[] = [];
  filteredOrganizations: OrganizationDto[] = [];
  defaultPageSize: number = 2;
  sortBy: string = '';
  sortOrder: number = 1; // 1 = ascending, -1 = descending
  searchText: string = '';
  selectedOrganization: string = '';

  // column data is added in html through a loop.
  columns = [
    { key: 'name', label: 'name' },
    { key: 'email', label: 'email' },
    { key: '', label: 'remove' },
  ];

  constructor(
    private router: Router,
    private organizationService: OrganizationsService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    organizationService.getAllOrganizations().subscribe((organizations: OrganizationDto[]) => {
      this.organizations = organizations;
      this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
      this.updateOrganizations(1, this.defaultPageSize);
    });
  }

  organizationToRemovePopup(organizationId, event): void {
    event.preventDefault();
    event.stopPropagation();
    this.selectedOrganization = organizationId;
    this.confirmationDialogService.open();
  }

  async removeOrganization(): Promise<void> {
    this.organizationService.deleteOrganization(this.selectedOrganization).subscribe(() => {
      this.organizations = this.organizations.filter(
        (organization) => organization.id !== this.selectedOrganization
      );
      this.updateOrganizations(1, this.defaultPageSize);
    });
  }

  updateOrganizations(page, size, organizations = this.organizations): void {
    this.filteredOrganizations = organizations.slice((page - 1) * size, page * size);
  }

  onPageChange(event): void {
    this.updateOrganizations(event.page, event.pageSize);
  }

  createNewOrganization(): void {
    void this.router.navigate(['/organizations/', 'new']);
  }

  goToOrganizationDetail(organizationId: string): void {
    void this.router.navigate(['/organizations/', organizationId]);
  }

  toggleSort(property: string): void {
    if (this.sortBy === property) {
      this.sortOrder = -this.sortOrder;
    } else {
      // reset to ascending if a different header is clicked
      this.sortBy = property;
      this.sortOrder = 1;
    }

    this.organizations = this.organizations.slice().sort((a, b) => {
      // the OR case is to catch empty cells
      const nameA = (a?.[property] || '').toLocaleLowerCase();
      const nameB = (b?.[property] || '').toLocaleLowerCase();
      return this.sortOrder === 1 ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });
    this.search();
  }

  search(): void {
    if (this.searchText != '') {
      const searchValue = this.searchText.toLocaleLowerCase();

      this.filteredOrganizations = this.organizations.filter((organization) => {
        return (
          organization.name?.toLocaleLowerCase().match(searchValue) ||
          organization.emailAddress?.toLocaleLowerCase().match(searchValue)
        );
      });
    } else {
      this.updateOrganizations(1, JSON.parse(localStorage.getItem('pageSize') || 'null') || 10);
    }
    this.updateOrganizations(
      1,
      JSON.parse(localStorage.getItem('pageSize') || 'null') || 10,
      this.filteredOrganizations
    );
  }
}
