"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadNativeClipperLibInstanceAsync = exports.ClipperLibWrapper = exports.ClipperError = exports.PolyTree = exports.PolyNode = exports.PointInPolygonResult = exports.NativeClipperLibRequestedFormat = exports.NativeClipperLibLoadedFormat = exports.PolyFillType = exports.JoinType = exports.EndType = exports.ClipType = void 0;
var clipFunctions_1 = require("./clipFunctions");
var ClipperError_1 = require("./ClipperError");
Object.defineProperty(exports, "ClipperError", {
  enumerable: true,
  get: function () {
    return ClipperError_1.ClipperError;
  }
});
var constants_1 = require("./constants");
var enums_1 = require("./enums");
Object.defineProperty(exports, "ClipType", {
  enumerable: true,
  get: function () {
    return enums_1.ClipType;
  }
});
Object.defineProperty(exports, "EndType", {
  enumerable: true,
  get: function () {
    return enums_1.EndType;
  }
});
Object.defineProperty(exports, "JoinType", {
  enumerable: true,
  get: function () {
    return enums_1.JoinType;
  }
});
Object.defineProperty(exports, "NativeClipperLibLoadedFormat", {
  enumerable: true,
  get: function () {
    return enums_1.NativeClipperLibLoadedFormat;
  }
});
Object.defineProperty(exports, "NativeClipperLibRequestedFormat", {
  enumerable: true,
  get: function () {
    return enums_1.NativeClipperLibRequestedFormat;
  }
});
Object.defineProperty(exports, "PointInPolygonResult", {
  enumerable: true,
  get: function () {
    return enums_1.PointInPolygonResult;
  }
});
Object.defineProperty(exports, "PolyFillType", {
  enumerable: true,
  get: function () {
    return enums_1.PolyFillType;
  }
});
var functions = require("./functions");
var offsetFunctions_1 = require("./offsetFunctions");
var PolyNode_1 = require("./PolyNode");
Object.defineProperty(exports, "PolyNode", {
  enumerable: true,
  get: function () {
    return PolyNode_1.PolyNode;
  }
});
var PolyTree_1 = require("./PolyTree");
Object.defineProperty(exports, "PolyTree", {
  enumerable: true,
  get: function () {
    return PolyTree_1.PolyTree;
  }
});
/**
 * A wrapper for the Native Clipper Library instance with all the operations available.
 */
var ClipperLibWrapper = /** @class */function () {
  /**
   * Internal constructor. Use loadNativeClipperLibInstanceAsync instead.
   *
   * @param instance
   * @param format
   */
  function ClipperLibWrapper(instance, format) {
    this.format = format;
    this.instance = instance;
  }
  /**
   * Performs a polygon clipping (boolean) operation, returning the resulting Paths or throwing an error if failed.
   *
   * The solution parameter in this case is a Paths or PolyTree structure. The Paths structure is simpler than the PolyTree structure. Because of this it is
   * quicker to populate and hence clipping performance is a little better (it's roughly 10% faster). However, the PolyTree data structure provides more
   * information about the returned paths which may be important to users. Firstly, the PolyTree structure preserves nested parent-child polygon relationships
   * (ie outer polygons owning/containing holes and holes owning/containing other outer polygons etc). Also, only the PolyTree structure can differentiate
   * between open and closed paths since each PolyNode has an IsOpen property. (The Path structure has no member indicating whether it's open or closed.)
   * For this reason, when open paths are passed to a Clipper object, the user must use a PolyTree object as the solution parameter, otherwise an exception
   * will be raised.
   *
   * When a PolyTree object is used in a clipping operation on open paths, two ancilliary functions have been provided to quickly separate out open and
   * closed paths from the solution - OpenPathsFromPolyTree and ClosedPathsFromPolyTree. PolyTreeToPaths is also available to convert path data to a Paths
   * structure (irrespective of whether they're open or closed).
   *
   * There are several things to note about the solution paths returned:
   * - they aren't in any specific order
   * - they should never overlap or be self-intersecting (but see notes on rounding)
   * - holes will be oriented opposite outer polygons
   * - the solution fill type can be considered either EvenOdd or NonZero since it will comply with either filling rule
   * - polygons may rarely share a common edge (though this is now very rare as of version 6)
   *
   * @param params - clipping operation data
   * @return {Paths} - the resulting Paths.
   */
  ClipperLibWrapper.prototype.clipToPaths = function (params) {
    return (0, clipFunctions_1.clipToPaths)(this.instance, params);
  };
  /**
   * Performs a polygon clipping (boolean) operation, returning the resulting PolyTree or throwing an error if failed.
   *
   * The solution parameter in this case is a Paths or PolyTree structure. The Paths structure is simpler than the PolyTree structure. Because of this it is
   * quicker to populate and hence clipping performance is a little better (it's roughly 10% faster). However, the PolyTree data structure provides more
   * information about the returned paths which may be important to users. Firstly, the PolyTree structure preserves nested parent-child polygon relationships
   * (ie outer polygons owning/containing holes and holes owning/containing other outer polygons etc). Also, only the PolyTree structure can differentiate
   * between open and closed paths since each PolyNode has an IsOpen property. (The Path structure has no member indicating whether it's open or closed.)
   * For this reason, when open paths are passed to a Clipper object, the user must use a PolyTree object as the solution parameter, otherwise an exception
   * will be raised.
   *
   * When a PolyTree object is used in a clipping operation on open paths, two ancilliary functions have been provided to quickly separate out open and
   * closed paths from the solution - OpenPathsFromPolyTree and ClosedPathsFromPolyTree. PolyTreeToPaths is also available to convert path data to a Paths
   * structure (irrespective of whether they're open or closed).
   *
   * There are several things to note about the solution paths returned:
   * - they aren't in any specific order
   * - they should never overlap or be self-intersecting (but see notes on rounding)
   * - holes will be oriented opposite outer polygons
   * - the solution fill type can be considered either EvenOdd or NonZero since it will comply with either filling rule
   * - polygons may rarely share a common edge (though this is now very rare as of version 6)
   *
   * @param params - clipping operation data
   * @return {PolyTree} - the resulting PolyTree.
   */
  ClipperLibWrapper.prototype.clipToPolyTree = function (params) {
    return (0, clipFunctions_1.clipToPolyTree)(this.instance, params);
  };
  /**
   * Performs a polygon offset operation, returning the resulting Paths or undefined if failed.
   *
   * This method encapsulates the process of offsetting (inflating/deflating) both open and closed paths using a number of different join types
   * and end types.
   *
   * Preconditions for offsetting:
   * 1. The orientations of closed paths must be consistent such that outer polygons share the same orientation, and any holes have the opposite orientation
   * (ie non-zero filling). Open paths must be oriented with closed outer polygons.
   * 2. Polygons must not self-intersect.
   *
   * Limitations:
   * When offsetting, small artefacts may appear where polygons overlap. To avoid these artefacts, offset overlapping polygons separately.
   *
   * @param params - offset operation params
   * @return {Paths|undefined} - the resulting Paths or undefined if failed.
   */
  ClipperLibWrapper.prototype.offsetToPaths = function (params) {
    return (0, offsetFunctions_1.offsetToPaths)(this.instance, params);
  };
  /**
   * Performs a polygon offset operation, returning the resulting PolyTree or undefined if failed.
   *
   * This method encapsulates the process of offsetting (inflating/deflating) both open and closed paths using a number of different join types
   * and end types.
   *
   * Preconditions for offsetting:
   * 1. The orientations of closed paths must be consistent such that outer polygons share the same orientation, and any holes have the opposite orientation
   * (ie non-zero filling). Open paths must be oriented with closed outer polygons.
   * 2. Polygons must not self-intersect.
   *
   * Limitations:
   * When offsetting, small artefacts may appear where polygons overlap. To avoid these artefacts, offset overlapping polygons separately.
   *
   * @param params - offset operation params
   * @return {PolyTree|undefined} - the resulting PolyTree or undefined if failed.
   */
  ClipperLibWrapper.prototype.offsetToPolyTree = function (params) {
    return (0, offsetFunctions_1.offsetToPolyTree)(this.instance, params);
  };
  //noinspection JSMethodCanBeStatic
  /**
   * This function returns the area of the supplied polygon. It's assumed that the path is closed and does not self-intersect. Depending on orientation,
   * this value may be positive or negative. If Orientation is true, then the area will be positive and conversely, if Orientation is false, then the
   * area will be negative.
   *
   * @param path - The path
   * @return {number} - Area
   */
  ClipperLibWrapper.prototype.area = function (path) {
    return functions.area(path);
  };
  /**
   * Removes vertices:
   * - that join co-linear edges, or join edges that are almost co-linear (such that if the vertex was moved no more than the specified distance the edges
   * would be co-linear)
   * - that are within the specified distance of an adjacent vertex
   * - that are within the specified distance of a semi-adjacent vertex together with their out-lying vertices
   *
   * Vertices are semi-adjacent when they are separated by a single (out-lying) vertex.
   *
   * The distance parameter's default value is approximately √2 so that a vertex will be removed when adjacent or semi-adjacent vertices having their
   * corresponding X and Y coordinates differing by no more than 1 unit. (If the egdes are semi-adjacent the out-lying vertex will be removed too.)
   *
   * @param path - The path to clean
   * @param distance - How close points need to be before they are cleaned
   * @return {Path} - The cleaned path
   */
  ClipperLibWrapper.prototype.cleanPolygon = function (path, distance) {
    if (distance === void 0) {
      distance = 1.1415;
    }
    return functions.cleanPolygon(this.instance, path, distance);
  };
  /**
   * Removes vertices:
   * - that join co-linear edges, or join edges that are almost co-linear (such that if the vertex was moved no more than the specified distance the edges
   * would be co-linear)
   * - that are within the specified distance of an adjacent vertex
   * - that are within the specified distance of a semi-adjacent vertex together with their out-lying vertices
   *
   * Vertices are semi-adjacent when they are separated by a single (out-lying) vertex.
   *
   * The distance parameter's default value is approximately √2 so that a vertex will be removed when adjacent or semi-adjacent vertices having their
   * corresponding X and Y coordinates differing by no more than 1 unit. (If the egdes are semi-adjacent the out-lying vertex will be removed too.)
   *
   * @param paths - The paths to clean
   * @param distance - How close points need to be before they are cleaned
   * @return {Paths} - The cleaned paths
   */
  ClipperLibWrapper.prototype.cleanPolygons = function (paths, distance) {
    if (distance === void 0) {
      distance = 1.1415;
    }
    return functions.cleanPolygons(this.instance, paths, distance);
  };
  //noinspection JSMethodCanBeStatic
  /**
   * This function filters out open paths from the PolyTree structure and returns only closed paths in a Paths structure.
   *
   * @param polyTree
   * @return {Paths}
   */
  ClipperLibWrapper.prototype.closedPathsFromPolyTree = function (polyTree) {
    return functions.closedPathsFromPolyTree(polyTree);
  };
  /**
   *  Minkowski Difference is performed by subtracting each point in a polygon from the set of points in an open or closed path. A key feature of Minkowski
   *  Difference is that when it's applied to two polygons, the resulting polygon will contain the coordinate space origin whenever the two polygons touch or
   *  overlap. (This function is often used to determine when polygons collide.)
   *
   * @param poly1
   * @param poly2
   * @return {Paths}
   */
  ClipperLibWrapper.prototype.minkowskiDiff = function (poly1, poly2) {
    return functions.minkowskiDiff(this.instance, poly1, poly2);
  };
  /**
   * Minkowski Addition is performed by adding each point in a polygon 'pattern' to the set of points in an open or closed path. The resulting polygon
   * (or polygons) defines the region that the 'pattern' would pass over in moving from the beginning to the end of the 'path'.
   *
   * @param pattern
   * @param path
   * @param pathIsClosed
   * @return {Paths}
   */
  ClipperLibWrapper.prototype.minkowskiSumPath = function (pattern, path, pathIsClosed) {
    return functions.minkowskiSumPath(this.instance, pattern, path, pathIsClosed);
  };
  /**
   * Minkowski Addition is performed by adding each point in a polygon 'pattern' to the set of points in an open or closed path. The resulting polygon
   * (or polygons) defines the region that the 'pattern' would pass over in moving from the beginning to the end of the 'path'.
   *
   * @param pattern
   * @param paths
   * @param pathIsClosed
   * @return {Paths}
   */
  ClipperLibWrapper.prototype.minkowskiSumPaths = function (pattern, paths, pathIsClosed) {
    return functions.minkowskiSumPaths(this.instance, pattern, paths, pathIsClosed);
  };
  //noinspection JSMethodCanBeStatic
  /**
   * This function filters out closed paths from the PolyTree structure and returns only open paths in a Paths structure.
   *
   * @param polyTree
   * @return {ReadonlyPath[]}
   */
  ClipperLibWrapper.prototype.openPathsFromPolyTree = function (polyTree) {
    return functions.openPathsFromPolyTree(polyTree);
  };
  //noinspection JSMethodCanBeStatic
  /**
   * Orientation is only important to closed paths. Given that vertices are declared in a specific order, orientation refers to the direction (clockwise or
   * counter-clockwise) that these vertices progress around a closed path.
   *
   * Orientation is also dependent on axis direction:
   * - On Y-axis positive upward displays, orientation will return true if the polygon's orientation is counter-clockwise.
   * - On Y-axis positive downward displays, orientation will return true if the polygon's orientation is clockwise.
   *
   * Notes:
   * - Self-intersecting polygons have indeterminate orientations in which case this function won't return a meaningful value.
   * - The majority of 2D graphic display libraries (eg GDI, GDI+, XLib, Cairo, AGG, Graphics32) and even the SVG file format have their coordinate origins
   * at the top-left corner of their respective viewports with their Y axes increasing downward. However, some display libraries (eg Quartz, OpenGL) have their
   * coordinate origins undefined or in the classic bottom-left position with their Y axes increasing upward.
   * - For Non-Zero filled polygons, the orientation of holes must be opposite that of outer polygons.
   * - For closed paths (polygons) in the solution returned by the clip method, their orientations will always be true for outer polygons and false
   * for hole polygons (unless the reverseSolution property has been enabled).
   *
   * @param path - Path
   * @return {boolean}
   */
  ClipperLibWrapper.prototype.orientation = function (path) {
    return functions.orientation(path);
  };
  //noinspection JSMethodCanBeStatic
  /**
   * Returns PointInPolygonResult.Outside when false, PointInPolygonResult.OnBoundary when point is on poly and PointInPolygonResult.Inside when point is in
   * poly.
   *
   * It's assumed that 'poly' is closed and does not self-intersect.
   *
   * @param point
   * @param path
   * @return {PointInPolygonResult}
   */
  ClipperLibWrapper.prototype.pointInPolygon = function (point, path) {
    return functions.pointInPolygon(point, path);
  };
  //noinspection JSMethodCanBeStatic
  /**
   * This function converts a PolyTree structure into a Paths structure.
   *
   * @param polyTree
   * @return {Paths}
   */
  ClipperLibWrapper.prototype.polyTreeToPaths = function (polyTree) {
    return functions.polyTreeToPaths(polyTree);
  };
  //noinspection JSMethodCanBeStatic
  /**
   * Reverses the vertex order (and hence orientation) in the specified path.
   *
   * @param path - Path to reverse, which gets overwritten rather than copied
   */
  ClipperLibWrapper.prototype.reversePath = function (path) {
    functions.reversePath(path);
  };
  //noinspection JSMethodCanBeStatic
  /**
   * Reverses the vertex order (and hence orientation) in each contained path.
   *
   * @param paths - Paths to reverse, which get overwritten rather than copied
   */
  ClipperLibWrapper.prototype.reversePaths = function (paths) {
    functions.reversePaths(paths);
  };
  /**
   * Removes self-intersections from the supplied polygon (by performing a boolean union operation using the nominated PolyFillType).
   * Polygons with non-contiguous duplicate vertices (ie 'touching') will be split into two polygons.
   *
   * Note: There's currently no guarantee that polygons will be strictly simple since 'simplifying' is still a work in progress.
   *
   * @param path
   * @param fillType
   * @return {Paths} - The solution
   */
  ClipperLibWrapper.prototype.simplifyPolygon = function (path, fillType) {
    if (fillType === void 0) {
      fillType = enums_1.PolyFillType.EvenOdd;
    }
    return functions.simplifyPolygon(this.instance, path, fillType);
  };
  /**
   * Removes self-intersections from the supplied polygons (by performing a boolean union operation using the nominated PolyFillType).
   * Polygons with non-contiguous duplicate vertices (ie 'vertices are touching') will be split into two polygons.
   *
   * Note: There's currently no guarantee that polygons will be strictly simple since 'simplifying' is still a work in progress.
   *
   * @param paths
   * @param fillType
   * @return {Paths} - The solution
   */
  ClipperLibWrapper.prototype.simplifyPolygons = function (paths, fillType) {
    if (fillType === void 0) {
      fillType = enums_1.PolyFillType.EvenOdd;
    }
    return functions.simplifyPolygons(this.instance, paths, fillType);
  };
  //noinspection JSMethodCanBeStatic
  /**
   * Scales a path by multiplying all its points by a number and then rounding them.
   *
   * @param path - Path to scale
   * @param scale - Scale multiplier
   * @return {Path} - The scaled path
   */
  ClipperLibWrapper.prototype.scalePath = function (path, scale) {
    return functions.scalePath(path, scale);
  };
  //noinspection JSMethodCanBeStatic
  /**
   * Scales all inner paths by multiplying all its points by a number and then rounding them.
   *
   * @param paths - Paths to scale
   * @param scale - Scale multiplier
   * @return {Paths} - The scaled paths
   */
  ClipperLibWrapper.prototype.scalePaths = function (paths, scale) {
    return functions.scalePaths(paths, scale);
  };
  /**
   * Max coordinate value (both positive and negative).
   */
  ClipperLibWrapper.hiRange = constants_1.hiRange;
  return ClipperLibWrapper;
}();
exports.ClipperLibWrapper = ClipperLibWrapper;
var wasmModule = {};
var asmJsModule = {};
function loadModule(result, requireNativeModule, format) {
  return __awaiter(this, void 0, void 0, function () {
    var createModuleAsync, library, _a, error_1;
    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          // We tried this already, and it failed?
          if (result.error) throw result.error;
          // We already have a library loaded?
          if (result.library) return [2 /*return*/, result.library];
          _b.label = 1;
        case 1:
          _b.trys.push([1, 3,, 4]);
          createModuleAsync = requireNativeModule();
          _a = ClipperLibWrapper.bind;
          return [4 /*yield*/, createModuleAsync()];
        case 2:
          library = new (_a.apply(ClipperLibWrapper, [void 0, _b.sent(), format]))();
          result.library = library;
          return [2 /*return*/, library];
        case 3:
          error_1 = _b.sent();
          result.error = error_1;
          throw error_1;
        case 4:
          return [2 /*return*/];
      }
    });
  });
}
function loadWasmModule() {
  return __awaiter(this, void 0, void 0, function () {
    return __generator(this, function (_a) {
      return [2 /*return*/, loadModule(wasmModule, function () {
        return require("./wasm/clipper-wasm");
      }, enums_1.NativeClipperLibLoadedFormat.Wasm)];
    });
  });
}
function loadAsmJsModule() {
  return __awaiter(this, void 0, void 0, function () {
    return __generator(this, function (_a) {
      return [2 /*return*/, loadModule(asmJsModule, function () {
        return require("./wasm/clipper");
      }, enums_1.NativeClipperLibLoadedFormat.AsmJs)];
    });
  });
}
/**
 * Asynchronously tries to load a new native instance of the clipper library to be shared across all method invocations.
 *
 * @param format - Format to load, either WasmThenAsmJs, WasmOnly or AsmJsOnly.
 * @return {Promise<ClipperLibWrapper>} - Promise that resolves with the wrapper instance.
 */
function loadNativeClipperLibInstanceAsync(format) {
  return __awaiter(this, void 0, void 0, function () {
    var loaders, loaders_1, loaders_1_1, loader;
    var e_1, _a;
    return __generator(this, function (_b) {
      loaders = [];
      if (format === enums_1.NativeClipperLibRequestedFormat.WasmWithAsmJsFallback || format === enums_1.NativeClipperLibRequestedFormat.WasmOnly) {
        loaders.push(loadWasmModule);
      }
      if (format === enums_1.NativeClipperLibRequestedFormat.WasmWithAsmJsFallback || format === enums_1.NativeClipperLibRequestedFormat.AsmJsOnly) {
        loaders.push(loadAsmJsModule);
      }
      try {
        for (loaders_1 = __values(loaders), loaders_1_1 = loaders_1.next(); !loaders_1_1.done; loaders_1_1 = loaders_1.next()) {
          loader = loaders_1_1.value;
          try {
            return [2 /*return*/, loader()];
          } catch (error) {
            // ignore
          }
        }
      } catch (e_1_1) {
        e_1 = {
          error: e_1_1
        };
      } finally {
        try {
          if (loaders_1_1 && !loaders_1_1.done && (_a = loaders_1.return)) _a.call(loaders_1);
        } finally {
          if (e_1) throw e_1.error;
        }
      }
      throw new ClipperError_1.ClipperError("could not load native clipper in the desired format");
    });
  });
}
exports.loadNativeClipperLibInstanceAsync = loadNativeClipperLibInstanceAsync;
