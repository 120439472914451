import { Component, Inject, OnInit } from '@angular/core';
import { PROJECT_ID } from '../dashboard.component';
import {
  MeasuresService,
  MeasureValueService,
  MeasureVariable,
  ProjectDto,
  ProjectsService,
  ScenarioDto,
  ScenariosService,
} from '@api-clients/project';
import { GenericSubjectInfoService } from '../../../services/generic_subject_info_service';
import { lastValueFrom } from 'rxjs';
import { EnrichedSubjects } from '../../measures-detail/measures-detail.component';
import { FormulaService } from '../../../services/formula.service';
import { UnitPinComponent } from '../../../components/unit-pin/unit-pin.component';
import { TranslateModule } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';

interface MeasureItem {
  measure_id: string;
  name: string;
  description: string;
  variables: Array<MeasureVariable>;
  calculations: Array<{ min: number; max: number; unit: string; formula: string }>;
}

@Component({
  selector: 'app-measures-tile',
  templateUrl: './measures-tile.component.html',
  styleUrl: './measures-tile.component.scss',
  standalone: true,
  imports: [UnitPinComponent, TranslateModule, DecimalPipe],
})
export class MeasuresTileComponent implements OnInit {
  protected project: ProjectDto | undefined;
  protected scenario: ScenarioDto | undefined;
  protected enrichedSubjects: EnrichedSubjects[] = [];
  protected measures: MeasureItem[] = [];

  constructor(
    @Inject(PROJECT_ID) protected projectId: string,
    private readonly projectsService: ProjectsService,
    private readonly measuresService: MeasuresService,
    private readonly measureValueService: MeasureValueService,
    private readonly formulaService: FormulaService,
    private readonly scenariosService: ScenariosService,
    protected readonly genericSubjectInfoService: GenericSubjectInfoService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.load();
  }

  async load(): Promise<void> {
    this.project = await lastValueFrom(this.projectsService.projectsIdGet(this.projectId));
    this.scenario = await lastValueFrom(this.scenariosService.scenariosDefaultGet(this.projectId));
    for (const subject of this.project.subjects) {
      const name = await this.genericSubjectInfoService.getNameForSubject(subject);
      this.enrichedSubjects.push({ subject: subject, name: name });
    }

    const measures = await lastValueFrom(
      this.measuresService.scenarioScenarioIdMeasuresGet(this.scenario.id)
    );

    const measureValues = await lastValueFrom(
      this.measureValueService.scenarioScenarioIdMeasureValuesGet(this.scenario.id)
    );

    this.measures = measures.map(
      (measure) =>
        <MeasureItem>{
          measure_id: measure.id,
          name: measure.name,
          description: measure.description,
          variables: measure.variables,
          calculations: measure.calculations.map(
            (calculation) =>
              <{ min: number; max: number; unit: string }>{
                min: 0,
                max: 0,
                unit: calculation.unit,
                formula: calculation.formula,
              }
          ),
        }
    );

    // Perform calculations
    for (const measure of this.measures) {
      measure.calculations.forEach((calculation) => {
        for (const measureValue of measureValues.filter(
          (m) => m.measure_id === measure.measure_id
        )) {
          const variables = Object.fromEntries(
            measure.variables.map((item) => [
              item.name,
              measureValue?.values
                .filter((v) => v.name === item.name)
                .reduce((sum, v) => sum + (v.value ?? 0), 0) || 0, // Sum all values, default to 1 if none
            ])
          );

          const result = this.formulaService.evaluateFormula(calculation.formula, variables);
          calculation.min += result.min;
          calculation.max += result.max;
        }
      });
    }
  }

  getTotalSummary(): { min: number; max: number } {
    let min = 0;
    let max = 0;
    for (const measure of this.measures) {
      for (const calculation of measure.calculations) {
        min += calculation.min;
        max += calculation.max;
      }
    }
    return { min, max };
  }

  protected readonly Object = Object;
  protected readonly JSON = JSON;
}
