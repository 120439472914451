import { Injectable } from '@angular/core';
import { BuildingOverviewService } from '@services/building-overview.service';
import { GenericSubject, MeasureSubject } from '@api-clients/project';
import {
  isAddressableUnitReference,
  isMeasureAddressableUnitReference,
  isMeasureRealEstateBuildingReference,
  isRealEstateBuildingReference,
} from '../utils';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { AddressableUnitsService } from '@api-clients/real-estate';

@Injectable({
  providedIn: 'root',
})
export class GenericSubjectInfoService {
  constructor(
    private readonly buildingOverviewService: BuildingOverviewService,
    private readonly addressableUnitsService: AddressableUnitsService
  ) {}

  public async getNameForSubject(subject: GenericSubject): Promise<string> {
    const buildings = await firstValueFrom(this.buildingOverviewService.ownedBuildings$);
    if (isRealEstateBuildingReference(subject)) {
      const building = buildings.find((i) => i.real_estate_id == subject.real_estate_building_id);
      return building?.buildingMetadata.city + ' - ' + building?.buildingMetadata.address;
    }
    if (isAddressableUnitReference(subject)) {
      const addressableUnit = await lastValueFrom(
        this.addressableUnitsService.unitsIdGet(subject.addressable_unit_id)
      );
      return (
        addressableUnit.place + ' - ' + addressableUnit.address + ' ' + addressableUnit.house_number
      );
    }
    return 'unknown';
  }

  public async getNameForMeasureSubject(subject: MeasureSubject): Promise<string> {
    const buildings = await firstValueFrom(this.buildingOverviewService.ownedBuildings$);
    if (isMeasureRealEstateBuildingReference(subject)) {
      const building = buildings.find((i) => i.real_estate_id == subject.real_estate_building_id);
      return building?.buildingMetadata.city + ' - ' + building?.buildingMetadata.address;
    }
    if (isMeasureAddressableUnitReference(subject)) {
      const addressableUnit = await lastValueFrom(
        this.addressableUnitsService.unitsIdGet(subject.addressable_unit_id)
      );
      return (
        addressableUnit.place + ' - ' + addressableUnit.address + ' ' + addressableUnit.house_number
      );
    }
    return 'unknown';
  }
}
