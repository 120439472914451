/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OptionalMeasureSubjectQueryMeasureSubjectAddressableUnitReference { 
    optional_MeasureSubject_addressable_unit_id: string;
    optional_MeasureSubject_type: OptionalMeasureSubjectQueryMeasureSubjectAddressableUnitReference.OptionalMeasureSubjectTypeEnum;
}
export namespace OptionalMeasureSubjectQueryMeasureSubjectAddressableUnitReference {
    export type OptionalMeasureSubjectTypeEnum = 'AddressableUnit';
    export const OptionalMeasureSubjectTypeEnum = {
        AddressableUnit: 'AddressableUnit' as OptionalMeasureSubjectTypeEnum
    };
}


