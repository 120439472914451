export interface PropertyCategory {
  name: string;
  properties: Array<Property>;
}

export interface Property {
  name: string;
  data_type: string;
  unit?: string;
}

export interface ProductProperty extends Property {
  data_type: 'products';
}

export interface EnumProperty extends Property {
  data_type: 'enum';
  values: string[];
}

export interface BooleanProperty extends Property {
  data_type: 'boolean';
}

export interface FloatProperty extends Property {
  data_type: 'float';
}

export interface IntegerProperty extends Property {
  data_type: 'integer';
}

export interface GeometryProperty extends Property {
  data_type: 'geometry';
}

export interface DegradationProperty extends Property {
  data_type: 'degradation';
}

export function isProductProperty(property: Property): property is ProductProperty {
  return property.data_type === 'products';
}

export function isEnumProperty(property: Property): property is EnumProperty {
  return property.data_type === 'enum';
}

export function isGeometryProperty(property: Property): property is GeometryProperty {
  return property.data_type === 'geometry';
}

export function isDegradationProperty(property: Property): property is DegradationProperty {
  return property.data_type === 'degradation';
}
export function isBooleanProperty(property: Property): property is BooleanProperty {
  return property.data_type === 'boolean';
}

export function isFloatProperty(property: Property): property is FloatProperty {
  return property.data_type === 'float';
}

export function isIntegerProperty(property: Property): property is IntegerProperty {
  return property.data_type === 'integer';
}
export function isColorProperty(property: Property): property is GeometryProperty {
  return property.data_type === 'color';
}
