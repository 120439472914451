<div class="model-viewer">
  <div class="big-spinner" *ngIf="threed.modelState === 'loading' || isSaving"></div>
  <div class="model-viewer-no-model" *ngIf="threed.modelState === 'error'">
    {{ 'no-3d-model-available' | translate }}
  </div>

  <app-threed #threed></app-threed>
  <div class="sidebar" *ngIf="threed.modelState === 'loaded'">
    <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

    <div class="sidebar__content" [class.hide]="visibleMenu !== 'overview'">
      <app-residence-sidebar
        (toggleCategoryVisibility)="threed.toggleCategoryVisibility($event)"
        [categories]="threed.objectCategories"
        (toggleLevelVisibility)="threed.toggleLevelVisibility($event)"
        [levels]="threed.levels" />
    </div>
    <div class="sidebar__content" [class.hide]="visibleMenu !== 'details'">
      <app-element-popup></app-element-popup>
    </div>
    <div class="sidebar__content" [class.hide]="visibleMenu !== 'changes'">
      <app-changes-summary></app-changes-summary>
    </div>
  </div>

  <button
    class="button-primary cta-button"
    *ngIf="visibleMenu !== 'changes'"
    [disabled]="shoppingCartCount === 0"
    [class.animate]="bounceShoppingCartButton"
    (click)="showShoppingCart()">
    ({{ shoppingCartCount }}) {{ 'changes' | translate }}
  </button>
  <button
    class="button-primary cta-button"
    *ngIf="visibleMenu === 'changes'"
    [disabled]="shoppingCartCount === 0 || isSaving"
    (click)="saveShoppingCart()">
    <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
    {{ 'save' | translate }}
  </button>
</div>
