import { ObjectCategory, RoomCategory } from '@api-clients/bim/model/models';
import { Mesh } from 'three';

export class NoGeomBuildingObject {
  bimId: string;
  rooms: Room[] = [];
  levels: Level[] = [];
  category?: Category;
  colorCache?: Uint8Array;

  constructor(bim_id: string, rooms: Room[], levels: Level[], category?: Category) {
    this.bimId = bim_id;
    this.rooms = rooms;
    this.levels = levels;
    this.category = category;
  }

  hasGeometry(): boolean {
    return false;
  }
}

export class MeshBuildingObject extends NoGeomBuildingObject {
  //an entire mesh object for installations
  mesh: Mesh;

  constructor(bim_id: string, mesh: Mesh, rooms: Room[], levels: Level[], category?: Category) {
    super(bim_id, rooms, levels, category);
    this.mesh = mesh;
  }

  override hasGeometry(): boolean {
    return this.mesh !== undefined;
  }
}

export class IndicesBuildingObject extends NoGeomBuildingObject {
  // This links to a mesh index icw a start and end index in the vertex buffer
  indices: [number, [number, number]]; // [meshIndex, [start, end]]
  batchId: number;

  constructor(
    bim_id: string,
    batchId: number,
    indices: [meshId: number, [vertexBufferStartIndex: number, vertexBufferEndIndex: number]],
    rooms: Room[],
    levels: Level[],
    category?: Category
  ) {
    super(bim_id, rooms, levels, category);
    this.indices = indices;
    this.batchId = batchId;
  }

  override hasGeometry(): boolean {
    return this.indices !== undefined;
  }
}

export class Level {
  bimId: string;
  level: number; // Level number (0 = ground floor)
  name: string; // Level name
  objects: NoGeomBuildingObject[]; // BimObjects on this level
  rooms: Room[]; // Rooms on this level
  visible = true;

  constructor(
    bimId: string,
    name: string,
    level: number,
    rooms: Room[],
    objects: NoGeomBuildingObject[]
  ) {
    this.bimId = bimId;
    this.name = name;
    this.level = level;
    this.rooms = rooms;
    this.objects = objects;
  }
}

export class Category {
  type: ObjectCategory;
  objects: NoGeomBuildingObject[];
  visible = true;

  constructor(type: ObjectCategory, elements: NoGeomBuildingObject[]) {
    this.type = type;
    this.objects = elements;
  }
}

export class Room {
  bimId: string;
  category: RoomCategory;
  objects: NoGeomBuildingObject[] = [];
  levels: Level[] = [];
  batchId?: number;
  indices: [meshIndex: number, [start: number, end: number]] | undefined;

  constructor(
    bimId: string,
    category: RoomCategory,
    objects: NoGeomBuildingObject[],
    levels: Level[],
    batchId?: number,
    indices?: [meshIndex: number, [start: number, end: number]]
  ) {
    this.bimId = bimId;
    this.category = category;
    this.objects = objects;
    this.levels = levels;
    this.batchId = batchId;
    this.indices = indices;
  }
}
