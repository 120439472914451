<div class="map-wrap">
  <div #map class="map"></div>

  <div class="layer-position" [class.sidebar-active]="sidebarActive">
    <map2d-custom-layers-legend
      #legendControl
      [source]="customLayers"
      [active]="legendActive"
      (closeEvent)="legendActive = false"
      (setSelected)="setSelected($event)"></map2d-custom-layers-legend>

    <div
      class="layers"
      [class.legend-active]="legendActive"
      [class.has-custom]="customLayers.length">
      <div class="base">
        <div class="image"><img src="assets/layers/base.png" alt="Layers" /></div>
      </div>

      <div class="options">
        <button
          class="option"
          [class.active]="this.activeLayer === 'normal'"
          (click)="changeLayer('normal')">
          <div class="label">{{ 'map-type' | translate }}</div>
          <div class="image"><img src="assets/layers/base.png" alt="Normal" /></div>
          <span>{{ 'normal' | translate }}</span>
        </button>
        <button
          class="option"
          [class.active]="this.activeLayer === 'satellite'"
          (click)="changeLayer('satellite')">
          <div class="image"><img src="assets/layers/satelite.png" alt="Satellite" /></div>
          <span>{{ 'satellite' | translate }}</span>
        </button>

        <button
          class="option"
          [class.active]="this.activeLayer === 'ahn4'"
          (click)="changeLayer('ahn4')">
          <div class="image"><img src="assets/layers/ahn.png" alt="AHN4" /></div>
          <span>{{ 'AHN4' | translate }}</span>
        </button>

        <div class="spacer"></div>

        <button class="option" [class.active]="showBag3DLayer" (click)="change3DLayer()">
          <div class="label">{{ 'map-layers' | translate }}</div>
          <div class="image"><img src="assets/layers/threed.png" alt="3D buildings" /></div>
          <span>{{ '3d_buildings' | translate }}</span>
        </button>

        <button class="option" [class.active]="showCadastralLayer" (click)="changeCadastralLayer()">
          <div class="image"><img src="assets/layers/kadastral.png" alt="Cadastral" /></div>
          <span>{{ 'cadastral_borders' | translate }}</span>
        </button>

        <!-- Or other layers button -->
        <button class="option" (click)="legendActive = !legendActive" *ngIf="customLayers.length">
          <div class="more"><span class="material-symbols-rounded">layers</span></div>
          <span>{{ 'Custom layers' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
