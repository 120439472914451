import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { MeasureTemplateDetailComponent } from './views/measure-template-detail/measure-template-detail.component';
import { MeasureTemplateOverviewComponent } from './views/measure-template-overview/measure-template-overview.component';
import { ProjectOverviewComponent } from './views/project-overview/project-overview.component';
import { ProjectDetailComponent } from './views/project-detail/project-detail.component';
import { ProjectDashboardComponent } from './views/dashboard/dashboard.component';
import { MeasuresDetailComponent } from './views/measures-detail/measures-detail.component';

const routes: Routes = [
  {
    path: 'measure-template',
    component: MeasureTemplateOverviewComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        { translate: 'project-module.measure-template.overview-page', link: '/measure-template' },
      ],
    },
  },
  {
    path: 'measure-template/new',
    component: MeasureTemplateDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        {
          translate: 'project-module.measure-template.page',
          link: '/measure-template',
        },
        { translate: 'project-module.measure-template.detail-page' },
      ],
    },
  },
  {
    path: 'measure-template/:id',
    component: MeasureTemplateDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        {
          translate: 'project-module.measure-template.page',
          link: '/measure-template',
        },
        { translate: 'project-module.measure-template.detail-page' },
      ],
    },
  },
  {
    path: 'project',
    component: ProjectOverviewComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        { translate: 'project-module.project.overview-page', link: '/project' },
      ],
    },
  },
  {
    path: 'project/:id/dashboard',
    component: ProjectDashboardComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        { translate: 'project-module.project.overview-page', link: '/project' },
        { translate: 'project-module.project.dashboard' },
      ],
    },
  },
  {
    path: 'project/:id/dashboard/measures',
    component: MeasuresDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        { translate: 'project-module.project.overview-page', link: '/project' },
        { translate: 'project-module.project.dashboard', link: '/project/:id/dashboard' },
        { translate: 'project-module.measure.detail-page' },
      ],
    },
  },
  {
    path: 'project/new',
    component: ProjectDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        { translate: 'project-module.project.overview-page', link: '/project' },
        { translate: 'project-module.project.detail-page' },
      ],
    },
  },
  {
    path: 'project/:id',
    component: ProjectDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        { translate: 'project-module.project.overview-page', link: '/project' },
        { translate: 'project-module.project.detail-page' },
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectRoutingModule {}
