import {
  Category,
  IndicesBuildingObject,
  Level,
  MeshBuildingObject,
  NoGeomBuildingObject,
  Room,
} from '@shared/services/assets/building-elements';
import {
  BimObjectGraphDto,
  BuildingInformationModelDetailedDto,
  ObjectCategory,
} from '@api-clients/bim';
import {
  Box3,
  BufferGeometry,
  Camera,
  Color,
  Group,
  Intersection,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Raycaster,
  Vector2,
} from 'three';
import { uniq } from 'ramda';
import { generateUUID } from 'three/src/math/MathUtils.js';
import { boxFromMatrix } from '../../../building-module/views/model-viewer/utils/geometry';

interface HighlightedElement {
  id: string;
  indices: [number, [number, number]];
  colors: Uint8Array;
}

export class BuildingModel extends Group {
  categories: Category[];
  levels: Level[];
  rooms: Room[];
  objects: NoGeomBuildingObject[];

  installationObjects = new Group();
  raycaster: Raycaster = new Raycaster();
  boundingBox!: Box3;
  highlightedElements: HighlightedElement[] = [];
  hiddenBimIds = new Set<string>();

  constructor(model: Group, bim: BuildingInformationModelDetailedDto) {
    super();

    // Intended use: objectIndices[meshIndex][batchId] = [startIndex, endIndex]
    // Currently, with only 2 meshes per model (opaque and transparent), this has the shape of [2][maxBatchId]
    const objectIndices: ([number, number] | undefined)[][] = [];
    model.children.forEach((child) => {
      const bufferGeometry = child['geometry'] as BufferGeometry;
      // The vertex batch id buffer stores the batch id for each vertex
      // This batch id is linked 1-to-1 to a bim id using the batch table that's also in the glb file.
      const vertexBatchIdBuffer = bufferGeometry.getAttribute('_batch_ids').array as Uint32Array;
      const max = vertexBatchIdBuffer.at(-1)!;
      // This code fills each batch id with the start and end index of the vertices that belong to that batch id
      const indices: ([number, number] | undefined)[] = Array(max).fill(undefined);
      let lastValue = vertexBatchIdBuffer.at(0)!;
      let lastIndex = 0;
      for (let i = 0; i < vertexBatchIdBuffer.length; i++) {
        const value = vertexBatchIdBuffer.at(i)!;
        if (value != lastValue) {
          indices[lastValue] = [lastIndex, i];
          lastIndex = i;
          lastValue = value;
          i += 2; // Minimum of 3 vertices per object, so we can skip 2
        }
      }
      indices.push([lastIndex, vertexBatchIdBuffer.length]);

      objectIndices.push(indices);
    });
    // Object indices now contains the start and end index of each object in the vertex buffer for each mesh and each batch id.

    const levels: Level[] = bim.levels.map(
      (level) => new Level(level.id, level.name, level.level, [], [])
    );

    const categories = categoriesFromBuildingObjects(bim.objects);

    const rooms: Room[] = bim.rooms.map((room) => {
      const batchId = room.mesh_id;
      const indices: [meshId: number, [vBufferStartId: number, vBufferEndId: number]] | undefined =
        batchId
          ? objectIndices
              .map((objectIndicesInMesh, meshIndex): [number, [number, number]] | undefined => {
                return objectIndicesInMesh[batchId] === undefined
                  ? undefined
                  : [meshIndex, objectIndicesInMesh[batchId]!];
              })
              .find((indices) => indices !== undefined)
          : undefined;
      return new Room(
        room.id,
        room.category,
        [],
        levels.filter((level) => room.levels.includes(level.bimId)),
        batchId,
        indices
      );
    });

    // Rooms have links to levels. Now add links from levels to rooms
    for (const room of rooms) {
      for (const level of room.levels) {
        level.rooms.push(room);
      }
    }

    // Construct building objects
    const objects = bim.objects.map((object) => {
      const objectLevels = levels.filter((level) => object.levels.includes(level.bimId));
      const objectRooms = rooms.filter((room) => object.rooms.includes(room.bimId));
      const objectCategory = categories.find((category) => category.type === object.category);
      const batchId = object.mesh_id;
      let buildingElement: NoGeomBuildingObject;
      if (object.geometry) {
        const newMesh = boxFromMatrix(new Matrix4().fromArray(object.geometry.value));
        newMesh.name = object.id;
        (newMesh.material as MeshBasicMaterial).color.set(new Color(object.color ?? '#c6ed3b'));
        this.installationObjects.add(newMesh);
        buildingElement = new MeshBuildingObject(
          object.id,
          newMesh,
          objectRooms,
          objectLevels,
          objectCategory
        );
      } else if (batchId !== undefined && batchId !== null) {
        // Each element can claim its own indices from the objectIndices array based on its mesh_id
        const indices: [meshId: number, [vBufferStartId: number, vBufferEndId: number]] =
          objectIndices
            .map((objectIndicesInMesh, meshIndex): [number, [number, number]] | undefined => {
              return objectIndicesInMesh[batchId] === undefined
                ? undefined
                : [meshIndex, objectIndicesInMesh[batchId]!];
            })
            .find((indices) => indices !== undefined)!;
        buildingElement = new IndicesBuildingObject(
          object.id,
          batchId,
          indices,
          objectRooms,
          objectLevels,
          objectCategory
        );
      } else {
        buildingElement = new NoGeomBuildingObject(
          object.id,
          objectRooms,
          objectLevels,
          objectCategory
        );
      }

      // Element already links to levels. Now also reverse the link
      for (const level of buildingElement.levels) {
        level.objects.push(buildingElement);
      }
      // Element already links to rooms. Now also reverse the link
      for (const room of buildingElement.rooms) {
        room.objects.push(buildingElement);
      }
      // If element already links to category. Now also reverse the link
      if (buildingElement.category) {
        buildingElement.category.objects.push(buildingElement);
      }

      this.raycast = (raycaster: Raycaster, intersects: Intersection[]): void => {
        const visibleIntersects = raycaster
          .intersectObjects(this.children, true)
          .filter((intersect) => {
            if (!intersect.face) return;
            const bufferGeometry = intersect.object['geometry'] as BufferGeometry | undefined;
            if (!bufferGeometry?.hasAttribute('_batch_ids')) return intersect.object.visible;
            const colors = bufferGeometry.getAttribute('color').array;
            const opacity = colors[intersect.face.a * 4 + 3];
            return opacity > 0;
          });
        intersects.push(...visibleIntersects);
      };

      return buildingElement;
    });
    this.children = model.children;
    this.add(this.installationObjects);
    this.objects = objects;
    this.rooms = rooms;
    this.levels = levels;
    this.categories = categories;
    this.boundingBox = new Box3().setFromObject(model);
    this.raycaster = new Raycaster();
    model.children.forEach((child) => {
      if (!child['isMesh']) return;
      const mesh = child as Mesh;
      mesh.geometry.computeVertexNormals();
      (mesh.material as MeshStandardMaterial).depthWrite = true;
    });
  }

  toggleCategoryVisibility(category: ObjectCategory): void {
    const categoryGroup = this.categories.find((categoryGroup) => categoryGroup.type === category);
    if (!categoryGroup) throw new Error('Category not found');
    this.setCategoryVisibility(category, !categoryGroup.visible);
  }

  setCategoryVisibility(category: ObjectCategory, visibility: boolean): void {
    const categoryGroup = this.categories.find((categoryGroup) => categoryGroup.type === category);
    if (!categoryGroup) throw new Error('Category not found');
    if (visibility) this.unhideCategory(categoryGroup);
    else this.hideCategory(categoryGroup);
  }

  toggleLevelVisibility(level: number): void {
    const levelGroup = this.levels.find((levelGroup) => levelGroup.level === level);
    if (!levelGroup) throw new Error('Level not found');
    this.setLevelVisibility(level, !levelGroup.visible);
  }

  setLevelVisibility(level: number, visibility: boolean): void {
    const levelGroup = this.levels.find((levelGroup) => levelGroup.level === level);
    if (!levelGroup) throw new Error('Level not found');
    if (visibility) this.unhideLevel(levelGroup);
    else this.hideLevel(levelGroup);
  }

  highlightElement(elementId: string): void {
    // Highlight the element in the buffer geometries
    const element =
      this.objects.find((object) => object.bimId === elementId) ??
      this.rooms.find((room) => room.bimId === elementId) ??
      this.levels.find((level) => level.bimId === elementId);
    if (!element) throw 'Requested element' + elementId + 'not found';

    const indices: [number, [number, number]] = element['indices'];
    const mesh: Mesh = element['mesh'];
    if (indices !== undefined) {
      const mesh = this.children[indices[0]];
      const bufferGeometry = mesh['geometry'] as BufferGeometry;
      const colorBuffer = bufferGeometry.getAttribute('color').array as Uint8Array;

      const colors = colorBuffer.subarray(indices[1][0] * 4, indices[1][1] * 4);
      const length = colors.length / 4;
      this.highlightedElements.push({
        id: elementId,
        indices,
        colors: Uint8Array.from(colors),
      });
      for (let i = 0; i < length; i++) {
        colors.set([100, 110, 181, 255], i * 4);
      }
      bufferGeometry.getAttribute('color').needsUpdate = true;
    } else if (mesh) {
      // Highlight the element in the installation objects
      for (const child of this.installationObjects.children) {
        if (child.name === elementId) {
          const material = (child as Mesh).material;
          (child as Mesh).userData['originalMaterial'] = material;
          const currentColor = (material as MeshStandardMaterial).color;
          (child as Mesh).material = new MeshStandardMaterial({
            color: adjustBrightness(currentColor.getHexString(), -0.1),
          });
        }
      }
    } else {
      throw 'Element has no geometry';
    }
  }

  setColorForCurrentInstallation(element_id: string, color: string): void {
    for (const child of this.installationObjects.children) {
      if (child.name === element_id) {
        (child as Mesh).userData['originalMaterial'].color.set(new Color(color));
      }
    }
  }

  unHighlightAllElements(): void {
    // Unhighlight all elements in the buffer geometries
    for (const element of this.highlightedElements) {
      const bufferGeometry = this.children[element.indices[0]]['geometry'] as BufferGeometry;
      const colorBuffer = bufferGeometry.getAttribute('color').array as Uint8Array;
      colorBuffer.set(element.colors, element.indices[1][0] * 4);
      bufferGeometry.getAttribute('color').needsUpdate = true;
    }
    // Unhighlight all elements in the installation objects
    for (const child of this.installationObjects.children) {
      if ((child as Mesh).userData['originalMaterial']) {
        (child as Mesh).material = (child as Mesh).userData['originalMaterial'];
        delete (child as Mesh).userData['originalMaterial'];
      }
    }
    this.highlightedElements = [];
  }

  public getFirstVisibleObjectIntersection(
    camera: Camera,
    mouseCoords: Vector2
  ): { id: string; intersection: Intersection } | undefined {
    const ids = this.objects.map((element) => element.bimId);
    return this.getFirstVisibleIntersection(camera, mouseCoords, ids);
  }

  /// The updateMap should contain the old element id as key and the new element id as value
  public updateElementIds(updateMap: { oldId: string; newId: string }[]): void {
    updateMap.forEach(({ oldId, newId }) => {
      const object = this.objects.find((object) => object.bimId === oldId);
      if (!object) return;
      object.bimId = newId;
      if (object instanceof MeshBuildingObject) {
        object.mesh.name = newId;
      }
    });
  }

  public getMeshIfInstallation(id: string): Mesh | undefined {
    const obj = this.objects.find(
      (o) => o.bimId === id && o instanceof MeshBuildingObject
    ) as MeshBuildingObject;
    return obj?.mesh;
  }

  // Returns the generated id of the installation
  public addInstallation(installation: Mesh, levelIds: string[], roomIds: string[]): string {
    const rooms = this.rooms.filter((room) => roomIds.includes(room.bimId));
    const levels = this.levels.filter((level) => levelIds.includes(level.bimId));
    let category = this.categories.find(
      (category) => category.type === ObjectCategory.Installation
    );
    if (!category) {
      this.categories = [...this.categories, new Category(ObjectCategory.Installation, [])];
      category = this.categories.find((category) => category.type === ObjectCategory.Installation);
    }

    const obj = new MeshBuildingObject(generateUUID(), installation, rooms, levels, category);

    installation.name = obj.bimId;
    this.installationObjects.add(installation);
    this.objects.push(obj);

    //link to level and room, and add meshbimobject references to rooms/levels
    for (const room of rooms) {
      room.objects.push(obj);
    }
    for (const level of levels) {
      level.objects.push(obj);
    }

    return installation.name;
  }

  public hideInstallation(id: string): void {
    const item = this.installationObjects.getObjectByName(id);
    if (!item) return;
    item.visible = false;
  }

  public showInstallation(id: string): void {
    const item = this.installationObjects.getObjectByName(id);
    if (!item) return;
    item.visible = true;
  }

  public removeInstallation(id: string): void {
    this.installationObjects.getObjectByName(id)?.removeFromParent();
    for (const room of this.rooms) {
      room.objects = room.objects.filter((object) => object.bimId !== id);
    }
    for (const level of this.levels) {
      level.objects = level.objects.filter((object) => object.bimId !== id);
    }
    this.objects = this.objects.filter((object) => object.bimId !== id);
  }

  private hideCategory(category: Category): void {
    const toBeHidden = category.objects
      .filter((object) => !this.hiddenBimIds.has(object.bimId))
      .filter((object) => object.hasGeometry());
    this.hideBuildingObjects(toBeHidden);
    category.visible = false;
  }

  private hideLevel(level: Level): void {
    const toBeHidden = level.objects
      .filter((object) => !this.hiddenBimIds.has(object.bimId))
      .filter((object) => object.hasGeometry());
    this.hideBuildingObjects(toBeHidden);
    level.visible = false;
  }

  private unhideCategory(category: Category): void {
    const toBeUnhidden = category.objects.filter(
      (object) =>
        this.hiddenBimIds.has(object.bimId) &&
        object.hasGeometry() &&
        !object.levels.some((level) => !level.visible) // Only unhide objects on visible levels
    );
    this.unhideBuildingObjects(toBeUnhidden);
    category.visible = true;
  }

  private unhideLevel(level: Level): void {
    const toBeUnhidden = level.objects.filter(
      (object) =>
        this.hiddenBimIds.has(object.bimId) &&
        object.hasGeometry() &&
        object.category?.visible === true // Only unhide objects with visible categories
    );
    this.unhideBuildingObjects(toBeUnhidden);
    level.visible = true;
  }

  private unhideBuildingObjects(objects: NoGeomBuildingObject[]): void {
    objects.forEach((object) => this.hiddenBimIds.delete(object.bimId));

    const buffers = this.children
      .filter((child): child is Mesh => child['isMesh'])
      .map((child) => child.geometry.getAttribute('color').array as Uint8Array);
    for (const object of objects) {
      if (object instanceof MeshBuildingObject) {
        object.mesh.visible = true;
      } else if (object instanceof IndicesBuildingObject) {
        const [meshIndex, indices] = object.indices;
        buffers[meshIndex].set(object.colorCache!, indices[0] * 4 + 3);
        delete object.colorCache;
      }
    }
    this.children
      .filter((child): child is Mesh => child['isMesh'])
      .forEach((child) => (child.geometry.getAttribute('color').needsUpdate = true));
  }

  private hideBuildingObjects(objects: NoGeomBuildingObject[]): void {
    objects.forEach((object) => {
      this.hiddenBimIds.add(object.bimId);
    });

    const buffers = this.children
      .filter((child): child is Mesh => child['isMesh'])
      .map((child) => child.geometry.getAttribute('color').array as Uint8Array);

    for (const object of objects) {
      if (object instanceof MeshBuildingObject) {
        object.mesh.visible = false;
      } else if (object instanceof IndicesBuildingObject) {
        const buffer = buffers[object.indices[0]];
        const startColorIndex = object.indices[1][0] * 4 + 3;
        const endColorIndex = object.indices[1][1] * 4 + 3;
        object.colorCache = Uint8Array.from(buffer.subarray(startColorIndex, endColorIndex));
        for (let i = startColorIndex; i < endColorIndex; i += 4) {
          buffer[i] = 0;
        }
      }
    }

    this.children
      .filter((child): child is Mesh => child['isMesh'])
      .forEach((child) => (child.geometry.getAttribute('color').needsUpdate = true));
  }

  private getFirstVisibleIntersection(
    camera: Camera,
    mouseCoords: Vector2,
    allowedUuids: string[]
  ): { id: string; intersection: Intersection } | undefined {
    this.raycaster.setFromCamera(mouseCoords, camera);
    const intersects = this.raycaster.intersectObject(this, true);

    for (const intersect of intersects) {
      let intersectionAndId: { id: string; intersection: Intersection } | undefined;
      if (!intersect.face) continue;
      const bufferGeometry = intersect.object['geometry'] as BufferGeometry | undefined;
      if (bufferGeometry?.hasAttribute('_batch_ids')) {
        // Handle the glb meshes using their buffers to check for transparency
        const colors = bufferGeometry.getAttribute('color').array;
        const transparency = colors[intersect.face.a * 4 + 3]; // get rgbA value of 1st vertex of the face
        if (transparency === 0) continue;
        const batchIds = bufferGeometry.getAttribute('_batch_ids').array;
        const batchId = batchIds[intersect.face.a];
        intersectionAndId = {
          intersection: intersect,
          id: this.objects.find((object) => object['batchId'] === batchId)!.bimId,
        };
      } else if (intersect.object.visible) {
        // Handle the installation objects as direct meshes
        intersectionAndId = { intersection: intersect, id: intersect.object.name };
      }
      // If the uuid is allowed, return it. This prevents room/level selection.
      if (intersectionAndId && allowedUuids.includes(intersectionAndId.id)) {
        return intersectionAndId;
      }
    }
    return undefined;
  }
}

// Gets the categories out of the objects and makes a unique list of them
function categoriesFromBuildingObjects(objects: BimObjectGraphDto[]): Category[] {
  return uniq(objects.map((object) => object.category)).map(
    (category) => new Category(category, [])
  );
}

function adjustBrightness(hex: string, factor = 0.2): string {
  // Ensure hex format is correct
  if (hex.startsWith('#')) hex = hex.slice(1);

  // Convert hex to RGB
  const num = parseInt(hex, 16);
  let r = (num >> 16) + factor * 255;
  let g = ((num >> 8) & 0x00ff) + factor * 255;
  let b = (num & 0x0000ff) + factor * 255;

  // Clamp values to the 0-255 range
  r = Math.min(255, Math.max(0, Math.round(r)));
  g = Math.min(255, Math.max(0, Math.round(g)));
  b = Math.min(255, Math.max(0, Math.round(b)));

  // Convert RGB back to hex
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}
