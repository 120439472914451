import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-unit-pin',
  templateUrl: './unit-pin.component.html',
  styleUrl: './unit-pin.component.scss',
  standalone: true,
  imports: [NgIf],
})
export class UnitPinComponent {
  @Input() title: string = '';
  @Input() count: string | number = '';
  @Input() style: 'primary' | 'secondary' = 'primary';
}
