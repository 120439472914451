<div class="floor-plan-viewer">
  <div
    class="big-spinner"
    *ngIf="
      (floorPlan.currentLevel < 0 || this.isSaving) && !floorPlan.hasError && !noLinkedBimAvailable
    "></div>
  <div
    class="floor-plan-viewer__overlay"
    [class.show]="
      (floorPlan.currentLevel < 0 || this.isSaving) && !floorPlan.hasError && !noLinkedBimAvailable
    "></div>

  <div class="floor-plan-viewer-no-model" *ngIf="noLinkedBimAvailable || floorPlan.hasError">
    {{ 'no-floor-plan-available' | translate }}
  </div>

  <div class="floor-plan-viewer__top-bar" #topBar>
    <app-breadcrumb></app-breadcrumb>

    <button (click)="downloadSvg()" class="button-secondary button--has-icon">
      <span class="material-symbols-rounded">download</span>
      {{ 'download' | translate }}
    </button>
    <button
      class="button-primary cta-button"
      *ngIf="visibleMenu !== 'changes'"
      [disabled]="shoppingCartCount === 0"
      [class.animate]="bounceShoppingCartButton"
      (click)="showShoppingCart()">
      ({{ shoppingCartCount }}) {{ 'changes' | translate }}
    </button>
    <button
      class="button-primary cta-button"
      *ngIf="visibleMenu === 'changes'"
      [disabled]="shoppingCartCount === 0 || isSaving"
      (click)="saveShoppingCart()">
      <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
      {{ 'save' | translate }}
    </button>
  </div>

  <div class="sidebar">
    <div class="big-input select-version" *ngIf="this.floorPlan.floorPlan">
      <select
        [(ngModel)]="this.floorPlan.currentLevel"
        (change)="selectBimId(undefined)"
        id="current_level_select">
        <option
          [ngValue]="index"
          *ngFor="let level of this.floorPlan.floorPlan.levelNames; index as index"
          [disabled]="!this.floorPlan.floorPlan.levels.at(index)">
          {{ level }}
        </option>
      </select>
    </div>
    <div class="sidebar__content" [class.hide]="visibleMenu !== 'details'">
      <app-element-popup [availableHeight]="availableHeight"></app-element-popup>
    </div>
    <div class="sidebar__content" [class.hide]="visibleMenu !== 'changes'">
      <app-changes-summary [availableHeight]="availableHeight"></app-changes-summary>
    </div>
  </div>

  <div class="floor-plan__container">
    <app-floor-plan
      #floorPlan
      [bimId]="bimId"
      [selectedElementId]="selectedElementId"
      (bimIdClicked)="selectBimId($event)"></app-floor-plan>

    <div class="navigation-group" *ngIf="floorPlan.currentLevel >= 0">
      <button (click)="floorPlan.rotate()">
        <span class="material-symbols-rounded">rotate_right</span>
      </button>
      <button (click)="floorPlan.resetViewPort()">
        <span class="material-symbols-rounded">reset_image</span>
      </button>
      <button (click)="floorPlan.zoomOut()">
        <span class="material-symbols-rounded">zoom_out</span>
      </button>
      <button (click)="floorPlan.zoomIn()">
        <span class="material-symbols-rounded">zoom_in</span>
      </button>
    </div>
  </div>
</div>
