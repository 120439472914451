"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PolyTree = void 0;
var PolyNode_1 = require("./PolyNode");
/**
 * PolyTree is intended as a read-only data structure that should only be used to receive solutions from clipping and offsetting operations. It's an
 * alternative to the Paths data structure which also receives these solutions. PolyTree's two major advantages over the Paths structure are: it properly
 * represents the parent-child relationships of the returned polygons; it differentiates between open and closed paths. However, since PolyTree is a more
 * complex structure than the Paths structure, and since it's more computationally expensive to process (the Execute method being roughly 5-10% slower), it
 * should used only be when parent-child polygon relationships are needed, or when open paths are being 'clipped'.
 *
 * A PolyTree object is a container for any number of PolyNode children, with each contained PolyNode representing a single polygon contour (either an outer
 * or hole polygon). PolyTree itself is a specialized PolyNode whose immediate children represent the top-level outer polygons of the solution. (It's own
 * Contour property is always empty.) The contained top-level PolyNodes may contain their own PolyNode children representing hole polygons that may also
 * contain children representing nested outer polygons etc. Children of outers will always be holes, and children of holes will always be outers.
 *
 * PolyTrees can also contain open paths. Open paths will always be represented by top level PolyNodes. Two functions are provided to quickly separate out
 * open and closed paths from a polytree - openPathsFromPolyTree and closedPathsFromPolyTree.
 */
var PolyTree = /** @class */function (_super) {
  __extends(PolyTree, _super);
  function PolyTree() {
    var _this = _super.call(this) || this;
    _this._total = 0;
    return _this;
  }
  Object.defineProperty(PolyTree.prototype, "total", {
    /**
     * Returns the total number of PolyNodes (polygons) contained within the PolyTree. This value is not to be confused with childs.length which returns the
     * number of immediate children only (Childs) contained by PolyTree.
     */
    get: function () {
      return this._total;
    },
    enumerable: false,
    configurable: true
  });
  /**
   * This method returns the first outer polygon contour if any, otherwise undefined.
   *
   * This function is equivalent to calling childs[0].
   */
  PolyTree.prototype.getFirst = function () {
    if (this.childs.length > 0) {
      return this.childs[0];
    } else {
      return undefined;
    }
  };
  /**
   * Internal use.
   * Constructs a PolyTree from a native PolyTree.
   */
  PolyTree.fromNativePolyTree = function (nativeLib, nativePolyTree, freeNativePolyTree) {
    var pt = new PolyTree();
    PolyNode_1.PolyNode.fillFromNativePolyNode(pt, nativeLib, nativePolyTree, undefined, 0, false); // do NOT free them, they are freed on destruction of the polytree
    pt._total = nativePolyTree.total();
    if (freeNativePolyTree) {
      nativePolyTree.delete(); // this deletes all inner paths, contours etc
    }
    return pt;
  };
  return PolyTree;
}(PolyNode_1.PolyNode);
exports.PolyTree = PolyTree;
