/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Dimensions } from './dimensions';


export interface MediaTypeVideo { 
    duration_seconds?: number;
    dimensions?: Dimensions;
    type: MediaTypeVideo.TypeEnum;
}
export namespace MediaTypeVideo {
    export type TypeEnum = 'Video';
    export const TypeEnum = {
        Video: 'Video' as TypeEnum
    };
}


