/**
 * IMBAG API - van de LVBAG
 * Dit is de [BAG API](https://zakelijk.kadaster.nl/-/bag-api) Individuele Bevragingen van de Landelijke Voorziening Basisregistratie Adressen en Gebouwen (LVBAG).  Meer informatie over de Basisregistratie Adressen en Gebouwen is te vinden op de website van het [Ministerie van Binnenlandse Zaken en Koninkrijksrelaties](https://www.geobasisregistraties.nl/basisregistraties/adressen-en-gebouwen) en [Kadaster](https://zakelijk.kadaster.nl/bag).  De BAG API levert informatie conform de [BAG Catalogus 2018](https://www.geobasisregistraties.nl/documenten/publicatie/2018/03/12/catalogus-2018) en het informatiemodel IMBAG 2.0. De API specificatie volgt de [Nederlandse API-Strategie](https://docs.geostandaarden.nl/api/API-Strategie) specificatie versie van 20200204 en is opgesteld in [OpenAPI Specificatie](https://www.forumstandaardisatie.nl/standaard/openapi-specification) (OAS) v3.  Het standaard mediatype HAL (`application/hal+json`) wordt gebruikt. Dit is een mediatype voor het weergeven van resources en hun relaties via hyperlinks.  Deze API is vooral gericht op individuele bevragingen (op basis van de identificerende gegevens van een object). Om gebruik te kunnen maken van de BAG API is een API key nodig, deze kan verkregen worden door het [aanvraagformulier](https://formulieren.kadaster.nl/aanvraag_bag_api_individuele_bevragingen_productie) in te vullen.  Voor vragen, neem contact op met de LVBAG beheerder o.v.v. BAG API 2.0. We zijn aan het kijken naar een geschikt medium hiervoor, mede ook om de API iteratief te kunnen opstellen of doorontwikkelen samen met de community. Als de API iets (nog) niet kan, wat u wel graag wilt, neem dan contact op.
 *
 * The version of the OpenAPI document: 2.9.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ExtendedProblemDetails } from '../model/extendedProblemDetails';
// @ts-ignore
import { LigplaatsIOHal } from '../model/ligplaatsIOHal';
// @ts-ignore
import { LigplaatsIOHalCollection } from '../model/ligplaatsIOHalCollection';
// @ts-ignore
import { LigplaatsIOLvcHalCollection } from '../model/ligplaatsIOLvcHalCollection';
// @ts-ignore
import { PointGeoJSON } from '../model/pointGeoJSON';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface LigplaatsGeometrieRequestParams {
    /** Geometrisch punt waarop de ligplaatsen bepaald moeten worden. */
    pointGeoJSON: PointGeoJSON;
    /** Filtert op objecten die geldig zijn op de opgegeven datum &#x60;JJJJ-MM-DD&#x60; Deze parameter komt uit de API strategie, het hoofdstuk  [Temporal](https://docs.geostandaarden.nl/api/API-Strategie-ext/#temporal).  */
    geldigOp?: string;
    /** Filtert op objecten die beschikbaar zijn op de opgegeven datum en tijd  &#x60;YYYY-MM-DDThh:mm:ss.sss\&#39;. Hierin kunnen de seconden \&#39;:ss\&#39; en milliseconden  \&#39;.sss\&#39; of alleen de milliseconden conform ISO 8601 specificatie worden  weggelaten, in dat geval krijgen deze automatisch de waarde \&#39;:00\&#39;  respectievelijk \&#39;.000\&#39;. Indien er voor de milliseconden \&#39;.sss\&#39; meer dan 3  cijfers worden opgegeven, bv. \&#39;.12345\&#39;, dan wordt dit afgekapt tot \&#39;.123\&#39;. Deze parameter komt uit de API strategie, het hoofdstuk  [Temporal](https://docs.geostandaarden.nl/api/API-Strategie-ext/#temporal).  */
    beschikbaarOp?: string;
    /** Indicatie dat alleen actuele object voorkomens zonder eindstatus gewenst zijn. */
    huidig?: boolean;
    /** Hier kan aangegeven worden welke gerelateerde resources meegeladen moeten worden. Resources en velden van resources die gewenst zijn kunnen in de expand parameter kommagescheiden worden opgegeven. Specifieke velden van resource kunnen worden opgegeven door het opgeven van de resource-naam gevolgd door de veldnaam, met daartussen een punt. */
    expand?: string;
    /** CRS van de meegegeven geometrie. */
    contentCrs?: 'epsg:28992';
    /** Gewenste CRS van de coördinaten in de response. */
    acceptCrs?: 'epsg:28992';
}

export interface LigplaatsIdentificatieRequestParams {
    /** De identificatie van een ligplaats uit de BAG. */
    identificatie: string;
    /** Filtert op objecten die geldig zijn op de opgegeven datum &#x60;JJJJ-MM-DD&#x60; Deze parameter komt uit de API strategie, het hoofdstuk  [Temporal](https://docs.geostandaarden.nl/api/API-Strategie-ext/#temporal).  */
    geldigOp?: string;
    /** Filtert op objecten die beschikbaar zijn op de opgegeven datum en tijd  &#x60;YYYY-MM-DDThh:mm:ss.sss\&#39;. Hierin kunnen de seconden \&#39;:ss\&#39; en milliseconden  \&#39;.sss\&#39; of alleen de milliseconden conform ISO 8601 specificatie worden  weggelaten, in dat geval krijgen deze automatisch de waarde \&#39;:00\&#39;  respectievelijk \&#39;.000\&#39;. Indien er voor de milliseconden \&#39;.sss\&#39; meer dan 3  cijfers worden opgegeven, bv. \&#39;.12345\&#39;, dan wordt dit afgekapt tot \&#39;.123\&#39;. Deze parameter komt uit de API strategie, het hoofdstuk  [Temporal](https://docs.geostandaarden.nl/api/API-Strategie-ext/#temporal).  */
    beschikbaarOp?: string;
    /** Hier kan aangegeven worden welke gerelateerde resources meegeladen moeten worden. Resources en velden van resources die gewenst zijn kunnen in de expand parameter kommagescheiden worden opgegeven. Specifieke velden van resource kunnen worden opgegeven door het opgeven van de resource-naam gevolgd door de veldnaam, met daartussen een punt. */
    expand?: string;
    /** Gewenste CRS van de coördinaten in de response. */
    acceptCrs?: 'epsg:28992';
    /** Indicatie dat alleen actuele object voorkomens zonder eindstatus gewenst zijn. */
    huidig?: boolean;
}

export interface LigplaatsIdentificatieVoorkomenRequestParams {
    /** De identificatie van een ligplaats uit de BAG. */
    identificatie: string;
    /** De versie van een voorkomen van een object. */
    versie: number;
    /** Een timestamp van het tijdstip waarop een voorkomen is geregistreerd in de LV BAG. */
    timestampRegistratieLv: string;
    /** Gewenste CRS van de coördinaten in de response. */
    acceptCrs?: 'epsg:28992';
}

export interface LigplaatsLvcIdentificatieRequestParams {
    /** De identificatie van een ligplaats uit de BAG. */
    identificatie: string;
    /** Filtert naast geldige voorkomens ook de voorkomens die uit de geldige levenscyclus van het object zijn verwijderd. Als deze parameter wordt weggelaten worden alleen geldige voorkomens geretourneerd (default is false).  */
    geheleLvc?: boolean;
    /** Gewenste CRS van de coördinaten in de response. */
    acceptCrs?: 'epsg:28992';
}

export interface ZoekLigplaatsenRequestParams {
    /** Filtert op objecten die geldig zijn op de opgegeven datum &#x60;JJJJ-MM-DD&#x60; Deze parameter komt uit de API strategie, het hoofdstuk  [Temporal](https://docs.geostandaarden.nl/api/API-Strategie-ext/#temporal).  */
    geldigOp?: string;
    /** Filtert op objecten die beschikbaar zijn op de opgegeven datum en tijd  &#x60;YYYY-MM-DDThh:mm:ss.sss\&#39;. Hierin kunnen de seconden \&#39;:ss\&#39; en milliseconden  \&#39;.sss\&#39; of alleen de milliseconden conform ISO 8601 specificatie worden  weggelaten, in dat geval krijgen deze automatisch de waarde \&#39;:00\&#39;  respectievelijk \&#39;.000\&#39;. Indien er voor de milliseconden \&#39;.sss\&#39; meer dan 3  cijfers worden opgegeven, bv. \&#39;.12345\&#39;, dan wordt dit afgekapt tot \&#39;.123\&#39;. Deze parameter komt uit de API strategie, het hoofdstuk  [Temporal](https://docs.geostandaarden.nl/api/API-Strategie-ext/#temporal).  */
    beschikbaarOp?: string;
    /** Indicatie dat alleen actuele object voorkomens zonder eindstatus gewenst zijn. */
    huidig?: boolean;
    /** Hier kan aangegeven worden welke gerelateerde resources meegeladen moeten worden. Resources en velden van resources die gewenst zijn kunnen in de expand parameter kommagescheiden worden opgegeven. Specifieke velden van resource kunnen worden opgegeven door het opgeven van de resource-naam gevolgd door de veldnaam, met daartussen een punt. */
    expand?: string;
    /** CRS van de meegegeven geometrie. */
    contentCrs?: 'epsg:28992';
    /** Gewenste CRS van de coördinaten in de response. */
    acceptCrs?: 'epsg:28992';
    /** Pagina nummer */
    page?: number;
    /** Aantal resultaten per pagina */
    pageSize?: number;
    /** Punt conform OGC API Features standaard. Met de content-crs header wordt aangegeven in welk CRS de coördinaten van het punt is.   */
    point?: PointGeoJSON;
    /** Rechthoek (bounding box) waarbinnen het object dat je zoekt valt. Voor een definitie van bbox, zie [OGC API Features specificatie - bbox definitie](https://docs.opengeospatial.org/is/17-069r3/17-069r3.html#ats_core_fc-bbox-definition). Met de content-crs header wordt aangegeven in welk CRS de coördinaten van de bbox zijn.  Coördinaten worden als volgt opgegeven: linksonder x, linksonder y, rechtsboven x, rechtsboven y. De oppervlakte van de bounding box mag maximaal 250.000 vierkante meter zijn.  */
    bbox?: Array<number>;
}


@Injectable({
  providedIn: 'root'
})
export class LigplaatsService {

    protected basePath = 'https://api.bag.kadaster.nl/lvbag/individuelebevragingen/v2';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * bevragen van een ligplaats, op basis van een geometrische locatie.
     * Bevragen/raadplegen van één of meer ligplaatsen met een geometrische locatie. Parameter huidig kan worden toegepast, zie [functionele specificatie huidig](https://github.com/lvbag/BAG-API/blob/master/Features/huidig.feature). De geldigOp en beschikbaarOp parameters kunnen gebruikt worden voor  tijdreis vragen, zie  [functionele specificatie tijdreizen](https://github.com/lvbag/BAG-API/blob/master/Features/tijdreizen.feature).  Als expand&#x3D;heeftAlsHoofdAdres, heeftAlsNevenAdres of true dan worden de gevraagde of alle objecten als geneste resource geleverd, zie [functionele specificatie expand](https://github.com/lvbag/BAG-API/blob/master/Features/expand.feature).
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ligplaatsGeometrie(requestParameters: LigplaatsGeometrieRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<LigplaatsIOHalCollection>;
    public ligplaatsGeometrie(requestParameters: LigplaatsGeometrieRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpResponse<LigplaatsIOHalCollection>>;
    public ligplaatsGeometrie(requestParameters: LigplaatsGeometrieRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpEvent<LigplaatsIOHalCollection>>;
    public ligplaatsGeometrie(requestParameters: LigplaatsGeometrieRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<any> {
        const pointGeoJSON = requestParameters.pointGeoJSON;
        if (pointGeoJSON === null || pointGeoJSON === undefined) {
            throw new Error('Required parameter pointGeoJSON was null or undefined when calling ligplaatsGeometrie.');
        }
        const geldigOp = requestParameters.geldigOp;
        const beschikbaarOp = requestParameters.beschikbaarOp;
        const huidig = requestParameters.huidig;
        const expand = requestParameters.expand;
        const contentCrs = requestParameters.contentCrs;
        const acceptCrs = requestParameters.acceptCrs;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (geldigOp !== undefined && geldigOp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>geldigOp, 'geldigOp');
        }
        if (beschikbaarOp !== undefined && beschikbaarOp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>beschikbaarOp, 'beschikbaarOp');
        }
        if (huidig !== undefined && huidig !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>huidig, 'huidig');
        }
        if (expand !== undefined && expand !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>expand, 'expand');
        }

        let localVarHeaders = this.defaultHeaders;
        if (contentCrs !== undefined && contentCrs !== null) {
            localVarHeaders = localVarHeaders.set('Content-Crs', String(contentCrs));
        }
        if (acceptCrs !== undefined && acceptCrs !== null) {
            localVarHeaders = localVarHeaders.set('Accept-Crs', String(acceptCrs));
        }

        let localVarCredential: string | undefined;
        // authentication (apiKeyBAG) required
        localVarCredential = this.configuration.lookupCredential('apiKeyBAG');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ligplaatsen`;
        return this.httpClient.request<LigplaatsIOHalCollection>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: pointGeoJSON,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * bevragen 1 ligplaats met de identificatie van een ligplaats.
     * Bevragen/raadplegen van een voorkomen van een ligplaats met de identificatie van de ligplaats. Parameter huidig kan worden toegepast, zie [functionele specificatie huidig](https://github.com/lvbag/BAG-API/blob/master/Features/huidig.feature). De geldigOp en beschikbaarOp parameters kunnen gebruikt worden voor  tijdreis vragen, zie  [functionele specificatie tijdreizen](https://github.com/lvbag/BAG-API/blob/master/Features/tijdreizen.feature).  Als expand&#x3D;heeftAlsHoofdAdres, heeftAlsNevenAdres of true dan worden de gevraagde of alle objecten als geneste resource geleverd, zie [functionele specificatie expand](https://github.com/lvbag/BAG-API/blob/master/Features/expand.feature).
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ligplaatsIdentificatie(requestParameters: LigplaatsIdentificatieRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<LigplaatsIOHal>;
    public ligplaatsIdentificatie(requestParameters: LigplaatsIdentificatieRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpResponse<LigplaatsIOHal>>;
    public ligplaatsIdentificatie(requestParameters: LigplaatsIdentificatieRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpEvent<LigplaatsIOHal>>;
    public ligplaatsIdentificatie(requestParameters: LigplaatsIdentificatieRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<any> {
        const identificatie = requestParameters.identificatie;
        if (identificatie === null || identificatie === undefined) {
            throw new Error('Required parameter identificatie was null or undefined when calling ligplaatsIdentificatie.');
        }
        const geldigOp = requestParameters.geldigOp;
        const beschikbaarOp = requestParameters.beschikbaarOp;
        const expand = requestParameters.expand;
        const acceptCrs = requestParameters.acceptCrs;
        const huidig = requestParameters.huidig;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (geldigOp !== undefined && geldigOp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>geldigOp, 'geldigOp');
        }
        if (beschikbaarOp !== undefined && beschikbaarOp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>beschikbaarOp, 'beschikbaarOp');
        }
        if (expand !== undefined && expand !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>expand, 'expand');
        }
        if (huidig !== undefined && huidig !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>huidig, 'huidig');
        }

        let localVarHeaders = this.defaultHeaders;
        if (acceptCrs !== undefined && acceptCrs !== null) {
            localVarHeaders = localVarHeaders.set('Accept-Crs', String(acceptCrs));
        }

        let localVarCredential: string | undefined;
        // authentication (apiKeyBAG) required
        localVarCredential = this.configuration.lookupCredential('apiKeyBAG');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ligplaatsen/${this.configuration.encodeParam({name: "identificatie", value: identificatie, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<LigplaatsIOHal>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * bevragen voorkomen van een ligplaats met de identificatie van een ligplaats en de identificatie van een voorkomen, bestaande uit een versie en een timestamp van het tijdstip van registratie in de LV BAG.
     * Bevragen/raadplegen van een voorkomen van een ligplaats met de identificatie van een ligplaats en de identificatie van een voorkomen, bestaande uit een versie en een timestamp van het tijdstip van registratie in de LV BAG.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ligplaatsIdentificatieVoorkomen(requestParameters: LigplaatsIdentificatieVoorkomenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<LigplaatsIOHal>;
    public ligplaatsIdentificatieVoorkomen(requestParameters: LigplaatsIdentificatieVoorkomenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpResponse<LigplaatsIOHal>>;
    public ligplaatsIdentificatieVoorkomen(requestParameters: LigplaatsIdentificatieVoorkomenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpEvent<LigplaatsIOHal>>;
    public ligplaatsIdentificatieVoorkomen(requestParameters: LigplaatsIdentificatieVoorkomenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<any> {
        const identificatie = requestParameters.identificatie;
        if (identificatie === null || identificatie === undefined) {
            throw new Error('Required parameter identificatie was null or undefined when calling ligplaatsIdentificatieVoorkomen.');
        }
        const versie = requestParameters.versie;
        if (versie === null || versie === undefined) {
            throw new Error('Required parameter versie was null or undefined when calling ligplaatsIdentificatieVoorkomen.');
        }
        const timestampRegistratieLv = requestParameters.timestampRegistratieLv;
        if (timestampRegistratieLv === null || timestampRegistratieLv === undefined) {
            throw new Error('Required parameter timestampRegistratieLv was null or undefined when calling ligplaatsIdentificatieVoorkomen.');
        }
        const acceptCrs = requestParameters.acceptCrs;

        let localVarHeaders = this.defaultHeaders;
        if (acceptCrs !== undefined && acceptCrs !== null) {
            localVarHeaders = localVarHeaders.set('Accept-Crs', String(acceptCrs));
        }

        let localVarCredential: string | undefined;
        // authentication (apiKeyBAG) required
        localVarCredential = this.configuration.lookupCredential('apiKeyBAG');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ligplaatsen/${this.configuration.encodeParam({name: "identificatie", value: identificatie, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "versie", value: versie, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/${this.configuration.encodeParam({name: "timestampRegistratieLv", value: timestampRegistratieLv, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<LigplaatsIOHal>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * bevragen levenscyclus van een ligplaats met de identificatie van een ligplaats.
     * Bevragen/raadplegen van de levenscyclus van een ligplaats, via de identificatie van de ligplaats.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ligplaatsLvcIdentificatie(requestParameters: LigplaatsLvcIdentificatieRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<LigplaatsIOLvcHalCollection>;
    public ligplaatsLvcIdentificatie(requestParameters: LigplaatsLvcIdentificatieRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpResponse<LigplaatsIOLvcHalCollection>>;
    public ligplaatsLvcIdentificatie(requestParameters: LigplaatsLvcIdentificatieRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpEvent<LigplaatsIOLvcHalCollection>>;
    public ligplaatsLvcIdentificatie(requestParameters: LigplaatsLvcIdentificatieRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<any> {
        const identificatie = requestParameters.identificatie;
        if (identificatie === null || identificatie === undefined) {
            throw new Error('Required parameter identificatie was null or undefined when calling ligplaatsLvcIdentificatie.');
        }
        const geheleLvc = requestParameters.geheleLvc;
        const acceptCrs = requestParameters.acceptCrs;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (geheleLvc !== undefined && geheleLvc !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>geheleLvc, 'geheleLvc');
        }

        let localVarHeaders = this.defaultHeaders;
        if (acceptCrs !== undefined && acceptCrs !== null) {
            localVarHeaders = localVarHeaders.set('Accept-Crs', String(acceptCrs));
        }

        let localVarCredential: string | undefined;
        // authentication (apiKeyBAG) required
        localVarCredential = this.configuration.lookupCredential('apiKeyBAG');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ligplaatsen/${this.configuration.encodeParam({name: "identificatie", value: identificatie, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/lvc`;
        return this.httpClient.request<LigplaatsIOLvcHalCollection>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Zoeken van ligplaatsen met een geometrische locatie of binnen een bounding box.
     * Zoek actuele ligplaatsen:  1. met een geometrische locatie.  2. binnen een geometrische contour (rechthoek).   Parameter huidig kan worden toegepast, zie [functionele specificatie huidig](https://github.com/lvbag/BAG-API/blob/master/Features/huidig.feature).  De geldigOp en beschikbaarOp parameters kunnen gebruikt worden voor  tijdreis vragen, zie  [functionele specificatie tijdreizen](https://github.com/lvbag/BAG-API/blob/master/Features/tijdreizen.feature).   Als expand&#x3D;heeftAlsHoofdAdres, heeftAlsNevenAdres of true dan worden de gevraagde of alle objecten als geneste resource geleverd, zie [functionele specificatie expand](https://github.com/lvbag/BAG-API/blob/master/Features/expand.feature).  Voor paginering, zie: [functionele specificatie paginering](https://github.com/lvbag/BAG-API/blob/master/Features/paginering.feature).
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoekLigplaatsen(requestParameters: ZoekLigplaatsenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<LigplaatsIOHalCollection>;
    public zoekLigplaatsen(requestParameters: ZoekLigplaatsenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpResponse<LigplaatsIOHalCollection>>;
    public zoekLigplaatsen(requestParameters: ZoekLigplaatsenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<HttpEvent<LigplaatsIOHalCollection>>;
    public zoekLigplaatsen(requestParameters: ZoekLigplaatsenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/hal+json' | 'application/problem+json', context?: HttpContext}): Observable<any> {
        const geldigOp = requestParameters.geldigOp;
        const beschikbaarOp = requestParameters.beschikbaarOp;
        const huidig = requestParameters.huidig;
        const expand = requestParameters.expand;
        const contentCrs = requestParameters.contentCrs;
        const acceptCrs = requestParameters.acceptCrs;
        const page = requestParameters.page;
        const pageSize = requestParameters.pageSize;
        const point = requestParameters.point;
        const bbox = requestParameters.bbox;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (geldigOp !== undefined && geldigOp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>geldigOp, 'geldigOp');
        }
        if (beschikbaarOp !== undefined && beschikbaarOp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>beschikbaarOp, 'beschikbaarOp');
        }
        if (huidig !== undefined && huidig !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>huidig, 'huidig');
        }
        if (expand !== undefined && expand !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>expand, 'expand');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'pageSize');
        }
        if (point !== undefined && point !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>point, 'point');
        }
        if (bbox) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...bbox].join(COLLECTION_FORMATS['csv']), 'bbox');
        }

        let localVarHeaders = this.defaultHeaders;
        if (contentCrs !== undefined && contentCrs !== null) {
            localVarHeaders = localVarHeaders.set('Content-Crs', String(contentCrs));
        }
        if (acceptCrs !== undefined && acceptCrs !== null) {
            localVarHeaders = localVarHeaders.set('Accept-Crs', String(acceptCrs));
        }

        let localVarCredential: string | undefined;
        // authentication (apiKeyBAG) required
        localVarCredential = this.configuration.lookupCredential('apiKeyBAG');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-Api-Key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/hal+json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ligplaatsen`;
        return this.httpClient.request<LigplaatsIOHalCollection>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
