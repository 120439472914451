import { Component } from '@angular/core';
import {
  BimService,
  BuildingInformationModelListDto,
  BuildingSortColumn,
  SortOrder,
} from '@api-clients/bim';
import { Router } from '@angular/router';
import { AddressableUnitDto, BuildingsService } from '@api-clients/real-estate';
import { BimSource } from '@api-clients/bim/model/bimSource';
import { BimStatus } from '@api-clients/bim/model/bimStatus';
import { getEnumValue, isRealEstateBuildingIdBimSubject } from '../../utils';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { FormsModule } from '@angular/forms';
import { TableFilterComponent } from '@shared/components/table-filter/table-filter.component';
import { NgFor, NgIf, LowerCasePipe, KeyValuePipe } from '@angular/common';
import { StatusBubbleComponent } from '@shared/components/status-bubble/status-bubble.component';
import { TablePaginationComponent } from '@shared/components/table-pagination/table-pagination.component';
import { TranslateModule } from '@ngx-translate/core';

interface BuildingInformationModelListExtendedDto extends BuildingInformationModelListDto {
  address?: AddressableUnitDto;
}

@Component({
  selector: 'app-bim-overview',
  templateUrl: './bim-overview.component.html',
  styleUrl: './bim-overview.component.scss',
  imports: [
    BreadcrumbComponent,
    FormsModule,
    TableFilterComponent,
    NgFor,
    NgIf,
    StatusBubbleComponent,
    TablePaginationComponent,
    LowerCasePipe,
    KeyValuePipe,
    TranslateModule,
  ],
})
export class BimOverviewComponent {
  protected defaultPageSize: number = 10;
  protected sortBy: BuildingSortColumn;
  protected sortOrder: SortOrder;
  protected searchText: string = '';
  protected filteredData: BuildingInformationModelListExtendedDto[] = [];
  protected totalCount: number = 0;
  protected page: number = 1;
  protected pageSize: number = this.defaultPageSize;

  protected selectedBimSource?: BimSource;
  protected selectedBimStatus?: BimStatus;

  // column data is added in html through a loop.
  columns = [
    { key: undefined, label: 'address' },
    { key: BuildingSortColumn.Description, label: 'description' },
    { key: BuildingSortColumn.Status, label: 'status' },
    { key: BuildingSortColumn.Source, label: 'source' },
    { key: BuildingSortColumn.UploadTime, label: 'upload-time' },
  ];

  constructor(
    private readonly bimService: BimService,
    private readonly router: Router,
    private readonly buildingService: BuildingsService
  ) {
    this.defaultPageSize = JSON.parse(localStorage.getItem('pageSize') || 'null') || 10;
    this.page = 1;
    this.pageSize = this.defaultPageSize;

    const sort_by = getEnumValue(BuildingSortColumn, localStorage.getItem('bim-overview-sort-by'));
    this.sortBy = sort_by ?? BuildingSortColumn.UploadTime;
    const sort_order = getEnumValue(SortOrder, localStorage.getItem('bim-overview-sort-order'));
    this.sortOrder = sort_order || SortOrder.Asc;

    this.load();
  }

  load(): void {
    this.bimService
      .bimGet(
        this.page - 1,
        this.pageSize,
        this.sortBy,
        this.sortOrder,
        this.selectedBimSource,
        this.selectedBimStatus,
        this.searchText
      )
      .subscribe((data) => {
        this.filteredData = data.items.map((item) => <BuildingInformationModelListExtendedDto>item);
        this.totalCount = data.count;

        this.buildingService
          .includingAddressesGet(
            this.filteredData
              .filter((item) => item.subject && isRealEstateBuildingIdBimSubject(item.subject))
              .map((item) => item.subject!.id)
          )
          .subscribe((buildings) => {
            for (const building of buildings) {
              const item = this.filteredData.find(
                (item) =>
                  item.subject &&
                  isRealEstateBuildingIdBimSubject(item.subject) &&
                  item.subject.id === building.id
              );
              if (item) {
                item.address = building.addresses[0];
              }
            }
          });
      });
  }

  onPageChange(event: { page: number; pageSize: number }): void {
    this.page = event.page;
    this.pageSize = event.pageSize;
    this.load();
  }

  toggleSort(property: BuildingSortColumn | undefined): void {
    if (this.sortBy === property) {
      this.sortOrder = this.sortOrder == SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
    } else {
      // reset to ascending if a different header is clicked
      this.sortBy = property ?? BuildingSortColumn.UploadTime;
      this.sortOrder = SortOrder.Asc;
    }
    localStorage.setItem('bim-overview-sort-by', this.sortBy);
    localStorage.setItem('bim-overview-sort-order', this.sortOrder);
    this.load();
  }

  openDetail(item: BuildingInformationModelListExtendedDto): void {
    void this.router.navigate(['bim', item.id, 'model', 'overview']);
  }

  search(): void {
    this.load();
  }

  protected readonly BimSource = BimSource;
  protected readonly BimStatus = BimStatus;
  protected readonly SortOrder = SortOrder;
  protected readonly isReaEstateBuildingIdSubject = isRealEstateBuildingIdBimSubject;
}
