import { NgClass, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-project-dashboard-tile',
  templateUrl: './dashboard-tile.component.html',
  styleUrl: './dashboard-tile.component.scss',
  imports: [CdkDrag, NgClass, NgIf, TranslateModule],
})
export class ProjectDashboardTileComponent implements AfterViewInit {
  @Input() width: 'third' | 'full' = 'third';
  @Input() title: string = '';
  @Input() buttonTitle: string = '';
  @Input() buttonLink: string = '';

  @ViewChild(CdkDrag) drag!: CdkDrag;
  @ViewChild(CdkDragHandle, { static: false }) dragHandle!: CdkDragHandle; // Ensure it's detected

  projectId: string | null;

  constructor(
    protected router: Router,
    private readonly route: ActivatedRoute
  ) {
    this.projectId = this.route.snapshot.paramMap.get('id');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      console.log('Tile Handle:', this.dragHandle); // Should now log the handle reference
    });
  }

  get tileClass(): string {
    return this.width === 'full' ? 'dashboard-tile--full' : 'dashboard-tile--third';
  }

  openButtonLink(): void {
    void this.router.navigate(['/project', this.projectId, 'dashboard', this.buttonLink]);
  }
}
