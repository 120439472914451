import { Component, Input } from '@angular/core';
import { Matrix4, Quaternion, Vector3 } from 'three';
import { GeometryType } from '@api-clients/bim';

@Component({
  selector: 'app-geometry-summary',
  templateUrl: './geometry-summary.component.html',
  styleUrl: './geometry-summary.component.scss',
})
export class GeometrySummaryComponent {
  @Input() set old(geometry: unknown | GeometryType) {
    const value = (geometry as GeometryType)?.value;
    if (!value) {
      this.oldGeometry = undefined;
      return;
    }
    this.oldGeometry = { scale: new Vector3(), position: new Vector3() };

    const rotation = new Quaternion();
    new Matrix4()
      .fromArray(value)
      .decompose(this.oldGeometry.position, rotation, this.oldGeometry.scale);
  }

  @Input() set new(geometry: GeometryType | undefined) {
    if (geometry) {
      const rotationOld = new Quaternion();
      this.newGeometry = { scale: new Vector3(), position: new Vector3() };
      new Matrix4()
        .fromArray(geometry.value)
        .decompose(this.newGeometry.position, rotationOld, this.newGeometry.scale);
    }
  }

  protected oldGeometry?: { scale: Vector3; position: Vector3 } = undefined;
  protected newGeometry?: { scale: Vector3; position: Vector3 } = undefined;
}
