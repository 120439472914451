import {Box3} from 'three';

export class BoundingBox {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  center: { x: number; y: number };

  constructor(x1: number, x2: number, y1: number, y2: number) {
    this.x1 = x1;
    this.x2 = x2;
    this.y1 = y1;
    this.y2 = y2;
    this.center = {x: (this.x1 + this.x2) / 2, y: (this.y1 + this.y2) / 2};
  }

  static fromPoly(polygon: { x: number; y: number }[]): BoundingBox {
    let x1 = Number.POSITIVE_INFINITY;
    let x2 = Number.NEGATIVE_INFINITY;
    let y1 = Number.POSITIVE_INFINITY;
    let y2 = Number.NEGATIVE_INFINITY;
    for (const {x, y} of polygon) {
      x1 = Math.min(x1, x);
      x2 = Math.max(x2, x);
      y1 = Math.min(y1, y);
      y2 = Math.max(y2, y);
    }
    return new BoundingBox(x1, x2, y1, y2);
  }

  static fromBox3(box: Box3): BoundingBox {
    return new BoundingBox(box.min.x, box.max.x, box.min.z, box.max.z);
  }

  join(other: BoundingBox): BoundingBox {
    return new BoundingBox(
      Math.min(this.x1, other.x1),
      Math.max(this.x2, other.x2),
      Math.min(this.y1, other.y1),
      Math.max(this.y2, other.y2)
    );
  }
}
