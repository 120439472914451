import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationsService } from '@api-clients/user';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { lastValueFrom } from 'rxjs';
import { PermissionService } from '../../../auth/permission-service';
import { ToastrService } from '@shared/services/toastr.service';
import { AddressAutocompleteService } from '@services/address-autocomplete-service';
import {
  AddressGroup,
  AddressDetailComponent,
} from '../../components/address-detail/address-detail.component';
import { Resource } from '../../../resources';
import { Scope } from '../../../scopes';
import { ThemeService } from '@services/theme.service';
import { UserService } from '@services/user.service';
import { TinyColor } from '@ctrl/tinycolor';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { ProfilePictureComponent } from '@shared/components/profile-picture/profile-picture.component';
import { ColorPickerComponent } from '@shared/components/color-picker/color-picker.component';
import { NgIf } from '@angular/common';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

export interface OrganizationForm {
  id: FormControl<string | undefined>;
  name: FormControl<string>;
  phoneNumber: FormControl<string | undefined | null>;
  emailAddress: FormControl<string | undefined | null>;
  address: FormGroup<AddressGroup | null>;
  primaryThemeColorHex: FormControl<string>;
  secondaryThemeColorHex: FormControl<string>;
}

@Component({
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.scss'],
  imports: [
    BreadcrumbComponent,
    FormsModule,
    ReactiveFormsModule,
    ProfilePictureComponent,
    ColorPickerComponent,
    NgIf,
    AddressDetailComponent,
    ConfirmationDialogComponent,
    TranslateModule,
  ],
})
export class OrganizationDetailComponent implements OnInit {
  public organizationDetailForm: FormGroup<OrganizationForm>;
  public addressDetailForm: FormGroup<AddressGroup>;
  protected isNew: boolean = false;

  private readonly defaultPrimaryColor: string = '#a8c0c3';
  private readonly defaultSecondaryColor: string = '#d7ed4c';
  private readonly hexValidatorPattern: RegExp = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly organizationService: OrganizationsService,
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly permissionService: PermissionService,
    private readonly router: Router,
    private readonly toastrService: ToastrService,
    private readonly zipcodeService: AddressAutocompleteService,
    private readonly formBuilder: FormBuilder,
    private readonly themeService: ThemeService,
    private readonly userService: UserService
  ) {
    this.addressDetailForm = this.formBuilder.group<AddressGroup>(<AddressGroup>{
      streetName: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      postalCode: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      number: new FormControl('', Validators.required),
    });

    this.organizationDetailForm = this.formBuilder.group<OrganizationForm>({
      id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
      name: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      phoneNumber: new FormControl('', [Validators.pattern(/^\+?\d+$/)]),
      emailAddress: new FormControl('', { validators: [Validators.email, Validators.required] }),
      address: this.addressDetailForm as FormGroup<AddressGroup | null>,
      primaryThemeColorHex: new FormControl(this.defaultPrimaryColor, {
        nonNullable: true,
        validators: [Validators.pattern(this.hexValidatorPattern)],
      }),
      secondaryThemeColorHex: new FormControl(this.defaultSecondaryColor, {
        nonNullable: true,
        validators: [Validators.pattern(this.hexValidatorPattern)],
      }),
    });
  }

  public canDelete: boolean = false;
  public canUpdate: boolean = false;

  async ngOnInit(): Promise<void> {
    this.canDelete = this.permissionService.userHasPermissionWithScope(
      Resource.AllOrganizations,
      Scope.DELETE
    );
    this.canUpdate =
      this.permissionService.userHasPermissionWithScope(Resource.AllOrganizations, Scope.UPDATE) ||
      this.permissionService.userHasPermissionWithScope(Resource.OwnOrganization, Scope.UPDATE);

    if (!this.canUpdate) {
      this.organizationDetailForm.disable();
    }

    const id = this.route.snapshot.paramMap.get('id');
    this.isNew = id === null;
    if (!id) return; // Creating new organization, don't fetch organization data

    const organization = await lastValueFrom(this.organizationService.getOrganization(id));
    // Ugly hack. Can be fixed by improving Users OpenAPI spec.
    // Specifically: address in OrganizationDtoBase should be nonNullable{
    const { address, ...org } = organization;
    org['address'] = address ?? undefined;
    // }

    const organizationWithDefaults = {
      ...organization,
      primaryThemeColorHex: organization.primaryThemeColorHex ?? this.defaultPrimaryColor,
      secondaryThemeColorHex: organization.secondaryThemeColorHex ?? this.defaultSecondaryColor,
    };

    this.organizationDetailForm.patchValue(organizationWithDefaults);
  }

  organizationToRemovePopup(): void {
    this.confirmationDialogService.open();
  }

  removeOrganization(): void {
    if (this.organizationDetailForm.value.id === undefined)
      throw new Error('OrganizationId is undefined');
    this.organizationService
      .deleteOrganization(this.organizationDetailForm.value.id as string)
      .subscribe(() => this.finished());
  }

  private finished(): Promise<boolean> {
    this.toastrService.showSuccess(
      'users-module.organization.save-success',
      'users-module.organization.save-title'
    );

    const form = this.organizationDetailForm.getRawValue();
    if (
      this.userService.organizationId === form.id &&
      (form.primaryThemeColorHex || form.secondaryThemeColorHex)
    ) {
      this.themeService.checkAndApplyTheme(form.id);
    }

    if (this.permissionService.userHasPermissionWithScope(Resource.AllOrganizations, Scope.READ)) {
      return this.router.navigate(['organizations']);
    } else {
      // stay on this page, do nothing
      return Promise.resolve(true);
    }
  }

  isSaving: boolean = false;

  checkPrimaryColor(hexColor: string): void {
    if (this.checkLightness(hexColor)) {
      this.organizationDetailForm.controls.primaryThemeColorHex.setValue(hexColor);
    }
  }

  checkSecondaryColor(hexColor: string): void {
    if (this.checkLightness(hexColor)) {
      this.organizationDetailForm.controls.secondaryThemeColorHex.setValue(hexColor);
    }
  }

  checkLightness(hexColor: string): boolean {
    const color = new TinyColor(hexColor).toHsl();
    if (color.l > 0.95 || color.s < 0.05) {
      this.toastrService.showFailure('theme.colorTooLightDescription', 'theme.colorTooLightTitle');
      return false;
    } else {
      return true;
    }
  }

  async updateOrganization(): Promise<void> {
    if (!this.organizationDetailForm.valid) return;
    if (this.isSaving) return;

    this.isSaving = true;

    const { id: organization_id, address, phoneNumber, emailAddress, ...attributes } = this.organizationDetailForm.getRawValue();

    const organization = {
      ...attributes,
      phoneNumber: phoneNumber ?? undefined,
      emailAddress: emailAddress ?? undefined,
      address: !address
        ? undefined
        : {
          streetName: address.streetName,
          city: address.city,
          number: address.number,
          country: address.country,
          postalCode: address.postalCode,
          // api allows living state as optional
          state: address.state ?? undefined,
        },
    }

    const next = (): Promise<boolean> => {
      this.isSaving = false;
      return this.finished();
    };

    // If we have an ID, we are updating an organization. If we have no ID, we are creating a new one.
    if (organization_id) {
      //update organization then update theme
      this.organizationService.updateOrganization(organization_id, organization).subscribe(next);
    } else {
      this.organizationService.createOrganization(organization).subscribe(next);
    }
  }
}
