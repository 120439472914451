<div class="inspection-list">
  <div class="inspection-list__header">
    <h3>{{ listTitle }}</h3>
    <button *ngIf="linkAction" (click)="linkAction.emit()">{{ linkTitle }}</button>
  </div>
  <div
    class="inspection-list__item"
    *ngFor="let inspection of inspections;"
    tabindex="-1"
    aria-hidden="true"
    (click)="navigateToInspections(inspection.id)"
    (keydown)="navigateToInspections(inspection.id)"
    [contextMenu]="menu"
    (mousedown)="setActive(inspection.id)">

    <div class="inspection-list__item__text">
      <span>{{ inspection.description }}</span>
      <span class="owner">{{ allUsers[inspection.user_id] }}</span>
    </div>

    <app-status-bubble [status]="(inspection.finished_time_utc) ? 'completed' : 'pending'"></app-status-bubble>

    <div class="inspection-list__item__date">{{ inspection.timestamp_utc + ' UTC' | date: 'dd-MM-yyyy HH:mm' }}</div>
  </div>
</div>
