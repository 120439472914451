import { AfterViewInit, Component, ElementRef, Output, ViewChild } from '@angular/core';
import {
  Intersection,
  Material,
  Matrix4,
  Mesh,
  MeshDistanceMaterial,
  Plane,
  Quaternion,
  Raycaster,
  Vector3,
} from 'three';
import { BuildingModel } from '@shared/services/assets/building-model';
import { ContextService } from '@services/context.service';
import {
  glCoordsFromMouseEvent,
  glCoordsFromMouseEventBeforeMotion,
} from '../../../building-module/views/model-viewer/utils/raycaster';
import { boxOnTopOfIntersection } from '../../../building-module/views/model-viewer/utils/geometry';
import { SceneComponent } from '@shared/components/threed/scene/scene.component';
import { vertexAlphaDepthMaterial } from '../../../building-module/views/model-viewer/utils/lighting';
import { pairwise } from 'rxjs';
import { ModelLoaderService } from '@shared/services/model-loader.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BimPropertyService } from '../../../building-module/services';
import { ObjectCategory } from '@api-clients/bim';
import { Category, Level } from '@shared/services/assets/building-elements';
import { TranslateService } from '@ngx-translate/core';
import { difference } from 'ramda';
import {
  DeleteElement,
  NewElement,
} from '../../../building-module/components/changes-summary/change';

@Component({
  selector: 'app-threed',
  templateUrl: './threed.component.html',
  styleUrl: './threed.component.scss',
})
export class ThreedComponent implements AfterViewInit {
  @Output() public objectCategories: Category[] = [];
  @Output() public levels: Level[] = [];
  @Output() public modelState: 'idle' | 'loading' | 'loaded' | 'error' = 'idle';

  @ViewChild('scene', { static: true }) sceneRef!: ElementRef<SceneComponent>;
  @ViewChild('container', { static: true }) containerRef!: ElementRef<HTMLDivElement>;

  private raycaster = new Raycaster();
  private pendingObject: Mesh | undefined = undefined;
  private buildingModel?: BuildingModel;
  private cameraMovedEventThrottle: number = 0; // Used to throttle the cameraMoved event. This is used to hide the context menu after a certain amount of camera movements

  // idle: not pressed, not above grabbable, aboveGrab: hovering a grabbable object, grabbing: mouse is down on a grabbable object, pressed: mouse down from idle, dragging: dragging/rotating camera
  private _mouseState: 'idle' | 'aboveGrab' | 'grabbing' | 'pressed' | 'dragging' = 'idle';

  private get mouseState(): 'idle' | 'aboveGrab' | 'grabbing' | 'pressed' | 'dragging' {
    return this._mouseState;
  }

  private mouseLocation: { offsetX; offsetY } = { offsetX: 0, offsetY: 0 };

  private set mouseState(value: 'idle' | 'aboveGrab' | 'grabbing' | 'pressed' | 'dragging') {
    if (value === 'grabbing') {
      document.body.style.cursor = 'grabbing';
      this.scene.orbitControls.enabled = false;
    } else if (value === 'aboveGrab') {
      document.body.style.cursor = 'grab';
    } else {
      document.body.style.cursor = 'inherit';
    }
    if (this._mouseState === 'grabbing' && value !== 'grabbing') {
      this.scene.orbitControls.enabled = true;
    }
    this._mouseState = value;
  }

  public toggleLevelVisibility(level: number): void {
    this.buildingModel?.toggleLevelVisibility(level);
    this.scene.shadowNeedsUpdate();
    this.scene.requestRender();
  }

  public toggleCategoryVisibility(category: ObjectCategory): void {
    this.buildingModel?.toggleCategoryVisibility(category);
    this.scene.shadowNeedsUpdate();
    this.scene.requestRender();
  }

  private get scene(): SceneComponent {
    return this.sceneRef as unknown as SceneComponent;
  }

  private get container(): HTMLDivElement {
    return this.containerRef.nativeElement;
  }

  constructor(
    private readonly contextService: ContextService,
    private readonly modelLoader: ModelLoaderService,
    private readonly bimPropertyService: BimPropertyService,
    private readonly translateService: TranslateService
  ) {
    this.bimPropertyService.selectedElement.pipe(takeUntilDestroyed()).subscribe((element) => {
      this.selectId(element?.id);
      if (!element || this.pendingObject?.name !== element.id) return;
      if (element.element.properties['geometry']) {
        const position = new Vector3();
        const rotation = new Quaternion();
        const scale = new Vector3();
        new Matrix4()
          .fromArray(element.element.properties['geometry'].value)
          .decompose(position, rotation, scale);

        this.pendingObject.scale.set(scale.x, scale.y, scale.z);
        this.pendingObject.position.set(position.x, position.y, position.z);
        this.pendingObject.updateMatrix();
      }

      if (element.element.properties['color']) {
        this.buildingModel!.setColorForCurrentInstallation(
          element.id,
          element.element.properties['color']
        );
      }
    });

    this.bimPropertyService.bimId.pipe(takeUntilDestroyed()).subscribe(async (bimId) => {
      this.modelState = 'idle';
      if (!bimId) return;
      this.modelState = 'loading';
      this.buildingModel = await this.modelLoader.loadCompositeModelWithMetadata(bimId);
      if (!this.buildingModel) {
        this.modelState = 'error';
        throw new Error('Failed to load model.');
      }
      this.modelState = 'loaded';
      this.setModel(this.buildingModel);
      this.objectCategories = this.buildingModel.categories;
      this.levels = this.buildingModel.levels;
    });

    this.bimPropertyService.savedElements.pipe(takeUntilDestroyed()).subscribe((elements) => {
      for (const deletedElement of elements.filter((element) => element.element === null)) {
        this.buildingModel?.removeInstallation(deletedElement.previousId);
      }
      this.buildingModel?.updateElementIds(
        elements
          .filter((element) => element.element !== null)
          .map((element) => ({ oldId: element.previousId, newId: element.element.id }))
      );
      this.scene.requestRender();
    });

    this.bimPropertyService.unsavedElements
      .pipe(takeUntilDestroyed())
      .pipe(pairwise())
      .subscribe((next) => {
        const removedChanges = difference(next[0], next[1]);
        const addedChanges = difference(next[1], next[0]);
        removedChanges.forEach((element) => {
          if (element instanceof NewElement) {
            // in case the newly added element change is reverted, remove it from the model
            this.buildingModel?.removeInstallation(element.id);
          }
          if (element instanceof DeleteElement) {
            // in case the element was set for deletion, but this is undone, re add it to the model
            this.buildingModel?.showInstallation(element.id);
          }
        });
        addedChanges.forEach((element) => {
          if (element instanceof DeleteElement) {
            // in case the element is set for deletion, we want to not display it anymore in the model
            this.buildingModel?.hideInstallation(element.id);
          }
        });
        this.scene.requestRender();
      });
  }

  ngAfterViewInit(): void {
    // don't hide the context menu too soon. Wait for 20 events at least.
    this.scene.cameraMoved.subscribe(() => {
      this.cameraMovedEventThrottle++;
      if (this.cameraMovedEventThrottle % 20 === 0) {
        this.contextService.hide();
      }
    });
  }

  private setModel(model: BuildingModel): void {
    const customDepthMaterial = vertexAlphaDepthMaterial();
    const customDistanceMaterial = new MeshDistanceMaterial({
      vertexColors: true,
      alphaTest: 0.3,
    });
    this.scene.addModel(model, true, true);
    const transparentMesh = model.getObjectByName('transparent_mesh') as Mesh | undefined;
    if (transparentMesh) {
      transparentMesh.renderOrder = 1;
      const transparentMaterial = transparentMesh.material as Material;
      transparentMaterial.transparent = true;
      transparentMaterial.alphaTest = 0.05;
    }
    const opaqueMesh: Mesh | undefined = model.getObjectByName('opaque_mesh') as Mesh | undefined;
    if (opaqueMesh) {
      opaqueMesh.castShadow = true;
      opaqueMesh.receiveShadow = true;
      const opaqueMaterial = opaqueMesh.material as Material;
      opaqueMaterial.transparent = true;
      opaqueMaterial.alphaTest = 0.05;
      opaqueMesh.customDepthMaterial = customDepthMaterial;
      opaqueMesh.customDistanceMaterial = customDistanceMaterial;
    }
  }

  private selectId(id: string | undefined): void {
    this.buildingModel?.unHighlightAllElements();
    if (id) {
      this.buildingModel?.highlightElement(id);
      this.pendingObject = this.buildingModel?.getMeshIfInstallation(id);
    } else {
      this.pendingObject = undefined;
    }
    this.scene?.requestRender();
  }

  protected onMouseDown = (event: MouseEvent): void => {
    if (this.mouseState === 'dragging') this.mouseState = 'idle';
    this.mouseLocation = { offsetX: event.offsetX, offsetY: event.offsetY };
    this.scene.requestRender();
    if (this.mouseState === 'aboveGrab') this.mouseState = 'grabbing';
    else if (this.mouseState === 'idle') this.mouseState = 'pressed';
  };

  protected onMouseUp = (event: MouseEvent): void => {
    this.scene.requestRender();
    if (this.mouseState === 'grabbing') {
      this.mouseState = 'aboveGrab';
    } else if (this.mouseState === 'pressed') {
      this.mouseState = 'idle';
      this.onClick(event);
    } else if (this.mouseState === 'dragging') {
      this.mouseState = 'idle';
    }
  };

  protected onMouseMove = (event: MouseEvent): void => {
    const hasMoved =
      Math.abs(this.mouseLocation.offsetX - event.offsetX) > 5 ||
      Math.abs(this.mouseLocation.offsetY - event.offsetY) > 5;

    if (this.mouseState === 'pressed' && hasMoved) {
      this.mouseState = 'dragging';
    }
    if (this.mouseState === 'pressed' && event.button === 1) this.mouseState = 'dragging';
    else if (this.mouseState === 'idle' || this.mouseState === 'aboveGrab') {
      if (this.pendingObject && this.tryIntersectObject(event, this.pendingObject)) {
        this.mouseState = 'aboveGrab';
      } else {
        this.mouseState = 'idle';
      }
    }
    if (this.mouseState === 'grabbing') {
      if (!this.pendingObject) throw 'Unreachable - pendingObject should be set';
      const mouseOffset3D = this.mouseOffset3D(event, this.pendingObject);
      // Add the difference between the new point and the old point to the object's position
      this.pendingObject.position.add(mouseOffset3D);
      this.pendingObject.updateMatrix();
      this.bimPropertyService.updateSelectedElementGeometry({
        type: 'Box',
        value: this.pendingObject.matrix.elements,
      });
    }
  };

  private onClick(event: MouseEvent): void {
    const mouseCoords = glCoordsFromMouseEvent(event, this.container);
    const firstVisibleIntersect = this.buildingModel?.getFirstVisibleObjectIntersection(
      this.scene.camera,
      mouseCoords
    );
    this.bimPropertyService.selectBimElement(firstVisibleIntersect?.id);
    if (event.button === 2 && firstVisibleIntersect) {
      this.cameraMovedEventThrottle = 0;
      this.contextService.set(event.x, event.y, [
        {
          name: this.translateService.instant('add-installation'),
          visible: true,
          action: (): void => {
            const [width, height, depth] = [2, 0.5, 0.1];
            const box = boxOnTopOfIntersection(
              firstVisibleIntersect.intersection,
              width,
              height,
              depth
            );
            // Todo: Give actual levels and rooms
            const installationId = this.buildingModel!.addInstallation(box, [], []);
            this.bimPropertyService.createAndSelectNewElement(installationId, {
              category: ObjectCategory.Installation,
              levels: [],
              rooms: [],
              properties: { geometry: { type: 'Box', value: box.matrix.elements } },
              type: 'Object', // no need to set the mesh_id here, because it has its own geometry
            });
            this.pendingObject = box;
            this.scene.requestRender();
          },
        },
      ]);
    } else {
      this.contextService.hide();
    }
  }

  private tryIntersectObject(event: MouseEvent, object: Mesh): Intersection | undefined {
    this.raycaster.setFromCamera(glCoordsFromMouseEvent(event, this.container), this.scene.camera);
    return this.raycaster.intersectObject(object)[0];
  }

  private mouseOffset3D(event: MouseEvent, object: Mesh): Vector3 {
    this.raycaster.setFromCamera(
      glCoordsFromMouseEventBeforeMotion(event, this.container),
      this.scene.camera
    );
    const intersects = this.raycaster.intersectObject(object);
    if (intersects.length === 0) throw 'Unreachable - while grabbing, object should be intersected';
    const previous3DPosition = intersects[0].point;

    const plane = objectAlignedPlaneAtPosition(object, intersects[0].point);
    this.raycaster.setFromCamera(glCoordsFromMouseEvent(event, this.container), this.scene.camera);
    const current3DPosition: Vector3 = this.raycaster.ray.intersectPlane(plane, new Vector3())!;

    return current3DPosition.sub(previous3DPosition);
  }
}

/// Generates a plane that contains point and that is aligned with the object coordinate's z-axis
function objectAlignedPlaneAtPosition(object: Mesh, point: Vector3): Plane {
  const matrix = object.matrixWorld.elements;
  const zNormal = new Vector3(matrix[8], matrix[9], matrix[10]).normalize();
  const planeZ = point.dot(zNormal);
  return new Plane(zNormal, -planeZ);
}
