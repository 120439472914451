/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Dimensions } from './dimensions';


export interface MediaTypeImage { 
    dimensions?: Dimensions;
    type: MediaTypeImage.TypeEnum;
}
export namespace MediaTypeImage {
    export type TypeEnum = 'Image' | 'Audio' | 'Video' | 'Other';
    export const TypeEnum = {
        Image: 'Image' as TypeEnum,
        Audio: 'Audio' as TypeEnum,
        Video: 'Video' as TypeEnum,
        Other: 'Other' as TypeEnum
    };
}


