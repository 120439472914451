<div class="organization-overview">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb></app-breadcrumb>

      <h1 class="h4">{{ 'organization-overview-page' | translate }}</h1>
    </div>

    <div class="header-bar__search">
      <button class="button-secondary button--has-icon" (click)="createNewOrganization()">
        <span class="material-symbols-rounded">add</span>
        {{ 'create-new-organization' | translate }}
      </button>
      <input class="top-search" type="search" [(ngModel)]="searchText" (input)="search()"
        placeholder="{{ 'search' | translate }}" />
    </div>
  </div>

  <app-table-filter></app-table-filter>
  <table class="table responsive-table">
    <thead>
      <tr>
        <ng-container *ngFor="let column of columns">
          <th (click)="toggleSort(column.key)" *ngIf="column.key.length" class="sortable">
            {{ column.label | translate }}
            <span [class.arrow-active]="sortBy === column.key" [class.arrow-down]="sortOrder === 1"
              class="arrow"></span>
          </th>
          <th *ngIf="!column.key.length">
            {{ column.label | translate }}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let organization of filteredOrganizations" (click)="goToOrganizationDetail(organization.id!)"
        class="table-link">
        <td [attr.data-title]="'name' | translate">{{ organization.name }}</td>
        <td [attr.data-title]="'email' | translate">{{ organization.emailAddress }}</td>

        <td [attr.data-title]="'remove' | translate" (click)="organizationToRemovePopup(organization.id, $event)">
          <span class="material-symbols-rounded table-remove ">delete</span>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="pagination">
    <app-table-pagination (pageChange)="onPageChange($event)" [total]="organizations.length"
      [pageSize]="defaultPageSize" [page]="1"></app-table-pagination>
  </div>
</div>

<app-confirmation-dialog [title]="'organization-delete-title' | translate"
  [description]="'organization-delete-description' | translate" [icon]="'bin'" [remove]="true"
  [confirmText]="'organization-delete-confirm' | translate"
  (confirmOutput)="removeOrganization()"></app-confirmation-dialog>