import { Component, Input } from '@angular/core';
import { ChangedElement, DeleteElement, NewElement, UnsavedElement } from './change';
import { BimElementDto } from '@api-clients/bim';
import { isBimObject, isBimRoom } from '../../views/model-viewer/utils/types';
import { BimPropertyService } from '../../services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, LowerCasePipe, KeyValuePipe } from '@angular/common';
import { GeometrySummaryComponent } from './geometry-summary/geometry-summary.component';
import { DegradationSummaryComponent } from './degradation-summary/degradation-summary.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-changes-summary',
    templateUrl: './changes-summary.component.html',
    styleUrl: './changes-summary.component.scss',
    imports: [NgFor, NgIf, NgSwitch, NgSwitchCase, GeometrySummaryComponent, DegradationSummaryComponent, NgSwitchDefault, LowerCasePipe, KeyValuePipe, TranslateModule]
})
export class ChangesSummaryComponent {
  @Input() availableHeight = 0;

  private isDragging: boolean = false;
  private startX: number = 0;
  private startY: number = 0;
  protected unsavedElements: UnsavedElement[] = [];

  constructor(private readonly bimPropertyService: BimPropertyService) {
    this.bimPropertyService.unsavedElements
      .pipe(takeUntilDestroyed())
      .subscribe((changedElements) => {
        this.unsavedElements = changedElements;
      });
  }

  removeAddedElement(
    element: NewElement,
    event: Event,
    buttonToBeRemoved: HTMLButtonElement
  ): void {
    buttonToBeRemoved.classList.add('delete');
    event.stopPropagation();
    setTimeout(() => {
      this.bimPropertyService.removeAddedElement(element);
    }, 400);
  }

  removePropertyChange(
    element: ChangedElement,
    property: string,
    event: Event,
    buttonToBeRemoved: HTMLButtonElement
  ): void {
    buttonToBeRemoved.classList.add('delete');
    event.stopPropagation();
    setTimeout(() => {
      element.removePropertyChange(property);
      this.bimPropertyService.updateSelectedElement(element);
    }, 400);
  }

  removeCategoryChange(
    element: ChangedElement,
    event: Event,
    buttonToBeRemoved: HTMLButtonElement
  ): void {
    buttonToBeRemoved.classList.add('delete');
    event.stopPropagation();
    setTimeout(() => {
      element.removeCategoryChange();
      this.bimPropertyService.updateSelectedElement(element);
    }, 400);
  }

  onMouseDown(event: MouseEvent): void {
    this.startX = event.clientX;
    this.startY = event.clientY;
    this.isDragging = false;
  }

  onMouseMove(event: MouseEvent): void {
    if (Math.abs(event.clientX - this.startX) > 20 || Math.abs(event.clientY - this.startY) > 20) {
      this.isDragging = true;
    }
  }

  activateElement(element: UnsavedElement): void {
    if (!this.isDragging) {
      this.bimPropertyService.selectBimElement(element.id);
    }
  }

  undeleteElement(
    element: DeleteElement,
    event: Event,
    buttonToBeRemoved: HTMLButtonElement
  ): void {
    buttonToBeRemoved.classList.add('delete');
    event.stopPropagation();
    setTimeout(() => {
      this.bimPropertyService.undeleteElement(element);
    }, 400);
  }

  getTypeName(element: BimElementDto): string {
    if (isBimObject(element.element)) {
      return element.element.category.toString();
    }
    if (isBimRoom(element.element)) {
      return 'Room';
    }

    return 'Object';
  }

  protected isInstanceOfChangedElement(element: UnsavedElement): element is ChangedElement {
    return element instanceof ChangedElement;
  }

  protected isInstanceOfAddedElement(element: UnsavedElement): element is NewElement {
    return element instanceof NewElement;
  }

  protected isInstanceOfDeletedElement(element: UnsavedElement): element is DeleteElement {
    return element instanceof DeleteElement;
  }
}
