import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-information-item',
    templateUrl: './information-item.component.html',
    styleUrls: ['./information-item.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('out', style({
                height: '*',
            })),
            state('in', style({
                height: '0',
            })),
            transition('in => out', animate('200ms ease-in-out')),
            transition('out => in', animate('200ms ease-in-out')),
        ]),
    ],
    imports: [NgIf]
})
export class InformationItemComponent {
  @Input() collaps = true;
  @Input() title = '';
  @Input() open = !this.title.length;

  get animationState(): string {
    return this.open ? 'out' : 'in';
  }

  openContent(): void {
    if (this.collaps) {
      this.open = !this.open;
    }
  }
}
