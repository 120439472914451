import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
    selector: 'app-callback',
    templateUrl: './authorized.component.html',
    styleUrls: ['./authorized.component.scss']
})
export class AuthorizedComponent implements OnInit {
  constructor(private authService: OidcSecurityService) {}

  ngOnInit(): void {
    // On app load, check if the user is authenticated (don't remove this)
    this.authService.checkAuth().subscribe(() => {});
  }
}
