<div class="date-input">
  <input
    [id]="id"
    *ngIf="id"
    class="input"
    type="text"
    [value]="value"
    [disabled]="disabled"
    [owlDateTime]="date_picker"
    [owlDateTimeTrigger]="date_picker"
    (keydown)="$event.preventDefault()" />

  <owl-date-time
    [pickerType]="'calendar'"
    #date_picker
    [disabled]="disabled"
    (dateSelected)="dateSelected($event)"></owl-date-time>

  <button
    type="button"
    *ngIf="!disabled"
    (click)="clearValue()"
    class="clear-button">
    <span class="material-symbols-rounded">clear</span>
  </button>
</div>
