import { BuildingModel } from '@shared/services/assets/building-model';
import { Mesh } from 'three';
import { BoundingBox } from './bounding-box';
import { Level } from './level';
import { Buffers } from './buffers';
import { Observable } from 'rxjs';
import { Feature, RoomFeature } from '@shared/components/floor-plan/floorplan/features';
import * as clipperLib from 'js-angusj-clipper/web';

export class FloorPlan {
  levels: Level[] = [];
  boundingBox!: BoundingBox;
  complete: boolean = false;
  levelNames: string[] = [];

  private constructor(
    levels: Level[],
    boundingBox: BoundingBox,
    complete: boolean,
    levelNames: string[]
  ) {
    this.levels = levels;
    this.boundingBox = boundingBox;
    this.complete = complete;
    this.levelNames = levelNames;
  }

  static async fromBuildingModel(model: BuildingModel): Promise<Observable<FloorPlan>> {
    const batchIdToFaceIndices = model.children
      .filter((child): child is Mesh => child['isMesh'])
      .map((child) => {
        const batchIdToFaceIndices = new Map<number, number[]>();
        const faceIndexArray = (child as Mesh).geometry.index!.array;
        const vertexBatchIndexArray = (child as Mesh).geometry.attributes['_batch_ids']!.array;
        for (let i = 0; i < faceIndexArray.length; i += 3) {
          const vertexIndex = faceIndexArray[i];
          const batchId = vertexBatchIndexArray[vertexIndex];
          const facesForBatchId = batchIdToFaceIndices.get(batchId);
          if (facesForBatchId) {
            facesForBatchId.push(i / 3);
          } else {
            batchIdToFaceIndices.set(batchId, [i / 3]);
          }
        }
        return batchIdToFaceIndices;
      });
    const buffers = model.children
      .filter((child): child is Mesh => child['isMesh'])
      .map(
        (mesh) =>
          <Buffers>{
            positionBuffer: mesh.geometry.attributes['position'].array,
            indexBuffer: mesh.geometry.index!.array,
          }
      );
    (model.children[0] as Mesh).geometry.computeBoundingBox();
    const rotationCorrectedBoundingBox = (model.children[0] as Mesh).geometry.boundingBox!;
    const boundingBox = new BoundingBox(
      rotationCorrectedBoundingBox.min.x * 1000,
      rotationCorrectedBoundingBox.max.x * 1000,
      rotationCorrectedBoundingBox.min.z * 1000,
      rotationCorrectedBoundingBox.max.z * 1000
    );
    const clipper = await clipperLib.loadNativeClipperLibInstanceAsync(
      clipperLib.NativeClipperLibRequestedFormat.WasmWithAsmJsFallback
    );
    return new Observable<FloorPlan>((observer) => {
      const levels: Level[] = [];
      const levelNames = model.levels.map((l) => l.name);
      observer.next(new FloorPlan(levels, boundingBox, false, levelNames));
      for (const level of model.levels) {
        const result = Level.fromBuildingModelLevel(level, buffers, batchIdToFaceIndices, clipper);
        levels.push(new Level(result));
        observer.next(
          new FloorPlan(levels, boundingBox, model.levels.length === levels.length, levelNames)
        );
      }
    });
  }

  findChildById(id: string): Feature | undefined {
    return this.levels
      .map((level) => level.findChildById(id))
      .find((feature) => feature !== undefined);
  }

  findFeatureAndLevelById(id: string): { feature: Feature; level: number } | undefined {
    return this.levels
      .map((level, index) => ({ feature: level.findChildById(id)!, level: index }))
      .find((feature) => feature.feature !== undefined);
  }

  findRoomById(id: string): RoomFeature | undefined {
    return this.levels
      .map((level) => level.findRoomById(id))
      .find((feature) => feature !== undefined);
  }
}
