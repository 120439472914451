import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TTError } from '@shared/models/error';
import { ErrorAction } from '@shared/models/error-action';
import { environment } from '@env/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Ignore requests coming from the dev portal
    // if (this.router.url.startsWith('/dev')) return next.handle(req);
    if (!req.url.startsWith(`${environment.API_BASE_PATH}`)) return next.handle(req);
    // Otherwise handle normally
    return next.handle(req).pipe(
      catchError((e) => {
        const resp = e as HttpErrorResponse;
        this.getErrorAction(resp.status, e.error)
          .then((action) => (action ? action : this.getStatusAction(resp.status, e.error)))
          .then((action) => this.executeAction(action));
        return throwError(e);
      })
    );
  }

  async executeAction(action: ErrorAction): Promise<void> {
    if (!action) return;
    switch (action.type) {
      case 'TOAST':
        this.toastr.error(action.message, this.translate.instant(action.title), { positionClass: 'toast-bottom-right' });
        break;
    }
  }

  async getErrorAction(statusCode: number, error: TTError): Promise<ErrorAction> {
    if (!error || !error.errorCode || statusCode === 401 || statusCode === 403) return null;

    if (statusCode === 404) return { type: 'NONE' };

    return {
      type: 'TOAST',
      title: 'errors.code.UNKNOWN.title',
      message: this.translate.instant('errors.code.UNKNOWN.message', {
        description: error.errorDescription,
      }),
      errorCode: error.errorCode,
    };
  }

  async getStatusAction(statusCode: number, error: unknown): Promise<ErrorAction> {
    if (statusCode >= 500 && statusCode < 600) {
      switch (statusCode) {
        case 500:
          return {
            type: 'TOAST',
            title: 'errors.http.500.title',
            message: this.translate.instant('errors.http.500.message'),
            errorCode: 'HTTP_' + statusCode,
          };
        case 502:
        case 503:
        case 504:
          return {
            type: 'TOAST',
            title: 'errors.http.502-504.title',
            message: this.translate.instant('errors.http.502-504.message'),
            errorCode: 'HTTP_' + statusCode,
          };
        default:
          return {
            type: 'TOAST',
            title: 'errors.http.5xx.title',
            message: this.translate.instant('errors.http.5xx.message'),
            errorCode: 'HTTP_' + statusCode,
          };
      }
    } else if (statusCode >= 400 && statusCode < 500) {
      switch (statusCode) {
        case 400:
          return {
            type: 'TOAST',
            title: 'errors.http.400.title',
            message: this.translate.instant('errors.http.400.message'),
            errorCode: 'HTTP_' + statusCode,
          };
        case 401:
        case 403:
          // Leave authorization errors for another interceptor
          break;
        case 409: // conflict
          return {
            type: 'TOAST',
            title: 'errors.http.4xx.title',
            message: error?.toString() ?? '',
            errorCode: 'HTTP_' + statusCode,
          };
        default:
          return {
            type: 'TOAST',
            title: 'errors.http.4xx.title',
            message: this.translate.instant('errors.http.4xx.message'),
            errorCode: 'HTTP_' + statusCode,
          };
      }
    }
    return null;
  }
}