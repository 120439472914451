"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hiRange = void 0;
/**
 * Max coordinate value (both positive and negative)
 */
exports.hiRange = 9007199254740991;
