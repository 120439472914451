"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clipToPolyTree = exports.clipToPaths = exports.clipToPathsOrPolyTree = void 0;
var Clipper_1 = require("./Clipper");
var ClipperError_1 = require("./ClipperError");
var enums_1 = require("./enums");
var devMode = typeof process !== "undefined" && process.env && process.env.NODE_ENV !== "production";
var addPathOrPaths = function (clipper, inputDatas, polyType) {
  if (inputDatas === undefined) {
    return;
  }
  // add each input
  for (var i = 0, maxi = inputDatas.length; i < maxi; i++) {
    var inputData = inputDatas[i];
    // add the path/paths
    var pathOrPaths = inputData.data;
    if (!pathOrPaths || pathOrPaths.length <= 0) {
      continue;
    }
    var closed_1 = inputData.closed === undefined ? true : inputData.closed;
    // is it a path or paths?
    if (Array.isArray(pathOrPaths[0])) {
      // paths
      if (!clipper.addPaths(pathOrPaths, polyType, closed_1)) {
        throw new ClipperError_1.ClipperError("invalid paths");
      }
    } else {
      // path
      if (!clipper.addPath(pathOrPaths, polyType, closed_1)) {
        throw new ClipperError_1.ClipperError("invalid path");
      }
    }
  }
};
function clipToPathsOrPolyTree(polyTreeMode, nativeClipperLib, params) {
  if (devMode) {
    if (!polyTreeMode && params.subjectInputs && params.subjectInputs.some(function (si) {
      return !si.closed;
    })) {
      throw new Error("clip to a PolyTree (not to a Path) when using open paths");
    }
  }
  var clipper = new Clipper_1.Clipper(nativeClipperLib, params);
  //noinspection UnusedCatchParameterJS
  try {
    addPathOrPaths(clipper, params.subjectInputs, enums_1.PolyType.Subject);
    addPathOrPaths(clipper, params.clipInputs, enums_1.PolyType.Clip);
    var result = void 0;
    var clipFillType = params.clipFillType === undefined ? params.subjectFillType : params.clipFillType;
    if (!polyTreeMode) {
      result = clipper.executeToPaths(params.clipType, params.subjectFillType, clipFillType, params.cleanDistance);
    } else {
      if (params.cleanDistance !== undefined) {
        throw new ClipperError_1.ClipperError("cleaning is not available for poly tree results");
      }
      result = clipper.executeToPolyTee(params.clipType, params.subjectFillType, clipFillType);
    }
    if (result === undefined) {
      throw new ClipperError_1.ClipperError("error while performing clipping task");
    }
    return result;
  } finally {
    clipper.dispose();
  }
}
exports.clipToPathsOrPolyTree = clipToPathsOrPolyTree;
function clipToPaths(nativeClipperLib, params) {
  return clipToPathsOrPolyTree(false, nativeClipperLib, params);
}
exports.clipToPaths = clipToPaths;
function clipToPolyTree(nativeClipperLib, params) {
  return clipToPathsOrPolyTree(true, nativeClipperLib, params);
}
exports.clipToPolyTree = clipToPolyTree;
