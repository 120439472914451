"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nativePathToPath = exports.doubleArrayToPath = exports.nativePathToDoubleArray = exports.pathToNativePath = exports.doubleArrayToNativePath = exports.pathToDoubleArray = exports.writePathToDoubleArray = exports.getNofItemsForPath = void 0;
var mem_1 = require("./mem");
var coordsPerPoint = 2;
function getNofItemsForPath(path) {
  return 1 + path.length * coordsPerPoint;
}
exports.getNofItemsForPath = getNofItemsForPath;
// js to c++
function writePathToDoubleArray(path, heapBytes, startPtr) {
  var len = path.length;
  heapBytes[startPtr] = len;
  var arrayI = 1 + startPtr;
  for (var i = 0; i < len; i++) {
    heapBytes[arrayI++] = path[i].x;
    heapBytes[arrayI++] = path[i].y;
  }
  return arrayI;
}
exports.writePathToDoubleArray = writePathToDoubleArray;
function pathToDoubleArray(nativeClipperLib, path) {
  var nofItems = getNofItemsForPath(path);
  var heapBytes = (0, mem_1.mallocDoubleArray)(nativeClipperLib, nofItems);
  writePathToDoubleArray(path, heapBytes, 0);
  return heapBytes;
}
exports.pathToDoubleArray = pathToDoubleArray;
function doubleArrayToNativePath(nativeClipperLib, array, freeArray) {
  var p = new nativeClipperLib.Path();
  nativeClipperLib.toPath(p, array.byteOffset);
  if (freeArray) {
    (0, mem_1.freeTypedArray)(nativeClipperLib, array);
  }
  return p;
}
exports.doubleArrayToNativePath = doubleArrayToNativePath;
function pathToNativePath(nativeClipperLib, path) {
  var array = pathToDoubleArray(nativeClipperLib, path);
  return doubleArrayToNativePath(nativeClipperLib, array, true);
}
exports.pathToNativePath = pathToNativePath;
// c++ to js
function nativePathToDoubleArray(nativeClipperLib, nativePath, freeNativePath) {
  var array = nativeClipperLib.fromPath(nativePath);
  if (freeNativePath) {
    nativePath.delete();
  }
  return array;
}
exports.nativePathToDoubleArray = nativePathToDoubleArray;
function doubleArrayToPath(nativeClipperLib, array, _freeDoubleArray, startPtr) {
  var len = array[startPtr];
  var path = [];
  path.length = len;
  var arrayI = 1 + startPtr;
  for (var i = 0; i < len; i++) {
    path[i] = {
      x: array[arrayI++],
      y: array[arrayI++]
    };
  }
  if (_freeDoubleArray) {
    (0, mem_1.freeTypedArray)(nativeClipperLib, array);
  }
  return {
    path: path,
    ptrEnd: arrayI
  };
}
exports.doubleArrayToPath = doubleArrayToPath;
function nativePathToPath(nativeClipperLib, nativePath, freeNativePath) {
  var array = nativePathToDoubleArray(nativeClipperLib, nativePath, freeNativePath);
  return doubleArrayToPath(nativeClipperLib, array, true, 0).path;
}
exports.nativePathToPath = nativePathToPath;
