<div class="dashboard-tile" [ngClass]="tileClass" cdkDrag>
  <div class="dashboard-tile__drag" cdkDragHandle>
    <span class="material-icons">drag_indicator</span>
  </div>

  <div class="dashboard-tile__header" *ngIf="title || buttonTitle">
    <h3 *ngIf="title">{{ title | translate }}</h3>
    <button
      class="button-secondary button--has-icon"
      (click)="openButtonLink()"
      *ngIf="buttonTitle && buttonLink">
      {{ buttonTitle | translate }}
    </button>
  </div>

  <div class="dashboard-tile__content">
    <ng-content></ng-content>
  </div>
</div>
