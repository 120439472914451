/**
 * BIM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OptionalBimSubjectQueryBimSubjectRealEstateBuildingReference {
  optional_BimSubject_id: string;
  optional_BimSubject_type: OptionalBimSubjectQueryBimSubjectRealEstateBuildingReference.OptionalBimSubjectTypeEnum;
}
export namespace OptionalBimSubjectQueryBimSubjectRealEstateBuildingReference {
  export type OptionalBimSubjectTypeEnum = 'RealEstateBuilding';
  export const OptionalBimSubjectTypeEnum = {
    RealEstateBuilding: 'RealEstateBuilding' as OptionalBimSubjectTypeEnum,
  };
}


