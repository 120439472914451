"use strict";

var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scalePaths = exports.scalePath = exports.simplifyPolygons = exports.simplifyPolygon = exports.reversePaths = exports.reversePath = exports.polyTreeToPaths = exports.pointInPolygon = exports.orientation = exports.openPathsFromPolyTree = exports.minkowskiSumPaths = exports.minkowskiSumPath = exports.minkowskiDiff = exports.closedPathsFromPolyTree = exports.cleanPolygons = exports.cleanPolygon = exports.area = void 0;
var enums_1 = require("./enums");
var nativeEnumConversion_1 = require("./native/nativeEnumConversion");
var PathsToNativePaths_1 = require("./native/PathsToNativePaths");
var PathToNativePath_1 = require("./native/PathToNativePath");
function tryDelete() {
  var e_1, _a;
  var objs = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    objs[_i] = arguments[_i];
  }
  try {
    for (var objs_1 = __values(objs), objs_1_1 = objs_1.next(); !objs_1_1.done; objs_1_1 = objs_1.next()) {
      var obj = objs_1_1.value;
      if (!obj.isDeleted()) {
        obj.delete();
      }
    }
  } catch (e_1_1) {
    e_1 = {
      error: e_1_1
    };
  } finally {
    try {
      if (objs_1_1 && !objs_1_1.done && (_a = objs_1.return)) _a.call(objs_1);
    } finally {
      if (e_1) throw e_1.error;
    }
  }
}
function area(path) {
  // we use JS since copying structures is slower than actually doing it
  var cnt = path.length;
  if (cnt < 3) {
    return 0;
  }
  var a = 0;
  for (var i = 0, j = cnt - 1; i < cnt; ++i) {
    a += (path[j].x + path[i].x) * (path[j].y - path[i].y);
    j = i;
  }
  return -a * 0.5;
}
exports.area = area;
function cleanPolygon(nativeLib, path, distance) {
  if (distance === void 0) {
    distance = 1.1415;
  }
  var nativePath = (0, PathToNativePath_1.pathToNativePath)(nativeLib, path);
  try {
    nativeLib.cleanPolygon(nativePath, distance);
    return (0, PathToNativePath_1.nativePathToPath)(nativeLib, nativePath, true); // frees nativePath
  } finally {
    tryDelete(nativePath);
  }
}
exports.cleanPolygon = cleanPolygon;
function cleanPolygons(nativeLib, paths, distance) {
  if (distance === void 0) {
    distance = 1.1415;
  }
  var nativePaths = (0, PathsToNativePaths_1.pathsToNativePaths)(nativeLib, paths);
  try {
    nativeLib.cleanPolygons(nativePaths, distance);
    return (0, PathsToNativePaths_1.nativePathsToPaths)(nativeLib, nativePaths, true); // frees nativePath
  } finally {
    tryDelete(nativePaths);
  }
}
exports.cleanPolygons = cleanPolygons;
function addPolyNodeToPaths(polynode, nt, paths) {
  var match = true;
  switch (nt) {
    case 1 /* NodeType.Open */:
      return;
    case 2 /* NodeType.Closed */:
      match = !polynode.isOpen;
      break;
    default:
      break;
  }
  if (polynode.contour.length > 0 && match) {
    paths.push(polynode.contour);
  }
  for (var ii = 0, max = polynode.childs.length; ii < max; ii++) {
    var pn = polynode.childs[ii];
    addPolyNodeToPaths(pn, nt, paths);
  }
}
function closedPathsFromPolyTree(polyTree) {
  // we do this in JS since copying path is more expensive than just doing it
  var result = [];
  // result.Capacity = polytree.Total;
  addPolyNodeToPaths(polyTree, 2 /* NodeType.Closed */, result);
  return result;
}
exports.closedPathsFromPolyTree = closedPathsFromPolyTree;
function minkowskiDiff(nativeLib, poly1, poly2) {
  var nativePath1 = (0, PathToNativePath_1.pathToNativePath)(nativeLib, poly1);
  var nativePath2 = (0, PathToNativePath_1.pathToNativePath)(nativeLib, poly2);
  var outNativePaths = new nativeLib.Paths();
  try {
    nativeLib.minkowskiDiff(nativePath1, nativePath2, outNativePaths);
    tryDelete(nativePath1, nativePath2);
    return (0, PathsToNativePaths_1.nativePathsToPaths)(nativeLib, outNativePaths, true); // frees outNativePaths
  } finally {
    tryDelete(nativePath1, nativePath2, outNativePaths);
  }
}
exports.minkowskiDiff = minkowskiDiff;
function minkowskiSumPath(nativeLib, pattern, path, pathIsClosed) {
  var patternNativePath = (0, PathToNativePath_1.pathToNativePath)(nativeLib, pattern);
  var nativePath = (0, PathToNativePath_1.pathToNativePath)(nativeLib, path);
  var outNativePaths = new nativeLib.Paths();
  try {
    nativeLib.minkowskiSumPath(patternNativePath, nativePath, outNativePaths, pathIsClosed);
    tryDelete(patternNativePath, nativePath);
    return (0, PathsToNativePaths_1.nativePathsToPaths)(nativeLib, outNativePaths, true); // frees outNativePaths
  } finally {
    tryDelete(patternNativePath, nativePath, outNativePaths);
  }
}
exports.minkowskiSumPath = minkowskiSumPath;
function minkowskiSumPaths(nativeLib, pattern, paths, pathIsClosed) {
  // TODO: im not sure if for this method we can reuse the input/output path
  var patternNativePath = (0, PathToNativePath_1.pathToNativePath)(nativeLib, pattern);
  var nativePaths = (0, PathsToNativePaths_1.pathsToNativePaths)(nativeLib, paths);
  try {
    nativeLib.minkowskiSumPaths(patternNativePath, nativePaths, nativePaths, pathIsClosed);
    tryDelete(patternNativePath);
    return (0, PathsToNativePaths_1.nativePathsToPaths)(nativeLib, nativePaths, true); // frees nativePaths
  } finally {
    tryDelete(patternNativePath, nativePaths);
  }
}
exports.minkowskiSumPaths = minkowskiSumPaths;
function openPathsFromPolyTree(polyTree) {
  // we do this in JS since copying path is more expensive than just doing it
  var result = [];
  var len = polyTree.childs.length;
  result.length = len;
  var resultLength = 0;
  for (var i = 0; i < len; i++) {
    if (polyTree.childs[i].isOpen) {
      result[resultLength++] = polyTree.childs[i].contour;
    }
  }
  result.length = resultLength;
  return result;
}
exports.openPathsFromPolyTree = openPathsFromPolyTree;
function orientation(path) {
  return area(path) >= 0;
}
exports.orientation = orientation;
function pointInPolygon(point, path) {
  // we do this in JS since copying path is more expensive than just doing it
  // returns 0 if false, +1 if true, -1 if pt ON polygon boundary
  // See "The Point in Polygon Problem for Arbitrary Polygons" by Hormann & Agathos
  // http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.88.5498&rep=rep1&type=pdf
  var result = 0;
  var cnt = path.length;
  if (cnt < 3) {
    return 0;
  }
  var ip = path[0];
  for (var i = 1; i <= cnt; ++i) {
    var ipNext = i === cnt ? path[0] : path[i];
    if (ipNext.y === point.y) {
      if (ipNext.x === point.x || ip.y === point.y && ipNext.x > point.x === ip.x < point.x) {
        return -1;
      }
    }
    if (ip.y < point.y !== ipNext.y < point.y) {
      if (ip.x >= point.x) {
        if (ipNext.x > point.x) {
          result = 1 - result;
        } else {
          var d = (ip.x - point.x) * (ipNext.y - point.y) - (ipNext.x - point.x) * (ip.y - point.y);
          if (d === 0) {
            return -1;
          } else if (d > 0 === ipNext.y > ip.y) {
            result = 1 - result;
          }
        }
      } else {
        if (ipNext.x > point.x) {
          var d = (ip.x - point.x) * (ipNext.y - point.y) - (ipNext.x - point.x) * (ip.y - point.y);
          if (d === 0) {
            return -1;
          } else if (d > 0 === ipNext.y > ip.y) {
            result = 1 - result;
          }
        }
      }
    }
    ip = ipNext;
  }
  return result;
}
exports.pointInPolygon = pointInPolygon;
function polyTreeToPaths(polyTree) {
  // we do this in JS since copying path is more expensive than just doing it
  var result = [];
  // result.Capacity = polytree.total;
  addPolyNodeToPaths(polyTree, 0 /* NodeType.Any */, result);
  return result;
}
exports.polyTreeToPaths = polyTreeToPaths;
function reversePath(path) {
  // we use JS since copying structures is slower than actually doing it
  path.reverse();
}
exports.reversePath = reversePath;
function reversePaths(paths) {
  // we use JS since copying structures is slower than actually doing it
  for (var i = 0, max = paths.length; i < max; i++) {
    reversePath(paths[i]);
  }
}
exports.reversePaths = reversePaths;
function simplifyPolygon(nativeLib, path, fillType) {
  if (fillType === void 0) {
    fillType = enums_1.PolyFillType.EvenOdd;
  }
  var nativePath = (0, PathToNativePath_1.pathToNativePath)(nativeLib, path);
  var outNativePaths = new nativeLib.Paths();
  try {
    nativeLib.simplifyPolygon(nativePath, outNativePaths, (0, nativeEnumConversion_1.polyFillTypeToNative)(nativeLib, fillType));
    tryDelete(nativePath);
    return (0, PathsToNativePaths_1.nativePathsToPaths)(nativeLib, outNativePaths, true); // frees outNativePaths
  } finally {
    tryDelete(nativePath, outNativePaths);
  }
}
exports.simplifyPolygon = simplifyPolygon;
function simplifyPolygons(nativeLib, paths, fillType) {
  if (fillType === void 0) {
    fillType = enums_1.PolyFillType.EvenOdd;
  }
  var nativePaths = (0, PathsToNativePaths_1.pathsToNativePaths)(nativeLib, paths);
  try {
    nativeLib.simplifyPolygonsOverwrite(nativePaths, (0, nativeEnumConversion_1.polyFillTypeToNative)(nativeLib, fillType));
    return (0, PathsToNativePaths_1.nativePathsToPaths)(nativeLib, nativePaths, true); // frees nativePaths
  } finally {
    tryDelete(nativePaths);
  }
}
exports.simplifyPolygons = simplifyPolygons;
function scalePath(path, scale) {
  var len = path.length;
  var sol = [];
  sol.length = path.length;
  for (var i = 0; i < len; i++) {
    var p = path[i];
    sol[i] = {
      x: Math.round(p.x * scale),
      y: Math.round(p.y * scale)
    };
  }
  return sol;
}
exports.scalePath = scalePath;
/**
 * Scales all inner paths by multiplying all its coordinates by a number and then rounding them.
 *
 * @param paths - Paths to scale
 * @param scale - Scale multiplier
 * @return {Paths} - The scaled paths
 */
function scalePaths(paths, scale) {
  if (scale === 0) {
    return [];
  }
  var len = paths.length;
  var sol = [];
  sol.length = len;
  for (var i = 0; i < len; i++) {
    sol[i] = scalePath(paths[i], scale);
  }
  return sol;
}
exports.scalePaths = scalePaths;
