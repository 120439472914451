import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { UsersPageComponent } from './views/user-overview/user-overview.component';
import { UserDetailComponent } from './views/user-detail/user-detail.component';
import { OrganizationDetailComponent } from './views/organization-detail/organization-detail.component';
import { OrganizationOverviewComponent } from './views/organization-overview/organization-overview.component';

const routes: Routes = [
  {
    path: 'users',
    component: UsersPageComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        {
          translate: 'users-overview-page',
          link: '/users',
        },
      ],
    },
  },
  {
    path: 'users/new',
    component: UserDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard], // Explicitly mentioned to avoid confusion
    data: {
      breadcrumb_segments: [
        {
          translate: 'users-overview-page',
          link: '/users',
        },
        { translate: 'user-detail-page' },
      ],
    },
  },
  {
    path: 'users/:id',
    component: UserDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        {
          translate: 'users-overview-page',
          link: '/users',
        },
        { translate: 'user-detail-page', link: '/users/:id' },
      ],
    },
  },
  {
    path: 'organizations',
    component: OrganizationOverviewComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        {
          translate: 'organization-overview-page',
          link: '/organizations',
        },
      ],
    },
  },
  {
    path: 'organizations/new', // Explicitly mentioned to avoid confusion
    component: OrganizationDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        {
          translate: 'organization-overview-page',
          link: '/organizations',
        },
        { translate: 'organization-detail-page' },
      ],
    },
  },
  {
    path: 'organizations/:id',
    component: OrganizationDetailComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    data: {
      breadcrumb_segments: [
        {
          translate: 'organization-overview-page',
          link: '/organizations',
        },
        { translate: 'organization-detail-page', link: '/organizations/:id' },
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
