<div class="bim-notes">
  <cdk-virtual-scroll-viewport class="timeline" itemSize="146">
    <div
      *cdkVirtualFor="let item of timeLine; let last = last"
      class="timeline-event"
      tabindex="0">
      <app-timeline-event
        [item]="item"
        [smallVersion]="true"
        (imageFunction)="emitImageClick(item, $event)"
        (fileFunction)="emitFileClick(item, $event)"
        [lastItem]="last"></app-timeline-event>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
