import { EventEmitter, Injectable } from '@angular/core';
import { ProjectDto } from '@api-clients/project';
import { PendingChange, ProjectMeasureList } from '../../../services/project_measure.service';
import { GenericSubjectInfoService } from '../../../services/generic_subject_info_service';
import { EnrichedSubjects } from '../measures-detail.component';

@Injectable({
  providedIn: 'root',
})
export class VariablesPopupService {
  public active: boolean = false;
  public project?: ProjectDto;
  public projectMeasures: ProjectMeasureList[] = [];
  public enrichedSubjects: EnrichedSubjects[] = [];
  public pendingChanges: PendingChange[] = [];

  public hidden: EventEmitter<boolean> = new EventEmitter<boolean>();
  public shown: EventEmitter<boolean> = new EventEmitter<boolean>();
  public saved: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly genericSubjectInfoService: GenericSubjectInfoService) {}

  public hide(): void {
    this.active = false;
    this.hidden.emit(true);
  }

  public async show(
    project: ProjectDto,
    projectMeasures: ProjectMeasureList[],
    pendingChanges: PendingChange[]
  ): Promise<void> {
    this.project = project;
    this.projectMeasures = projectMeasures;
    this.pendingChanges = pendingChanges;

    this.enrichedSubjects = [];
    for (const subject of this.project.subjects) {
      const name = await this.genericSubjectInfoService.getNameForSubject(subject);
      this.enrichedSubjects.push({ subject: subject, name: name });
    }

    this.active = true;
    this.shown.emit(true);
  }
}
