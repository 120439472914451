import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GenericSubject,
  MeasureCalculation,
  MeasureListItem,
  MeasureSubject,
  MeasureValueDto,
  ProjectDto,
  ProjectsService,
  ScenarioDto,
  ScenariosService,
} from '@api-clients/project';
import { GenericSubjectInfoService } from '../../services/generic_subject_info_service';
import {
  PendingChange,
  ProjectMeasureList,
  ProjectMeasureService,
} from '../../services/project_measure.service';
import { lastValueFrom } from 'rxjs';
import { VariablesPopupService } from './variables-popup/variables-popup.service';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { TranslateModule } from '@ngx-translate/core';
import { InfoBubbleComponent } from '@shared/components/info-bubble/info-bubble.component';
import { DecimalPipe, NgIf } from '@angular/common';
import { VariablesPopupComponent } from './variables-popup/variables-popup.component';
import { UnitPinComponent } from '../../components/unit-pin/unit-pin.component';

export interface EnrichedSubjects {
  subject: GenericSubject;
  name: string;
}

@Component({
  selector: 'app-measure-detail',
  templateUrl: './measures-detail.component.html',
  styleUrl: './measures-detail.component.scss',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    TranslateModule,
    InfoBubbleComponent,
    DecimalPipe,
    VariablesPopupComponent,
    UnitPinComponent,
    NgIf,
  ],
})
export class MeasuresDetailComponent implements OnInit {
  protected activeUnit: number = 0;

  protected project: ProjectDto | undefined;
  protected scenario: ScenarioDto | undefined;
  protected enrichedSubjects: EnrichedSubjects[] = [];
  protected measures: MeasureListItem[] = [];
  protected pendingChanges: PendingChange[] = [];
  protected projectMeasureList: ProjectMeasureList[] = [];
  protected isSaving: boolean = false;
  protected editMode: boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly projectsService: ProjectsService,
    private readonly scenariosService: ScenariosService,
    private readonly genericSubjectInfoService: GenericSubjectInfoService,
    private readonly projectMeasureService: ProjectMeasureService,
    private readonly variablesPopupService: VariablesPopupService
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async (next) => {
      const project_id = next.get('id');

      if (!project_id) return;

      this.project = await lastValueFrom(this.projectsService.projectsIdGet(project_id));
      this.scenario = await lastValueFrom(this.scenariosService.scenariosDefaultGet(project_id));
      for (const subject of this.project.subjects) {
        const name = await this.genericSubjectInfoService.getNameForSubject(subject);
        this.enrichedSubjects.push({ subject: subject, name: name });
      }

      await this.reload();
    });

    this.variablesPopupService.saved.subscribe(async () => {
      await this.reload();
    });
  }

  async reload(): Promise<void> {
    if (!this.project || !this.scenario) return;

    this.projectMeasureList = await this.projectMeasureService.getMeasuresForScenario(
      this.scenario.id
    );
  }

  async save(): Promise<void> {
    if (!this.project || !this.scenario) return;
    await this.projectMeasureService.save(this.scenario.id, this.pendingChanges);
    this.pendingChanges = [];
    await this.reload();
    this.editMode = false;
    await this.variablesPopupService.show(
      this.project,
      this.projectMeasureList,
      this.pendingChanges
    );
  }

  getPendingChangesForSubject(subject: EnrichedSubjects): PendingChange[] {
    return this.pendingChanges.filter((pc: PendingChange) =>
      this.isSame(pc.subject, subject.subject)
    );
  }

  hasCheckedMeasures(subjectKey: string): boolean {
    if (this.editMode) return true;
    return this.projectMeasureList.some((pm) => pm.subjects.includes(subjectKey));
  }

  toggle(event: Event, projectMeasure: ProjectMeasureList, subject: EnrichedSubjects): void {
    const inputElement = event.target as HTMLInputElement;
    const valueExists = projectMeasure.subjects.includes(JSON.stringify(subject.subject));
    const checked = inputElement.checked;
    if (checked && !valueExists) {
      this.pendingChanges.push({
        measure_template_id: projectMeasure.measure_template_id,
        measure_id: projectMeasure.measure_id,
        subject: subject.subject,
        values: undefined,
        checked,
      });
    } else {
      const index = this.pendingChanges.findIndex(
        (pc) =>
          pc.measure_template_id === projectMeasure!.measure_template_id &&
          pc.subject === subject.subject
      );
      if (index >= 0) {
        this.pendingChanges.splice(index, 1);
      } else {
        // the value is not in the list, but the checkbox is unchecked

        this.pendingChanges.push({
          measure_template_id: projectMeasure.measure_template_id,
          measure_id: projectMeasure.measure_id,
          subject: subject.subject,
          values: undefined,
          measureValue: projectMeasure.measureValues?.filter((mv) =>
            this.isSame(mv.subject, subject.subject)
          )[0],
          checked,
        });
      }
    }
  }

  isSame(measureSubject: MeasureSubject, subject: GenericSubject): boolean {
    return JSON.stringify(measureSubject) === JSON.stringify(subject);
  }

  getSummaryForSubject(
    subjectKey: string,
    projectMeasures: ProjectMeasureList[]
  ): { min: number; max: number } {
    let min = 0;
    let max = 0;
    for (const projectMeasure of projectMeasures) {
      if (projectMeasure.evaluated_calculations && projectMeasure.subjects.includes(subjectKey)) {
        const calculation = projectMeasure.evaluated_calculations[subjectKey];
        min += calculation.min;
        max += calculation.max;
      }
    }
    return { min, max };
  }

  getSummaryForAll(): { min: number; max: number } {
    let min = 0;
    let max = 0;
    for (const subject of this.project?.subjects || []) {
      const subjectKey = JSON.stringify(subject);
      for (const projectMeasure of this.projectMeasureList) {
        if (projectMeasure.evaluated_calculations && projectMeasure.subjects.includes(subjectKey)) {
          const calculation = projectMeasure.evaluated_calculations[subjectKey];
          min += calculation.min;
          max += calculation.max;
        }
      }
    }
    return { min, max };
  }

  showFriendlyFormula(
    measureCalculation: MeasureCalculation,
    measureValues: MeasureValueDto[] | undefined,
    subjectKey: string
  ): string {
    if (!measureValues) return measureCalculation.formula;
    let result = measureCalculation.formula;
    for (const measureValue of measureValues.filter(
      (mv) => JSON.stringify(mv.subject) === subjectKey
    )) {
      for (const measureValueVariable of measureValue.values) {
        result = result.replace(
          '[' + measureValueVariable.name + ']',
          measureValueVariable.value?.toString() ?? '?'
        );
      }
    }
    return result;
  }

  edit(): void {
    this.editMode = true;
  }

  cancel(): void {
    this.editMode = false;
    this.pendingChanges = [];
  }

  getMeasureValueForVariable(
    subjectKey: string,
    variable: string,
    measureValues: MeasureValueDto[] | undefined
  ): string {
    if (!measureValues) return '';
    for (const measureValue of measureValues.filter(
      (mv) => JSON.stringify(mv.subject) === subjectKey
    )) {
      for (const measureValueVariable of measureValue.values) {
        if (measureValueVariable.name === variable && measureValueVariable) {
          return measureValueVariable.value?.toString() ?? '';
        }
      }
    }
    return '';
  }

  protected readonly Object = Object;
  protected readonly JSON = JSON;
}
