import { BimElement, ObjectCategory, RoomCategory } from '@api-clients/bim';
import { Product } from '@api-clients/product';
import { Property } from '../../views/model-viewer/property';
import { symmetricDifference } from 'ramda';

export interface UnsavedElement {
  element: BimElement;
  id: string;
  propertyDefinition?: Map<string, Property>;
  originalValues: Map<string, unknown>;
  originalCategory?: RoomCategory | ObjectCategory;

  getProducts(): Product[];

  addProducts(products: Product[]): void;

  removeProduct(product: Product): void;

  getChangeCount(): number;

  undo(): void;
}

export class DeleteElement implements UnsavedElement {
  id: string;
  element: BimElement;
  propertyDefinition?: Map<string, Property>;
  originalValues: Map<string, unknown>;
  originalCategory?: RoomCategory | ObjectCategory;

  constructor(
    id: string,
    element: BimElement,
    originalValues: Map<string, unknown>,
    propertyDefinition?: Map<string, Property>
  ) {
    this.id = id;
    this.element = element;
    this.originalValues = originalValues;
    this.propertyDefinition = propertyDefinition;
  }

  getProducts(): Product[] {
    return this.element.properties['products'] || [];
  }

  addProducts(products: Product[]): void {
    console.error('Cannot add products to deleted element', products);
  }

  removeProduct(product: Product): void {
    console.error('Cannot remove product from deleted element', product);
  }

  getChangeCount(): number {
    return 1;
  }

  undo(): void {
    console.error('Cannot undo deleted element');
  }
}

export class ChangedElement implements UnsavedElement {
  element: BimElement;
  id: string;
  originalValues: Map<string, unknown>;
  originalCategory?: RoomCategory | ObjectCategory;
  // Defines the properties that this element can have
  propertyDefinition?: Map<string, Property>;

  constructor(
    id: string,
    element: BimElement,
    originalValues: Map<string, unknown>,
    originalCategory?: RoomCategory | ObjectCategory,
    propertyDefinition?: Map<string, Property>
  ) {
    this.id = id;
    this.element = element;
    this.originalValues = originalValues;
    this.originalCategory = originalCategory;
    this.propertyDefinition = propertyDefinition;
  }

  changeCategory(category: RoomCategory | ObjectCategory): void {
    if (this.originalCategory == category) {
      this.originalCategory = undefined;
    } else if (this.originalCategory === undefined) {
      this.originalCategory = this.element['category'];
    }
    // Todo: make this typed for Room/Object
    this.element['category'] = category;
  }

  removeCategoryChange(): void {
    this.element['category'] = this.originalCategory;
    this.originalCategory = undefined;
  }

  changeProperty(property: string, value: unknown): void {
    if (this.originalValues.get(property) === (value ?? null)) {
      // replace by null, because the undefined value is used to indicate that the property was not set
      // If we're back to the original configuration, remove the original values
      this.originalValues.delete(property);
    } else if (this.originalValues.get(property) === undefined) {
      // If we don't have an original value, set it
      this.originalValues.set(property, this.element.properties[property] ?? null);
    }
    this.element.properties[property] = value;
  }

  addProducts(products: Product[]): void {
    // Potentially init original Products
    if (this.originalValues.get('products') === undefined) {
      this.originalValues.set('products', this.getProducts());
    }

    this.element.properties['products'] = [...this.getProducts(), ...products];

    // If we're back to the original configuration, remove the original values
    this.removeProductChangesIfBackToOriginal();
  }

  removeProduct(product: Product): void {
    // Potentially init original Products
    if (this.originalValues.get('products') === undefined) {
      this.originalValues.set('products', this.getProducts());
    }

    this.element.properties['products'] = this.getProducts().filter(
      (p) => !equalToProduct(p)(product)
    );

    // If we're back to the original configuration, remove the original values
    this.removeProductChangesIfBackToOriginal();
  }

  removePropertyChange(property: string): void {
    // if the original value is undefined or null, the property should be completely deleted.
    if (this.originalValues.get(property) === null) {
      delete this.element.properties[property];
    } else {
      this.element.properties[property] = this.originalValues.get(property);
    }
    this.originalValues.delete(property);
  }

  undo(): void {
    for (const propertyName of this.originalValues.keys()) {
      this.removePropertyChange(propertyName);
    }
    if (this.originalCategory !== undefined) {
      this.removeCategoryChange();
    }
  }

  // Todo: make this typed for Room/Object
  getProducts(): Product[] {
    return this.element.properties['products'] || [];
  }

  getChangeCount(): number {
    const originalProducts = this.originalValues.get('products') as Product[] | undefined;
    const productDifference =
      originalProducts !== undefined
        ? symmetricDifference(this.getProducts(), originalProducts).length - 1 // -1 to avoid counting twice in originalValues array
        : 0;

    return (
      this.originalValues.size + // Changed properties
      (this.originalCategory === undefined ? 0 : 1) + // Changed category
      productDifference // Changed products
    );
  }

  removeProductChangesIfBackToOriginal(): void {
    if (
      symmetricDifference(
        (this.originalValues.get('products') as Product[]) || [],
        this.getProducts()
      ).length === 0
    ) {
      this.originalValues.delete('products');
    }
  }
}

export class NewElement extends ChangedElement {
  constructor(
    id: string,
    element: BimElement,
    originalValues: Map<string, unknown>,
    propertyDefinition?: Map<string, Property>
  ) {
    super(id, element, originalValues, undefined, propertyDefinition);
  }

  override changeProperty(property: string, value: unknown): void {
    if (property === 'geometry') {
      this.element.properties[property] = value;
    } else {
      super.changeProperty(property, value);
    }
  }

  override getChangeCount(): number {
    return super.getChangeCount() + 1;
  }
}

function equalToProduct(a: Product): (b: Product) => boolean {
  return (b: Product) =>
    a.manufacturer_gln === b.manufacturer_gln && a.product_code === b.product_code;
}
