import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-bubble',
  templateUrl: './info-bubble.component.html',
  styleUrl: './info-bubble.component.scss',
  standalone: true,
})
export class InfoBubbleComponent {
  @Input() small: boolean = false;
  @Input() message: string = '';
}
