import { Component } from '@angular/core';
import { BimPropertyService, TimelineService } from '../../services';
import { DossierDetailService } from '../../services/dossier-detail.service';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-building-wrapper',
    templateUrl: './building-wrapper.component.html',
    styleUrl: './building-wrapper.component.scss',
    providers: [TimelineService, BimPropertyService, DossierDetailService],
    imports: [RouterOutlet]
})
export class BuildingWrapperComponent {
  constructor() {}
}
