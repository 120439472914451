import { BoxGeometry, Intersection, Matrix4, Mesh, MeshStandardMaterial, Vector3 } from 'three';

export function boxFromMatrix(matrix: Matrix4): Mesh {
  const geometry = new BoxGeometry();
  const material = new MeshStandardMaterial();
  material.color.set(0xc6ed3b);
  const box = new Mesh(geometry, material);
  box.applyMatrix4(matrix);
  return box;
}

export function boxOnTopOfIntersection(
  intersection: Intersection,
  width: number,
  height: number,
  length: number
): Mesh {
  if (!intersection.face) throw 'No face found';
  const normal = intersection.face!.normal!;
  normal.applyMatrix4(intersection.object.matrixWorld!);
  const position = intersection.point
    .add(intersection.object.position)
    .add(normal.clone().multiplyScalar(length / 2));
  const matrix = new Matrix4()
    .lookAt(new Vector3(), normal, new Vector3(0, 1, 0))
    .scale(new Vector3(width, height, length))
    .setPosition(position);
  return boxFromMatrix(matrix);
}
