/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProjectStatus = 'Unknown' | 'Started' | 'InProgress' | 'Done';

export const ProjectStatus = {
    Unknown: 'Unknown' as ProjectStatus,
    Started: 'Started' as ProjectStatus,
    InProgress: 'InProgress' as ProjectStatus,
    Done: 'Done' as ProjectStatus
};

