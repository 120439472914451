import { Component, DestroyRef, OnInit, ViewChild } from '@angular/core';
import { FloorPlanComponent } from '@shared/components/floor-plan/floor-plan.component';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, distinctUntilChanged, map, ReplaySubject, skip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { isBimRoom } from '../model-viewer/utils/types';
import { BimPropertyService } from '../../services';
import { DossierDetailService } from '../../services/dossier-detail.service';

@Component({
  selector: 'app-floor-plan-viewer',
  templateUrl: './floor-plan-viewer.component.html',
  styleUrl: './floor-plan-viewer.component.scss',
})
export class FloorPlanViewerComponent implements OnInit {
  @ViewChild(FloorPlanComponent, { static: true }) floorPlan!: FloorPlanComponent;
  protected noLinkedBimAvailable: boolean = false;

  private buildingId: string | undefined;

  protected bimId = new ReplaySubject<string>(1);
  protected selectedElementId = new BehaviorSubject<string | undefined>(undefined);

  public shoppingCartCount: number = 0;
  protected bounceShoppingCartButton: boolean = false;
  protected visibleMenu: 'overview' | 'changes' | 'details' = 'overview';
  protected isSaving: boolean = false;
  protected breadcrumbTree: { name: string; link: string }[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly toastr: ToastrService,
    private readonly bimPropertyService: BimPropertyService,
    private readonly dossierDetailService: DossierDetailService,
    private destroyRef: DestroyRef
  ) {
    this.dossierDetailService.dossier
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async (dossier) => {
        this.buildingId = dossier.building_id;
        const linkedBimId = dossier.linked_bim_ids.at(0);
        if (linkedBimId) {
          this.bimId.next(linkedBimId);
        } else {
          this.noLinkedBimAvailable = true;
        }
      });

    // Keep track of the current menu state
    this.activatedRoute.paramMap
      .pipe(
        takeUntilDestroyed(),
        map((paramMap) => paramMap.get('state')),
        distinctUntilChanged()
      )
      .subscribe((state: string | null) => {
        if (state === 'changes') {
          if (this.shoppingCartCount > 0) this.visibleMenu = 'changes';
          else this.navigate('overview');
        } else if (state === 'details') {
          this.visibleMenu = 'details';
        } else if (state === 'overview') {
          this.visibleMenu = 'overview';
        }
        this.updateBreadCrumb();
      });

    this.bimPropertyService.selectedElement
      .pipe(takeUntilDestroyed())
      .subscribe((selectedElement) => {
        this.selectedElementId.next(selectedElement?.id);
        if (selectedElement) {
          this.navigate('details');
        } else {
          this.navigate('overview');
        }
      });
    // Todo: this button logic should be moved to a separate component
    this.bimPropertyService.changeCount.pipe(skip(1)).subscribe((count) => {
      if (count > 0) {
        this.bounceShoppingCartButton = true;
        setTimeout(() => {
          this.bounceShoppingCartButton = false;
        }, 400);
      }
    });

    this.bimPropertyService.changeCount.subscribe((count) => {
      this.shoppingCartCount = count;
      if (this.shoppingCartCount == 0 && this.visibleMenu === 'changes') {
        this.navigate('overview');
      }
    });

    this.bimPropertyService.unsavedElements
      .pipe(takeUntilDestroyed())
      .subscribe((unsavedElements) => {
        // If a room changed category, update the floor plan
        for (const unsavedElement of unsavedElements) {
          // Todo: only recategorize if the category changed
          if (!isBimRoom(unsavedElement.element)) continue;
          this.floorPlan.recategorizeRoom(unsavedElement.id, unsavedElement.element.category);
        }

        this.selectedElementId.next(undefined);
      });
  }

  async ngOnInit(): Promise<void> {
    this.updateBreadCrumb();
  }

  protected selectBimId(id: string | undefined): void {
    this.bimPropertyService.selectBimElement(id);
  }

  updateBreadCrumb(): void {
    this.breadcrumbTree = [];
    this.breadcrumbTree.push({
      name: this.translateService.instant('building_information'),
      link: '/buildings/' + this.buildingId + '/floor-plan/overview',
    });
    if (this.visibleMenu === 'changes' || this.visibleMenu === 'details') {
      this.breadcrumbTree.push({
        name: this.translateService.instant(this.visibleMenu),
        link: '/buildings/' + this.buildingId + '/floor-plan/' + this.visibleMenu,
      });
    }
  }

  showShoppingCart(): void {
    this.navigate('changes');
  }

  async saveShoppingCart(): Promise<void> {
    if (this.isSaving) return;
    this.isSaving = true;
    await this.bimPropertyService.submitChanges();
    this.toastr.success(this.translateService.instant('changes_saved'));
    this.isSaving = false;
    this.navigate('details');
  }

  downloadSvg(): void {
    this.floorPlan.resetViewPort();
    window.setTimeout(() => {
      const svg_content = this.floorPlan.svg.nativeElement.outerHTML;
      const blob = new Blob([svg_content], {
        type: 'image/svg+xml',
      });

      const downloadelem = document.createElement('a');
      const url = URL.createObjectURL(blob);
      downloadelem.download = `floorplan - ${
        this.floorPlan.floorPlan.levels[this.floorPlan.currentLevel].description
      }.svg`;
      downloadelem.href = url;
      downloadelem.click();
      downloadelem.remove();
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  navigate(menu: 'overview' | 'changes' | 'details'): void {
    void this.router.navigate(['../' + menu], { relativeTo: this.activatedRoute });
  }
}
