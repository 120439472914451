import { LngLat, Map } from 'maplibre-gl';
import Proj4 from 'proj4';
import { Matrix4, Vector3 } from 'three';

Proj4.defs([
  [
    'EPSG:28992',
    '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs',
  ],
  ['EPSG:4326', '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees'],
]);

export function getCameraPositionInAmersfoortCoordinates(map: Map): Vector3 {
  const altitude = map.transform.getCameraAltitude();
  const lngLat = map.transform.getCameraLngLat();
  const cameraPositionInAmersfoort = LatLngToAmersfoort(lngLat.lat, lngLat.lng);
  return new Vector3(cameraPositionInAmersfoort[0], cameraPositionInAmersfoort[1], altitude);
}

export function getCameraRotationInAmersfoort(map: Map): Matrix4 {
  const lngLat = map.transform.getCameraLngLat();
  const offset = new LngLat(lngLat.lng + 0.0001, lngLat.lat);
  const originalInAmersfoort = LatLngToAmersfoort(lngLat.lat, lngLat.lng);
  const offsetInAmersfoort = LatLngToAmersfoort(offset.lat, offset.lng);
  const rotation = Math.atan2(
    offsetInAmersfoort[1] - originalInAmersfoort[1],
    offsetInAmersfoort[0] - originalInAmersfoort[0]
  );
  return new Matrix4()
    .makeRotationX(-Math.PI / 2) // Rotate around x to make the z-up map coordinates align with the y-up threejs coordinates
    .multiply(
      new Matrix4().makeRotationZ(
        (-map.transform.bearing / 180) * Math.PI + rotation // local rotation is added to compensate for Amersfoort angle to geo location.
      )
    )
    .multiply(new Matrix4().makeRotationX((map.transform.pitch / 180) * Math.PI));
}

export function LatLngToAmersfoort(lat: number, lng: number): [number, number] {
  return Proj4('EPSG:4326', 'EPSG:28992', [lng, lat]);
}

export function AmersfoortToLngLat(x: number, y: number): [lng: number, lat: number] {
  return Proj4('EPSG:28992', 'EPSG:4326', [x, y]);
}

export function getProjectionMatrixFromMap(map: Map, near: number, far: number): Matrix4 {
  const h = map.transform.height;
  const w = map.transform.width;

  const fovY = (map.transform.fov * Math.PI) / 180;
  const aspectRatio = w / h;

  const f = 1.0 / Math.tan(fovY / 2);
  const nf = 1 / (near - far);

  // prettier-ignore
  const matrix = [
    f / aspectRatio,
    0,
    0,
    0,
    0,
    f,
    0,
    0,
    0,
    0,
    (far + near) * nf,
    -1,
    0,
    0,
    2 * far * near * nf,
    0,
  ];

  return new Matrix4().fromArray(matrix);
}
