"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.endTypeToNative = exports.joinTypeToNative = exports.polyTypeToNative = exports.clipTypeToNative = exports.polyFillTypeToNative = void 0;
var enums_1 = require("../enums");
function polyFillTypeToNative(nativeLib, polyFillType) {
  switch (polyFillType) {
    case enums_1.PolyFillType.EvenOdd:
      return nativeLib.PolyFillType.EvenOdd;
    case enums_1.PolyFillType.NonZero:
      return nativeLib.PolyFillType.NonZero;
    case enums_1.PolyFillType.Positive:
      return nativeLib.PolyFillType.Positive;
    case enums_1.PolyFillType.Negative:
      return nativeLib.PolyFillType.Negative;
    default:
      throw new Error("unknown poly fill type");
  }
}
exports.polyFillTypeToNative = polyFillTypeToNative;
function clipTypeToNative(nativeLib, clipType) {
  switch (clipType) {
    case enums_1.ClipType.Intersection:
      return nativeLib.ClipType.Intersection;
    case enums_1.ClipType.Union:
      return nativeLib.ClipType.Union;
    case enums_1.ClipType.Difference:
      return nativeLib.ClipType.Difference;
    case enums_1.ClipType.Xor:
      return nativeLib.ClipType.Xor;
    default:
      throw new Error("unknown clip type");
  }
}
exports.clipTypeToNative = clipTypeToNative;
function polyTypeToNative(nativeLib, polyType) {
  switch (polyType) {
    case enums_1.PolyType.Subject:
      return nativeLib.PolyType.Subject;
    case enums_1.PolyType.Clip:
      return nativeLib.PolyType.Clip;
    default:
      throw new Error("unknown poly type");
  }
}
exports.polyTypeToNative = polyTypeToNative;
function joinTypeToNative(nativeLib, joinType) {
  switch (joinType) {
    case enums_1.JoinType.Square:
      return nativeLib.JoinType.Square;
    case enums_1.JoinType.Round:
      return nativeLib.JoinType.Round;
    case enums_1.JoinType.Miter:
      return nativeLib.JoinType.Miter;
    default:
      throw new Error("unknown join type");
  }
}
exports.joinTypeToNative = joinTypeToNative;
function endTypeToNative(nativeLib, endType) {
  switch (endType) {
    case enums_1.EndType.ClosedPolygon:
      return nativeLib.EndType.ClosedPolygon;
    case enums_1.EndType.ClosedLine:
      return nativeLib.EndType.ClosedLine;
    case enums_1.EndType.OpenButt:
      return nativeLib.EndType.OpenButt;
    case enums_1.EndType.OpenSquare:
      return nativeLib.EndType.OpenSquare;
    case enums_1.EndType.OpenRound:
      return nativeLib.EndType.OpenRound;
    default:
      throw new Error("unknown end type");
  }
}
exports.endTypeToNative = endTypeToNative;
