import { Dossier } from '@api-clients/dossier';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, lastValueFrom, Observable, Subscription } from 'rxjs';
import { DossierService } from '@services/dossier.service';
import { FilterModel } from './FilterModel';
import { EnrichedTimeLineDto } from '../../views/dossier-detail/EnrichedTimeLineDto';
import { UsersService } from '@api-clients/user';

export class TimelineDataSource extends DataSource<EnrichedTimeLineDto | undefined> {
  private pageSize: number = 8;
  private cachedData: EnrichedTimeLineDto[] = Array.from<EnrichedTimeLineDto>({ length: 0 });
  private fetchedPages: Set<number> = new Set<number>();
  private readonly dataStream = new BehaviorSubject<(EnrichedTimeLineDto | undefined)[]>(
    this.cachedData
  );

  private readonly _subscription: Subscription = new Subscription();

  constructor(
    private dossier: Dossier,
    private dossierService: DossierService,
    private userApiService: UsersService,
    private filterModel: FilterModel
  ) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<(EnrichedTimeLineDto | undefined)[]> {
    this.dossierService
      .getDossierEvents(
        this.dossier.id,
        1,
        this.pageSize,
        this.filterModel.timeLineType,
        this.toDateString(this.filterModel.from),
        this.toDateString(this.filterModel.until)
      )
      .then((result) => {
        this.cachedData = Array.from<EnrichedTimeLineDto>({ length: result.count });

        this._subscription.add(
          collectionViewer.viewChange.subscribe((range) => {
            const startPage = this.getPageForIndex(range.start);
            const endPage = this.getPageForIndex(range.end - 1);

            for (let i = startPage; i <= endPage; i++) {
              this.fetchPage(i).catch(() => {});
            }
          })
        );
        this.dataStream.next(this.cachedData);
      });
    return this.dataStream;
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }

  addEvent(event: EnrichedTimeLineDto): void {
    if (!this.dossier) return;
    this.cachedData = [event, ...this.cachedData];
    this.dataStream.next(this.cachedData);
    this.dossier.event_count++;
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize);
  }

  private async fetchPage(page: number): Promise<void> {
    if (this.fetchedPages.has(page)) {
      return;
    }
    this.fetchedPages.add(page);

    //get all events for the current page and enrich them with separately fetched user's names
    const timeLineResponse = await this.dossierService.getDossierEvents(
      this.dossier!.id,
      page + 1,
      this.pageSize,
      this.filterModel.timeLineType,
      this.toDateString(this.filterModel.from),
      this.toDateString(this.filterModel.until)
    );

    const events = timeLineResponse.items;
    const ids = events.map((event) => event.user_id);
    const users = await lastValueFrom(this.userApiService.getNames(ids));
    const enrichedEvents = events.map((event) => ({ ...event, userName: users[event.user_id] }));

    //add event to the data cache and notify the data stream
    this.cachedData.splice(
      page * this.pageSize,
      events.length,
      ...Array.from({ length: enrichedEvents.length }).map((_, i) => enrichedEvents[i])
    );
    this.dataStream.next(this.cachedData);
  }

  private toDateString(date: Date | undefined): string | undefined {
    if (date === undefined) {
      return undefined;
    }
    const isoDate: string = date.toISOString();
    return isoDate.slice(0, 10) + ' ' + isoDate.slice(11, 19);
  }
}
