import { EventEmitter, Injectable } from '@angular/core';
import { MeasureStatus } from './measure-copy-popup.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MeasurePopupService {
  public measureStatus: MeasureStatus = { active: false };
  public measureStatusChange: Subject<MeasureStatus> = new Subject<MeasureStatus>();
  public hidden: EventEmitter<boolean> = new EventEmitter<boolean>();
  public shown: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  public hide(): void {
    this.measureStatus.active = false;
    this.hidden.emit(true);
  }

  public show(): void {
    this.measureStatus.active = true;
    this.shown.emit(true);
  }
}
