<div class="inspection-overview">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb [tree]="breadcrumbTree"></app-breadcrumb>

      <h1 class="h4">{{ 'inspections-overview-page' | translate }}</h1>
    </div>

    <div class="header-bar__search">
      <button (click)="showInspectionPopup()" class="button button--has-icon">
        <span class="material-symbols-rounded">event_note</span>
        {{ 'add-inspection' | translate }}
      </button>
      <input
        class="top-search"
        type="search"
        [(ngModel)]="searchText"
        (input)="search()"
        placeholder="{{ 'search' | translate }}" />
    </div>
  </div>

  <div class="list-container">
    <app-inspection-list
      [inspections]="inspections.slice(0, 4)"
      [allUsers]="allUsers"
      [listTitle]="'recently-added' | translate"
      (selectedAction)="navigateToInspections($event)"></app-inspection-list>
    <app-inspection-list
      [inspections]="getOwnInspections()"
      [allUsers]="allUsers"
      [listTitle]="'for-you' | translate"
      (selectedAction)="navigateToInspections($event)"></app-inspection-list>
  </div>

  <h2 class="form-subtitle">{{ 'all-forms' | translate }}</h2>

  <app-table-filter></app-table-filter>
  <table class="table responsive-table">
    <thead>
      <tr>
        <ng-container *ngFor="let column of columns">
          <th (click)="toggleSort(column.key)" *ngIf="column.key.length" class="sortable">
            {{ column.label | translate }}
            <span
              [class.arrow-active]="sortBy === column.key"
              [class.arrow-down]="sortOrder === 1"
              class="arrow"></span>
          </th>
          <th *ngIf="!column.key.length">
            {{ column.label | translate }}
          </th>
        </ng-container>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let inspection of filteredInspections"
        [contextMenu]="menu"
        (mousedown)="setActive(inspection.id)">
        <td
          [attr.data-title]="'name' | translate"
          (click)="navigateToInspections(inspection.id)"
          class="workspace-link">
          {{ inspection.description }}
        </td>
        <td
          [attr.data-title]="'timestamp' | translate"
          (click)="navigateToInspections(inspection.id)">
          {{ inspection.timestamp_utc + ' UTC' | date: 'dd-MM-yyyy HH:mm' }}
        </td>
        <td [attr.data-title]="'status' | translate">
          <app-status-bubble [status]="(inspection.finished_time_utc) ? 'completed' : 'pending'"></app-status-bubble>
        </td>
        <td
          [attr.data-title]="'user' | translate"
          (click)="navigateToInspections(inspection.id)">
          {{ this.allUsers[inspection.user_id] }}
        </td>
        <td [attr.data-title]="'actions' | translate" class="actions">
          <app-three-dot [menu]="menu"></app-three-dot>
        </td>
      </tr>
    </tbody>
  </table>

  <app-table-pagination
    [page]="1"
    [pageSize]="10"
    (pageChange)="onPageChange($event)"
    [total]="inspections.length"></app-table-pagination>

  <app-inspection-popup [dossierId]="dossierId!"></app-inspection-popup>
</div>
