import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Product, ProductDetails, ProductsService } from '@api-clients/product';
import { Observable } from 'rxjs';
import { NgIf } from '@angular/common';
import { ImgFallbackDirective } from '@shared/directives/img-fallback.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-product-details-popup',
  templateUrl: './product-details-popup.component.html',
  styleUrl: './product-details-popup.component.scss',
  imports: [NgIf, ImgFallbackDirective, TranslateModule],
})
export class ProductDetailsPopupComponent implements AfterViewInit {
  @Input() active: boolean = false;
  @Input() showProduct!: Observable<Product>;
  @Output() requestClose = new EventEmitter();
  protected productDetails?: ProductDetails;
  protected state: 'loading' | 'error' | 'loaded' = 'loading';

  constructor(private readonly productService: ProductsService) {}

  closePopup(): void {
    this.requestClose.emit();
  }

  ngAfterViewInit(): void {
    this.showProduct.subscribe((product) => {
      this.state = 'loading';
      this.productService
        .productsDetailsSourceIdGet(product.product_source, product.product_source_id)
        .subscribe({
          next: (productDetails) => {
            this.productDetails = productDetails;
            this.state = 'loaded';
          },
          error: () => (this.state = 'error'),
        });
    });
  }
}
