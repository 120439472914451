import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MapFilesService, MapLayerDto } from '@api-clients/map';
import { lastValueFrom } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UserService } from '@services/user.service';
import { ToastrService } from 'ngx-toastr';
import { FileUploadHelper } from '@shared/services/file-upload-helper';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerComponent } from '@shared/components/color-picker/color-picker.component';
import { NgIf } from '@angular/common';

export interface MapForm {
  description: FormControl<string | undefined>;
  layer_type: FormControl<string | undefined>;
  color: FormControl<string | undefined>;
  organizationId: FormControl<string>;
}

@Component({
  selector: 'app-map-management',
  standalone: true,
  templateUrl: './map-management.component.html',
  styleUrl: './map-management.component.scss',
  imports: [
    MatSelectionList,
    MatListOption,
    ReactiveFormsModule,
    TranslateModule,
    ColorPickerComponent,
    NgIf,
  ],
})
export class MapManagementComponent implements OnInit {
  protected layers: MapLayerDto[] = [];
  protected mapForm!: FormGroup<MapForm>;
  protected selectedLayer?: MapLayerDto;
  protected isSaving = false;

  @ViewChild('fileInput') protected fileInput!: ElementRef;
  @ViewChild('upload') protected upload!: ElementRef;

  constructor(
    private readonly mapFilesService: MapFilesService,
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly toastr: ToastrService,
    private readonly fileUploadHelper: FileUploadHelper
  ) {
    this.buildForm();
  }

  buildForm(): void {
    this.mapForm = this.formBuilder.group<MapForm>({
      description: new FormControl<string | undefined>(undefined, { nonNullable: true }),
      layer_type: new FormControl<string | undefined>(undefined, { nonNullable: true }),
      color: new FormControl<string | undefined>(undefined, { nonNullable: true }),
      organizationId: new FormControl<string>(this.userService.organizationId, {
        nonNullable: true,
      }),
    });
  }

  async ngOnInit(): Promise<void> {
    await this.reload();
  }

  async reload(): Promise<void> {
    this.layers = await lastValueFrom(this.mapFilesService.layersGet());
    this.layers.sort((a, b) => (a.description ?? '').localeCompare(b.description ?? ''));
  }

  async save(): Promise<void> {
    if (!this.selectedLayer) return;
    this.isSaving = true;
    this.selectedLayer = await lastValueFrom(
      this.mapFilesService.layersIdPut(this.selectedLayer.id, this.mapForm.value)
    );
    await this.reload();
    this.selectedLayer = undefined;
    this.mapForm.reset();
    this.isSaving = false;
  }

  setColor(hexColor: string): void {
    this.mapForm.controls.color.setValue(hexColor);
  }

  select(mapLayer: MapLayerDto): void {
    this.selectedLayer = mapLayer;
    this.mapForm.patchValue(mapLayer);
  }

  uploadFile(): void {
    this.fileInput.nativeElement.click();
  }

  cancel(): void {
    this.selectedLayer = undefined;
    this.mapForm.reset();
  }

  async remove(mapLayer: MapLayerDto): Promise<void> {
    await lastValueFrom(this.mapFilesService.layersIdDelete(mapLayer.id));
    await this.reload();
    this.selectedLayer = undefined;
    this.mapForm.reset();
  }

  async fileInputChanged(event: Event): Promise<void> {
    if (!event) return;
    const eventTarget = event.target as HTMLInputElement;
    const file = eventTarget.files?.[0];
    if (!file) throw new Error('No file selected');
    (this.upload.nativeElement as HTMLInputElement).value = '';

    const upload = this.fileUploadHelper.createUploadProgressToast('Uploading file', () =>
      this.reload()
    );

    if (file.name.endsWith('.pmtiles')) {
      this.mapFilesService
        .filePost(file.name, file.size, file.name, file, 'events', true)
        .subscribe(upload);
    }

    if (file.name.endsWith('.geojson')) {
      this.mapFilesService
        .filePost_1(file.name, file.size, file.name, file, 'events', true)
        .subscribe(upload);
    }
  }
}
