import { Component } from '@angular/core';
import {
  InspectionsService,
  InspectionTemplate,
  InspectionTemplateList,
} from '@api-clients/dossier';
import { ChooseTemplatePopupService } from './choose-template-popup.service';
import { ToastrService } from '@shared/services/toastr.service';
import { FormsModule } from '@angular/forms';
import { NgFor } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-choose-template-popup',
    templateUrl: './choose-template-popup.component.html',
    styleUrl: './choose-template-popup.component.scss',
    imports: [FormsModule, NgFor, TranslateModule]
})
export class ChooseTemplatePopupComponent {
  protected selectedTemplateId?: string;
  protected active: boolean = false;

  protected inspectionTemplateList: InspectionTemplateList[] = [];

  constructor(
    private readonly chooseTemplatePopupService: ChooseTemplatePopupService,
    private readonly inspectionsService: InspectionsService,
    private readonly toastrService: ToastrService
  ) {
    this.chooseTemplatePopupService.shown.subscribe(() => {
      this.inspectionsService.templateGet().subscribe((next) => {
        this.inspectionTemplateList = next;
        if (next.length > 0) {
          this.selectedTemplateId = next[0].id; // TODO: remove
        }
      });
      this.active = true;
    });

    this.chooseTemplatePopupService.hidden.subscribe(() => {
      this.active = false;
    });
  }

  close(): void {
    this.chooseTemplatePopupService.hide();
  }

  copy(): void {
    if (!this.selectedTemplateId) return;
    this.inspectionsService.templateIdGet(this.selectedTemplateId).subscribe((next) => {
      this.chooseTemplatePopupService.templateSelected(next);
      this.close();
    });
  }

  async upload(): Promise<void> {
    const jsonFiles = await this.getJsonUpload();
    const json = jsonFiles.join('/n');
    const inspectionTemplate: InspectionTemplate = JSON.parse(json);

    if (inspectionTemplate?.definition === undefined) {
      this.toastrService.showFailure(
        'dossier-module.template.invalid-json',
        'dossier-module.template.invalid-json-title'
      );
      return;
    }

    this.chooseTemplatePopupService.templateSelected(inspectionTemplate);
    this.close();
  }

  private getJsonUpload = (): Promise<string[]> =>
    new Promise<string[]>((resolve, reject) => {
      const inputFileElement = document.createElement('input');
      inputFileElement.setAttribute('type', 'file');
      inputFileElement.setAttribute('multiple', 'true');
      inputFileElement.setAttribute('accept', '.json');

      inputFileElement.addEventListener(
        'change',
        (event: Event) => {
          const files = (event.target as HTMLInputElement)?.files;
          if (!files) {
            reject(new Error('No files selected'));
            return;
          }

          const filePromises = [...files].map((file) => file.text());
          Promise.all(filePromises).then(resolve).catch(reject);
        },
        { once: true } // Ensures the event listener is only executed once
      );

      inputFileElement.click();
    });
}
