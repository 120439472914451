import { Injectable } from '@angular/core';
import {
  GenericSubject,
  MeasureCalculation,
  MeasuresService,
  MeasureTemplateSortColumn,
  MeasureTemplatesService,
  MeasureValueDto,
  MeasureValueService,
  MeasureValueVariable,
  SortOrder,
} from '@api-clients/project';
import { lastValueFrom } from 'rxjs';
import { FormulaService } from './formula.service';

export interface PendingChange {
  measure_template_id?: string;
  measure_id?: string;
  subject: GenericSubject;
  checked: boolean;
  values?: Array<MeasureValueVariable>;
  measureValue?: MeasureValueDto;
}

export interface ProjectMeasureList {
  measure_template_id?: string;
  measure_id?: string;
  name: string;
  description: string;
  calculations: Array<MeasureCalculation>;
  evaluated_calculations: { [subjectKey: string]: { min: number; max: number } };
  variables: Array<MeasureValueVariable>;
  subjects: Array<string>; // JSON stringified GenericSubject
  measureValues?: Array<MeasureValueDto>;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectMeasureService {
  constructor(
    private readonly measureTemplatesService: MeasureTemplatesService,
    private readonly measureValueService: MeasureValueService,
    private readonly measureService: MeasuresService,
    private readonly formulaService: FormulaService
  ) {}

  public async getMeasuresForScenario(scenarioId: string): Promise<ProjectMeasureList[]> {
    // We need to display all measures that are attached to the project (which does have a measure_value record)
    // and we need te display all measure templates for which we don't have measures yet.

    // Get all the measure templates
    // TODO :  Restricted to 100 templates. What to do in the case we have more templates?
    const measureTemplateResponse = await lastValueFrom(
      this.measureTemplatesService.templatesGet(
        0,
        100,
        MeasureTemplateSortColumn.Name,
        SortOrder.Asc
      )
    );

    // Get the measures for this project
    const measures = await lastValueFrom(
      this.measureService.scenarioScenarioIdMeasuresGet(scenarioId)
    );

    // Get the measure values for this project
    const measureValues = await lastValueFrom(
      this.measureValueService.scenarioScenarioIdMeasureValuesGet(scenarioId)
    );

    // Merge them into a list of ProjectMeasureList
    const result = [
      // Add measures that have a measure_template_id
      ...measures.map(
        (measure) =>
          <ProjectMeasureList>{
            measure_template_id: measure.measure_template_id,
            measure_id: measure.id,
            name: measure.name,
            description: measure.description,
            calculations: measure.calculations,
            evaluated_calculations: {},
            variables: measure.variables,
            subjects: measureValues
              .filter((value) => value.measure_id === measure.id)
              .map((value) => JSON.stringify(value.subject)),
            measureValues: measureValues.filter((value) => value.measure_id === measure.id),
          }
      ),

      // Add measureTemplates that do not have a corresponding measure
      ...measureTemplateResponse.items
        .filter(
          (template) => !measures.some((measure) => measure.measure_template_id === template.id)
        )
        .map(
          (template) =>
            <ProjectMeasureList>{
              measure_template_id: template.id,
              measure_id: undefined,
              name: template.name,
              description: template.description,
              calculations: template.calculations,
              evaluated_calculations: {},
              variables: template.variables,
              subjects: [],
              measureValues: [],
            }
        ),
    ].sort((a, b) => a.name.localeCompare(b.name));

    // Perform calculations
    for (const projectMeasure of result) {
      projectMeasure.calculations.forEach((calculation) => {
        for (const subjectKey of projectMeasure.subjects) {
          const measureValue = projectMeasure.measureValues?.filter(
            (mv) => JSON.stringify(mv.subject) == subjectKey
          )[0];
          const variables = Object.fromEntries(
            projectMeasure.variables.map((item) => [
              item.name,
              measureValue?.values.find((v) => v.name == item.name)?.value ?? 0,
            ])
          );
          projectMeasure.evaluated_calculations[subjectKey] = this.formulaService.evaluateFormula(
            calculation.formula,
            variables
          );
        }
      });
    }

    return result;
  }

  // Saves all pending changes by creating/deleting measures and measureValues
  public async save(scenario_id: string, pendingChanges: PendingChange[]): Promise<void> {
    // first check if we have to create or delete a measure
    const measureTemplateIds: { measure_template_id: string; measure_id: string }[] = [];

    for (const pendingChange of pendingChanges) {
      const existing = measureTemplateIds.find(
        (mt) => mt.measure_template_id === pendingChange.measure_template_id
      );
      if (pendingChange.measure_template_id && !pendingChange.measure_id) {
        if (!existing) {
          // create measure
          const measureTemplate = await lastValueFrom(
            this.measureTemplatesService.templatesIdGet(pendingChange.measure_template_id)
          );
          const measure = await lastValueFrom(
            this.measureService.measuresPost({
              name: measureTemplate.name,
              description: measureTemplate.description,
              measure_template_id: pendingChange.measure_template_id,
              variables: measureTemplate.variables,
              calculations: measureTemplate.calculations,
              scenario_id: scenario_id,
            })
          );
          pendingChange.measure_id = measure.id;
          measureTemplateIds.push({
            measure_template_id: pendingChange.measure_template_id,
            measure_id: pendingChange.measure_id,
          });
        } else {
          pendingChange.measure_id = existing.measure_id;
        }
      }
    }

    // then we need to add or remove a measure value
    for (const pendingChange of pendingChanges) {
      if (pendingChange.checked && pendingChange.measure_id) {
        // add measure value
        await lastValueFrom(
          this.measureValueService.measuresMeasureIdMeasureValuesPost(pendingChange.measure_id, {
            subject: pendingChange.subject,
            values: pendingChange.values ?? [],
          })
        );
      } else if (!pendingChange.checked && pendingChange.measure_id && pendingChange.measureValue) {
        await lastValueFrom(
          this.measureValueService.measuresMeasureIdMeasureValuesIdDelete(
            pendingChange.measureValue.measure_id,
            pendingChange.measureValue.id
          )
        );
      }
    }
  }
}
