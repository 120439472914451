/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MediaSubjectProjectReference { 
    project_id: string;
    type: MediaSubjectProjectReference.TypeEnum;
}
export namespace MediaSubjectProjectReference {
    export type TypeEnum = 'Project';
    export const TypeEnum = {
        Project: 'Project' as TypeEnum
    };
}


