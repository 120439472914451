/**
 * BIM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoomCategory } from './roomCategory';


export interface BimElementBimRoomDto { 
    category: RoomCategory;
    levels: Array<string>;
    properties: any | null;
    mesh_id?: number;
    type: BimElementBimRoomDto.TypeEnum;
}
export namespace BimElementBimRoomDto {
    export type TypeEnum = 'Room';
    export const TypeEnum = {
        Room: 'Room' as TypeEnum
    };
}


