<div
  class="file-preview"
  [class.expand]="sliderExpanded"
  [class.popup-version]="popupVersion"
  *ngIf="fileUrl">
  <div class="file-preview__doc" *ngIf="isDoc && showPreview">
    <a class="download-button" download="{{ sanitizeFileName(fileName) }}" href="{{ fileUrl }}">
      <span class="material-symbols-rounded">download</span>
    </a>
    <ngx-doc-viewer
      [url]="fileUrl"
      viewer="google"
      style="width: 100%; height: 100%"></ngx-doc-viewer>
  </div>

  <div class="file-preview__image" *ngIf="isImage && showPreview">
    <img src="{{ fileUrl }}" alt="image" />
  </div>

  <div class="file-preview__video" *ngIf="isVideo && showPreview">
    <div class="video-container">
      <video *ngIf="isVideo" autoplay controls>
        <source src="{{ fileUrl }}" type="video/{{ fileType.toLocaleLowerCase() }}" />
      </video>
    </div>
  </div>

  <div class="file-preview__no-show" *ngIf="!showPreview">
    {{ 'file-no-preview-text' | translate }}

    <a
      class="button-primary button--has-icon"
      download="{{ sanitizeFileName(fileName) }}"
      href="{{ fileUrl }}">
      <span class="material-symbols-rounded">download</span>
      {{ 'download' | translate }}
    </a>
  </div>
</div>
