/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateUpdateProjectRequest } from '../model/createUpdateProjectRequest';
// @ts-ignore
import { OptionalGenericSubjectQuery } from '../model/optionalGenericSubjectQuery';
// @ts-ignore
import { ProjectDto } from '../model/projectDto';
// @ts-ignore
import { ProjectListResponse } from '../model/projectListResponse';
// @ts-ignore
import { ProjectSortColumn } from '../model/projectSortColumn';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Retrieve all projects that match the search criteria.
     * # Query Parameters: - &#x60;filter_text&#x60; (query parameter, optional): A search string that will be matched against project names and descriptions. - &#x60;page_index&#x60; (query parameter, required): The index of the page to retrieve (for pagination). - &#x60;page_size&#x60; (query parameter, required): The number of projects to return per page. - &#x60;sort_column&#x60; (query parameter, required): The column by which results should be sorted. Allowed values: &#x60;\&quot;Id\&quot;&#x60;, &#x60;\&quot;Name\&quot;&#x60;, &#x60;\&quot;CreationTimestampUtc\&quot;&#x60;. - &#x60;sort_order&#x60; (query parameter, required): The sorting order. Allowed values: &#x60;\&quot;Asc\&quot;&#x60;, &#x60;\&quot;Desc\&quot;&#x60;. - &#x60;subject&#x60; (query parameter, optional): The subject filter to narrow down projects.  # Responses: - **200 OK:** Returns a JSON array of projects.  # Error Responses: - **400 Bad Request:** If an invalid &#x60;sort_column&#x60; or &#x60;sort_order&#x60; is provided. - **500 Internal Server Error:** If there is a server error during the retrieval process.  # Example Response Body (200 OK): &#x60;&#x60;&#x60;json [{ \&quot;id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;organization_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;creation_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;last_modified_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;subjects\&quot;: [{ \&quot;type\&quot;: \&quot;RealEstateBuilding\&quot;, \&quot;real_estate_building_id\&quot;: \&quot;fc26f625-c70f-4c7d-ad57-01314617af2d\&quot; }], \&quot;name\&quot;: \&quot;Updated Project Name\&quot;, \&quot;description\&quot;: \&quot;Updated Project Description\&quot; }] &#x60;&#x60;&#x60;
     * @param pageIndex 
     * @param pageSize 
     * @param sortColumn 
     * @param sortOrder 
     * @param filterText 
     * @param subject 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsGet(pageIndex: number, pageSize: number, sortColumn: ProjectSortColumn, sortOrder: SortOrder, filterText?: string, subject?: OptionalGenericSubjectQuery, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<ProjectListResponse>;
    public projectsGet(pageIndex: number, pageSize: number, sortColumn: ProjectSortColumn, sortOrder: SortOrder, filterText?: string, subject?: OptionalGenericSubjectQuery, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<ProjectListResponse>>;
    public projectsGet(pageIndex: number, pageSize: number, sortColumn: ProjectSortColumn, sortOrder: SortOrder, filterText?: string, subject?: OptionalGenericSubjectQuery, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<ProjectListResponse>>;
    public projectsGet(pageIndex: number, pageSize: number, sortColumn: ProjectSortColumn, sortOrder: SortOrder, filterText?: string, subject?: OptionalGenericSubjectQuery, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (pageIndex === null || pageIndex === undefined) {
            throw new Error('Required parameter pageIndex was null or undefined when calling projectsGet.');
        }
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling projectsGet.');
        }
        if (sortColumn === null || sortColumn === undefined) {
            throw new Error('Required parameter sortColumn was null or undefined when calling projectsGet.');
        }
        if (sortOrder === null || sortOrder === undefined) {
            throw new Error('Required parameter sortOrder was null or undefined when calling projectsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (filterText !== undefined && filterText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterText, 'filter_text');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'page_index');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (sortColumn !== undefined && sortColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortColumn, 'sort_column');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sort_order');
        }
        if (subject !== undefined && subject !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>subject, 'subject');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/projects`;
        return this.httpClient.request<ProjectListResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an existing project.
     * # Path Parameters: - &#x60;id&#x60; (path parameter): The unique identifier of the project. # Responses: - **204 No Content:** If the project was successfully deleted. # Error Responses: - **404 Not Found:** If the specified project is not found. - **500 Internal Server Error:** If there is a server error during the deletion process.
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsIdDelete(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public projectsIdDelete(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public projectsIdDelete(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public projectsIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/projects/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a specific project by ID including its associated subjects.
     * # Path Parameters: - &#x60;id&#x60;: The unique identifier of the project. # Responses: - **200 OK:** Returns the requested project. # Error Responses: - **404 Not Found:** If the specified project cannot be found. - **500 Internal Server Error:** If there is a server error during the retrieval process. # Example Response Body (200 OK): &#x60;&#x60;&#x60;json { \&quot;id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;organization_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;creation_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;last_modified_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;subjects\&quot;: [{ \&quot;type\&quot;: \&quot;RealEstateBuilding\&quot;, \&quot;real_estate_building_id\&quot;: \&quot;550e8400-e29b-41d4-a716-446655440002\&quot; }], \&quot;name\&quot;: \&quot;Project Name\&quot;, \&quot;description\&quot;: \&quot;Project Description\&quot; } &#x60;&#x60;&#x60;
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsIdGet(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<ProjectDto>;
    public projectsIdGet(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<ProjectDto>>;
    public projectsIdGet(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<ProjectDto>>;
    public projectsIdGet(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/projects/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<ProjectDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing project.
     * # Path Parameters: - &#x60;id&#x60; (path parameter): The unique identifier of the project. # Responses: - **200 OK:** Returns the updated project. # Error Responses: - **404 Not Found:** If the specified project is not found. - **500 Internal Server Error:** If there is a server error during the update process. # Example Request Body: &#x60;&#x60;&#x60;json { \&quot;name\&quot;: \&quot;Updated Project Name\&quot;, \&quot;description\&quot;: \&quot;Updated Project Description\&quot;, \&quot;subjects\&quot;: [{ \&quot;type\&quot;: \&quot;RealEstateBuilding\&quot;, \&quot;real_estate_building_id\&quot;: \&quot;fc26f625-c70f-4c7d-ad57-01314617af2d\&quot; }], } &#x60;&#x60;&#x60; # Example Response Body (200 OK): &#x60;&#x60;&#x60;json { \&quot;id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;organization_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;creation_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;last_modified_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;subjects\&quot;: [{ \&quot;type\&quot;: \&quot;RealEstateBuilding\&quot;, \&quot;real_estate_building_id\&quot;: \&quot;fc26f625-c70f-4c7d-ad57-01314617af2d\&quot; }], \&quot;name\&quot;: \&quot;Updated Project Name\&quot;, \&quot;description\&quot;: \&quot;Updated Project Description\&quot; } &#x60;&#x60;&#x60;
     * @param id 
     * @param createUpdateProjectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsIdPut(id: string, createUpdateProjectRequest: CreateUpdateProjectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<ProjectDto>;
    public projectsIdPut(id: string, createUpdateProjectRequest: CreateUpdateProjectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<ProjectDto>>;
    public projectsIdPut(id: string, createUpdateProjectRequest: CreateUpdateProjectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<ProjectDto>>;
    public projectsIdPut(id: string, createUpdateProjectRequest: CreateUpdateProjectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling projectsIdPut.');
        }
        if (createUpdateProjectRequest === null || createUpdateProjectRequest === undefined) {
            throw new Error('Required parameter createUpdateProjectRequest was null or undefined when calling projectsIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/projects/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: true, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<ProjectDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createUpdateProjectRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new project.
     * # Responses: - **201 Created:** Returns the created project. # Error Responses: - **500 Internal Server Error:** If there is a server error during the creation process. # Example Request Body: &#x60;&#x60;&#x60;json { \&quot;subjects\&quot;: [{ \&quot;type\&quot;: \&quot;RealEstateBuilding\&quot;, \&quot;real_estate_building_id\&quot;: \&quot;550e8400-e29b-41d4-a716-446655440002\&quot; }], \&quot;name\&quot;: \&quot;Project Name\&quot;, \&quot;description\&quot;: \&quot;Project Description\&quot; } &#x60;&#x60;&#x60; # Example Response Body (201 Created): &#x60;&#x60;&#x60;json { \&quot;id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;organization_id\&quot;: \&quot;ab2bf871-92a8-45f1-845d-7c3f8d9dc153\&quot;, \&quot;creation_timestamp_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;last_modified_utc\&quot;: \&quot;2021-09-01T12:00:00Z\&quot;, \&quot;subjects\&quot;: [{ \&quot;type\&quot;: \&quot;RealEstateBuilding\&quot;, \&quot;real_estate_building_id\&quot;: \&quot;550e8400-e29b-41d4-a716-446655440002\&quot; }], \&quot;name\&quot;: \&quot;Project Name\&quot;, \&quot;description\&quot;: \&quot;Project Description\&quot; } &#x60;&#x60;&#x60;
     * @param createUpdateProjectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsPost(createUpdateProjectRequest: CreateUpdateProjectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<ProjectDto>;
    public projectsPost(createUpdateProjectRequest: CreateUpdateProjectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<ProjectDto>>;
    public projectsPost(createUpdateProjectRequest: CreateUpdateProjectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<ProjectDto>>;
    public projectsPost(createUpdateProjectRequest: CreateUpdateProjectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (createUpdateProjectRequest === null || createUpdateProjectRequest === undefined) {
            throw new Error('Required parameter createUpdateProjectRequest was null or undefined when calling projectsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (OauthDevPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OauthProdPermissions) required
        localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (BearerPermissions) required
        localVarCredential = this.configuration.lookupCredential('BearerPermissions');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/projects`;
        return this.httpClient.request<ProjectDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createUpdateProjectRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
