import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { DossierService } from '@services/dossier.service';
import { Location } from '@api-clients/dossier';
import { TranslateService } from '@ngx-translate/core';
import { TimelineService } from '../../services';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss'],
})
export class AddNoteComponent {
  protected image_ids: string[] = [];
  protected locations: Location[] = [];
  protected location_id?: string;
  protected dossierId: string = '';
  protected filesPreview: string[] = [];

  @Output() canceled = new EventEmitter<void>();
  @Output() noteAdded = new EventEmitter<void>();

  @ViewChild('noteInput') private noteInput!: ElementRef;
  @ViewChild('imageInput') protected imageInput!: ElementRef;

  constructor(
    private readonly dossierService: DossierService,
    private readonly timelineService: TimelineService,
    protected readonly translateService: TranslateService
  ) {
    this.dossierService.getLocations().then((next) => {
      this.locations = next;
    });
  }

  async submit(): Promise<void> {
    await this.postNoteEvent(this.noteInput.nativeElement.value.replace(/\n/g, '<br>'));
    this.timelineService.refreshTimeline();
    this.noteAdded.emit();
  }

  close(): void {
    this.noteInput.nativeElement.value = '';
    this.canceled.emit();
  }

  async postNoteEvent(value: string): Promise<void> {
    await this.timelineService.publishNoteEvent(value, this.image_ids, this.location_id);
  }

  uploadImage(): void {
    this.imageInput.nativeElement.click();
  }

  removeImage(index: number): void {
    this.filesPreview.splice(index, 1);
    this.image_ids.splice(index, 1);
  }

  reset(): void {
    this.noteInput.nativeElement.value = '';
    this.image_ids = [];
    this.filesPreview = [];
    this.location_id = undefined;
  }

  async imageInputChanged(): Promise<void> {
    const dossierId = this.timelineService.dossier?.id;
    if (dossierId) {
      for (const file of this.imageInput.nativeElement.files) {
        // TODO: don't upload image unless it gets submitted
        const dossierImage = await this.dossierService.postImage(dossierId, file, 'uploaded');
        this.image_ids.push(dossierImage.id);

        const url = URL.createObjectURL(file);
        this.filesPreview.push(url);
      }
    }
  }
}
