<p>
  <ng-container *ngIf="oldGeometry && newGeometry">
    {{ 'dimensions' | translate }} {{'changed' | translate }}:<br/>
    L: {{oldGeometry.scale.x.toFixed(2)}}m -> {{newGeometry.scale.x.toFixed(2)}}m<br/>
    W: {{oldGeometry.scale.y.toFixed(2)}}m -> {{newGeometry.scale.y.toFixed(2)}}m<br/>
    H: {{oldGeometry.scale.z.toFixed(2)}}m -> {{newGeometry.scale.z.toFixed(2)}}m<br/>
    <br/>
    {{ 'positions' | translate }} {{'changed' | translate }}:<br/>
    X: {{oldGeometry.position.x.toFixed(2)}}m -> {{newGeometry.position.x.toFixed(2)}}m<br/>
    Y: {{oldGeometry.position.y.toFixed(2)}}m -> {{newGeometry.position.y.toFixed(2)}}m<br/>
    Z: {{oldGeometry.position.z.toFixed(2)}}m -> {{newGeometry.position.z.toFixed(2)}}m<br/>
  </ng-container>
  <ng-container *ngIf="!oldGeometry && newGeometry">
    {{ 'dimensions' | translate }}:<br/>
    L: {{newGeometry.scale.x.toFixed(2)}}m<br/>
    W: {{newGeometry.scale.y.toFixed(2)}}m<br/>
    H: {{newGeometry.scale.z.toFixed(2)}}m<br/>
    <br/>
    {{ 'positions' | translate }}:<br/>
    X: {{newGeometry.position.x.toFixed(2)}}m<br/>
    Y: {{newGeometry.position.y.toFixed(2)}}m<br/>
    Z: {{newGeometry.position.z.toFixed(2)}}m<br/>
  </ng-container>
</p>
