import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Matrix4, Quaternion, Vector3 } from 'three';
import { isFloatProperty } from '../../views/model-viewer/property';

type Dimensions = {
  width: number;
  height: number;
  length: number;
  matrix: Matrix4;
};

@Component({
  selector: 'app-geometry-edit',
  templateUrl: './geometry-edit.component.html',
  styleUrl: './geometry-edit.component.scss',
})
export class GeometryEditComponent {
  protected height: number = 0;
  protected width: number = 0;
  protected length: number = 0;

  protected originalHeight: number = 0;
  protected originalWidth: number = 0;
  protected originalLength: number = 0;

  private _box!: number[];
  @Input()
  public get box(): number[] {
    return this._box;
  }

  public set box(value: number[]) {
    if (value !== this._box) {
      this._box = value;
      const dimensions = decompose(value);

      this.height = dimensions.height;
      this.width = dimensions.width;
      this.length = dimensions.length;
      this.matrix = dimensions.matrix;
    }
  }

  @Output() boxChange = new EventEmitter<{ type: string; value: number[] }>();

  private _originalBox: number[] | undefined = undefined;
  @Input()
  public set originalBox(value: number[]) {
    this._originalBox = value;
    if (this._originalBox === undefined) return;
    const dimensions = decompose(value);

    this.originalHeight = dimensions.height;
    this.originalWidth = dimensions.width;
    this.originalLength = dimensions.length;
  }

  public get originalBox(): number[] | undefined {
    return this._originalBox;
  }

  @Input() disabled: boolean = false;

  protected matrix?: Matrix4;

  fireBoxChange(): void {
    if (!this.matrix) return;
    const position = new Vector3();
    const rotation = new Quaternion();
    const scale = new Vector3();
    this.matrix.decompose(position, rotation, scale);
    this.matrix.compose(position, rotation, new Vector3(this.width, this.height, this.length));

    const box = { type: 'Box', value: this.matrix.elements };
    this.boxChange.emit(box);
  }

  protected readonly isFloatProperty = isFloatProperty;
}

function decompose(data: number[]): Dimensions {
  const position = new Vector3();
  const quaternion = new Quaternion();
  const scale = new Vector3();
  const matrix = new Matrix4().fromArray(data).decompose(position, quaternion, scale);

  return {
    width: parseFloat(scale.x.toFixed(2)),
    height: parseFloat(scale.y.toFixed(2)),
    length: parseFloat(scale.z.toFixed(2)),
    matrix: matrix,
  };
}
