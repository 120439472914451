/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OptionalMeasureSubjectQueryMeasureSubjectRealEstateBuildingReference { 
    optional_MeasureSubject_real_estate_building_id: string;
    optional_MeasureSubject_type: OptionalMeasureSubjectQueryMeasureSubjectRealEstateBuildingReference.OptionalMeasureSubjectTypeEnum;
}
export namespace OptionalMeasureSubjectQueryMeasureSubjectRealEstateBuildingReference {
    export type OptionalMeasureSubjectTypeEnum = 'RealEstateBuilding';
    export const OptionalMeasureSubjectTypeEnum = {
        RealEstateBuilding: 'RealEstateBuilding' as OptionalMeasureSubjectTypeEnum
    };
}


