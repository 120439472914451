import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { forEach } from 'ramda';
import { AddressComponent, geoCode } from '@core/models/geocoding-model';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AddressAutocompleteService {
  constructor(private http: HttpClient) {}

  getAddressDetails(zipcode: string): Observable<AddressData> {
    return this.http
      .get<geoCode>(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=${environment.ADDRESS_API_KEY}`
      )
      .pipe(
        map((data) => {
          const addressData: AddressData = new AddressData();

          if (data.results.length > 0) {
            /// for each address component in the geoCode results
            forEach((addressComponent: AddressComponent) => {
              if (addressComponent.types[0] == 'locality') {
                addressData.placeName = addressComponent.long_name;
              } else if (addressComponent.types[0] == 'administrative_area_level_1') {
                addressData.state = addressComponent.long_name;
              } else if (addressComponent.types[0] == 'country') {
                addressData.country = addressComponent.long_name;
              }
            }, data.results[0].address_components);
          }

          return addressData;
        })
      );
  }
}

class AddressData {
  placeName: string | undefined;
  state: string | undefined;
  country: string | undefined;
}
