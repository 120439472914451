<div class="geometry-edit">
  <div
    class="big-input"
    [class.has-change]="originalBox !== undefined && originalHeight !== height"
    [class.error]="height === null || height <= 0">
    <label for="height">{{ 'height' | translate }}</label>
    <input
      (change)="fireBoxChange($event)"
      [disabled]="disabled"
      [(ngModel)]="height"
      required
      id="height"
      min="0.01"
      step="0.01"
      type="number" />
    <label class="units" for="height">m</label>
  </div>
  <div class="big-input" [class.has-change]="originalBox !== undefined && originalWidth !== width"
       [class.error]="width === null || width <= 0">
    <label for="width">{{ 'width' | translate }}</label>
    <input
      (change)="fireBoxChange($event)"
      [disabled]="disabled"
      [(ngModel)]="width"
      required
      id="width"
      min="0.01"
      step="0.01"
      type="number" />

    <label class="units" for="width">m</label>
  </div>
  <div
    class="big-input"
    [class.has-change]="originalBox !== undefined && originalLength !== length"
    [class.error]="length === null || length <= 0">
    <label for="length">{{ 'length' | translate }}</label>
    <input
      (change)="fireBoxChange($event)"
      [disabled]="disabled"
      [(ngModel)]="length"
      required
      id="length"
      min="0.01"
      step="0.01"
      type="number" />

    <label class="units" for="length">m</label>
  </div>
</div>
