<div class="degradation-edit">
  <div [class.has-change]="hasExpiryDateChanges" [class.error]="hasInvalidState" class="big-input">
    <label for="estimatedExpiry">{{ 'estimated_lifespan' | translate }}</label>

    <input
      [owlDateTime]="estimatedExpiryPicker"
      [owlDateTimeTrigger]="estimatedExpiryPicker"
      placeholder="{{ 'select-date' | translate }}"
      (dateTimeChange)="onDateChangedEvent()"
      [(ngModel)]="estimatedExpiry"
      id="estimatedExpiry" />
    <owl-date-time [pickerType]="'calendar'" #estimatedExpiryPicker></owl-date-time>
  </div>

  <app-growing-textarea
    [hasChange]="hasDescriptionChanges"
    [placeholder]="'description'"
    [(ngModel)]="description"
    [title]="'description'"></app-growing-textarea>
</div>
