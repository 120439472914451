import { Injectable } from '@angular/core';
import { BimElementBimObjectDto, BimElementDto, BimService, GeometryType } from '@api-clients/bim';
import {
  ChangedElement,
  NewElement,
  UnsavedElement,
  DeleteElement,
} from '../components/changes-summary/change';
import { BehaviorSubject, forkJoin, map, Observable, ReplaySubject } from 'rxjs';
import { Property } from '../views/model-viewer/property';
import propertyDefinitions from '../views/model-viewer/properties.json';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DossierDetailService } from './dossier-detail.service';

@Injectable({
  providedIn: 'root',
})
export class BimPropertyService {
  private bimId$ = new BehaviorSubject<string | undefined>(undefined);
  public bimId = this.bimId$.asObservable();

  private savedElements$ = new ReplaySubject<{ element: BimElementDto; previousId: string }[]>(1);
  // Emits the changed elements only when changes are submitted to backend
  public savedElements = this.savedElements$.asObservable();

  private selectedElement$ = new BehaviorSubject<ChangedElement | undefined>(undefined);
  // Emits the selected element every time it is selected, deselected or changes
  public selectedElement = this.selectedElement$.asObservable();

  private unsavedElements$ = new BehaviorSubject<UnsavedElement[]>([]);
  // Emits when a staged element is added, removed or changed
  public unsavedElements = this.unsavedElements$.asObservable();

  public changeCount: Observable<number> = this.unsavedElements$.pipe(
    map((elements) =>
      elements.map((element) => element.getChangeCount()).reduce((a, b) => a + b, 0)
    )
  );

  constructor(
    private readonly bimService: BimService,
    private readonly dossierDetailService: DossierDetailService
  ) {
    this.dossierDetailService.dossier.pipe(takeUntilDestroyed()).subscribe((dossier) => {
      this.bimId$.next(dossier.linked_bim_ids.at(0));
    });
  }

  public selectBimElement(elementId: string | undefined): void {
    if (!this.bimId$.value) return;
    if (!elementId) {
      this.selectedElement$.next(undefined);
      return;
    }
    const unsavedElement = this.unsavedElements$.value.find((element) => element.id === elementId);
    if (unsavedElement) {
      this.selectedElement$.next(unsavedElement as ChangedElement);
    } else {
      this.bimService
        .bimBimIdElementsElementIdGet(this.bimId$.value, elementId)
        .subscribe((elementDto) => {
          const propertyDefinition: Map<string, Property> | undefined =
            propertyDefinitions[elementDto.element['category']]?.properties;
          this.selectedElement$.next(
            new ChangedElement(
              elementDto.id,
              elementDto.element,
              new Map(),
              undefined,
              propertyDefinition
            )
          );
        });
    }
  }

  public createAndSelectNewElement(id: string, bimElement: BimElementBimObjectDto): void {
    const propertyDefinition: Map<string, Property> | undefined =
      propertyDefinitions[bimElement.category]?.properties;
    const originalValues = new Map<string, unknown>();
    const newElement = new NewElement(id, bimElement, originalValues, propertyDefinition);

    this.unsavedElements$.next([...this.unsavedElements$.value, newElement]);
    this.selectedElement$.next(newElement);
  }

  public updateSelectedElement(changedElement: UnsavedElement): void {
    const newChanges = this.unsavedElements$.value;
    const existing = newChanges.find((e) => e.id === changedElement.id);
    if (existing) {
      this.unsavedElements$.next(
        newChanges.map((element) => (element.id === changedElement.id ? changedElement : element))
      );
    } else {
      this.unsavedElements$.next([...newChanges, changedElement]);
    }
    this.selectedElement$.next(changedElement as ChangedElement);
  }

  public updateSelectedElementGeometry(geometry: GeometryType): void {
    if (!this.selectedElement$.value) return;
    if (!this.selectedElement$.value.element.properties['geometry']) return;
    this.selectedElement$.value.changeProperty('geometry', geometry);
    this.updateSelectedElement(this.selectedElement$.value);
  }

  public submitChanges(): void {
    if (!this.bimId$.value) return;
    // Todo: changes made by someone else after staging are now discarded. Make this robust.
    const bimApiCalls = this.unsavedElements$.value.map((changedElement) => {
      const bimApiCall =
        changedElement instanceof NewElement // Is this a new element or an existing one?
          ? this.bimService.bimBimIdElementsPost(this.bimId$.value!, changedElement.element)
          : changedElement instanceof DeleteElement // Is this a element to really delete?
          ? this.bimService.bimBimIdElementsElementIdDelete(this.bimId$.value!, changedElement.id)
          : this.bimService.bimBimIdElementsElementIdPut(
              this.bimId$.value!,
              changedElement.id,
              changedElement.element
            );
      // previousId allows us to update elements that had a temporary id before
      return bimApiCall.pipe(map((element) => ({ element, previousId: changedElement.id })));
    });
    forkJoin(bimApiCalls).subscribe((elements) => {
      this.savedElements$.next(elements);
      this.unsavedElements$.next([]);
    });
  }

  public removeAddedElement(element: NewElement): void {
    this.unsavedElements$.next(this.unsavedElements$.value.filter((e) => e.id !== element.id));
    if (this.selectedElement$.value?.id === element.id) {
      this.selectedElement$.next(undefined);
    }
  }

  public removeChangedElement(element: ChangedElement): void {
    const deletedElement = new DeleteElement(
      element.id,
      element.element,
      element.originalValues,
      element.propertyDefinition
    );
    this.unsavedElements$.next([
      ...this.unsavedElements$.value.filter((e) => e.id !== element.id),
      deletedElement,
    ]);
    if (this.selectedElement$.value?.id === element.id) {
      this.selectedElement$.next(undefined);
    }
  }

  public undeleteElement(element: DeleteElement): void {
    this.unsavedElements$.next([...this.unsavedElements$.value.filter((e) => e.id !== element.id)]);
  }

  public deleteChanges(): void {
    this.unsavedElements$.next([]);
  }
}
