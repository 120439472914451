/**
 * Dossier API
 * This api is used to maintain dossiers and dossier events.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { CreateInspectionTemplateRequest } from '../model/createInspectionTemplateRequest';
// @ts-ignore
import { Inspection } from '../model/inspection';
// @ts-ignore
import { InspectionList } from '../model/inspectionList';
// @ts-ignore
import { InspectionTemplate } from '../model/inspectionTemplate';
// @ts-ignore
import { InspectionTemplateList } from '../model/inspectionTemplateList';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class InspectionsService {
  protected basePath = 'https://api.dev.twinspot.com/dossiers';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param dossierId
   * @param inspection
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dossiersDossierIdInspectionFinishPut(
    dossierId: string,
    inspection: Inspection,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<Inspection>;
  public dossiersDossierIdInspectionFinishPut(
    dossierId: string,
    inspection: Inspection,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpResponse<Inspection>>;
  public dossiersDossierIdInspectionFinishPut(
    dossierId: string,
    inspection: Inspection,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpEvent<Inspection>>;
  public dossiersDossierIdInspectionFinishPut(
    dossierId: string,
    inspection: Inspection,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<any> {
    if (dossierId === null || dossierId === undefined) {
      throw new Error(
        'Required parameter dossierId was null or undefined when calling dossiersDossierIdInspectionFinishPut.'
      );
    }
    if (inspection === null || inspection === undefined) {
      throw new Error(
        'Required parameter inspection was null or undefined when calling dossiersDossierIdInspectionFinishPut.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (OauthDevPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (OauthProdPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (BearerPermissions) required
    localVarCredential = this.configuration.lookupCredential('BearerPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json; charset=utf-8'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/dossiers/${this.configuration.encodeParam({
      name: 'dossierId',
      value: dossierId,
      in: 'path',
      style: 'simple',
      explode: true,
      dataType: 'string',
      dataFormat: 'uuid',
    })}/inspection/finish`;
    return this.httpClient.request<Inspection>(
      'put',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: inspection,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Retrieves a list of inspection results for a specific dossier.
   * # Parameters  - &#x60;dossier_id&#x60;: The unique identifier of the dossier. - &#x60;description&#x60;: An optional filter for the description of the inspection results. - &#x60;auth&#x60;: The authentication information of the user.  # Returns  Returns a list of inspection results that match the criteria.  # Errors  Returns an error if: - The user does not have the required permissions. - The user is not part of the organization associated with the dossier. - There is an issue processing the request.
   * @param dossierId
   * @param description
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dossiersDossierIdInspectionGet(
    dossierId: string,
    description?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<Array<InspectionList>>;
  public dossiersDossierIdInspectionGet(
    dossierId: string,
    description?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpResponse<Array<InspectionList>>>;
  public dossiersDossierIdInspectionGet(
    dossierId: string,
    description?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpEvent<Array<InspectionList>>>;
  public dossiersDossierIdInspectionGet(
    dossierId: string,
    description?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<any> {
    if (dossierId === null || dossierId === undefined) {
      throw new Error(
        'Required parameter dossierId was null or undefined when calling dossiersDossierIdInspectionGet.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (description !== undefined && description !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>description,
        'description'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (OauthDevPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (OauthProdPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (BearerPermissions) required
    localVarCredential = this.configuration.lookupCredential('BearerPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/dossiers/${this.configuration.encodeParam({
      name: 'dossierId',
      value: dossierId,
      in: 'path',
      style: 'simple',
      explode: true,
      dataType: 'string',
      dataFormat: 'uuid',
    })}/inspection`;
    return this.httpClient.request<Array<InspectionList>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Retrieves an inspection result for a specific dossier.
   * # Parameters  - &#x60;dossier_id&#x60;: The unique identifier of the dossier. - &#x60;id&#x60;: The unique identifier of the inspection result to be retrieved. - &#x60;auth&#x60;: The authentication information of the user.  # Returns  Returns the requested inspection result if it exists.  # Errors  Returns an error if: - The user does not have the required permissions. - The user is not part of the organization associated with the dossier. - The inspection result is not found. - There is an issue processing the request.
   * @param dossierId
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dossiersDossierIdInspectionIdGet(
    dossierId: string,
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<Inspection>;
  public dossiersDossierIdInspectionIdGet(
    dossierId: string,
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpResponse<Inspection>>;
  public dossiersDossierIdInspectionIdGet(
    dossierId: string,
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpEvent<Inspection>>;
  public dossiersDossierIdInspectionIdGet(
    dossierId: string,
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<any> {
    if (dossierId === null || dossierId === undefined) {
      throw new Error(
        'Required parameter dossierId was null or undefined when calling dossiersDossierIdInspectionIdGet.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling dossiersDossierIdInspectionIdGet.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (OauthDevPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (OauthProdPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (BearerPermissions) required
    localVarCredential = this.configuration.lookupCredential('BearerPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/dossiers/${this.configuration.encodeParam({
      name: 'dossierId',
      value: dossierId,
      in: 'path',
      style: 'simple',
      explode: true,
      dataType: 'string',
      dataFormat: 'uuid',
    })}/inspection/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: true,
      dataType: 'string',
      dataFormat: 'uuid',
    })}`;
    return this.httpClient.request<Inspection>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Enriches a inspection result for a specific dossier.
   * # Parameters  - &#x60;dossier_id&#x60;: The unique identifier of the dossier for which the inspection result is being created. - &#x60;inspection_result&#x60;: The details of the inspection result to be created. - &#x60;auth&#x60;: The authentication information of the user.  # Returns  Returns the newly created inspection result.  # Errors  Returns an error if: - The user does not have the required permissions. - The user is not part of the organization associated with the dossier. - There is an issue processing the data.
   * @param dossierId
   * @param inspection
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dossiersDossierIdInspectionPut(
    dossierId: string,
    inspection: Inspection,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<Inspection>;
  public dossiersDossierIdInspectionPut(
    dossierId: string,
    inspection: Inspection,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpResponse<Inspection>>;
  public dossiersDossierIdInspectionPut(
    dossierId: string,
    inspection: Inspection,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpEvent<Inspection>>;
  public dossiersDossierIdInspectionPut(
    dossierId: string,
    inspection: Inspection,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<any> {
    if (dossierId === null || dossierId === undefined) {
      throw new Error(
        'Required parameter dossierId was null or undefined when calling dossiersDossierIdInspectionPut.'
      );
    }
    if (inspection === null || inspection === undefined) {
      throw new Error(
        'Required parameter inspection was null or undefined when calling dossiersDossierIdInspectionPut.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (OauthDevPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (OauthProdPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (BearerPermissions) required
    localVarCredential = this.configuration.lookupCredential('BearerPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json; charset=utf-8'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/dossiers/${this.configuration.encodeParam({
      name: 'dossierId',
      value: dossierId,
      in: 'path',
      style: 'simple',
      explode: true,
      dataType: 'string',
      dataFormat: 'uuid',
    })}/inspection`;
    return this.httpClient.request<Inspection>(
      'put',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: inspection,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * # Start an Inspection
   * This endpoint is used to start a new inspection for a specific dossier using a given inspection template.  ## Path &#x60;POST /dossiers/:dossier_id/start_inspection/:inspection_template_id&#x60;  ## Parameters - &#x60;dossier_id&#x60;: The unique identifier of the dossier for which the inspection should be started. - &#x60;inspection_template_id&#x60;: The unique identifier of the inspection template to be used for the new inspection. - &#x60;auth&#x60;: The authentication information to verify the user\&#39;s identity and permissions.  ## Permissions The user making the request must have the necessary permissions to start an inspection for the given dossier.  ## Responses - **201 Created**: The inspection was successfully created and returned in the response. - **404 Not Found**: The specified dossier or inspection template was not found. - **500 Internal Server Error**: An error occurred while creating the inspection.  ## Example Usage To start a new inspection for the dossier with ID &#x60;123e4567-e89b-12d3-a456-426614174000&#x60; using the inspection template with ID &#x60;987e4567-e89b-12d3-a456-426614174000&#x60;:  &#x60;&#x60;&#x60; POST /dossiers/123e4567-e89b-12d3-a456-426614174000/start_inspection/987e4567-e89b-12d3-a456-426614174000 &#x60;&#x60;&#x60;
   * @param dossierId
   * @param inspectionTemplateId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dossiersDossierIdStartInspectionInspectionTemplateIdPost(
    dossierId: string,
    inspectionTemplateId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<Inspection>;
  public dossiersDossierIdStartInspectionInspectionTemplateIdPost(
    dossierId: string,
    inspectionTemplateId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpResponse<Inspection>>;
  public dossiersDossierIdStartInspectionInspectionTemplateIdPost(
    dossierId: string,
    inspectionTemplateId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpEvent<Inspection>>;
  public dossiersDossierIdStartInspectionInspectionTemplateIdPost(
    dossierId: string,
    inspectionTemplateId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<any> {
    if (dossierId === null || dossierId === undefined) {
      throw new Error(
        'Required parameter dossierId was null or undefined when calling dossiersDossierIdStartInspectionInspectionTemplateIdPost.'
      );
    }
    if (inspectionTemplateId === null || inspectionTemplateId === undefined) {
      throw new Error(
        'Required parameter inspectionTemplateId was null or undefined when calling dossiersDossierIdStartInspectionInspectionTemplateIdPost.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (OauthDevPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (OauthProdPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (BearerPermissions) required
    localVarCredential = this.configuration.lookupCredential('BearerPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/dossiers/${this.configuration.encodeParam({
      name: 'dossierId',
      value: dossierId,
      in: 'path',
      style: 'simple',
      explode: true,
      dataType: 'string',
      dataFormat: 'uuid',
    })}/start_inspection/${this.configuration.encodeParam({
      name: 'inspectionTemplateId',
      value: inspectionTemplateId,
      in: 'path',
      style: 'simple',
      explode: true,
      dataType: 'string',
      dataFormat: 'uuid',
    })}`;
    return this.httpClient.request<Inspection>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * This method handles the request to get a list of inspection templates.
   * # Description When you send a GET request to &#x60;/inspection_template&#x60;, this method will return a list of inspection templates. You can optionally filter the templates by providing a description in the query parameters.  # Parameters - &#x60;description&#x60; (optional): A keyword to filter the inspection templates by their description. - &#x60;auth&#x60;: Your authentication information to verify your identity and permissions.  # Permissions You need to have special permission to use this feature. Specifically, you must be allowed to create timeline events.  # Returns - If successful, you\&#39;ll receive a list of inspection templates in JSON format with a status code of 200 OK. - If there\&#39;s an error, you\&#39;ll receive an error message explaining what went wrong.  # Usage Example - To get all inspection templates: &#x60;GET /inspection_template&#x60; - To get templates with a specific description: &#x60;GET /inspection_template?description&#x3D;Annual&#x60;  # Notes Make sure you have the necessary permissions and are properly authenticated to use this endpoint.
   * @param description
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public templateGet(
    description?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<Array<InspectionTemplateList>>;
  public templateGet(
    description?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpResponse<Array<InspectionTemplateList>>>;
  public templateGet(
    description?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpEvent<Array<InspectionTemplateList>>>;
  public templateGet(
    description?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<any> {
    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (description !== undefined && description !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>description,
        'description'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (OauthDevPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (OauthProdPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (BearerPermissions) required
    localVarCredential = this.configuration.lookupCredential('BearerPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/inspection_template`;
    return this.httpClient.request<Array<InspectionTemplateList>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * This method handles the request to delete a specific inspection template by its ID.
   * # Description When you send a DELETE request to &#x60;/inspection_template/:id&#x60;, this method will delete the inspection template with the specified ID. You need to replace &#x60;:id&#x60; with the actual ID of the inspection template you want to delete.  # Parameters - &#x60;id&#x60;: The unique identifier of the inspection template you want to delete. - &#x60;auth&#x60;: Your authentication information to verify your identity and permissions.  # Permissions You need to have special permission to use this feature. Specifically, you must be allowed to delete dossiers.  # Returns - If successful, you\&#39;ll receive a status code of 204 No Content, indicating that the inspection template was successfully deleted. - If the inspection template is not found, you\&#39;ll receive a 404 Not Found error with a message saying \&quot;Inspection template not found\&quot;. - If there\&#39;s an internal error, you\&#39;ll receive an error message explaining what went wrong.  # Usage Example - To delete a specific inspection template: &#x60;DELETE /inspection_template/123e4567-e89b-12d3-a456-426614174000&#x60;  # Notes Make sure you have the necessary permissions and are properly authenticated to use this endpoint.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public templateIdDelete(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext }
  ): Observable<any>;
  public templateIdDelete(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext }
  ): Observable<HttpResponse<any>>;
  public templateIdDelete(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext }
  ): Observable<HttpEvent<any>>;
  public templateIdDelete(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling templateIdDelete.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (OauthDevPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (OauthProdPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (BearerPermissions) required
    localVarCredential = this.configuration.lookupCredential('BearerPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/inspection_template/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: true,
      dataType: 'string',
      dataFormat: 'uuid',
    })}`;
    return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * This method handles the request to get details of a specific inspection template by its ID.
   * # Description When you send a GET request to &#x60;/inspection_template/:id&#x60;, this method will return the details of the inspection template with the specified ID. You need to replace &#x60;:id&#x60; with the actual ID of the inspection template you are looking for.  # Parameters - &#x60;id&#x60;: The unique identifier of the inspection template you want to retrieve. - &#x60;auth&#x60;: Your authentication information to verify your identity and permissions.  # Permissions You need to have special permission to use this feature. Specifically, you must be allowed to create timeline events.  # Returns - If successful, you\&#39;ll receive the details of the inspection template in JSON format with a status code of 200 OK. - If the inspection template is not found, you\&#39;ll receive a 404 Not Found error with a message saying \&quot;Inspection template not found\&quot;. - If there\&#39;s an internal error, you\&#39;ll receive an error message explaining what went wrong.  # Usage Example - To get the details of a specific inspection template: &#x60;GET /inspection_template/123e4567-e89b-12d3-a456-426614174000&#x60;  # Notes Make sure you have the necessary permissions and are properly authenticated to use this endpoint.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public templateIdGet(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<InspectionTemplate>;
  public templateIdGet(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpResponse<InspectionTemplate>>;
  public templateIdGet(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpEvent<InspectionTemplate>>;
  public templateIdGet(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling templateIdGet.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (OauthDevPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (OauthProdPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (BearerPermissions) required
    localVarCredential = this.configuration.lookupCredential('BearerPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/inspection_template/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: true,
      dataType: 'string',
      dataFormat: 'uuid',
    })}`;
    return this.httpClient.request<InspectionTemplate>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Updates an existing inspection template based on the provided ID.
   * # Arguments * &#x60;Path(id)&#x60; - The unique identifier of the inspection template to be updated. * &#x60;Json(inspection_definition)&#x60; - The new inspection template data provided in the request body. * &#x60;auth&#x60; - The authentication object containing user permissions.  # Returns * &#x60;Result&lt;Json&lt;InspectionTemplate&gt;&gt;&#x60; - The updated inspection template in JSON format if successful, otherwise an error.  This method performs the following steps: 1. Checks if the user has the necessary permission to create timeline events. 2. Retrieves the organization ID associated with the authenticated user. 3. Converts the inspection definition to a JSON value. 4. Calls the repository method to update the inspection template in the database. 5. Returns the updated inspection template in the response if the operation is successful, otherwise returns an internal server error.
   * @param id
   * @param createInspectionTemplateRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public templateIdPut(
    id: string,
    createInspectionTemplateRequest: CreateInspectionTemplateRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<InspectionTemplate>;
  public templateIdPut(
    id: string,
    createInspectionTemplateRequest: CreateInspectionTemplateRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpResponse<InspectionTemplate>>;
  public templateIdPut(
    id: string,
    createInspectionTemplateRequest: CreateInspectionTemplateRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpEvent<InspectionTemplate>>;
  public templateIdPut(
    id: string,
    createInspectionTemplateRequest: CreateInspectionTemplateRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling templateIdPut.');
    }
    if (createInspectionTemplateRequest === null || createInspectionTemplateRequest === undefined) {
      throw new Error(
        'Required parameter createInspectionTemplateRequest was null or undefined when calling templateIdPut.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (OauthDevPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (OauthProdPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (BearerPermissions) required
    localVarCredential = this.configuration.lookupCredential('BearerPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json; charset=utf-8'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/inspection_template/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: true,
      dataType: 'string',
      dataFormat: 'uuid',
    })}`;
    return this.httpClient.request<InspectionTemplate>(
      'put',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: createInspectionTemplateRequest,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * This method handles the request to create a new inspection template.
   * # Description When you send a POST request to &#x60;/inspection_template&#x60;, this method will create a new inspection template with the details you provide.  # Parameters - &#x60;inspection_template&#x60;: A JSON object containing the details of the new inspection template. This includes the description and content of the template. - &#x60;auth&#x60;: Your authentication information to verify your identity and permissions.  # Permissions You need to have special permission to use this feature. Specifically, you must be allowed to create timeline events.  # Returns - If successful, you\&#39;ll receive the details of the newly created inspection template in JSON format with a status code of 200 OK. - If there\&#39;s an error, you\&#39;ll receive an error message explaining what went wrong.  # Usage Example - To create a new inspection template, send a POST request to &#x60;/inspection_template&#x60; with the following JSON body: &#x60;&#x60;&#x60;json { \&quot;description\&quot;: \&quot;Annual Inspection\&quot;, \&quot;content\&quot;: { ... } } &#x60;&#x60;&#x60;  # Notes Make sure you have the necessary permissions and are properly authenticated to use this endpoint.
   * @param createInspectionTemplateRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public templatePost(
    createInspectionTemplateRequest: CreateInspectionTemplateRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<InspectionTemplate>;
  public templatePost(
    createInspectionTemplateRequest: CreateInspectionTemplateRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpResponse<InspectionTemplate>>;
  public templatePost(
    createInspectionTemplateRequest: CreateInspectionTemplateRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<HttpEvent<InspectionTemplate>>;
  public templatePost(
    createInspectionTemplateRequest: CreateInspectionTemplateRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8'; context?: HttpContext }
  ): Observable<any> {
    if (createInspectionTemplateRequest === null || createInspectionTemplateRequest === undefined) {
      throw new Error(
        'Required parameter createInspectionTemplateRequest was null or undefined when calling templatePost.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (OauthDevPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthDevPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (OauthProdPermissions) required
    localVarCredential = this.configuration.lookupCredential('OauthProdPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    // authentication (BearerPermissions) required
    localVarCredential = this.configuration.lookupCredential('BearerPermissions');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json; charset=utf-8'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/inspection_template`;
    return this.httpClient.request<InspectionTemplate>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: createInspectionTemplateRequest,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
