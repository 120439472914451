import { Input, Component } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NgClass, NgStyle, NgIf } from '@angular/common';

const MAX_FILE_SIZE = 10e6; // 1e6 is roughly 1MB

@Component({
    selector: 'app-profile-picture',
    templateUrl: './profile-picture.component.html',
    styleUrl: './profile-picture.component.scss',
    imports: [NgClass, NgStyle, NgIf, TranslateModule]
})
export class ProfilePictureComponent {
  constructor(private translate: TranslateService) {}

  @Input() isRectangle: boolean = false;
  fileName = '';
  imageUrl: string | ArrayBuffer = '';
  allowedFileTypes: Array<string> = ['image/png', 'image/jpg', 'image/jpeg'];

  onFileSelected(event): void {
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;

      if (!this.allowedFileTypes.includes(file.type)) {
        return alert(this.translate.instant('file-type-error'));
      } else if (file.size > MAX_FILE_SIZE) {
        return alert(this.translate.instant('file-size-error') + `${MAX_FILE_SIZE / 1e6}MB`);
      }

      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>): void => {
        this.imageUrl = e.target!.result!.toString();
      };

      reader.readAsDataURL(file);
    }
  }
}
