<div class="measure-copy-popup" [class.active]="measureStatus.active">
  <div class="measure-copy-popup__header">
    <h4>{{ 'project-module.measure.add-measure' | translate }}</h4>
    <button class="close-button" (click)="close()">
      <span class="material-symbols-rounded">close</span>
    </button>
  </div>
  <div class="measure-copy-popup__content">
    <ng-container>
      <div class="measure-copy-popup__content__container big-input">
        <mat-form-field>
          <input
            type="text"
            matInput
            [formControl]="searchControl"
            [matAutocomplete]="auto"
            placeholder="{{ 'project-module.measure.choose-your-measure-template' | translate }}"
          />
        </mat-form-field>

        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onOptionSelected($event)"
          (opened)="loadTemplates(searchControl.value).subscribe()"
          [displayWith]="displayFn"
        >
          <mat-option *ngFor="let template of templates" [value]="template">
            {{ template.name }}
          </mat-option>

          <mat-option *ngIf="isLoading">
            <mat-spinner diameter="20"></mat-spinner> Loading...
          </mat-option>
        </mat-autocomplete>

      </div>

      <div class="measure-copy-popup__content__other-option">
        <span class="divider">{{ 'or' | translate }}</span>
        <button (click)="navigateToNewMeasure()">{{ 'project-module.measure.create' | translate }}</button>
      </div>
    </ng-container>

    <div class="measure-copy-popup__content__buttons">
      <button (click)="close()" class="cancel-button">{{ 'cancel' | translate }}</button>
      <button (click)="saveAndFinish()" class="button-primary">
        {{ 'save-and-finish' | translate }}
      </button>
    </div>
  </div>
</div>

<button
  class="measure-copy-popup__background"
  (click)="close()"
  [class.active]="measureStatus.active"
>
</button>
