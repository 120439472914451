<div class="file-upload" [class.edit]="edit" [contextMenu]="menu">
  <label for="input" class="file-upload__title">
    Bewijsstukken aanleveren {{ fileRequest.isRequired ? '*' : '' }}
  </label>

  <div class="file-upload__wrapper">
    <div class="file-upload__input big-input" *ngIf="edit">
      <input type="text" name="input" id="input" autocomplete="off" />
    </div>

    <app-three-dot [menu]="menu"></app-three-dot>

    <div class="file-select" *ngIf="!edit">
      <label class="upload-button">
        <input type="file" (change)="onUpload($event)" multiple />
        {{ 'file-upload' | translate }}
      </label>
    </div>

    <div class="file-upload__list">
      <div *ngFor="let file of uploadedFiles" class="list-item">
        <span class="list-item__title">{{ file.name }}</span>
        <span class="list-item__size">({{ file.size | filesize }})</span>
        <button
          class="material-symbols-rounded list-item__remove"
          (click)="deleteUploadedFile(file)">
          close
        </button>
      </div>
    </div>
  </div>
</div>
