import { Component } from '@angular/core';
import { ChangedElement, DeleteElement, NewElement, UnsavedElement } from './change';
import { BimElementDto } from '@api-clients/bim';
import { isBimObject, isBimRoom } from '../../views/model-viewer/utils/types';
import { BimPropertyService } from '../../services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-changes-summary',
  templateUrl: './changes-summary.component.html',
  styleUrl: './changes-summary.component.scss',
})
export class ChangesSummaryComponent {
  protected unsavedElements: UnsavedElement[] = [];

  constructor(private readonly bimPropertyService: BimPropertyService) {
    this.bimPropertyService.unsavedElements
      .pipe(takeUntilDestroyed())
      .subscribe((changedElements) => {
        this.unsavedElements = changedElements;
      });
  }

  removeAddedElement(
    element: NewElement,
    event: Event,
    buttonToBeRemoved: HTMLButtonElement
  ): void {
    buttonToBeRemoved.classList.add('delete');
    event.stopPropagation();
    setTimeout(() => {
      this.bimPropertyService.removeAddedElement(element);
    }, 400);
  }

  removePropertyChange(
    element: ChangedElement,
    property: string,
    event: Event,
    buttonToBeRemoved: HTMLButtonElement
  ): void {
    buttonToBeRemoved.classList.add('delete');
    event.stopPropagation();
    setTimeout(() => {
      element.removePropertyChange(property);
      this.bimPropertyService.updateSelectedElement(element);
    }, 400);
  }

  removeCategoryChange(
    element: ChangedElement,
    event: Event,
    buttonToBeRemoved: HTMLButtonElement
  ): void {
    buttonToBeRemoved.classList.add('delete');
    event.stopPropagation();
    setTimeout(() => {
      element.removeCategoryChange();
      this.bimPropertyService.updateSelectedElement(element);
    }, 400);
  }

  activateElement(element: UnsavedElement): void {
    this.bimPropertyService.selectBimElement(element.id);
  }

  undeleteElement(
    element: DeleteElement,
    event: Event,
    buttonToBeRemoved: HTMLButtonElement
  ): void {
    buttonToBeRemoved.classList.add('delete');
    event.stopPropagation();
    setTimeout(() => {
      this.bimPropertyService.undeleteElement(element);
    }, 400);
  }

  getTypeName(element: BimElementDto): string {
    if (isBimObject(element.element)) {
      return element.element.category.toString();
    }
    if (isBimRoom(element.element)) {
      return 'Room';
    }

    return 'Object';
  }

  protected isInstanceOfChangedElement(element: UnsavedElement): element is ChangedElement {
    return element instanceof ChangedElement;
  }

  protected isInstanceOfAddedElement(element: UnsavedElement): element is NewElement {
    return element instanceof NewElement;
  }

  protected isInstanceOfDeletedElement(element: UnsavedElement): element is DeleteElement {
    return element instanceof DeleteElement;
  }
}
