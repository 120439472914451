/**
 * BIM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ObjectCategory = 'Undefined' | 'Wall' | 'Window' | 'Door' | 'Roof' | 'Stair' | 'Floor' | 'Installation' | 'Ceiling';

export const ObjectCategory = {
    Undefined: 'Undefined' as ObjectCategory,
    Wall: 'Wall' as ObjectCategory,
    Window: 'Window' as ObjectCategory,
    Door: 'Door' as ObjectCategory,
    Roof: 'Roof' as ObjectCategory,
    Stair: 'Stair' as ObjectCategory,
    Floor: 'Floor' as ObjectCategory,
    Installation: 'Installation' as ObjectCategory,
    Ceiling: 'Ceiling' as ObjectCategory
};

