import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Product, ProductsResult, ProductsService } from '@api-clients/product';

@Component({
  selector: 'app-products-add-popup',
  templateUrl: './products-add-popup.component.html',
  styleUrl: './products-add-popup.component.scss',
})
export class ProductsAddPopupComponent {
  @ViewChild('input') input!: ElementRef;

  @Input() active: boolean = false;
  @Input() currentProducts?: readonly Product[];

  @Output() productsAddedChange = new EventEmitter();
  @Output() productRemovedChange = new EventEmitter();
  @Output() requestClose = new EventEmitter();

  private isDragging: boolean = false;
  private startX: number = 0;
  private startY: number = 0;

  constructor(private readonly productService: ProductsService) {}

  protected search: string = '';
  protected productsResult?: ProductsResult = undefined;
  protected previousOffset: number = 0;
  protected isBusy: boolean = false;

  doSearch(): void {
    this.previousOffset = 0;
    this.doRequest();
  }

  doRequest(): void {
    this.isBusy = true;
    this.productService.productsGet(this.search, this.previousOffset).subscribe((next) => {
      this.productsResult = next;
      this.isBusy = false;
    });
  }

  incrementOffset(): void {
    this.previousOffset += this.productsResult?.products.length ?? 0;
    this.doRequest();
  }

  onMouseDown(event: MouseEvent): void {
    this.startX = event.clientX;
    this.startY = event.clientY;
    this.isDragging = false;
  }

  onMouseMove(event: MouseEvent): void {
    if (Math.abs(event.clientX - this.startX) > 20 || Math.abs(event.clientY - this.startY) > 20) {
      this.isDragging = true;
    }
  }

  toggleProduct(product: Product): void {
    if (this.isDragging) {
      return;
    } else if (!this.productAlreadyAdded(product)) {
      this.productsAddedChange.emit([product]);
    } else {
      this.productRemovedChange.emit(product);
    }
  }

  closePopup(): void {
    this.requestClose.emit();

    //wait for hide then empty everything
    setTimeout(() => {
      this.productsResult = undefined;
      this.search = '';
    }, 250);
  }

  productAlreadyAdded(product: Product): boolean {
    const sameProduct = (p: Product): boolean =>
      p.manufacturer_gln === product.manufacturer_gln && p.product_code == product.product_code;
    return this.currentProducts?.some(sameProduct) || false;
  }
}
