<div class="confirmation-dialog" [class.active]="active" [class.no-header]="!icon">
  <div class="confirmation-dialog__header" *ngIf="icon">
    <div class="confirmation-dialog__header__icon">
      <img src="assets/images/{{ icon }}.png" alt="{{ icon }}" />
    </div>
  </div>

  <div class="confirmation-dialog__content">
    <h4 class="title">{{ title }}</h4>
    <p class="description">{{ description }}</p>
    <ng-content></ng-content>
    <button class="{{ remove ? 'button-danger' : 'button-primary' }}" (click)="confirm()">
      {{ confirmText }}
    </button>
    <button class="cancel-button" (click)="cancel()">{{ 'cancel' | translate }}</button>
  </div>
</div>

<div
  class="confirmation-dialog__background"
  [class.active]="active"
  (click)="cancel()"
  (window:keydown)="keyDown($event)"
  tabindex="-1"
  aria-hidden="true"></div>
