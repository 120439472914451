import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DossiersService,
  NotesService,
  TimeLineAttachments,
  TimeLineDto,
  TimeLineType,
} from '@api-clients/dossier';
import { EnrichedTimeLineDto } from '../../views/dossier-detail/EnrichedTimeLineDto';

import { lastValueFrom } from 'rxjs';
import { UsersService } from '@api-clients/user';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { TimelineEventComponent } from '../timeline-event/timeline-event.component';

@Component({
    selector: 'app-bim-notes',
    templateUrl: './bim-notes.component.html',
    styleUrl: './bim-notes.component.scss',
    imports: [
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        TimelineEventComponent,
    ],
})
export class BimNotesComponent implements OnInit {
  private elementId!: string;

  @Input({ required: true })
  get element_id(): string {
    return this.elementId;
  }

  set element_id(value: string) {
    if (this.elementId !== value) {
      this.elementId = value;
      this.reload().then(() => {});
    }
  }

  @Input({ required: true }) bim_id!: string;

  protected timeLine: EnrichedTimeLineDto[] = [];

  @Output() imageClick: EventEmitter<{ item: TimeLineDto; imageId: string }> = new EventEmitter<{
    item: TimeLineDto;
    imageId: string;
  }>();

  @Output() fileClick: EventEmitter<{ item: TimeLineDto; fileId: string }> = new EventEmitter<{
    item: TimeLineDto;
    fileId: string;
  }>();

  constructor(
    private readonly notesService: NotesService,
    private readonly usersInfoService: UsersService,
    private readonly dossiersService: DossiersService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.reload();
  }

  async reload(): Promise<void> {
    if (!this.bim_id || !this.element_id) return;

    const notes = await lastValueFrom(
      this.notesService.notesGet(undefined, undefined, {
        optional_GenericSubject_type: 'BimElement',
        optional_GenericSubject_bim_id: this.bim_id,
        optional_GenericSubject_bim_element_id: this.element_id,
      })
    );

    const user_ids = notes.map((note) => note.user_id);
    const image_ids = notes.flatMap((note) => note.image_ids);
    const file_ids = notes.flatMap((note) => note.file_ids);
    const users = await lastValueFrom(this.usersInfoService.getNames(user_ids));

    const images: { id: string; file_name: string }[] = await Promise.all(
      image_ids.map(async (image_id) => {
        const image = await lastValueFrom(this.dossiersService.imageImageIdMetaGet(image_id));
        return { id: image_id, file_name: image.file_name };
      })
    );

    const files: { id: string; file_name: string }[] = await Promise.all(
      file_ids.map(async (file_id) => {
        const file = await lastValueFrom(this.dossiersService.fileFileIdMetaGet(file_id));
        return { id: file_id, file_name: file.file_name };
      })
    );

    this.timeLine = notes.map((note) => {
      return {
        dossier_id: note.dossier_id ?? '', // TODO : this is not how it should be done. Best is to create a note-component without dossier_id
        description: note.rich_text,
        item_id: note.id,
        item_type: TimeLineType.Note,
        id: note.id,
        user_id: note.user_id,
        userName: users[note.user_id],
        timestamp_utc: note.timestamp_utc,
        attachments: <TimeLineAttachments>{
          images: note.image_ids.map((imageId) => {
            return {
              id: imageId,
              file_name: images.find((i) => i.id === imageId)?.file_name ?? '',
            };
          }),
          files: note.file_ids.map((fileId) => {
            return {
              id: fileId,
              file_name: files.find((i) => i.id === fileId)?.file_name ?? '',
            };
          }),
          documents: [],
        },
      };
    });
  }

  emitImageClick(item: TimeLineDto, imageId: string): void {
    this.imageClick.emit({ item, imageId });
  }

  emitFileClick(item: TimeLineDto, fileId: string): void {
    this.fileClick.emit({ item, fileId });
  }
}
