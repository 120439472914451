/**
 * BIM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BuildingSortColumn = 'Description' | 'Status' | 'Source' | 'UploadTime';

export const BuildingSortColumn = {
    Description: 'Description' as BuildingSortColumn,
    Status: 'Status' as BuildingSortColumn,
    Source: 'Source' as BuildingSortColumn,
    UploadTime: 'UploadTime' as BuildingSortColumn
};

