"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.offsetToPolyTree = exports.offsetToPaths = void 0;
var ClipperOffset_1 = require("./ClipperOffset");
var addPathOrPaths = function (offset, inputDatas) {
  if (inputDatas === undefined) {
    return;
  }
  // add each input
  for (var i = 0, maxi = inputDatas.length; i < maxi; i++) {
    var inputData = inputDatas[i];
    // add the path/paths
    var pathOrPaths = inputData.data;
    if (!pathOrPaths || pathOrPaths.length <= 0) {
      continue;
    }
    // is it a path or paths?
    if (Array.isArray(pathOrPaths[0])) {
      // paths
      offset.addPaths(pathOrPaths, inputData.joinType, inputData.endType);
    } else {
      // path
      offset.addPath(pathOrPaths, inputData.joinType, inputData.endType);
    }
  }
};
function offsetToPathsOrPolyTree(polyTreeMode, nativeClipperLib, params) {
  var filledData = __assign({
    arcTolerance: 0.25,
    miterLimit: 2
  }, params);
  var offset = new ClipperOffset_1.ClipperOffset(nativeClipperLib, filledData.miterLimit, filledData.arcTolerance);
  //noinspection UnusedCatchParameterJS
  try {
    addPathOrPaths(offset, params.offsetInputs);
    if (!polyTreeMode) {
      return offset.executeToPaths(params.delta, params.cleanDistance);
    } else {
      if (params.cleanDistance !== undefined) {
        return undefined; // cleaning is not available for poly tree results
      }
      return offset.executeToPolyTree(params.delta);
    }
  } catch (err) {
    return undefined;
  } finally {
    offset.dispose();
  }
}
function offsetToPaths(nativeClipperLib, params) {
  return offsetToPathsOrPolyTree(false, nativeClipperLib, params);
}
exports.offsetToPaths = offsetToPaths;
function offsetToPolyTree(nativeClipperLib, params) {
  return offsetToPathsOrPolyTree(true, nativeClipperLib, params);
}
exports.offsetToPolyTree = offsetToPolyTree;
