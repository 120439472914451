/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OptionalMeasureSubjectQueryMeasureSubjectBimElementReference { 
    optional_MeasureSubject_bim_element_id: string;
    optional_MeasureSubject_type: OptionalMeasureSubjectQueryMeasureSubjectBimElementReference.OptionalMeasureSubjectTypeEnum;
}
export namespace OptionalMeasureSubjectQueryMeasureSubjectBimElementReference {
    export type OptionalMeasureSubjectTypeEnum = 'BimElement';
    export const OptionalMeasureSubjectTypeEnum = {
        BimElement: 'BimElement' as OptionalMeasureSubjectTypeEnum
    };
}


