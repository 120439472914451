import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrl: './color-picker.component.scss',
    imports: [NgIf, FormsModule]
})
export class ColorPickerComponent implements OnChanges {
  @Input() color = '#3f6165'; // In final version, fetch colors from api
  @Input() description = '';

  @Output() colorChange = new EventEmitter<string>();

  protected contrast = '#ffffff';

  constructor() {}

  ngOnChanges(): void {
    this.updateContrastColor();
  }

  updateContrastColor(): void {
    if (!this.description) return;
    this.contrast = this.calculateContrastColor(this.color);
  }

  calculateContrastColor(hexColor: string): string {
    const rgb = this.hexToRgb(hexColor);
    const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255; // https://www.w3.org/TR/AERT/#color-contrast
    return luminance > 0.5 ? '#000000' : '#ffffff';
  }

  hexToRgb(hex: string): { r: number; g: number; b: number } {
    const num: number = parseInt(hex.slice(1), 16); // converts color hex to int
    const r = (num >> 16) & 255;
    const g = (num >> 8) & 255;
    const b = num & 255;
    return { r, g, b };
  }
}
