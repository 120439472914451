<div class="map-wrap">
  <div #map class="map"></div>

  <div class="layers" [class.sidebar-active]="sidebarActive">
    <div class="base">
      <div class="image"><img src="assets/layers/base.png" alt="Layers" /></div>
    </div>

    <div class="options">
      <button
        class="option"
        [class.active]="this.activeLayer === 'normal'"
        (click)="changeLayer('normal')">
        <div class="label">{{ 'map-type' | translate }}</div>
        <div class="image"><img src="assets/layers/base.png" alt="Normal" /></div>
        <span>{{ 'normal' | translate }}</span>
      </button>
      <button
        class="option"
        [class.active]="this.activeLayer === 'satellite'"
        (click)="changeLayer('satellite')">
        <div class="image"><img src="assets/layers/satelite.png" alt="Satellite" /></div>
        <span>{{ 'satellite' | translate }}</span>
      </button>

      <button class="option" [class.active]="this.activeLayer === 'ahn4'" (click)="changeLayer('ahn4')">
        <div class="image"><img src="assets/layers/ahn.png" alt="AHN4" /></div>
        <span>{{ 'AHN4' | translate }}</span>
      </button>

      <div class="spacer"></div>

      <button class="option" [class.active]="showBag3DLayer" (click)="change3DLayer()">
        <div class="label">{{ 'map-layers' | translate }}</div>
        <div class="image"><img src="assets/layers/threed.png" alt="3D buildings" /></div>
        <span>{{ '3d_buildings' | translate }}</span>
      </button>

      <button class="option" [class.active]="showCadastralLayer" (click)="changeCadastralLayer()">
        <div class="image"><img src="assets/layers/kadastral.png" alt="Cadastral" /></div>
        <span>{{ 'cadastral_borders' | translate }}</span>
      </button>
    </div>
  </div>
</div>
