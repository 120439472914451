<div class="file-preview-popup" [class.active]="active">
  <div class="file-preview-popup__content">
    <div class="file-preview-popup__content__header">
      <h3>{{ fileName ? fileName : ('file-preview' | translate) }}</h3>

      <a
        class="download-button"
        download="{{ sanitizeFileName(fileName) }}"
        target="_blank"
        href="{{ fileUrl }}">
        <span class="material-symbols-rounded">download</span>
        {{ 'download' | translate }}
      </a>
      <button class="close-button" (click)="closePopup()">
        <span class="material-symbols-rounded">close</span>
      </button>
    </div>

    <app-file-preview
      [fileUrl]="fileUrl"
      [fileSize]="fileSize"
      [fileName]="fileName"
      [popupVersion]="true"></app-file-preview>
  </div>
  <button class="file-preview-popup__background" (click)="closePopup()">&nbsp;</button>
</div>
