import { Component, OnInit } from '@angular/core';
import { Inspection, InspectionsService, TimeLineType } from '@api-clients/dossier';
import { ActivatedRoute } from '@angular/router';
import { DossierDetailService } from '../../../building-module/services/dossier-detail.service';

@Component({
  selector: 'app-inspection-detail',
  templateUrl: './inspection-detail.component.html',
})
export class InspectionDetailComponent implements OnInit {
  protected readonly TimeLineType = TimeLineType;
  private inspectionId: string;
  private dossierId!: string;
  protected inspection?: Inspection;

  constructor(
    private readonly inspectionsService: InspectionsService,
    private readonly route: ActivatedRoute,
    private readonly dossierDetailService: DossierDetailService
  ) {
    this.inspectionId = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.dossierDetailService.dossier.subscribe((dossier) => {
      this.dossierId = dossier.id;
      this.inspectionsService
        .dossiersDossierIdInspectionIdGet(this.dossierId, this.inspectionId)
        .subscribe((inspection) => {
          this.inspection = inspection;
        });
    });
  }
}
