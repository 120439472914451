<div class="dossier-timeline">
  <cdk-virtual-scroll-viewport class="timeline" itemSize="146">
    <div
      *cdkVirtualFor="let item of timelineService.ds; let last = last"
      class="timeline-event"
      tabindex="0"
      (click)="timelineEventClick.emit(item)"
      (keyup)="timelineEventClick.emit(item)">
      <app-timeline-event
        *ngIf="item"
        [item]="item"
        [smallVersion]="smallVersion"
        (imageFunction)="emitImageClick(item, $event)"
        (fileFunction)="emitFileClick(item, $event)"
        [lastItem]="last"></app-timeline-event>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
