import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Category, Level } from '@shared/services/assets/building-elements';
import { ObjectCategory } from '@api-clients/bim';
import { InformationContainerComponent } from '../information-container/information-container.component';
import { InformationDescriptionComponent } from '../information-container/information-description/information-description.component';
import { InformationItemComponent } from '../information-container/information-item/information-item.component';
import { NgFor, NgIf, LowerCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@UntilDestroy()
@Component({
    selector: 'app-residence-sidebar',
    templateUrl: './residence-sidebar.component.html',
    styleUrls: ['./residence-sidebar.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('out', style({
                height: '*',
            })),
            state('in', style({
                height: '0',
            })),
            transition('in => out', animate('250ms ease-in-out')),
            transition('out => in', animate('250ms ease-in-out')),
        ]),
    ],
    imports: [InformationContainerComponent, InformationDescriptionComponent, InformationItemComponent, NgFor, NgIf, LowerCasePipe, TranslateModule]
})
export class ResidenceSidebarComponent {
  @Input() active: boolean = false;
  @Input() categories?: Category[];
  @Input() levels?: Level[];
  @Input() availableHeight: number = 0;
  @Output() toggleCategoryVisibility = new EventEmitter<ObjectCategory>();
  @Output() toggleLevelVisibility = new EventEmitter<number>();

  constructor() {}

  protected toggleCategory(category: ObjectCategory): void {
    this.toggleCategoryVisibility.emit(category);
  }

  protected toggleLevel(level: number): void {
    this.toggleLevelVisibility.emit(level);
  }
}
