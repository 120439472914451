import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RequestContentItem } from '../../models';
import { ContextMenuItem } from '@services/context.service';
import { ContextMenuDirective } from '@shared/directives/context-menu.directive';
import { NgIf, NgFor } from '@angular/common';
import { ThreeDotComponent } from '@shared/components/three-dot/three-dot.component';
import { TranslateModule } from '@ngx-translate/core';
import { FileSizePipe } from '@shared/pipes/file-size.pipe';

@Component({
  selector: 'app-workspace-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  imports: [ContextMenuDirective, NgIf, ThreeDotComponent, NgFor, TranslateModule, FileSizePipe],
})
export class WorkspaceFileUploadComponent implements OnInit {
  @Input() fileRequest!: RequestContentItem;
  @Input() first = false;
  @Input() last = false;
  @Input() edit = false;
  @Output() delete = new EventEmitter();
  @Output() move = new EventEmitter<number>();

  uploadedFiles: File[] = [];
  menu: ContextMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'move-up',
        icon: 'move_up',
        visible: (): boolean => !this.first,
        action: (): void => this.moveItem(-1),
      },
      {
        name: 'move-down',
        icon: 'move_down',
        visible: (): boolean => !this.last,
        action: (): void => this.moveItem(1),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: (): void => this.delete.emit(),
      },
      {
        divider: true,
      },
      {
        name: 'is-required',
        checked: (): boolean => this.fileRequest.isRequired,
        action: (): void => this.toggleRequired(),
      },
    ];
  }

  toggleRequired(): void {
    this.fileRequest.isRequired = !this.fileRequest.isRequired;
    // this.fileRequest.update();
  }

  moveItem(movement): void {
    this.move.emit(movement);
    this.buildMenu();
  }

  deleteUploadedFile(file: File): void {
    this.uploadedFiles = this.uploadedFiles.filter((f) => f !== file);
  }

  onUpload(event: Event): void {
    for (const file of event.target?.['files'] as File[]) {
      if (this.uploadedFiles.find((f) => f.name === file.name && f.size === file.size)) continue;
      this.uploadedFiles.push(file);
      void this.fileRequest.insertContentItem(file);
    }
  }
}
