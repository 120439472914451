"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nativeFinalizationRegistry = void 0;
exports.nativeFinalizationRegistry = typeof FinalizationRegistry === "undefined" ? undefined : new FinalizationRegistry(function (nativeObj) {
  if (!nativeObj.isDeleted()) {
    nativeObj.delete();
  }
});
