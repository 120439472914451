import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-model-viewer-external',
    templateUrl: './model-viewer-external.component.html',
    styleUrls: ['./model-viewer-external.component.scss']
})
export class ModelViewerExternalComponent {
  modelId: string | null;

  constructor(
    route: ActivatedRoute,
    private domSanitizer: DomSanitizer
  ) {
    this.modelId = route.snapshot.paramMap.get('id');
  }

  get url(): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      `https://3dviewer.net/#model=https://storage.googleapis.com/tt-3d-models/${this.modelId}`
    );
  }
}
