<div>
  <mat-form-field>
    <mat-chip-grid #chipGrid [formControl]="formControl">
      @for (subject of value; track subject) {
        @if (isRealEstateBuildingReference(subject)) {
          @let building = buildings.get(subject.real_estate_building_id);
          <mat-chip-row (removed)="remove(subject)">
            <mat-icon>{{(building?.buildingMetadata?.addresses?? []).length > 1 ? 'apartment' :'home'}}</mat-icon>
            {{building?.buildingMetadata?.city}} - {{building?.buildingMetadata?.address}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        }
        @if (isAddressableUnitReference(subject)) {
          @let addressable_unit = addressableUnits.get(subject.addressable_unit_id);
          <mat-chip-row (removed)="remove(subject)">
            <mat-icon>local_post_office</mat-icon>
            {{ addressable_unit?.place }} - {{ addressable_unit?.address }} {{ addressable_unit?.house_number }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        }
      }
    </mat-chip-grid>
    <input
      matInput
      #input
      (keyup)="inputChange($event)"
      [placeholder]="'project-module.search-and-attach-building-and-addressable-units' | translate"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete requireSelection #auto="matAutocomplete" (optionSelected)="add($event.option.value)">
      @for (entry of filteredBuildings$ | async; track entry) {
        @if (isBuilding(entry)) {
          <mat-option [value]="entry"><mat-icon>{{entry.buildingMetadata.addresses.length > 1 ? 'apartment' :'home'}}</mat-icon>{{entry?.buildingMetadata?.city}} - {{entry?.buildingMetadata?.address}}</mat-option>
          @if (entry.buildingMetadata.addresses.length > 1) {
            @for (addressable_unit of entry.buildingMetadata.addresses; track addressable_unit) {
              <mat-option [value]="addressable_unit" style="margin-left: 26px"><mat-icon>local_post_office</mat-icon>{{addressable_unit.place}} - {{addressable_unit.address}} {{addressable_unit.house_number}}</mat-option>
            }
          }
        } @else {
          <mat-option [value]="entry"><mat-icon>local_post_office</mat-icon>{{entry.place}} - {{entry.address}} {{entry.house_number}}</mat-option>
        }
      }
    </mat-autocomplete>
  </mat-form-field>
</div>
