<div
  class="building-menu"
  [class.show]="active"
  [class.collapse]="localStorage.getItem('buildingMenuExpand') === 'false'">
  <button class="expand" (click)="toggleExpand()">
    <span class="material-symbols-rounded">chevron_left</span>
    <span class="material-symbols-rounded">chevron_left</span>
  </button>
  <div class="building-menu__header">
    <ng-container *ngIf="building?.buildingMetadata as metadata">
      <h2>{{ metadata.name || metadata.address }}</h2>
      <h3 *ngIf="metadata.name && metadata.address">{{ metadata.address }}</h3>
      <h3>{{ metadata.postalCode }} {{ metadata.city }}</h3>
    </ng-container>
  </div>

  <ul>
    <li *ngFor="let item of menuItems">
      <a
        tabindex="-1"
        (keydown)="navigate(item.route)"
        (click)="navigate(item.route)"
        [class.active]="isActive(item.route)"
        *ngIf="item.label">
        <span class="material-symbols-rounded menu-icon">{{ item.icon }}</span>
        {{ item.label | translate }}
      </a>
      <button
        *ngIf="item.subMenu"
        [class.active]="item.subActive"
        (click)="item.subActive = !item.subActive"
        class="material-symbols-rounded menu-arrow">
        expand_more
      </button>
      <ul *ngIf="item.subMenu" [class.active]="item.subActive">
        <li *ngFor="let subItem of item.subMenu">
          <a
            tabindex="-1"
            (keydown)="navigate(subItem.route)"
            (click)="navigate(subItem.route)"
            [routerLink]="item.route"
            *ngIf="subItem.label">
            {{ subItem.label | translate }}
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>

<div class="building-menu__line" [class.show]="active"></div>
<div
  class="building-menu__space"
  [class.show]="active"
  [class.collapse]="localStorage.getItem('buildingMenuExpand') === 'false'"></div>
