<div class="custom-layers-legend" *ngIf="source.length > 0" [class.active]="active">
  <div class="custom-layers-legend__header">
    <h4>{{ 'Custom layers' | translate }}</h4>
    <button class="material-symbols-rounded close-button" (click)="closeEvent.emit()">close</button>
  </div>

  <mat-tree #treeControl [dataSource]="source" [childrenAccessor]="childrenAccessor">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <div class="layer-color" [style.background-color]="node.color || 'red'"></div>
      <!-- use a disabled button to provide padding for tree leaf -->

      <mat-checkbox
        (change)="toggleLayer(node)"
        [checked]="checklistSelection.isSelected(node)"
        class="mat-checkbox">
        {{ node.description | translate }}
      </mat-checkbox>
    </mat-tree-node>

    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      matTreeNodeToggle
      [cdkTreeNodeTypeaheadLabel]="node.description">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.description">
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <mat-checkbox
        (change)="toggleLayer(node)"
        [checked]="checklistSelection.isSelected(node)"
        [indeterminate]="descendantsPartiallySelected(node)"
        class="node-checkbox">
        {{ node.description | translate }}
      </mat-checkbox>
    </mat-tree-node>
  </mat-tree>
</div>
