<div class="add-note">
  <p>{{ 'Note' | translate }}</p>
  <div class="add-note__container">
    <textarea class="textarea" #noteInput></textarea>
    <div class="add-note__container__buttons">
      <input
        type="file"
        #fileInput
        style="display: none"
        (change)="fileInputChanged()" />
      <button (click)="uploadFile()" class="button-primary button--has-icon">
        <span class="material-symbols-rounded">post_add</span>
        <span *ngIf="file_ids.length">({{ file_ids.length }})</span>
      </button>
    </div>
  </div>
  <div class="image-list">
    <div *ngFor="let image of filesPreview; let i = index" class="image-list__item">
      <img [src]="image" alt="note-image" />
      <button (click)="removeImage(i)" class="image-list__item__remove">
        <span class="material-symbols-rounded">close</span>
      </button>
    </div>
  </div>
  <div class="file-list">
    <div *ngFor="let file_id of file_ids; let i = index" class="file-list__item">
      <mat-icon>attach_file</mat-icon>
      <button (click)="removeFile(i)" class="file-list__item__remove">
        <span class="material-symbols-rounded">close</span>
      </button>
    </div>
  </div>

  <div class="big-input">
    <label for="location_id" class="title">{{ 'location' | translate }}</label>
    <select [(ngModel)]="location_id" id="location_id">
      <option [ngValue]="undefined">{{ 'no-location' | translate }}</option>
      <option [ngValue]="location.id" *ngFor="let location of locations">
        {{ location['name_' + translateService.currentLang] || location.name_en }}
      </option>
    </select>
  </div>

  <div class="add-note__buttons">
    <button (click)="close()" class="cancel-button">{{ 'cancel' | translate }}</button>
    <button (click)="submit()" class="button-primary">{{ 'place' | translate }}</button>
  </div>
</div>
