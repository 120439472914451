import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { AddressAutocompleteService } from '@services/address-autocomplete-service';
import { lastValueFrom } from 'rxjs';

export interface AddressGroup {
  streetName: FormControl<string>;
  city: FormControl<string>;
  number: FormControl<string>;
  country: FormControl<string>;
  postalCode: FormControl<string>;
  state: FormControl<string | null>;
}

@Component({
  selector: 'app-address-detail',
  templateUrl: './address-detail.component.html',
  styleUrls: ['./address-detail.component.scss'],
})
export class AddressDetailComponent implements OnInit {
  public addressFormGroup!: FormGroup;

  constructor(
    private readonly zipcodeService: AddressAutocompleteService,
    private readonly controlContainer: ControlContainer
  ) {}

  ngOnInit(): void {
    // Set our addressFormGroup property to the parent control
    // (i.e. FormGroup) that was passed to us, so that our
    // view can data bind to it
    this.addressFormGroup = this.controlContainer.control as FormGroup;
  }

  async onZipcodeChange(event: Event): Promise<void> {
    const inputElement = event.target as HTMLInputElement;
    const postalCode = inputElement.value;
    if (postalCode) {
      try {
        const data = await lastValueFrom(this.zipcodeService.getAddressDetails(postalCode));
        const place = data.placeName;
        const state = data.state;
        const country = data.country;

        this.addressFormGroup.patchValue({
          city: place,
          state: state,
          country: country,
        });
      } catch (error) {
        console.error('Error fetching address details:', error);
      }
    }
  }
}
