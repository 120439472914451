import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextMenuItem } from '@services/context.service';
import { InspectionPopupService } from '../../components/inspection-popup/inspection-popup.service';
import { DossierDetailService } from '../../../building-module/services/dossier-detail.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InspectionList, InspectionsService } from '@api-clients/dossier';
import { UserService } from '@services/user.service';
import { UsersInfoService } from '@api-clients/user';
import { ToastrService } from '@shared/services/toastr.service';

@Component({
  selector: 'app-inspection-overview',
  templateUrl: './inspection-overview.component.html',
  styleUrls: ['./inspection-overview.component.scss'],
})
export class InspectionOverviewComponent implements OnInit {
  buildingId: string | null = '';
  dossierId: string | null = '';
  inspections: InspectionList[] = [];
  filteredInspections: InspectionList[] = [];
  defaultPageSize: number = 10;
  sortBy: string = '';
  sortOrder: number = 1; // 1 = ascending, -1 = descending
  searchText: string = '';
  activeInspection: string = '';
  menu: ContextMenuItem[] = [];
  allUsers: { [p: string]: string } = {};

  breadcrumbTree = [
    {
      translate: 'workspaces-overview-page',
      link: '/forms',
    },
  ];

  columns = [
    { key: 'name', label: 'name' },
    { key: '', label: 'actions' },
  ];

  constructor(
    private readonly router: Router,
    private readonly inspectionsService: InspectionsService,
    protected readonly inspectionPopupService: InspectionPopupService,
    protected readonly dossierDetailService: DossierDetailService,
    public readonly userService: UserService,
    private readonly usersInfoService: UsersInfoService,
    private readonly toastrService: ToastrService
  ) {
    this.dossierDetailService.dossier.pipe(takeUntilDestroyed()).subscribe((dossier) => {
      this.buildingId = dossier.building_id;
      this.dossierId = dossier.id;

      this.reload();
    });
    this.inspectionPopupService.hidden.subscribe(() => {
      this.reload();
    });
  }

  reload(): void {
    this.inspectionsService
      .dossiersDossierIdInspectionGet(this.dossierId!)
      .subscribe((inspections) => {
        this.inspections = inspections.sort(
          (a, b) => Date.parse(b.timestamp_utc) - Date.parse(a.timestamp_utc)
        );
        const userIds = [...new Set(this.inspections.map((i) => i.user_id))];
        this.usersInfoService.get(userIds).subscribe((users) => {
          this.allUsers = users;
        });
        this.search();
      });
  }

  async ngOnInit(): Promise<void> {
    this.buildMenu();
  }

  showInspectionPopup(): void {
    this.inspectionPopupService.show();
  }

  scrollDown(): void {
    window.scrollTo(0, document.body.scrollHeight);
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'edit',
        icon: 'edit',
        action: (): void => this.navigateToInspections(this.activeInspection),
      },
      {
        name: 'finish',
        icon: 'check_small',
        action: (): void => this.finishInspection(this.activeInspection),
      },
    ];
  }

  finishInspection(inspectionId: string): void {
    if (!this.dossierId) return;
    const dossierId = this.dossierId;
    this.inspectionsService
      .dossiersDossierIdInspectionIdGet(dossierId, inspectionId)
      .subscribe((inspection) => {
        if (inspection.finished_time_utc === null) {
          this.inspectionsService
            .dossiersDossierIdInspectionFinishPut(dossierId, inspection)
            .subscribe(() => {
              this.toastrService.showSuccess(
                'inspection_finished_description',
                'inspection_finished_header'
              );
              this.reload();
            });
        } else {
          this.toastrService.showFailure(
            'inspection_already_finished_description',
            'inspection_already_finished_header'
          );
        }
      });
  }

  setActive(inspectionId: string): void {
    this.activeInspection = inspectionId;
    this.buildMenu();
  }

  updateInspections(page, size, inspections = this.inspections): void {
    this.filteredInspections = inspections.slice((page - 1) * size, page * size);
  }

  onPageChange(event): void {
    this.updateInspections(event.page, event.pageSize);
  }

  toggleSort(property: string): void {
    if (this.sortBy === property) {
      this.sortOrder = -this.sortOrder;
    } else {
      // reset to ascending if a different header is clicked
      this.sortBy = property;
      this.sortOrder = 1;
    }

    this.inspections = this.inspections.slice().sort((a: InspectionList, b: InspectionList) => {
      // the OR case is to catch empty cells
      const nameA = (a?.[property] || '').toLocaleLowerCase();
      const nameB = (b?.[property] || '').toLocaleLowerCase();
      return this.sortOrder === 1 ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });
    this.search();
  }

  search(): void {
    if (this.searchText != '') {
      const searchValue = this.searchText.toLocaleLowerCase();

      this.filteredInspections = this.inspections.filter((inspection) => {
        return inspection.description?.toLocaleLowerCase().match(searchValue);
      });
    } else {
      this.updateInspections(1, JSON.parse(localStorage.getItem('pageSize') || 'null') || 10);
    }
    this.updateInspections(
      1,
      JSON.parse(localStorage.getItem('pageSize') || 'null') || 10,
      this.filteredInspections
    );
  }

  navigateToInspections(inspectionId: string): void {
    //This eventually needs to be "go to form inside buildingId if it exists in the form, else go to form without buildingId"
    if (this.buildingId) {
      void this.router.navigate(['/buildings/' + this.buildingId + '/inspections/' + inspectionId]);
    } else {
      void this.router.navigate(['/inspections/' + inspectionId]);
    }
  }

  goToBuilding(): void {
    if (this.buildingId) {
      void this.router.navigate(['/buildings/' + this.buildingId]);
    } else {
      //DUMMY code this should never happen in the end product.
      //The end product should only have goToBuilding() if buildingId is not null on the form.
      console.error('No buildingId found');
      void this.router.navigate(['/buildings']);
    }
  }

  getOwnInspections(): InspectionList[] {
    return this.inspections.filter((i) => i.user_id === this.userService.userId).slice(0, 4);
  }
}
