import { Component, Input } from '@angular/core';
import { ChooseTemplatePopupService } from './choose-template/choose-template-popup.service';
import { InspectionService } from '../../services/inspection.service';
import { InspectionSection, InspectionTemplateSection } from '@api-clients/dossier';
import { ChooseTemplatePopupComponent } from './choose-template/choose-template-popup.component';

@Component({
    selector: 'app-inspection-toolbox',
    templateUrl: './toolbox.component.html',
    styleUrl: './toolbox.component.scss',
    imports: [ChooseTemplatePopupComponent]
})
export class ToolboxComponent {
  @Input() edit = false;
  @Input() currentSection: InspectionTemplateSection | InspectionSection | undefined;

  constructor(
    private readonly chooseTemplatePopupService: ChooseTemplatePopupService,
    private readonly inspectionService: InspectionService
  ) {}

  async insertSection(): Promise<void> {
    await this.inspectionService.insertSection();
  }

  async insertTemplate(): Promise<void> {
    this.chooseTemplatePopupService.show();
  }

  insertCheckList(): void {
    this.inspectionService.insertCheckList();
  }

  insertRadioList(): void {
    this.inspectionService.insertRadioList();
  }

  insertOpenQuestion(): void {
    this.inspectionService.insertOpenQuestion();
  }

  insertBooleanQuestion(): void {
    this.inspectionService.insertBooleanQuestion();
  }

  insertNumberQuestion(): void {
    this.inspectionService.insertNumberQuestion();
  }

  insertImageListQuestion(): void {
    this.inspectionService.insertImageListQuestion();
  }
}
