<div class="changes-summary">
  <div class="changes-summary-header">
    <h1 class="h6">{{ 'changes' | translate }}</h1>
  </div>
  <div
    class="changes-summary-content"
    [style.max-height.px]="availableHeight ? availableHeight - 16 : ''">
    <ng-container *ngFor="let unsavedElement of unsavedElements">
      <div *ngIf="isInstanceOfChangedElement(unsavedElement)">
        <ng-container *ngFor="let property of unsavedElement.originalValues | keyvalue">
          <button
            #propertyButton
            (mousedown)="onMouseDown($event)"
            (mousemove)="onMouseMove($event)"
            (mouseup)="activateElement(unsavedElement)"
            class="changes-summary-content-item">
            <button
              (click)="removePropertyChange(unsavedElement, property.key, $event, propertyButton)"
              class="material-symbols-rounded close">
              close
            </button>

            <h3 class="h6">
              {{ getTypeName(unsavedElement) | lowercase | translate }}
            </h3>

            <ng-container [ngSwitch]="property.key">
              <p *ngSwitchCase="'products'">
                {{ 'products-changed' | translate }}
              </p>

              <app-geometry-summary
                *ngSwitchCase="'geometry'"
                [old]="unsavedElement.originalValues.get('geometry')"
                [new]="unsavedElement.element.properties['geometry']"></app-geometry-summary>

              <app-degradation-summary
                *ngSwitchCase="'degradation'"
                [old]="unsavedElement.originalValues.get('degradation')"
                [new]="unsavedElement.element.properties['degradation']"></app-degradation-summary>

              <p *ngSwitchDefault>
                {{ property.key | lowercase | translate }}
                {{
                  property.value
                    ? ('from' | translate) + ' ' + (property.value.toString() | translate)
                    : ''
                }}
                {{ 'changed_to' | translate }}
                {{ unsavedElement.element.properties[property.key]?.toString() ?? '' | translate }}
              </p>
            </ng-container>
          </button>
        </ng-container>

        <button
          #categoryButton
          (click)="activateElement(unsavedElement)"
          *ngIf="unsavedElement.originalCategory as originalCategory"
          class="changes-summary-content-item">
          <button
            (click)="removeCategoryChange(unsavedElement, $event, categoryButton)"
            class="material-symbols-rounded close">
            close
          </button>
          <h3 class="h6">
            {{ getTypeName(unsavedElement) | lowercase | translate }}
          </h3>
          <p>
            {{ 'category' | translate }}
            {{ ('from' | translate) + ' ' + (originalCategory.toString() | lowercase | translate) }}
            {{ 'changed_to' | translate }}
            {{ unsavedElement.element['category'].toString() ?? '' | lowercase | translate }}
          </p>
        </button>
      </div>

      <div *ngIf="isInstanceOfAddedElement(unsavedElement)">
        <button
          #newElementButton
          (click)="activateElement(unsavedElement)"
          class="changes-summary-content-item">
          <button
            (click)="removeAddedElement(unsavedElement, $event, newElementButton)"
            class="material-symbols-rounded close">
            close
          </button>
          <h3 class="h6">
            {{ getTypeName(unsavedElement) | lowercase | translate }}
          </h3>
          <p>
            {{ 'newly-added' | translate }}
          </p>
          <br />
          <app-geometry-summary
            [old]="unsavedElement.originalValues.get('geometry')"
            [new]="unsavedElement.element.properties['geometry']"></app-geometry-summary>
        </button>
      </div>

      <div *ngIf="isInstanceOfDeletedElement(unsavedElement)">
        <button #deleteElementButton class="changes-summary-content-item">
          <button
            (click)="undeleteElement(unsavedElement, $event, deleteElementButton)"
            class="material-symbols-rounded close">
            close
          </button>
          <h3 class="h6">
            {{ getTypeName(unsavedElement) | lowercase | translate }}
          </h3>
          <p>
            {{ 'remove' | translate }}
          </p>
        </button>
      </div>
    </ng-container>
  </div>
</div>
