import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Matrix4, Quaternion, Vector3 } from 'three';
import { isFloatProperty } from '../../views/model-viewer/property';

type Dimensions = {
  width: number;
  height: number;
  length: number;
};

@Component({
  selector: 'app-geometry-edit',
  templateUrl: './geometry-edit.component.html',
  styleUrl: './geometry-edit.component.scss',
})
export class GeometryEditComponent {
  protected height: number | null = null;
  protected width: number | null = null;
  protected length: number | null = null;

  protected originalHeight: number = 0;
  protected originalWidth: number = 0;
  protected originalLength: number = 0;

  private _box!: number[];
  @Input()
  public get box(): number[] {
    return this._box;
  }

  public set box(value: number[]) {
    if (value !== this._box) {
      this._box = value;
      const dimensions = decompose(value);

      this.height = dimensions.height;
      this.width = dimensions.width;
      this.length = dimensions.length;
    }
  }

  @Output() boxChange = new EventEmitter<{ type: string; value: number[] }>();

  private _originalBox: number[] | undefined = undefined;
  @Input()
  public set originalBox(value: number[]) {
    this._originalBox = value;
    if (this._originalBox === undefined) return;
    const dimensions = decompose(value);

    this.originalHeight = dimensions.height;
    this.originalWidth = dimensions.width;
    this.originalLength = dimensions.length;
  }

  public get originalBox(): number[] | undefined {
    return this._originalBox;
  }

  @Input() disabled: boolean = false;

  fireBoxChange(e: Event): void {
    if (!this._box) return;
    if (
      isNanNullOrZero(this.width) ||
      isNanNullOrZero(this.height) ||
      isNanNullOrZero(this.length)
    ) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }
    // Get position/rotation/scale before changing scale
    const position = new Vector3();
    const rotation = new Quaternion();
    const scale = new Vector3();
    new Matrix4().fromArray(this._box).decompose(position, rotation, scale);

    // Compensate for the change in length so the installation stays glued to its surface
    const lengthChange = this.length - scale.z;
    const lengthCompensation = new Vector3(0, 0, -lengthChange / 2).applyQuaternion(rotation);
    position.add(lengthCompensation);

    const newScale = new Vector3(this.width, this.height, this.length);
    const matrix = new Matrix4().compose(position, rotation, newScale);
    this.boxChange.emit({ type: 'Box', value: matrix.elements });
  }

  protected readonly isFloatProperty = isFloatProperty;
}

function decompose(data: number[]): Dimensions {
  const position = new Vector3();
  const quaternion = new Quaternion();
  const scale = new Vector3();
  new Matrix4().fromArray(data).decompose(position, quaternion, scale);

  return {
    width: parseFloat(scale.x.toFixed(2)),
    height: parseFloat(scale.y.toFixed(2)),
    length: parseFloat(scale.z.toFixed(2)),
  };
}

function isNanNullOrZero(num: number | null): num is null {
  return num === null || isNaN(num) || num === 0;
}
