// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const eq = (prop, value) => ['==', ['get', prop], value];
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const and = (exp1, exp2) => ['and', exp1, exp2];
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const rgba = (r, g, b, a) => `rgba(${r}, ${g}, ${b}, ${a})`;

export const colorRuleMap = [
  eq('thema', 'datatransport'),
  rgba(0, 255, 0, 255),
  eq('thema', 'gasLageDruk'),
  rgba(255, 215, 80, 255),
  eq('thema', 'gasHogeDruk'),
  rgba(255, 175, 60, 255),
  eq('thema', 'buisleidingGevaarlijkeInhoud'),
  rgba(255, 127, 0, 255),
  eq('thema', 'petrochemie'),
  rgba(182, 74, 0, 255),
  eq('thema', 'landelijkHoogspanningsnet'),
  rgba(255, 0, 0, 255),
  eq('thema', 'hoogspanning'),
  rgba(200, 0, 0, 255),
  eq('thema', 'middenspanning'),
  rgba(200, 0, 0, 200),
  eq('thema', 'laagspanning'),
  rgba(200, 0, 0, 150),
  eq('thema', 'rioolVrijverval'),
  rgba(186, 56, 168, 255),
  eq('thema', 'rioolOnderOverOfOnderdruk'),
  rgba(200, 0, 0, 255),
  eq('thema', 'warmte'),
  rgba(128, 0, 128, 255),
  eq('thema', 'wees'),
  rgba(145, 138, 111, 255),
  eq('thema', 'overig'),
  rgba(111, 92, 16, 255),

  // Assen
  eq('layer', 'BTD_groenvoorziening_bosplantsoenV'),
  rgba('197', '203', '92', '255'),
  eq('layer', 'BTD_groenvoorziening_gras__en_kruidachtigenV'),
  rgba('168', '209', '44', '255'),
  eq('layer', 'BTN_groenvoorziening_heestersV'),
  rgba('115', '210', '191', '255'),
  eq('layer', 'BEB BTD_groenvoorziening_plantenV'),
  rgba('201', '103', '12', '255'),
  eq('layer', 'BTD_groenvoorzieningV'),
  rgba('219', '133', '225', '255'),
  eq('layer', 'GBI_luifelV'),
  rgba('201', '197', '172', '255'),
  eq('layer', 'KWD_duikerV'),
  rgba('92', '233', '177', '1'),
  eq('layer', 'KWD_strekdamV'),
  rgba('50', '59', '211', '255'),
  eq('layer', 'OBD_dekV'),
  rgba('100', '33', '223', '255'),
  eq('layer', 'OBT_erfV'),
  rgba('74', '203', '201', '255'),
  eq('layer', 'OBT_gesloten_verharding_cementbetonV'),
  rgba('211', '64', '191', '255'),
  eq('layer', 'OBT_onverhard_zandV'),
  rgba('144', '223', '128', '255'),

  eq('layer', 'OBT_open_verharding_betonstraatstenenV'),
  rgba('112', '235', '164', '255'),
  eq('layer', 'OBT_open_verharding_sierbestratingV'),
  rgba('221', '112', '69', '255'),
  eq('layer', 'OBT_open_verharding_tegelsV'),
  rgba('59', '101', '239', '255'),
  eq('layer', 'OBW_lage_trafoV'),
  rgba('213', '154', '35', '255'),
  eq('layer', 'OBW_overkappingV'),
  rgba('100', '147', '208', '255'),
  eq('layer', 'OWG_berm_begroeidV'),
  rgba('151', '83', '206', '255'),
  eq('layer', 'OWG_berm_groenV'),
  rgba('49', '239', '52', '255'),
  eq('layer', 'OWG_berm_verhardV'),
  rgba('188', '100', '217', '255'),
  eq('layer', 'OWT_oeverV'),
  rgba('217', '41', '21', '255'),
  eq('layer', 'PND_gebouw_grondvlakV'),
  rgba('98', '210', '122', '255'),
  eq('layer', 'SHD_muurV'),
  rgba('223', '40', '59', '255'),
  eq('layer', 'WGD_fietspadV'),
  rgba('78', '158', '204', '255'),
  eq('layer', 'WGD_inritV'),
  rgba('134', '213', '231', '255'),
  eq('layer', 'WGD_parkeervlakV'),
  rgba('234', '140', '186', '255'),
  eq('layer', 'WGD_rijbaan_lokale_wegV'),
  rgba('112', '93', '221', '255'),
  eq('layer', 'WGD_voetpadV'),
  rgba('231', '130', '199', '255'),
  eq('layer', 'WTD_waterloop_griend_en_hakhoutV'),
  rgba('231', '130', '199', '255'),
  eq('layer', 'WTD_waterloop_slootV'),
  rgba('202', '58', '101', '255'),

  // beheerobjecten_ut
  eq('bgt_type', 'BB'),
  rgba('252', '240', '3', '255'),
  eq('bgt_type', 'BC'),
  rgba('153', '21', '100', '255'),
  eq('bgt_type', 'BEB'),
  rgba('228', '26', '28', '255'),
  eq('bgt_type', 'BEB CAPITOOL'),
  rgba('228', '26', '28', '255'),
  eq('bgt_type', 'BEB THERMEN'),
  rgba('228', '26', '28', '255'),
  eq('bgt_type', 'BEREGENINGSPUT'),
  rgba('132', '194', '171', '255'),
  eq('bgt_type', 'BERM'),
  rgba('151', '212', '190', '255'),
  eq('bgt_type', 'BINNENTUIN'),
  rgba('142', '252', '134', '255'),
  eq('bgt_type', 'BLOEMBOLLEN'),
  rgba('181', '212', '94', '255'),
  eq('bgt_type', 'BOOMSPIEGEL'),
  rgba('93', '201', '151', '255'),
  eq('bgt_type', 'BOPH'),
  rgba('235', '148', '18', '255'),
  eq('bgt_type', 'BOS'),
  rgba('64', '123', '30', '255'),
  eq('bgt_type', 'BOSPL'),
  rgba('51', '160', '44', '255'),
  eq('bgt_type', 'CON'),
  rgba('45', '211', '33', '255'),
  eq('bgt_type', 'DAKVEGETATIE'),
  rgba('208', '206', '201', '255'),
  eq('bgt_type', 'DREMPEL'),
  rgba('150', '150', '150', '255'),
  eq('bgt_type', 'DUG OUT'),
  rgba('251', '152', '13', '255'),
  eq('bgt_type', 'EXT'),
  rgba('152', '216', '117', '255'),
  eq('bgt_type', 'EXT-HE'),
  rgba('178', '223', '138', '255'),
  eq('bgt_type', 'EXT-T'),
  rgba('40', '240', '57', '255'),
  eq('bgt_type', 'FIETS'),
  rgba('255', '0', '234', '255'),
  eq('bgt_type', 'FIETS-UT-AF'),
  rgba('231', '40', '123', '255'),
  eq('bgt_type', 'GOPH'),
  rgba('255', '127', '0', '255'),
  eq('bgt_type', 'GREEN TO COLOUR'),
  rgba('39', '121', '215', '255'),
  eq('bgt_type', 'GREPPEL'),
  rgba('190', '215', '227', '255'),
  eq('bgt_type', 'GROEN CAPITOOL'),
  rgba('126', '152', '111', '255'),
  eq('bgt_type', 'GROEN THERMEN'),
  rgba('51', '160', '44', '255'),
  eq('bgt_type', 'GR-W'),
  rgba('158', '230', '106', '255'),
  eq('bgt_type', 'GZA'),
  rgba('85', '240', '38', '255'),
  eq('bgt_type', 'GZA-H'),
  rgba('105', '175', '100', '255'),
  eq('bgt_type', 'GZA-T'),
  rgba('97', '247', '33', '255'),
  eq('bgt_type', 'GZA-Z'),
  rgba('196', '232', '129', '255'),
  eq('bgt_type', 'GZBT'),
  rgba('218', '230', '83', '255'),
  eq('bgt_type', 'HEEM'),
  rgba('227', '192', '33', '255'),
  eq('bgt_type', 'HEI'),
  rgba('255', '0', '234', '255'),
  eq('bgt_type', 'HELOFYTENZONE'),
  rgba('92', '228', '208', '255'),
  eq('bgt_type', 'HG-15'),
  rgba('77', '175', '74', '255'),
  eq('bgt_type', 'HG-20'),
  rgba('64', '123', '30', '255'),
  eq('bgt_type', 'HTR'),
  rgba('227', '44', '148', '255'),
  eq('bgt_type', 'HTR-B'),
  rgba('205', '230', '94', '255'),
  eq('bgt_type', 'IN MUTATIE'),
  rgba('228', '26', '28', '255'),
  eq('bgt_type', 'KLIM'),
  rgba('136', '227', '189', '255'),
  eq('bgt_type', 'KUNSTGRAS'),
  rgba('147', '224', '116', '255'),
  eq('bgt_type', 'KUNSTGRAS ZAND INGESTROOID'),
  rgba('23', '127', '206', '255'),
  eq('bgt_type', 'KUNSTOBJECT'),
  rgba('144', '239', '127', '255'),
  eq('bgt_type', 'LED-WALL'),
  rgba('97', '212', '180', '255'),
  eq('bgt_type', 'LIJNGOOT'),
  rgba('240', '129', '233', '255'),
  eq('bgt_type', 'LUIK'),
  rgba('102', '67', '205', '255'),
  eq('bgt_type', 'MOES'),
  rgba('221', '254', '214', '255'),
  eq('bgt_type', 'MUUR'),
  rgba('255', '0', '234', '255'),
  eq('bgt_type', 'MUUR CAPITOOL'),
  rgba('255', '0', '234', '255'),
  eq('bgt_type', 'MUUR THERMEN'),
  rgba('255', '0', '234', '255'),
  eq('bgt_type', 'OPH'),
  rgba('227', '103', '25', '255'),
  eq('bgt_type', 'PACHT'),
  rgba('206', '75', '14', '255'),
  eq('bgt_type', 'PLANTENBAK'),
  rgba('97', '63', '232', '255'),
  eq('bgt_type', 'PRIVE'),
  rgba('35', '35', '35', '255'),
  eq('bgt_type', 'PUTDEKSEL'),
  rgba('148', '148', '148', '255'),
  eq('bgt_type', 'RHODO'),
  rgba('130', '114', '207', '255'),
  eq('bgt_type', 'ROOSTER'),
  rgba('230', '127', '229', '255'),
  eq('bgt_type', 'SCHK'),
  rgba('208', '195', '73', '255'),
  eq('bgt_type', 'SCHK-WA'),
  rgba('135', '213', '66', '255'),
  eq('bgt_type', 'SCHOONLOOPROOSTER'),
  rgba('124', '124', '124', '255'),
  eq('bgt_type', 'SOL'),
  rgba('51', '160', '44', '255'),
  eq('bgt_type', 'SPEL'),
  rgba('203', '51', '168', '255'),
  eq('bgt_type', 'SPEL BOOT'),
  rgba('100', '204', '224', '255'),
  eq('bgt_type', 'STAPSTENEN'),
  rgba('230', '30', '37', '255'),
  eq('bgt_type', 'SV-A'),
  rgba('88', '27', '231', '255'),
  eq('bgt_type', 'SV-BOOTCAMP'),
  rgba('156', '42', '226', '255'),
  eq('bgt_type', 'SV-GRAVEL HOCKEY EN SOFTBAL'),
  rgba('253', '191', '111', '255'),
  eq('bgt_type', 'SV-GRAVEL TENNIS'),
  rgba('253', '191', '111', '255'),
  eq('bgt_type', 'SV-K'),
  rgba('148', '236', '65', '255'),
  eq('bgt_type', 'SV-KW'),
  rgba('127', '238', '119', '255'),
  eq('bgt_type', 'SV-N'),
  rgba('178', '223', '138', '255'),
  eq('bgt_type', 'SV-N BASTILLE'),
  rgba('178', '223', '138', '255'),
  eq('bgt_type', 'SV-N BASTILLE UITLOOP'),
  rgba('178', '223', '138', '255'),
  eq('bgt_type', 'SV-N DOEL'),
  rgba('97', '247', '33', '255'),
  eq('bgt_type', 'SV-N UTRACK'),
  rgba('178', '223', '138', '255'),
  eq('bgt_type', 'SV-N UTRACK DOEL'),
  rgba('178', '223', '138', '255'),
  eq('bgt_type', 'SV-N UTRACK UITLOOP'),
  rgba('159', '224', '126', '255'),
  eq('bgt_type', 'SV-N VERREVELD'),
  rgba('178', '223', '138', '255'),
  eq('bgt_type', 'SV-N VERREVELD DOEL'),
  rgba('132', '229', '121', '255'),
  eq('bgt_type', 'SV-PANNAKOOI'),
  rgba('205', '199', '71', '255'),
  eq('bgt_type', 'SV-S'),
  rgba('18', '97', '206', '255'),
  eq('bgt_type', 'SV-T'),
  rgba('133', '163', '236', '255'),
  eq('bgt_type', 'SV-Z BEACH'),
  rgba('252', '240', '3', '255'),
  eq('bgt_type', 'SV-Z VERSPRINGEN'),
  rgba('252', '240', '3', '255'),
  eq('bgt_type', 'SV-Z WITBREUK'),
  rgba('252', '240', '3', '255'),
  eq('bgt_type', 'TRAFO'),
  rgba('117', '145', '227', '255'),
  eq('bgt_type', 'TRAP'),
  rgba('201', '31', '176', '255'),
  eq('bgt_type', 'UT VLINDER'),
  rgba('105', '197', '222', '255'),
  eq('bgt_type', 'VBAK CARRE'),
  rgba('213', '171', '129', '255'),
  eq('bgt_type', 'VBAK KOELVIJVER'),
  rgba('139', '136', '234', '255'),
  eq('bgt_type', 'VBAK O&O plein'),
  rgba('114', '200', '130', '255'),
  eq('bgt_type', 'VBOUW'),
  rgba('91', '215', '29', '255'),
  eq('bgt_type', 'VH-B'),
  rgba('221', '224', '110', '255'),
  eq('bgt_type', 'VH-BETON'),
  rgba('124', '124', '124', '255'),
  eq('bgt_type', 'VH-BETON STELCON'),
  rgba('137', '114', '114', '255'),
  eq('bgt_type', 'VH-BETON THEATER'),
  rgba('124', '124', '124', '255'),
  eq('bgt_type', 'VH-E'),
  rgba('208', '206', '201', '255'),
  eq('bgt_type', 'VH-E CAPITOOL'),
  rgba('124', '52', '217', '255'),
  eq('bgt_type', 'VH-E DERDEN'),
  rgba('208', '206', '201', '255'),
  eq('bgt_type', 'VH-E ONDER BEBOUWING'),
  rgba('208', '206', '201', '255'),
  eq('bgt_type', 'VH-E ONDER TRAP'),
  rgba('208', '206', '201', '255'),
  eq('bgt_type', 'VH-E THERMEN'),
  rgba('208', '206', '201', '255'),
  eq('bgt_type', 'VH-E TR'),
  rgba('148', '148', '148', '255'),
  eq('bgt_type', 'VH-E Z'),
  rgba('208', '206', '201', '255'),
  eq('bgt_type', 'VH-G'),
  rgba('150', '150', '150', '255'),
  eq('bgt_type', 'VH-H'),
  rgba('148', '148', '148', '255'),
  eq('bgt_type', 'VH-H CAPITOOL'),
  rgba('148', '148', '148', '255'),
  eq('bgt_type', 'VH-H THERMEN'),
  rgba('133', '62', '208', '255'),
  eq('bgt_type', 'VH-MOLGOOT'),
  rgba('208', '206', '201', '255'),
  eq('bgt_type', 'VH-MOLGOOT CAPITOOL'),
  rgba('208', '206', '201', '255'),
  eq('bgt_type', 'VH-PUIN'),
  rgba('208', '206', '201', '255'),
  eq('bgt_type', 'VH-S'),
  rgba('73', '201', '195', '255'),
  eq('bgt_type', 'VH-ZA'),
  rgba('224', '228', '138', '255'),
  eq('bgt_type', 'VIJVER'),
  rgba('88', '169', '222', '255'),
  eq('bgt_type', 'VIJVER BOUWKUNDIG FOLIE MATENWEG PATIO'),
  rgba('31', '120', '180', '255'),
  eq('bgt_type', 'VIJVER BOUWKUNDIG HELOFYTENFILTER HORST'),
  rgba('31', '120', '180', '255'),
  eq('bgt_type', 'VIJVER BOUWKUNDIG HOGEKAMP'),
  rgba('31', '120', '180', '255'),
  eq('bgt_type', 'VIJVER BOUWKUNDIG HOGEKAMP FOLIE'),
  rgba('31', '120', '180', '255'),
  eq('bgt_type', 'VIJVER BOUWKUNDIG HOGEKAMP HELOFYTEN'),
  rgba('31', '120', '180', '255'),
  eq('bgt_type', 'VLND'),
  rgba('0', '0', '0', '255'),
  eq('bgt_type', 'VP'),
  rgba('77', '175', '74', '255'),
  eq('bgt_type', 'WAT'),
  rgba('166', '206', '227', '255'),
  eq('bgt_type', 'WAT DERDEN'),
  rgba('55', '126', '184', '255'),
  eq('bgt_type', 'WERK'),
  rgba('162', '171', '175', '255'),
  eq('bgt_type', 'ZAND DEPOT'),
  rgba('240', '237', '168', '255'),
  eq('bgt_type', 'ZB'),
  rgba('148', '148', '148', '255'),
  eq('bgt_type', 'ZITBANK THEATER'),
  rgba('208', '206', '201', '255'),
  eq('bgt_type', 'ZITSTENEN'),
  rgba('124', '124', '124', '255'),
  eq('bgt_type', 'ZUIL'),
  rgba('11', '201', '97', '255'),
  eq('bgt_type', 'ZWEMBAD'),
  rgba('166', '206', '227', '255'),
  eq('bgt_type', ''),
  rgba('252', '240', '4', '255'),
  eq('bgt_type', 'GZA-INTENSIEF'),
  rgba('75', '196', '57', '255'),
  eq('bgt_type', 'GZA THE GALLERY'),
  rgba('51', '160', '44', '255'),
  eq('bgt_type', 'CON TOT 2 METER'),
  rgba('65', '123', '31', '255'),
  eq('bgt_type', 'BLOEMBOLLEN INTENSIEF GAZON THE GALLERY'),
  rgba('252', '240', '4', '255'),
  eq('bgt_type', 'VH-E ONDER BEBOUWING THE GALLERY'),
  rgba('148', '148', '148', '255'),
  eq('bgt_type', 'VH-E THE GALLERY'),
  rgba('124', '124', '124', '255'),
  eq('bgt_type', 'BEB THE GALLERY'),
  rgba('183', '72', '75', '255'),
  eq('bgt_type', 'CON HOGER DAN 2 METER'),
  rgba('51', '103', '21', '255'),
  eq('bgt_type', 'VH-E FIETS'),
  rgba('210', '109', '163', '255'),
  eq('bgt_type', 'GZA-BT'),
  rgba('71', '81', '84', '255'),
  eq('bgt_type', 'BLOEMBOLLEN EXTENSIEF'),
  rgba('252', '240', '4', '255'),
  eq('bgt_type', 'BLOEMBOLLEN INTENSIEF GAZON'),
  rgba('232', '221', '22', '255'),
  eq('bgt_type', 'GZA-T THE GALLERY'),
  rgba('97', '247', '33', '255'),
  eq('bgt_type', 'EXT-T THE GALLERY'),
  rgba('178', '223', '138', '255'),
  eq('bgt_type', 'VH-B THE GALLERY'),
  rgba('82', '82', '82', '255'),
  eq('bgt_type', 'CON THE GALLERY'),
  rgba('65', '123', '31', '255'),
  eq('bgt_type', 'HG-15 THE GALLERY'),
  rgba('57', '196', '101', '255'),
  eq('bgt_type', 'EXT-T THE GALLERY'),
  rgba('190', '207', '80', '255'),
  eq('bgt_type', 'VH-E TR THE GALLERY'),
  rgba('204', '204', '204', '255'),
  eq('bgt_type', 'MUUR THE GALLERY'),
  rgba('141', '90', '153', '255'),
  eq('bgt_type', 'VH-G FIETS'),
  rgba('194', '94', '0', '255'),
  eq('bgt_type', 'VH-S FIETS'),
  rgba('210', '109', '163', '255'),
];
