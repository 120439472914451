/**
 * Product API
 * This api is used to query for products in both the 2ba and madaster data.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductSource } from './productSource';
import { Dimensions } from './dimensions';


export interface ProductDetails { 
    description: string;
    gtin?: string;
    manufacturer_gln: string;
    product_code: string;
    product_source: ProductSource;
    product_source_id: number;
    cdn?: string;
    deeplink?: string;
    manufacturer_name?: string;
    country_of_production?: Array<string>;
    weight?: number;
    weight_unit?: string;
    dimensions?: Dimensions;
}
export namespace ProductDetails {
}


