import { Component, InjectionToken, Injector, OnInit, Type } from '@angular/core';
import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { ProjectDto, ProjectsService, ProjectStatus } from '@api-clients/project';
import { ColorPickerComponent } from '@shared/components/color-picker/color-picker.component';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { ProjectDashboardTileComponent } from './dashboard-tile/dashboard-tile.component';
import { NgComponentOutlet } from '@angular/common';
import { MeasuresTileComponent } from './measures-tile/measures-tile.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

interface Tile<T = unknown> {
  id: string;
  title: string;
  content: Type<T>;
  width?: string;
  buttonTitle?: string;
  buttonLink?: string;
}

export const PROJECT_ID = new InjectionToken<string>('project.id');

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  imports: [BreadcrumbComponent, CdkDropList, ProjectDashboardTileComponent, NgComponentOutlet, TranslateModule],
})
export class ProjectDashboardComponent implements OnInit {
  protected projectId: string | null = '8e91c995-2a16-4eb3-9a32-05d289bff513';
  protected tileInjector: Injector;
  protected project: ProjectDto | undefined = undefined;

  //Color picker is temp for demo showcase
  tiles: Tile[] = [
    { id: 'test', title: 'Test', content: ColorPickerComponent, width: 'full' },
    { id: 'wow', title: 'Wow', content: ColorPickerComponent },
    { id: 'titeltje', title: 'Titeltje', content: ColorPickerComponent },
    {
      id: 'measures',
      title: 'Measures',
      content: MeasuresTileComponent,
      width: 'full',
      buttonTitle: 'add-measures',
      buttonLink: 'measures',
    },
  ];

  constructor(
    private readonly injector: Injector,
    private readonly route: ActivatedRoute,
    private readonly projectsService: ProjectsService,
    private readonly router: Router
  ) {
    this.projectId = this.route.snapshot.paramMap.get('id');

    this.tileInjector = Injector.create({
      providers: [{ provide: PROJECT_ID, useValue: this.projectId }],
      parent: injector,
    });

    if (!this.projectId) return;

    this.projectsService.projectsIdGet(this.projectId).subscribe((project) => {
      this.project = project;
    });
  }

  ngOnInit(): void {
    const savedTilesOrder = localStorage.getItem('project-dashboard-tiles-order');
    if (savedTilesOrder) {
      const storedOrder = JSON.parse(savedTilesOrder) as string[];
      this.tiles = this.restoreTileOrder(storedOrder, this.tiles);
    }
  }

  drop(event: CdkDragDrop<ProjectDashboardTileComponent[]>): void {
    moveItemInArray(this.tiles, event.previousIndex, event.currentIndex);
    const tileOrder = this.tiles.map((tile) => tile.id);
    localStorage.setItem('project-dashboard-tiles-order', JSON.stringify(tileOrder));
  }

  private restoreTileOrder(savedOrder: string[], defaultTiles: Tile[]): Tile[] {
    const tileMap = new Map(defaultTiles.map((tile) => [tile.id, tile]));
    const sortedTiles: Tile[] = savedOrder
      .map((id) => tileMap.get(id))
      .filter((tile): tile is Tile => tile !== undefined);

    const newTiles = defaultTiles.filter((tile) => !savedOrder.includes(tile.id));
    return [...sortedTiles, ...newTiles];
  }

  openDetail(): void {
    void this.router.navigate(['project', this.projectId]);
  }

  protected readonly ProjectStatus = ProjectStatus;
  protected readonly Object = Object;
}
