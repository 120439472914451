import { Injectable } from '@angular/core';
import { OrganizationsService } from '@api-clients/user';
import { TinyColor } from '@ctrl/tinycolor';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(private readonly organizationService: OrganizationsService) {
    //get colors out of localstorage
    const primaryColor = localStorage.getItem('primaryColor');
    const secondaryColor = localStorage.getItem('secondaryColor');
    if (primaryColor) {
      this.changeColor(primaryColor, 'primary');
    }
    if (secondaryColor) {
      this.changeColor(secondaryColor, 'secondary');
    }
  }

  public checkAndApplyTheme(organizationId: string): void {
    this.organizationService.getOrganization(organizationId).subscribe((organization) => {
      if (organization.primaryThemeColorHex) {
        this.changeColor(organization.primaryThemeColorHex, 'primary');
        localStorage.setItem('primaryColor', organization.primaryThemeColorHex);
      }
      if (organization.secondaryThemeColorHex) {
        this.changeColor(organization.secondaryThemeColorHex, 'secondary');
        localStorage.setItem('secondaryColor', organization.secondaryThemeColorHex);
      }
    });
  }

  changeColor(color: string, type: string): void {
    const usedColor = new TinyColor(color);
    const baseHsl = usedColor.toHsl();

    // Define relative adjustments for saturation and lightness
    const shades = {
      ['--' + type + '-100']: { s: -0.086, l: 0.253 },
      ['--' + type + '-200']: { s: -0.154, l: 0.2 },
      ['--' + type + '-300']: { s: -0.214, l: 0.13 },
      ['--' + type + '-400']: { s: -0.135, l: 0.07 },
      ['--' + type + '-500']: { s: 0.0, l: 0.0 }, // Base color
      ['--' + type + '-600']: { s: -0.037, l: -0.15 },
      ['--' + type + '-700']: { s: 0.048, l: -0.3 },
      ['--' + type + '-800']: { s: 0.162, l: -0.45 },
      ['--' + type + '-900']: { s: 0.816, l: -0.55 },
    };

    const MAX_LIGHTNESS = 0.98; // Upper bound for lightness
    const MIN_LIGHTNESS = {
      ['--' + type + '-100']: 0.96,
      ['--' + type + '-200']: 0.85,
      ['--' + type + '-300']: 0.75,
      ['--' + type + '-400']: 0.65,
    };
    const MIN_SATURATION = 0.1; // Minimum saturation for all shades

    for (const [key, adjustment] of Object.entries(shades)) {
      let shade = usedColor.toHexString();
      if (key !== '--' + type + '-500') {
        const minLightness = MIN_LIGHTNESS[key] || 0.1; // Use specific thresholds for lighter shades or default to 0.1
        const adjustedLightness = Math.max(
          minLightness,
          Math.min(MAX_LIGHTNESS, baseHsl.l + adjustment.l)
        ); // Clamp lightness
        const adjustedSaturation = Math.max(MIN_SATURATION, Math.min(1, baseHsl.s + adjustment.s)); // Clamp saturation with a minimum threshold

        shade = new TinyColor({
          h: baseHsl.h, // Keep the hue constant
          s: adjustedSaturation,
          l: adjustedLightness,
        }).toHexString();
      }

      document.documentElement.style.setProperty(key, shade);
      document.documentElement.style.setProperty(
        key + '--contrast',
        new TinyColor(shade).isLight() ? '#000' : '#fff'
      );
    }
  }
}
