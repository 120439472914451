import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TimeLineDto } from '@api-clients/dossier';
import { FormControl } from '@angular/forms';
import { TimelineService } from '../../services';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { TimelineEventComponent } from '../timeline-event/timeline-event.component';

@Component({
    selector: 'app-dossier-timeline',
    templateUrl: './dossier-timeline.component.html',
    styleUrl: './dossier-timeline.component.scss',
    imports: [
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        NgIf,
        TimelineEventComponent,
    ],
})
export class DossierTimelineComponent {
  @Input() control: FormControl = new FormControl();
  @Input() smallVersion: boolean = false;

  @Output() selectedTimelineItem: TimeLineDto | undefined;
  @Output() timelineEventClick: EventEmitter<TimeLineDto> = new EventEmitter<TimeLineDto>();
  @Output() imageClick: EventEmitter<{ item: TimeLineDto; imageId: string }> = new EventEmitter<{
    item: TimeLineDto;
    imageId: string;
  }>();

  @Output() fileClick: EventEmitter<{ item: TimeLineDto; fileId: string }> = new EventEmitter<{
    item: TimeLineDto;
    fileId: string;
  }>();

  constructor(public timelineService: TimelineService) {}

  emitImageClick(item: TimeLineDto, imageId: string): void {
    this.imageClick.emit({ item, imageId });
  }

  emitFileClick(item: TimeLineDto, fileId: string): void {
    this.fileClick.emit({ item, fileId });
  }
}
