import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ConfirmationDialogService } from './confirmation-dialog.service';
import { Subscription } from 'rxjs';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrl: './confirmation-dialog.component.scss',
    imports: [NgIf, TranslateModule],
})
export class ConfirmationDialogComponent implements OnDestroy {
  @Input() title: string = '';
  @Input() description: string | undefined;
  @Input() icon: string = '';
  @Input() confirmText: string = '';
  @Input() remove: boolean = false;
  @Output() confirmOutput = new EventEmitter();
  private subscription: Subscription;
  active: boolean = false;

  constructor(private confirmationDialogService: ConfirmationDialogService) {
    this.subscription = this.confirmationDialogService.dialogStatus$.subscribe((status) => {
      this.active = status.active;
    });
  }

  confirm(): void {
    this.confirmOutput.emit();
    this.confirmationDialogService.close();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  cancel(): void {
    this.confirmationDialogService.close();
  }

  keyDown(e: KeyboardEvent): void {
    if (e.key == 'Escape') {
      this.confirmationDialogService.close();
    }
  }
}
