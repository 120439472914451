import {
  AddressableUnitReference,
  MeasureSubjectAddressableUnitReference,
  MeasureSubjectBimElementReference,
  MeasureSubjectBimReference,
  MeasureSubjectRealEstateBuildingReference,
  RealEstateBuildingReference,
} from '@api-clients/project';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { AddressableUnitDto } from '@api-clients/real-estate';

// Type Guard function to check if it's a BuildingOverviewEntry
export function isBuilding(
  entry: BuildingOverviewEntry | AddressableUnitDto
): entry is BuildingOverviewEntry {
  return (entry as BuildingOverviewEntry).real_estate_id !== undefined;
}

// Type Guard function to check if it's an AddressableUnitDto
export function isAddressableUnit(
  entry: BuildingOverviewEntry | AddressableUnitDto
): entry is AddressableUnitDto {
  return (entry as AddressableUnitDto).id !== undefined;
}

export function isRealEstateBuildingReference(
  entry: RealEstateBuildingReference | AddressableUnitReference
): entry is RealEstateBuildingReference {
  return (entry as RealEstateBuildingReference).real_estate_building_id !== undefined;
}

export function isMeasureRealEstateBuildingReference(
  entry:
    | MeasureSubjectRealEstateBuildingReference
    | MeasureSubjectAddressableUnitReference
    | MeasureSubjectBimReference
    | MeasureSubjectBimElementReference
): entry is MeasureSubjectRealEstateBuildingReference {
  return (entry as MeasureSubjectRealEstateBuildingReference).real_estate_building_id !== undefined;
}

export function isMeasureAddressableUnitReference(
  entry:
    | MeasureSubjectRealEstateBuildingReference
    | MeasureSubjectAddressableUnitReference
    | MeasureSubjectBimReference
    | MeasureSubjectBimElementReference
): entry is MeasureSubjectAddressableUnitReference {
  return (entry as MeasureSubjectAddressableUnitReference).addressable_unit_id !== undefined;
}

export function isAddressableUnitReference(
  entry: RealEstateBuildingReference | AddressableUnitReference
): entry is AddressableUnitReference {
  return (entry as AddressableUnitReference).addressable_unit_id !== undefined;
}
