<div class="measures-detail-page">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb></app-breadcrumb>

      <h1 class="h4">{{ 'project-module.measure.page' | translate }}</h1>
    </div>
  </div>

  <div class="measures-detail-page__content">
    <!--suppress JSIncompatibleTypesComparison -->

    <div class="measures-detail-page__content__settings">
      <h1 class="h4">{{ 'measures' | translate }}</h1>

      <button
        (click)="edit()"
        *ngIf="!editMode && enrichedSubjects.length > 0"
        class="button-primary">
        {{ 'edit' | translate }}
      </button>

      <button
        (click)="save()"
        [disabled]="pendingChanges.length === 0 || isSaving"
        *ngIf="editMode && pendingChanges.length > 0"
        class="button-primary"
        [class.has-icon]="isSaving">
        <span *ngIf="isSaving" class="material-symbols-rounded spin">progress_activity</span>
        ({{ pendingChanges.length }}) {{ 'changes' | translate }}
      </button>

      <button
        (click)="cancel()"
        *ngIf="editMode && pendingChanges.length === 0"
        class="button-primary">
        {{ 'cancel' | translate }}
      </button>
    </div>

    @if (enrichedSubjects.length === 0) {
      <div class="no-subjects">{{ 'no-subjects-added' | translate }}</div>
    } @else {
      <div class="measures-detail-page__header">
        <h3>{{ 'addresses' | translate }}</h3>
        <h3>{{ 'measures' | translate }}</h3>
        <h3>{{ 'number' | translate }}</h3>
        <h3>{{ 'unit' | translate }}</h3>
        <h3>{{ 'average_costs' | translate }}</h3>
      </div>

      @for (subject of enrichedSubjects; let i = $index; track subject) {
        @let subjectKey = JSON.stringify(subject.subject);

        <button class="unit" (click)="activeUnit = i" [class.active]="activeUnit === i">
          <div class="unit__header">
            <app-unit-pin
              [title]="subject.name"
              [style]="'secondary'"
              [count]="
                editMode && getPendingChangesForSubject(subject).length > 0
                  ? getPendingChangesForSubject(subject).length
                  : ''
              "></app-unit-pin>

            <span class="material-symbols-rounded arrow">keyboard_arrow_down</span>
          </div>

          <div class="unit__content">
            @if (hasCheckedMeasures(subjectKey) || editMode) {
              @for (
                projectMeasure of projectMeasureList;
                track projectMeasure.measure_id ?? projectMeasure.measure_template_id
              ) {
                @let checked = projectMeasure.subjects.includes(subjectKey);
                @if (checked || editMode) {
                  <label
                    for="{{ subject.name + projectMeasure.measure_id }}"
                    class="unit__content__measure">
                    <div>
                      @if (editMode) {
                        <input
                          type="checkbox"
                          class="checkbox"
                          id="{{ subject.name + projectMeasure.measure_id }}"
                          [checked]="checked"
                          (change)="toggle($event, projectMeasure, subject)" />
                      }
                      <span>
                        {{ projectMeasure.description }}
                        <app-info-bubble
                          [small]="true"
                          [message]="
                            showFriendlyFormula(
                              projectMeasure.calculations[0],
                              projectMeasure.measureValues,
                              subjectKey
                            )
                          "></app-info-bubble>
                      </span>
                    </div>
                    @if (projectMeasure.evaluated_calculations && checked) {
                      @let calculation = projectMeasure.evaluated_calculations[subjectKey];
                      <div class="unit">
                        {{
                          getMeasureValueForVariable(
                            subjectKey,
                            projectMeasure.variables[0].name,
                            projectMeasure.measureValues
                          )
                        }}
                      </div>
                      <div class="unit-type">{{ projectMeasure.calculations[0].unit }}</div>
                      <div class="price" *ngIf="calculation.min > 0">
                        € {{ calculation.min | number: '1.0-2' : 'nl-NL' }} -
                        {{ calculation.max | number: '1.0-2' : 'nl-NL' }}
                      </div>
                      <div class="price" *ngIf="calculation.min === 0">-</div>
                    }
                  </label>
                }
              }
            } @else {
              <div class="no-measures">{{ 'no-measures-added' | translate }}</div>
            }
          </div>

          <div class="unit__total" *ngIf="hasCheckedMeasures(subjectKey)">
            @let subject_summary = getSummaryForSubject(subjectKey, projectMeasureList);
            <span class="unit__total__price" *ngIf="subject_summary.min > 0">
              € {{ subject_summary.min | number: '1.0-2' : 'nl-NL' }} -
              {{ subject_summary.max | number: '1.0-2' : 'nl-NL' }}
            </span>
            <span class="unit__total__price" *ngIf="subject_summary.min === 0">-</span>
          </div>
        </button>
      }

      <div class="units-total">
        @let summary = getSummaryForAll();
        <span class="units-total__title">{{ 'total' | translate }}:</span>
        <span class="units-total__price" *ngIf="summary.min > 0">
          € {{ summary.min | number: '1.0-2' : 'nl-NL' }} -
          {{ summary.max | number: '1.0-2' : 'nl-NL' }}
        </span>
        <span class="units-total__price" *ngIf="summary.min === 0">-</span>
      </div>
    }
  </div>
</div>

<app-variables-popup></app-variables-popup>
