/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MediaSubjectRealEstateBuildingReference { 
    real_estate_building_id: string;
    type: MediaSubjectRealEstateBuildingReference.TypeEnum;
}
export namespace MediaSubjectRealEstateBuildingReference {
    export type TypeEnum = 'RealEstateBuilding' | 'AddressableUnit' | 'Bim' | 'BimElement' | 'Project';
    export const TypeEnum = {
        RealEstateBuilding: 'RealEstateBuilding' as TypeEnum,
        AddressableUnit: 'AddressableUnit' as TypeEnum,
        Bim: 'Bim' as TypeEnum,
        BimElement: 'BimElement' as TypeEnum,
        Project: 'Project' as TypeEnum
    };
}


