import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { BreadcrumbService, TreeItem } from '@shared/services/breadcrumb-service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  imports: [RouterLink, NgIf, TranslateModule],
})
export class BreadcrumbComponent {
  protected tree: (TreeItem | undefined)[] = [];

  constructor(
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbService.tree$.subscribe((tree) => {
      this.tree = tree;
    });
  }

  changeRouteSection(link: string, queryParams?: Record<string, string>): void {
    void this.router.navigate([link], { queryParams });
  }
}
