import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MapLayerExtendedDto } from '@shared/components/viewer/map-2d/map-2d.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  MatTree,
  MatTreeNode,
  MatTreeNodeDef,
  MatTreeNodePadding,
  MatTreeNodeToggle,
} from '@angular/material/tree';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';

/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'map2d-custom-layers-legend',
  templateUrl: 'custom-layers-legend.component.html',
  styleUrls: ['custom-layers-legend.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatTree,
    MatTreeNode,
    MatCheckbox,
    MatTreeNodeDef,
    MatTreeNodePadding,
    MatTreeNodeToggle,
    MatIconButton,
    MatIcon,
    NgIf,
  ],
})
export class Map2DLegendComponent {
  private _source: MapLayerExtendedDto[] = [];
  @Input() active = false;

  @Input() get source(): MapLayerExtendedDto[] {
    return this._source;
  }

  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

  set source(value: MapLayerExtendedDto[]) {
    this._source = value;

    if (value.length === 0) return;

    const selectedLayers =
      JSON.parse(localStorage.getItem('map2d.selectedLayers') || 'null') ||
      this.source.map((s) => s.id);
    //const selection = this.source.filter((s) => selectedLayers.includes(s.id));

    // Collect both main layers and subLayers that match selected IDs
    const selection: MapLayerExtendedDto[] = this._source.flatMap((layer) => {
      const selectedSubLayers =
        layer.subLayers?.filter((sub) => selectedLayers.includes(sub.id)) || [];
      return selectedLayers.includes(layer.id) ? [layer, ...selectedSubLayers] : selectedSubLayers;
    });

    this.checklistSelection = new SelectionModel<MapLayerExtendedDto>(
      true /* multiple */,
      selection
    );
    this.setSelected.emit(selection);
  }

  @Output() setSelected = new EventEmitter<MapLayerExtendedDto[]>();

  public checklistSelection!: SelectionModel<MapLayerExtendedDto>;

  childrenAccessor = (node: MapLayerExtendedDto): MapLayerExtendedDto[] => node.subLayers ?? [];

  hasChild = (_: number, node: MapLayerExtendedDto): boolean =>
    !!node.subLayers && node.subLayers.length > 0;

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: MapLayerExtendedDto): boolean {
    const descendants = node.subLayers;
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    const allSelected = descendants.every((child) => this.checklistSelection.isSelected(child));
    return result && !allSelected;
  }

  toggleLayer(node: MapLayerExtendedDto): void {
    this.checklistSelection.toggle(node);
    const selected = this.checklistSelection.isSelected(node);

    for (const subLayer of node.subLayers ?? []) {
      if (selected) {
        this.checklistSelection.select(subLayer);
      } else {
        this.checklistSelection.deselect(subLayer);
      }
    }

    this.setSelection(this.checklistSelection.selected);
  }

  setSelection(selection: MapLayerExtendedDto[]): void {
    localStorage.setItem('map2d.selectedLayers', JSON.stringify(selection.map((s) => s.id)));
    this.setSelected.emit(selection);
  }
}
