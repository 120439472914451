<div class="measure-template-overview">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb></app-breadcrumb>

      <h1 class="h4">{{ 'project-module.measure-template.overview-page' | translate }}</h1>
    </div>

    <div class="header-bar__search">
      <app-authorized-view [permission]="[Resource.MeasureTemplates]" [scope]="Scope.CREATE">
        <button class="button-secondary button--has-icon" (click)="createMeasureTemplate()">
          <span class="material-symbols-rounded">add</span>
          {{ 'project-module.measure-template.create' | translate }}
        </button>
      </app-authorized-view>
      <input
        class="top-search"
        type="search"
        [(ngModel)]="searchText"
        (input)="search()"
        placeholder="{{ 'search' | translate }}" />
    </div>
  </div>

  <app-table-filter>
  </app-table-filter>
  <table class="table responsive-table">
    <thead>
    <tr>
      <ng-container *ngFor="let column of columns">
        <th (click)="toggleSort(column.key)" [class.sortable]="column.key !== undefined">
          {{ column.label | translate }}
          <span
            [class.arrow-active]="sortBy === column.key"
            [class.arrow-down]="sortOrder === SortOrder.Desc"
            class="arrow"></span>
        </th>
      </ng-container>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of filteredData" (click)="openDetail(item)" class="table-link">
      <td [attr.data-title]="'name' | translate">{{ item.name }}</td>
      <td [attr.data-title]="'description' | translate">{{ item.description }}</td>
      <td [attr.data-title]="'creation-time' | translate">{{ item.creation_timestamp_utc }}</td>
      <td [attr.data-title]="'last-modified-time' | translate">{{ item.last_modified_timestamp_utc }}</td>
      <app-authorized-view [permission]="[Resource.Projects]" [scope]="Scope.DELETE">
        <td [attr.data-title]="'remove' | translate" (click)="measureTemplateToRemovePopup($event, item.id)">
          <span class="material-symbols-rounded table-remove">delete</span>
        </td>
      </app-authorized-view>
    </tr>
    </tbody>
  </table>

  <div class="pagination">
    <app-table-pagination
      (pageChange)="onPageChange($event)"
      [total]="totalCount"
      [pageSize]="defaultPageSize"
      [page]="1"></app-table-pagination>
  </div>
</div>

<app-confirmation-dialog
  [title]="'project-module.measure-template-delete-title' | translate"
  [description]="'project-module.measure-template-delete-description' | translate"
  [icon]="'bin'"
  [confirmText]="'project-module.measure-template-delete-confirm' | translate"
  (confirmOutput)="removeMeasureTemplate()"></app-confirmation-dialog>
