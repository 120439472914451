import { Component, Input, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BimPropertyService, TimelineService } from '../../services';
import { Product, ProductsService } from '@api-clients/product';
import { ObjectCategory, RoomCategory } from '@api-clients/bim';
import { isBimObject, isBimRoom } from '../../views/model-viewer/utils/types';
import {
  isBooleanProperty,
  isColorProperty,
  isDegradationProperty,
  isEnumProperty,
  isFloatProperty,
  isGeometryProperty,
} from '../../views/model-viewer/property';
import { ChangedElement, NewElement, UnsavedElement } from '../changes-summary/change';
import { distinctUntilChanged, lastValueFrom, Subject } from 'rxjs';
import { ProductsAddPopupComponent } from '../products-add-popup/products-add-popup.component';
import { Matrix4, Quaternion, Vector3 } from 'three';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-element-popup',
  templateUrl: './element-popup.component.html',
  styleUrls: ['./element-popup.component.scss'],
})
export class ElementPopupComponent {
  @Input() availableHeight = 0;
  protected visible: boolean = false;
  protected selectedElement?: ChangedElement;
  // Todo: Can we make this cleaner? Is there a way to find the categories in the element itself?
  protected categories: string[] = [];
  protected productsMenuState: 'default' | 'add' | 'detail' = 'default';
  protected detailedProduct = new Subject<Product>();
  private dimensionChange?: number[];

  protected animationParams = {
    active: false,
    position: '0px',
    type: '',
  };

  protected elementTab = {
    properties: 'properties',
    dossier: 'dossier',
    addNote: 'add-note',
    products: 'products',
  };

  protected currentTab: string = this.elementTab.properties;
  protected amountOfProducts: number = 0;
  @ViewChild('addProductsPopup')
  protected productAddPopup!: ProductsAddPopupComponent;

  constructor(
    protected readonly bimPropertyService: BimPropertyService,
    protected readonly timelineService: TimelineService,
    protected readonly productService: ProductsService,
    private readonly confirmationDialogService: ConfirmationDialogService
  ) {
    this.bimPropertyService.selectedElement
      .pipe(takeUntilDestroyed(), distinctUntilChanged())
      .subscribe((selectedChangedElement) => {
        this.animateVisibility(selectedChangedElement);
        this.productsMenuState = 'default';
        if (!selectedChangedElement) return;
        if (isBimRoom(selectedChangedElement.element)) {
          this.categories = Object.keys(RoomCategory);
        } else if (isBimObject(selectedChangedElement.element)) {
          this.categories = Object.keys(ObjectCategory);
        }
        // Todo: Make product properties a separate element in ChangedElement

        //get the amount of products
        this.amountOfProducts = selectedChangedElement.getProducts().length;
      });
  }

  protected updateShoppingCart(event?: Event): void {
    if (event) {
      this.cartAnimate(event);
    }
    if (!this.selectedElement) throw new Error('No selected element. Component should be hidden.');
    void this.bimPropertyService.updateSelectedElement(this.selectedElement);
  }

  protected selectCategory(category: ObjectCategory | RoomCategory): void {
    if (!this.selectedElement) throw new Error('No selected element. Component should be hidden.');
    this.selectedElement.changeCategory(category);
  }

  protected propertyChanged(key: string, newValue: unknown | undefined): void {
    if (!this.selectedElement) throw new Error('No selected element. Component should be hidden.');
    this.selectedElement.changeProperty(key, newValue);
  }

  protected async addProducts(productsToAdd: Product[]): Promise<void> {
    if (!this.selectedElement) throw new Error('No selected element. Component should be hidden.');

    // if we have one new product, see if it has dimensions and then apply these dimensions
    if (
      productsToAdd.length === 1 &&
      isBimObject(this.selectedElement.element) &&
      this.selectedElement.getProducts().length === 0 &&
      this.selectedElement.element.category === 'Installation'
    ) {
      const product = productsToAdd[0];
      const detailedProduct = await lastValueFrom(
        this.productService.productsDetailsSourceIdGet(
          product.product_source,
          product.product_source_id
        )
      );
      if (detailedProduct.dimensions) {
        // In case we have an installation, propose to change the dimensions of the element
        const geometry = this.selectedElement.element.properties['geometry']?.value;
        if (geometry === undefined) return;
        const matrix = new Matrix4().fromArray(geometry);
        const position = new Vector3();
        const rotation = new Quaternion();
        const scale = new Vector3();
        matrix.decompose(position, rotation, scale);

        // Compensate for the change in length so the installation stays glued to its surface
        const lengthChange = detailedProduct.dimensions.depth / 1000 - scale.z;
        const lengthCompensation = new Vector3(0, 0, -lengthChange / 2).applyQuaternion(rotation);
        position.add(lengthCompensation);

        matrix.compose(
          position,
          rotation,
          new Vector3(
            detailedProduct.dimensions.width / 1000,
            detailedProduct.dimensions.height / 1000,
            detailedProduct.dimensions.depth / 1000
          )
        );
        this.dimensionChange = matrix.elements;
        this.confirmationDialogService.open();
      }
    }
    this.selectedElement.addProducts(productsToAdd);
    this.amountOfProducts = this.selectedElement.getProducts().length;
    this.updateShoppingCart();
  }

  protected confirmDimensionChange(): void {
    if (!this.selectedElement) throw 'This function should only run when an element is selected';
    if (!this.dimensionChange) throw 'This function should only run when a new dimension is set';
    this.changeBox(this.selectedElement, this.dimensionChange);
  }

  protected removeProduct(product: Product): void {
    if (!this.selectedElement) throw 'This function should only run when an element is selected';
    this.selectedElement.removeProduct(product);
    this.amountOfProducts = this.selectedElement.getProducts().length;
    this.updateShoppingCart();
  }

  protected openProductsAdd(): void {
    this.productsMenuState = 'add';
  }

  protected openProductDetail(product: Product): void {
    this.productsMenuState = 'detail';
    this.detailedProduct.next(product);
  }

  protected closeProductPopup(): void {
    this.productsMenuState = 'default';
  }

  protected categoryIsDisabled(selectedElement: UnsavedElement): boolean {
    return (
      selectedElement instanceof ChangedElement && selectedElement.originalCategory !== undefined
    );
  }

  protected hasOriginalValue(selectedElement: UnsavedElement): boolean {
    return selectedElement instanceof ChangedElement && selectedElement.originalValues.size > 0;
  }

  cartAnimate(event: Event): void {
    if (this.animationParams.active) return;
    const target = event.target as HTMLInputElement;
    this.animationParams = {
      position: `${target.getBoundingClientRect().top - 80}px`,
      type: target.type,
      active: true,
    };

    setTimeout(() => {
      this.animationParams.active = false;
    }, 550);
  }

  animateVisibility(changedElement: ChangedElement | undefined): void {
    if (changedElement) this.selectedElement = changedElement;
    this.visible = changedElement !== undefined;
    if (!changedElement) {
      setTimeout(() => {
        this.selectedElement = undefined;
      }, 200);
    }
  }

  undo(): void {
    if (!this.selectedElement) throw 'This function should only run when an element is selected';

    this.selectedElement.undo();
    this.amountOfProducts = this.selectedElement.getProducts().length;

    this.updateShoppingCart();
  }

  updateColor(element: ChangedElement, color: string): void {
    element.changeProperty('color', color);
    this.bimPropertyService.updateSelectedElement(element);
  }

  delete(): void {
    if (!this.selectedElement) return;
    if (this.selectedElement instanceof NewElement) {
      this.bimPropertyService.removeAddedElement(this.selectedElement);
    } else {
      this.bimPropertyService.removeChangedElement(this.selectedElement);
    }
  }

  changeBox(element: ChangedElement, data: number[]): void {
    element.changeProperty('geometry', { type: 'Box', value: data });
    this.bimPropertyService.updateSelectedElement(element);
  }

  dossierTimeline(): void {
    if (this.currentTab === this.elementTab.dossier) return;
    this.currentTab = this.elementTab.dossier;
    // TODO: implement dossier filtering on selected element/room
    this.timelineService.resetTimeline();
  }

  protected readonly console = console;
  protected readonly isFloatProperty = isFloatProperty;
  protected readonly isBooleanProperty = isBooleanProperty;
  protected readonly isEnumProperty = isEnumProperty;
  protected readonly isGeometryProperty = isGeometryProperty;
  protected readonly isColorProperty = isColorProperty;
  protected readonly isDegradationProperty = isDegradationProperty;

  protected isInstanceOfAddedElement(element: UnsavedElement): element is NewElement {
    return element instanceof NewElement;
  }
}
