<div [class.active]="active" class="context-wrapper">
  <app-information-container>
    <app-information-description
      title="{{ 'building-filters' | translate }}"></app-information-description>
    <app-information-item [open]="true" title="Categories">
      <div *ngFor="let category of categories">
        <span class="property">{{ category.type | lowercase | translate }}</span>
        <button
          (click)="toggleCategory(category.type)"
          [class.active]="!category.visible"
          class="material-symbols-rounded eye-button">
          visibility
        </button>
      </div>
    </app-information-item>

    <app-information-item *ngIf="levels" [open]="true" title="Levels">
      <div *ngFor="let level of levels">
        <span class="property">{{ level.name }}</span>
        <button
          (click)="toggleLevel(level.level)"
          [class.active]="!level.visible"
          class="material-symbols-rounded eye-button">
          visibility
        </button>
      </div>
    </app-information-item>
  </app-information-container>
</div>
