<div>
  <mat-form-field class="example-form-field">
    <mat-chip-grid #chipGrid [formControl]="formControl">
      @for (subject of value; track subject) {
        @if (isRealEstateBuildingReference(subject)) {
          @let building = buildings.get(subject.real_estate_building_id);
          <mat-chip-row (removed)="remove(subject)">
            {{building?.buildingMetadata?.city}} - {{building?.buildingMetadata?.address}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        }
      }
    </mat-chip-grid>
    <input
      matInput
      #input
      (keyup)="inputChange($event)"
      [placeholder]="'project-module.search-and-attach-building' | translate"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete requireSelection #auto="matAutocomplete" (optionSelected)="add($event.option.value)">
      @for (entry of filteredBuildings$ | async; track entry) {
        @if (isBuilding(entry)) {
          <mat-option [value]="entry"><mat-icon>home</mat-icon>{{entry?.buildingMetadata?.city}} - {{entry?.buildingMetadata?.address}}</mat-option>
          @if (entry.buildingMetadata.addresses.length > 1) {
          }
        }
      }
    </mat-autocomplete>
  </mat-form-field>
</div>
