import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-information-description',
    templateUrl: './information-description.component.html',
    styleUrls: ['./information-description.component.scss'],
    imports: [NgIf]
})
export class InformationDescriptionComponent {
  @Input() title = '';
}
