<p>
  <ng-container *ngIf="oldDegradation && newDegradation">
    <ng-container *ngIf="oldDegradation.estimated_expiry !== newDegradation.estimated_expiry">
      {{ 'estimated_lifespan' | translate }} {{ 'changed' | translate }}:
      <br />
      {{ oldDegradation.estimated_expiry || ('undefined' | translate) }} →
      {{ newDegradation.estimated_expiry || ('undefined' | translate) }}
      <br />
    </ng-container>
    <ng-container *ngIf="oldDegradation.description !== newDegradation.description">
      {{ 'description' | translate }} {{ 'changed' | translate }}:
      <br />
      {{ oldDegradation.description || ('undefined' | translate) }} →
      {{ newDegradation.description || ('undefined' | translate) }}
      <br />
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!oldDegradation && newDegradation">
    <ng-container *ngIf="newDegradation.estimated_expiry">
      {{ 'estimated_lifespan' | translate }}:
      <br />
      {{ newDegradation.estimated_expiry || ('undefined' | translate) }}
      <br />
    </ng-container>
    <ng-container *ngIf="newDegradation.description">
      {{ 'description' | translate }}:
      <br />
      {{ newDegradation.description || ('undefined' | translate) }}
      <br />
    </ng-container>
  </ng-container>

  <ng-container *ngIf="oldDegradation && !newDegradation">
    <ng-container *ngIf="oldDegradation.estimated_expiry">
      {{ 'estimated_lifespan' | translate }}:
      <br />
      {{ oldDegradation.estimated_expiry || ('undefined' | translate) }}
      <br />
    </ng-container>
    <ng-container *ngIf="oldDegradation.description">
      {{ 'description' | translate }}:
      <br />
      {{ oldDegradation.description || ('undefined' | translate) }}
      <br />
    </ng-container>
  </ng-container>
</p>
