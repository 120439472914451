<div class="element-popup">
  <div class="element-popup__wrapper" [class.active]="visible">
    <div class="element-popup__header">
      <h5>
        {{ '3d-model-enrichment' | translate }}
      </h5>
      <div *ngIf="selectedElement">
        <button
          (click)="delete()"
          *ngIf="selectedElement.element['category'] === 'Installation'"
          (keydown)="delete()"
          class="material-symbols-rounded undo">
          delete
        </button>
        <button
          (click)="undo()"
          [disabled]="
            !(
              selectedElement.originalValues.size > 0 ||
              selectedElement.originalCategory !== undefined
            )
          "
          (keydown)="undo()"
          class="material-symbols-rounded undo">
          undo
        </button>
        <button
          (click)="bimPropertyService.selectBimElement(undefined)"
          (keydown)="bimPropertyService.selectBimElement(undefined)"
          class="material-symbols-rounded">
          close
        </button>
      </div>
    </div>
    <div class="element-popup__tabs">
      <button
        (click)="currentTab = elementTab.properties"
        [class.active]="currentTab === elementTab.properties">
        {{ 'add-data' | translate }}
      </button>
      <button
        (click)="dossierTimeline()"
        [class.active]="currentTab === elementTab.dossier || currentTab === elementTab.addNote">
        {{ 'notes' | translate }}
      </button>
      <button
        (click)="currentTab = elementTab.products"
        [class.active]="currentTab === elementTab.products">
        <span *ngIf="amountOfProducts">({{ amountOfProducts }})</span>
        {{ elementTab.products | translate }}
      </button>
    </div>

    <div
      class="element-popup__content"
      [style.max-height.px]="availableHeight ? availableHeight - 136 : ''"
      *ngIf="selectedElement && currentTab === elementTab.properties">
      <div class="big-input category-input" [class.has-change]="selectedElement.originalCategory">
        <label class="title" for="category">
          {{ 'category' | translate }}
        </label>

        <select
          (ngModelChange)="selectCategory($event)"
          [disabled]="hasOriginalValue(selectedElement)"
          [ngModel]="selectedElement.element['category']"
          (change)="updateShoppingCart($event)"
          [class.has-change]="selectedElement.originalCategory"
          id="category">
          <option *ngFor="let item of categories" [value]="item">
            {{ item | lowercase | translate }}
          </option>
        </select>
      </div>
      <div *ngIf="selectedElement.propertyDefinition">
        <div *ngFor="let category of selectedElement.propertyDefinition">
          <app-element-category [title]="category.name | translate">
            <ng-container *ngFor="let property of category.properties">
              <div
                class="big-input"
                *ngIf="isFloatProperty(property)"
                [class.has-change]="selectedElement.originalValues.has(property.name)">
                <label class="title" for="{{ property.name }}">
                  {{ property.name | translate }}
                </label>
                <input
                  (ngModelChange)="propertyChanged(property.name, $event)"
                  [disabled]="categoryIsDisabled(selectedElement)"
                  [ngModel]="selectedElement.element.properties[property.name]"
                  (change)="updateShoppingCart($event)"
                  id="{{ property.name }}"
                  type="number" />

                <label class="units" for="{{ property.name }}">
                  {{ property.unit }}
                </label>
              </div>

              <label
                *ngIf="isBooleanProperty(property)"
                [class.disabled]="categoryIsDisabled(selectedElement)"
                [class.has-change]="selectedElement.originalValues.has(property.name)"
                class="checkboxcontainer big-checkbox">
                {{ property.name || '' | translate }}
                <input
                  (ngModelChange)="propertyChanged(property.name, $event)"
                  [disabled]="categoryIsDisabled(selectedElement)"
                  [ngModel]="selectedElement.element.properties[property.name]"
                  (change)="updateShoppingCart($event)"
                  id="{{ property.name }}"
                  type="checkbox" />
                <span class="checkmark"></span>
              </label>

              <div
                *ngIf="isEnumProperty(property)"
                class="big-input"
                [class.has-change]="selectedElement.originalValues.has(property.name)">
                <label class="title" for="{{ property.name }}">
                  {{ property.name | translate }}
                </label>

                <select
                  (ngModelChange)="propertyChanged(property.name, $event)"
                  [disabled]="categoryIsDisabled(selectedElement)"
                  [ngModel]="selectedElement.element.properties[property.name]"
                  (change)="updateShoppingCart($event)"
                  id="{{ property.name }}">
                  <option [ngValue]="undefined">{{ 'unset' | translate }}</option>
                  <option *ngFor="let item of property.values" [value]="item">
                    {{ item | translate }}
                  </option>
                </select>
              </div>

              <div
                *ngIf="
                  isGeometryProperty(property) && selectedElement.element.properties[property.name]
                "
                class="big-input">
                <span class="geometry-title">
                  {{ property.name | translate }}
                </span>

                <app-geometry-edit
                  [box]="selectedElement.element.properties[property.name].value"
                  [disabled]="categoryIsDisabled(selectedElement)"
                  [originalBox]="
                    $any(selectedElement.originalValues.get(property.name))?.value ?? undefined
                  "
                  (boxChange)="propertyChanged(property.name, $event)"
                  (change)="updateShoppingCart($event)"
                  id="{{ property.name }}"></app-geometry-edit>
              </div>

              <div *ngIf="isDegradationProperty(property)" class="big-input">
                <span class="degradation-title">
                  {{ property.name | translate }}
                </span>

                <app-degradation-edit
                  [properties]="selectedElement.element.properties[property.name]"
                  [originalState]="$any(selectedElement.originalValues.get(property.name))"
                  (change)="updateShoppingCart($event)"
                  (emitExpiryDateChangedEvent)="updateShoppingCart($event)"
                  (emitDegradationChangedEvent)="propertyChanged(property.name, $event)"
                  id="{{ property.name }}"></app-degradation-edit>
              </div>

              <div *ngIf="isColorProperty(property)" class="big-input">
                <span class="title">
                  {{ property.name | translate }}
                </span>

                <app-color-picker
                  [class.has-change]="selectedElement.originalValues.has('color')"
                  [color]="selectedElement.element.properties[property.name] ?? '#c6ed3b'"
                  (colorChange)="updateColor(selectedElement, $event)"
                  (change)="updateShoppingCart($event)"
                  id="{{ property.name }}"></app-color-picker>
              </div>
            </ng-container>
          </app-element-category>
        </div>
      </div>
    </div>

    <div
      *ngIf="currentTab === elementTab.dossier"
      cdkVirtualScrollingElement
      [style.max-height.px]="availableHeight ? availableHeight - 188 : ''"
      class="element-popup__content element-popup__content--timeline">
      <app-dossier-timeline
        (imageClick)="console.log('TODO goto dossier page and scroll to event and select image')"
        (timelineEventClick)="console.log('TODO goto dossier page and scroll to event')"
        [smallVersion]="true"></app-dossier-timeline>
    </div>

    <div *ngIf="currentTab === elementTab.addNote" class="element-popup__content">
      <app-add-note
        (canceled)="currentTab = elementTab.dossier"
        (noteAdded)="currentTab = elementTab.dossier"></app-add-note>
    </div>
    <div
      *ngIf="selectedElement && currentTab === elementTab.products"
      class="element-popup__content"
      [style.max-height.px]="availableHeight ? availableHeight - 136 : ''">
      <div class="element-popup__products">
        <h4 class="element-popup__products__title">
          {{ 'added-products-title' | translate }}
        </h4>
        <span *ngIf="amountOfProducts === 0" class="element-popup__products__no-result">
          {{ 'no-products-added' | translate }}
        </span>
        <ng-container *ngIf="selectedElement.getProducts() as products">
          <ul
            class="element-popup__products__list"
            [class.has-change]="selectedElement.originalValues.has('products')">
            <li *ngFor="let product of products; let i = index">
              <a (click)="openProductDetail(product); $event.preventDefault()" href="#">
                <span class="description">{{ product.description }}</span>
              </a>
              <button (click)="removeProduct(product)">
                <span class="material-symbols-rounded">close</span>
              </button>
            </li>
          </ul>
        </ng-container>
        <button (click)="openProductsAdd()" class="add-button">
          {{ 'add-products' | translate }}
          <span class="material-symbols-rounded">chevron_right</span>
        </button>
      </div>
    </div>

    <div *ngIf="currentTab !== elementTab.addNote" class="element-popup__buttons">
      <button
        (click)="currentTab = elementTab.addNote"
        *ngIf="currentTab === elementTab.dossier"
        class="secondary-button">
        {{ 'add-note' | translate }}
      </button>
    </div>
    <div
      [ngClass]="[animationParams.active ? 'animate' : '', animationParams.type]"
      [style.top]="animationParams.position"
      class="element-popup__added"></div>

    <app-products-add-popup
      #addProductsPopup
      [active]="productsMenuState === 'add' && selectedElement !== undefined"
      (productsAddedChange)="addProducts($event)"
      (productRemovedChange)="removeProduct($event)"
      [currentProducts]="selectedElement?.getProducts()"
      (requestClose)="closeProductPopup()"></app-products-add-popup>
    <app-product-details-popup
      [active]="productsMenuState === 'detail' && selectedElement !== undefined"
      [showProduct]="detailedProduct"
      (requestClose)="closeProductPopup()"></app-product-details-popup>
    <app-confirmation-dialog
      [title]="'take-dimension-from-product-title' | translate"
      [description]="'take-dimension-from-product-description' | translate"
      [confirmText]="'take-dimension-from-product-confirm' | translate"
      (confirmOutput)="confirmDimensionChange()"></app-confirmation-dialog>
  </div>
</div>
