import { SourceSpecification } from '@maplibre/maplibre-gl-style-spec';
import { LayerSpecification } from 'maplibre-gl';
import { FeatureCollection, Point } from 'geojson';

export interface Layer {
  id: string;
  type?: 'fill' | 'line';
  disabled?: boolean;
  subLayers?: string[];
  postGISColumn?: string;
  isMapLayer?: boolean;
}

const allLayers: Layer[] = [];

export const layers = allLayers.filter((layer) => !layer.disabled);

export const clustersLayer: LayerSpecification = {
  id: 'clusters',
  type: 'circle',
  source: 'buildings',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#a8c0c3',
    'circle-radius': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      ['step', ['get', 'point_count'], 32, 100, 42, 250, 52],
      ['step', ['get', 'point_count'], 30, 100, 40, 250, 50],
    ],
  },
};

export const clusterCountLayer: LayerSpecification = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'buildings',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['Plus Jakarta Sans', 'sans-serif'],
    'text-size': 16,
  },
};

export const unclusteredPointLayer: LayerSpecification = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'buildings',
  filter: ['all', ['!', ['has', 'point_count']]],
  paint: {
    'circle-color': ['case', ['boolean', ['feature-state', 'active'], false], '#68888c', '#a8c0c3'],
    'circle-radius': ['case', ['boolean', ['feature-state', 'hover'], false], 16, 15],
  },
};

export const pdokAhn4Layer = (initialVisibility: boolean): LayerSpecification => ({
  id: 'pdok-ahn4',
  type: 'raster',
  source: 'pdok-ahn4',
  layout: {
    visibility: initialVisibility ? 'visible' : 'none',
  },
});

export const brkKadastraalLayer = (initialVisibility: boolean): LayerSpecification => ({
  id: 'brk-kadastraal',
  type: 'raster',
  source: 'brk-kadastraal',
  layout: {
    visibility: initialVisibility ? 'visible' : 'none',
  },
});

export const mapboxSatelliteLayer = (initialVisibility: boolean): LayerSpecification => ({
  type: 'raster',
  id: 'satellite',
  source: 'mapbox-satellite',
  layout: { visibility: initialVisibility ? 'visible' : 'none' },
});

export const pdokAhn: SourceSpecification = {
  type: 'raster',
  tiles: [
    'https://service.pdok.nl/rws/ahn/wms/v1_0?SERVICE=WMS&request=GetMap&layers=dsm_05m&styles=&format=image%2Fpng&transparent=true&version=1.3.0&width=512&height=512&maxZoom=24&maxNativeZoom=22&tileSize=512&zIndex=3&minZoom=18&crs=EPSG%3A3857&bbox={bbox-epsg-3857}',
  ],
  tileSize: 256,
};

export const brkKadastraal: SourceSpecification = {
  type: 'raster',
  tiles: [
    'https://service.pdok.nl/kadaster/kadastralekaart/wms/v5_0?&service=WMS&request=GetMap&layers=KadastraleGrens%2CLabel%2CBijpijling%2CNummeraanduidingreeks%2COpenbareRuimteNaam&styles=standaard%2Cstandaard%2Cstandaard%2Cstandaard%2Cstandaard&format=image%2Fpng&transparent=true&version=1.3.0&width=512&height=512&maxZoom=24&maxNativeZoom=22&tileSize=512&zIndex=3&minZoom=18&attribution=kadastralekaart1&crs=EPSG%3A3857&bbox={bbox-epsg-3857}',
  ],
  tileSize: 512,
};

export const mapboxSatellite: SourceSpecification = {
  type: 'raster',
  url: 'https://api.maptiler.com/tiles/satellite-v2/tiles.json?key=XCli4HbSVDG3WCLxU24r',
  tileSize: 256,
};

export const buildings = (buildings: FeatureCollection<Point>): SourceSpecification => ({
  type: 'geojson',
  data: buildings,
  generateId: true,
  cluster: true,
  clusterMaxZoom: 14, // Max zoom to cluster points. MIND: this should be higher than 14, clustering should be visible when the 3d buildings are shown.
  clusterRadius: 50,
});
