export const environment = {
  ENVIRONMENT_SPECIFIC_PROVIDERS: [],
  AUTH_PATH: 'https://auth.dev.twinspot.com',
  API_BASE_PATH: 'https://api.dev.twinspot.com',
  BAG_3D_TILESET: 'https://data.3dbag.nl/v20240228/3dtiles/lod22/tileset.json',
  KADASTER_2D_MAP: 'https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0?',
  TREX_TILESERVER: 'https://trex.twinspot.com',
  NOMINATIM: 'http://nominatim-service:9419/search.php',
  ADDRESS_API_KEY: 'AIzaSyC55FwIyqOrFpklgVEmLTMl1BIBcTC_PoM',
};
