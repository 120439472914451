<div [formGroup]="addressFormGroup" class="subform">

  <div class="form-group">
    <label for="postal-code">{{ 'postal-code' | translate }}</label>
    <input id="postal-code" class="input" type="text" formControlName="postalCode"
           (focusout)="onZipcodeChange($event)" />
  </div>

  <div class="form-group-row">
    <div class="form-group big">
      <label for="street-name">{{ 'address' | translate }}</label>
      <input id="street-name" class="input" type="text" formControlName="streetName" />
    </div>

    <div class="form-group small">
      <label for="address-number">{{ 'address-number' | translate }}</label>
      <input id="address-number" class="input" type="text" formControlName="number" />
    </div>
  </div>


  <div class="form-group">
    <label for="city">{{ 'city' | translate }}</label>
    <input id="city" class="input" type="text" formControlName="city" />
  </div>

  <div class="form-group">
    <label for="country">{{ 'country' | translate }}</label>
    <input id="country" class="input" type="text" formControlName="country" />
  </div>

  <div class="form-group">
    <label for="state">{{ 'state' | translate }}</label>
    <input id="state" class="input" type="text" formControlName="state" />
  </div>
</div>
