{
  "Door": {
    "categories": [
      {
        "name": "general",
        "properties": [
          {
            "name": "is_external",
            "data_type": "boolean"
          },
          {
            "name": "thermal_transmittance",
            "data_type": "float",
            "unit": "W/(m2K)"
          },
          {
            "name": "products",
            "data_type": "products"
          }
        ]
      }
    ]
  },
  "Roof": {
    "categories": [
      {
        "name": "general",
        "properties": [
          {
            "name": "thermal_transmittance",
            "data_type": "float",
            "unit": "W/(m2K)"
          },
          {
            "name": "products",
            "data_type": "products"
          }
        ]
      }
    ]
  },
  "Stair": {
    "categories": [
      {
        "name": "general",
        "properties": [
          {
            "name": "number_of_riser",
            "data_type": "int"
          },
          {
            "name": "thread_length",
            "data_type": "float",
            "unit": "m"
          }
        ]
      }
    ]
  },
  "Room": {
    "categories": [
      {
        "name": "general",
        "properties": []
      }
    ]
  },
  "Wall": {
    "categories": [
      {
        "name": "general",
        "properties": [
          {
            "name": "thermal_transmittance",
            "data_type": "float",
            "unit": "W/(m2K)"
          },
          {
            "name": "is_external",
            "data_type": "boolean"
          },
          {
            "name": "load_bearing",
            "data_type": "boolean"
          },
          {
            "name": "products",
            "data_type": "products"
          },
          {
            "name": "degradation",
            "data_type": "degradation"
          }
        ]
      }
    ]
  },
  "Window": {
    "categories": [
      {
        "name": "general",
        "properties": [
          {
            "name": "is_external",
            "data_type": "boolean"
          },
          {
            "name": "thermal_transmittance",
            "data_type": "float",
            "unit": "W/(m2K)"
          },
          {
            "name": "products",
            "data_type": "products"
          }
        ]
      },
      {
        "name": "window-specs",
        "properties": [
          {
            "name": "window_type",
            "data_type": "enum",
            "values": ["single_glass", "double_glass", "triple_glass", "hr++"]
          },
          {
            "name": "frame_type",
            "data_type": "enum",
            "values": ["wood", "steel", "plastic", "aluminium"]
          }
        ]
      }
    ]
  },
  "Installation": {
    "categories": [
      {
        "name": "general",
        "properties": [
          {
            "name": "degradation",
            "data_type": "degradation"
          }
        ]
      },
      {
        "name": "appearance",
        "properties": [
          {
            "name": "geometry",
            "data_type": "geometry"
          },
          {
            "name": "color",
            "data_type": "color"
          }
        ]
      }
    ]
  }
}
