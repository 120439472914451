<ng-container>
  <div class="button-bar">
    <button (click)="changeView()" class="button-primary">
        <span class="material-symbols-rounded">
          {{ listView ? 'map' : 'list' }}
        </span>
      {{ (listView ? 'map_view' : 'list_view') | translate }}
    </button>
  </div>
  <app-viewer *ngIf="!listView"></app-viewer>
  <app-list-view *ngIf="listView"></app-list-view>
</ng-container>
