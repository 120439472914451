/**
 * Project API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GenericSubjectAddressableUnitReference { 
    addressable_unit_id: string;
    type: GenericSubjectAddressableUnitReference.TypeEnum;
}
export namespace GenericSubjectAddressableUnitReference {
    export type TypeEnum = 'AddressableUnit';
    export const TypeEnum = {
        AddressableUnit: 'AddressableUnit' as TypeEnum
    };
}


