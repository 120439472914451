"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.freeTypedArray = exports.mallocDoubleArray = void 0;
function mallocDoubleArray(nativeClipperLib, len) {
  var nofBytes = len * Float64Array.BYTES_PER_ELEMENT;
  var ptr = nativeClipperLib._malloc(nofBytes);
  return new Float64Array(nativeClipperLib.HEAPF64.buffer, ptr, len);
}
exports.mallocDoubleArray = mallocDoubleArray;
function freeTypedArray(nativeClipperLib, array) {
  nativeClipperLib._free(array.byteOffset);
}
exports.freeTypedArray = freeTypedArray;
