import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuItem } from '@services/context.service';
import { InspectionList } from '@api-clients/dossier';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { ContextMenuDirective } from '@shared/directives/context-menu.directive';
import { StatusBubbleComponent } from '@shared/components/status-bubble/status-bubble.component';

@Component({
  selector: 'app-inspection-list',
  templateUrl: './inspection-list.component.html',
  styleUrls: ['./inspection-list.component.scss'],
  imports: [NgIf, NgFor, ContextMenuDirective, StatusBubbleComponent, DatePipe],
})
export class InspectionListComponent implements OnInit {
  @Input() inspections: InspectionList[] = [];
  @Input() listTitle: string = '';
  @Input() linkTitle: string = '';
  @Input() allUsers: { [p: string]: string } = {};

  @Output() linkAction = new EventEmitter<InspectionList>();
  @Output() deleteAction = new EventEmitter<InspectionList>();
  @Output() selectedAction = new EventEmitter<string>();

  menu: ContextMenuItem[] = [];
  activeForm: string = '';
  testStatus: Array<string> = ['recieved', 'draft', 'completed', 'sent-out'];

  async ngOnInit(): Promise<void> {
    this.buildMenu();
  }

  buildMenu(): void {
    this.menu = [
      {
        name: 'edit',
        icon: 'edit',
        action: (): void => this.navigateToInspections(this.activeForm),
      },
      {
        name: 'remove',
        icon: 'delete',
        action: (): void => console.log('delete'),
      },
    ];
  }

  setActive(inspectionId: string): void {
    this.activeForm = inspectionId;
    this.buildMenu();
  }

  navigateToInspections(inspectionId: string): void {
    this.selectedAction.emit(inspectionId);
  }
}
