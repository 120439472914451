<label *ngIf="title" [for]="title.toLowerCase().replace(' ', '')">
  {{ title | translate }}
</label>

<div
  class="growing-textarea"
  [ngClass]="theme"
  [class.has-button]="floatingButton"
  [class.has-change]="hasChange"
  [attr.data-replicated-value]="value">
  <textarea
    #noteInput
    name="text"
    [id]="title.toLowerCase().replace(' ', '')"
    (input)="onInput($event)"
    [placeholder]="placeholder | translate"
    rows="1"
    >{{ value | translate }}</textarea
  >

  <button
    *ngIf="floatingButton"
    (click)="buttonClick(noteInput.value)"
    class="floating-button"
    [class.visible]="noteInput.value && noteInput.value.length > 0">
    <span class="material-symbols-rounded">send</span>
  </button>

  <ng-content></ng-content>
</div>
