import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { NgIf, NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-growing-textarea',
    templateUrl: './growing-textarea.component.html',
    styleUrls: ['./growing-textarea.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GrowingTextareaComponent),
            multi: true
        }
    ],
    imports: [NgIf, NgClass, TranslateModule]
})
export class GrowingTextareaComponent implements ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() theme: 'default' | 'primary' = 'default';
  @Input() floatingButton: boolean = false;
  @Input() hasChange: boolean = false;
  @Input() title: string = '';

  @Output() clickFloatingButton: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('noteInput') private noteInput!: ElementRef;

  value: string = '';

  private onChange: (value: string) => void = () => { };
  private onTouched: () => void = () => { };

  writeValue(value: string): void {
    this.value = value || '';
    if (this.noteInput) {
      this.noteInput.nativeElement.value = this.value;
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.noteInput) {
      this.noteInput.nativeElement.disabled = isDisabled;
    }
  }

  onInput(event: Event): void {
    const newValue = (event.target as HTMLTextAreaElement).value;
    this.value = newValue;
    this.onChange(newValue);
    this.onTouched();
  }

  buttonClick(value: string): void {
    const trimmedValue = value.trim();
    if (!trimmedValue) return;

    this.clickFloatingButton.emit(trimmedValue);
    this.value = '';
    this.onChange(this.value);
    this.noteInput.nativeElement.value = '';
    this.noteInput.nativeElement.dispatchEvent(new Event('input'));
  }
}
