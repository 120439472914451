/**
 * BIM API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoomCategory } from './roomCategory';


export interface BimRoomGraphDto { 
    id: string;
    category: RoomCategory;
    levels: Array<string>;
    mesh_id?: number;
}
export namespace BimRoomGraphDto {
}


