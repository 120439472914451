<div class="user-overview">
  <div class="header-bar">
    <div class="header-bar__text">
      <app-breadcrumb></app-breadcrumb>

      <h1 class="h4">{{ 'users-overview-page' | translate }}</h1>
    </div>

    <div class="header-bar__search">
      <app-authorized-view [permission]="[Resource.OwnOrganizationUsers, Resource.AllUsers]" [scope]="Scope.CREATE">
        <button class="button-secondary button--has-icon" (click)="createNewUser()">
          <span class="material-symbols-rounded">add</span>
          {{ 'create-new-user' | translate }}
        </button>
      </app-authorized-view>
      <input class="top-search" type="search" [(ngModel)]="searchText" (input)="search()"
        placeholder="{{ 'search' | translate }}" />
    </div>
  </div>

  <app-table-filter></app-table-filter>
  <table class="table responsive-table">
    <thead>
      <tr>
        <ng-container *ngFor="let column of columns">
          <th (click)="toggleSort(column.key)" *ngIf="column.key.length" class="sortable">
            {{ column.label | translate }}
            <span [class.arrow-active]="sortBy === column.key" [class.arrow-down]="sortOrder === 1"
              class="arrow"></span>
          </th>
          <th *ngIf="!column.key.length">
            {{ column.label | translate }}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of filteredUsers" (click)="goToUserDetail(user)" class="table-link">
        <td [attr.data-title]="'first-name' | translate">{{ user.firstName }}</td>
        <td [attr.data-title]="'last-name' | translate">{{ user.lastName }}</td>
        <td [attr.data-title]="'email' | translate">{{ user.email }}</td>

        <td [attr.data-title]="'Company' | translate">
          <!--          {{ getCompanyName(user.companyId) }}-->
        </td>
        <app-authorized-view [permission]="[Resource.OwnOrganizationUsers, Resource.AllUsers]" [scope]="Scope.DELETE">
          <td [attr.data-title]="'remove' | translate" (click)="userToRemovePopup($event, user.id)">
            <span class="material-symbols-rounded table-remove">delete</span>
          </td>
        </app-authorized-view>
      </tr>
    </tbody>
  </table>

  <app-confirmation-dialog [title]="'user-delete-title' | translate"
    [description]="'user-delete-description' | translate" [icon]="'bin'" [remove]="true"
    [confirmText]="'user-delete-confirm' | translate" (confirmOutput)="removeUser()"></app-confirmation-dialog>

  <div class="pagination">
    <app-table-pagination (pageChange)="onPageChange($event)" [total]="users.length" [pageSize]="defaultPageSize"
      [page]="1"></app-table-pagination>
  </div>
</div>