import { Component, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-element-category',
  templateUrl: './element-category.component.html',
  styleUrls: ['./element-category.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'out',
        style({
          height: '*',
        })
      ),
      state(
        'in',
        style({
          height: '0',
        })
      ),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out')),
    ]),
  ],
})
export class ElementCategoryComponent implements OnInit {
  @Input() title: string = '';

  storageArray: string[] = [];

  constructor() {}

  ngOnInit(): void {
    this.getNewestStateOfStorage();
  }

  getNewestStateOfStorage(): void {
    const storedData = localStorage.getItem('hiddenElementCategories');
    this.storageArray = storedData ? JSON.parse(storedData) : [];
  }

  toggle(): void {
    this.getNewestStateOfStorage();

    if (this.storageArray.includes(this.title)) {
      this.storageArray = this.storageArray.filter((x) => x !== this.title);
    } else {
      this.storageArray.push(this.title);
    }

    localStorage.setItem('hiddenElementCategories', JSON.stringify(this.storageArray));
  }

  get state(): boolean {
    return this.storageArray.includes(this.title);
  }
}
