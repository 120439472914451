<div class="measures-tile">
  <div class="measures-tile__header">
    @for (subject of enrichedSubjects; track subject) {
      <app-unit-pin [title]="subject.name"></app-unit-pin>
    }
  </div>
  <div class="measures-tile__content">
    <table>
      <tr>
        <th>{{ 'Measure' | translate }}</th>
        <th>{{ 'average_costs' | translate }}</th>
        <th>{{ 'unit' | translate }}</th>
      </tr>
      @for (measure of measures; track measure.measure_id) {
        @let first_calculation = measure.calculations[0];
        <tr>
          <td [attr.rowspan]="measure.calculations.length">
            <div>{{ measure.description }}</div>
          </td>
          <td>
            <div>
              € {{ first_calculation.min | number: '1.0-2' : 'nl-NL' }} -
              {{ first_calculation.max | number: '1.0-2' : 'nl-NL' }}
            </div>
          </td>
          <td>
            <div>{{ first_calculation.unit }}</div>
          </td>
        </tr>

        @for (calculation of measure.calculations.slice(1); track calculation.unit) {
          <tr>
            <td>
              <div>
                € {{ calculation.min | number: '1.0-2' : 'nl-NL' }}-
                {{ calculation.max | number: '1.0-2' : 'nl-NL' }}
              </div>
            </td>
            <td>
              <div>{{ calculation.unit }}</div>
            </td>
          </tr>
        }
      }
      <tr class="total">
        @let summary = getTotalSummary();
        <td>{{ 'total_average_costs' | translate }}:</td>
        <td>
          € {{ summary.min | number: '1.0-2' : 'nl-NL' }} -
          {{ summary.max | number: '1.0-2' : 'nl-NL' }}
        </td>
      </tr>
    </table>
  </div>
</div>
