import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-information-link',
    templateUrl: './information-link.component.html',
    styleUrls: ['./information-link.component.scss'],
    imports: [RouterLink, NgIf]
})
export class InformationLinkComponent {
  @Input() title = '';
  @Input() link = '';
  @Input() icon = '';
  @Input() iconStyle = 'material-symbols-rounded';
}
