import { Object3D } from 'three';
import { CadastralId } from '@services/building-overview.service';

export function cadastralIdsToBatchIds(model: Object3D, castralIds: CadastralId[]): number[] {
  const pandIds: string[] = model['pandIds'];
  const batchIds: number[] = [];
  for (let i = 0; i < pandIds.length; i++) {
    if (castralIds.includes(pandIds[i])) {
      batchIds.push(i);
    }
  }
  return batchIds;
}

export function batchIdsToCadastralIds(model: Object3D, batchIds: number[]): string[] {
  const pandIds: string[] = model['pandIds'];
  return batchIds.map((batchId) => pandIds[batchId]);
}

export function tileContainsCadastralId(model: Object3D, cadastralId: string): boolean {
  const pandIds: string[] = model['pandIds'];
  return pandIds.includes(cadastralId);
}
