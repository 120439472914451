import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Feature, FeatureCollection, Point } from 'geojson';
import {
  CircleStyleLayer,
  FillExtrusionStyleLayer,
  FillStyleLayer,
  GeoJSONSource,
  HeatmapStyleLayer,
  HillshadeStyleLayer,
  LayerSpecification,
  LineStyleLayer,
  LngLat,
  Map as MaplibreMap,
  MapMouseEvent,
  SymbolStyleLayer,
  VectorSourceSpecification,
} from 'maplibre-gl';

import { environment } from 'environments/environment';
import { Map3DLayerComponent } from '../map-3d/map-3d-layer';

import { colorRuleMap } from './color-map';
import { layers } from './layers';
import { combineLatestWith, Observable, skip, take } from 'rxjs';
import { LatLngZoom } from '@shared/components/viewer/viewer.component';
import { ContextService } from '@services/context.service';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { RealEstateId } from '@shared/components/viewer/map-3d/assets/hd-building-manager';
import { TranslateService } from '@ngx-translate/core';
import { generateUUID } from 'three/src/math/MathUtils';

type StyleLayer =
  | FillStyleLayer
  | LineStyleLayer
  | SymbolStyleLayer
  | HeatmapStyleLayer
  | CircleStyleLayer
  | FillExtrusionStyleLayer
  | HillshadeStyleLayer;

@Component({
  selector: 'map-2d',
  templateUrl: './map-2d.component.html',
  styleUrls: ['./map-2d.component.scss'],
})
export class Map2DComponent implements AfterViewInit, OnDestroy {
  @Input({ required: true }) currentPose!: Observable<LatLngZoom>;
  @Input({ required: true }) map3dLayer!: Map3DLayerComponent;
  @Input({ required: true }) ownedBuildings$!: Observable<BuildingOverviewEntry[]>;
  @Input({ required: true }) selectedBuilding$!: Observable<BuildingOverviewEntry | undefined>;
  @Input() sidebarActive: boolean = false;

  @Output() newLatLngZoom = new EventEmitter<LatLngZoom>();
  @Output() dossierAtLatLngRequested = new EventEmitter<LngLat>();
  @Output() selectBuildingRequested = new EventEmitter<RealEstateId>();
  private map!: MaplibreMap;
  protected gisLayers: StyleLayer[] = [];
  protected buildings: FeatureCollection<Point> = {
    type: 'FeatureCollection',
    features: [],
  };

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  private mapLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  private sourceLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected activeRealEstateID: string | undefined;

  protected showBag3DLayer: boolean = true;
  protected showSateliteLayer: boolean = false;
  protected showAHN4Layer: boolean = false;
  protected showCadastralLayer: boolean = false;

  constructor(
    public readonly contextService: ContextService,
    private readonly translateService: TranslateService
  ) {
    this.showSateliteLayer = JSON.parse(localStorage.getItem('satellite-layer') || 'false');
    this.showCadastralLayer = JSON.parse(localStorage.getItem('cadastral-layer') || 'false');
    this.showAHN4Layer = JSON.parse(localStorage.getItem('ahn4-layer') || 'false');
    this.showBag3DLayer = JSON.parse(localStorage.getItem('3d-layer') || 'true');
  }

  ngAfterViewInit(): void {
    this.currentPose.pipe(take(1)).subscribe(this.initMap.bind(this));
    this.currentPose.pipe(skip(1)).subscribe(this.navigateToLocation.bind(this));

    this.ownedBuildings$.pipe(combineLatestWith(this.mapLoaded)).subscribe((next) => {
      this.handleOwnedBuildingsChanged(next[0]);
    });

    this.selectedBuilding$.pipe(combineLatestWith(this.sourceLoaded)).subscribe((next) => {
      const building = next[0];
      this.activeRealEstateID = building?.real_estate_id;
      this.handleBuildingSelected();
    });

    this.mapLoaded.subscribe(this.check3DLayerVisibility.bind(this));
  }

  initMap(latLngZoom: LatLngZoom): void {
    const { lat, lng, zoom } = latLngZoom;
    const style =
      'https://api.maptiler.com/maps/57feab4f-e013-4079-b901-0ac7b678f6e9/style.json?key=XCli4HbSVDG3WCLxU24r';

    this.map = new MaplibreMap({
      container: this.mapContainer.nativeElement,
      style,
      center: [lng, lat],
      maxPitch: 45,
      pitch: 45,
      zoom,
      boxZoom: false,
    });

    const map = this.map;
    const bag3DLayer = this.map3dLayer;

    this.map.on('load', () => {
      layers
        .filter((layer) => !this.mapLayers.includes(layer.id))
        .forEach(({ id, type }) => {
          map.addSource(id, {
            url: `${environment.TREX_TILESERVER}/${id}.json`,
            type: 'vector',
          } as VectorSourceSpecification);

          const colorProp = type === 'fill' ? 'fill-color' : 'line-color';
          const layer = {
            id,
            source: id,
            'source-layer': id,
            paint: {
              [colorProp]: ['case', ...colorRuleMap, 'transparent'],
            },
            type: type || 'line',
            visibility: 'visible',
          } as LayerSpecification;

          map.addLayer(layer);
          const styleLayer = map.getLayer(layer.id);
          this.gisLayers.push(styleLayer as StyleLayer);
        });

      map.addSource('buildings', {
        type: 'geojson',
        data: this.buildings,
        generateId: true,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points. MIND: this should be higher than 14, clustering should be visible when the 3d buildings are shown.
        clusterRadius: 50,
      });

      map.addSource('mapbox-satellite', {
        type: 'raster',
        url: 'https://api.maptiler.com/tiles/satellite-v2/tiles.json?key=XCli4HbSVDG3WCLxU24r',
        tileSize: 256,
      });

      map.addLayer({
        type: 'raster',
        id: 'satellite-map',
        source: 'mapbox-satellite',
        layout: { visibility: this.showSateliteLayer ? 'visible' : 'none' },
      });

      map.addLayer(bag3DLayer);

      this.map.addSource('pdok-ahn4', {
        type: 'raster',
        tiles: [
          'https://service.pdok.nl/rws/ahn/wms/v1_0?SERVICE=WMS&request=GetMap&layers=dsm_05m&styles=&format=image%2Fpng&transparent=true&version=1.3.0&width=512&height=512&maxZoom=24&maxNativeZoom=22&tileSize=512&zIndex=3&minZoom=18&crs=EPSG%3A3857&bbox={bbox-epsg-3857}',
        ],
        tileSize: 256,
      });

      this.map.addSource('brk-kadastraal', {
        type: 'raster',
        tiles: [
          'https://service.pdok.nl/kadaster/kadastralekaart/wms/v5_0?&service=WMS&request=GetMap&layers=KadastraleGrens%2CLabel%2CBijpijling%2CNummeraanduidingreeks%2COpenbareRuimteNaam&styles=standaard%2Cstandaard%2Cstandaard%2Cstandaard%2Cstandaard&format=image%2Fpng&transparent=true&version=1.3.0&width=512&height=512&maxZoom=24&maxNativeZoom=22&tileSize=512&zIndex=3&minZoom=18&attribution=kadastralekaart1&crs=EPSG%3A3857&bbox={bbox-epsg-3857}',
        ],
        tileSize: 512,
      });

      this.map.addLayer(
        {
          id: 'pdok-ahn4',
          type: 'raster',
          source: 'pdok-ahn4',
          layout: {
            visibility: this.showAHN4Layer ? 'visible' : 'none',
          },
        },
        this.map3dLayer.id
      );

      this.map.addLayer(
        {
          id: 'brk-kadastraal',
          type: 'raster',
          source: 'brk-kadastraal',
          layout: {
            visibility: this.showCadastralLayer ? 'visible' : 'none',
          },
        },
        this.map3dLayer.id
      );

      map.setLayoutProperty(
        this.map3dLayer.id,
        'visibility',
        this.showBag3DLayer ? 'visible' : 'none'
      );

      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'buildings',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#a8c0c3',
          'circle-radius': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            ['step', ['get', 'point_count'], 32, 100, 42, 250, 52],
            ['step', ['get', 'point_count'], 30, 100, 40, 250, 50],
          ],
        },
      });

      // cluster count text
      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'buildings',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Plus Jakarta Sans', 'sans-serif'],
          'text-size': 16,
        },
      });

      this.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'buildings',
        filter: ['all', ['!', ['has', 'point_count']]],
        paint: {
          'circle-color': [
            'case',
            ['boolean', ['feature-state', 'active'], false],
            '#68888c',
            '#a8c0c3',
          ],
          'circle-radius': ['case', ['boolean', ['feature-state', 'hover'], false], 16, 15],
        },
      });

      this.mapLoaded.emit(true);
    });

    // On click cluster and unclustered points start
    this.map.on('click', 'unclustered-point', (e) => {
      const feature = e.features?.[0];
      if (!feature) {
        return;
      }

      this.selectBuildingRequested.emit(feature.properties['real_estate_id']);
    });

    this.map.on('click', 'clusters', (e) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      });

      if (!features.length) {
        return;
      }

      const clusterId = features[0].properties['cluster_id'];
      const source = this.map.getSource('buildings') as GeoJSONSource;

      source.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err || zoom === null || zoom === undefined) {
          return;
        }

        this.map.easeTo({
          center: features[0].geometry['coordinates'],
          zoom: zoom,
        });
      });
    });
    // On click cluster and unclustered points end

    // hover states start
    let hoveredFeatureId: number | string | undefined;
    const setPointerAndHover = (layerId): void => {
      this.map.on('mouseenter', layerId, (e) => {
        for (const feature of e.features || []) {
          hoveredFeatureId = feature.id;
          this.map.setFeatureState({ source: 'buildings', id: hoveredFeatureId }, { hover: true });
        }

        if (hoveredFeatureId) {
          this.map.getCanvas().style.cursor = 'pointer';
        }
      });
      this.map.on('mouseleave', layerId, () => {
        if (hoveredFeatureId) {
          this.map.setFeatureState({ source: 'buildings', id: hoveredFeatureId }, { hover: false });
          this.map.getCanvas().style.cursor = '';
          hoveredFeatureId = undefined;
        }
      });
    };

    setPointerAndHover('clusters');
    setPointerAndHover('unclustered-point');
    // hover states end

    let sourceLoaded = false;

    this.map.on('resize', () => bag3DLayer.updateProjectionMatrix(map));
    this.map.on('click', (event) => this.handleMouseClick(event));
    this.map.on('contextmenu', (event) => this.handleRightClick(event));
    this.map.on('error', (e) => console.warn('MapLibre error', e));
    this.map.on('zoomstart', () => this.contextService.hide());
    this.map.on('zoomend', () => this.updateZoom());
    this.map.on('dragstart', () => this.contextService.hide());
    this.map.on('dragend', () => this.updateLatLngZoom());
    this.map.on('sourcedata', (e) => {
      if (!sourceLoaded && e.sourceId === 'buildings' && e.isSourceLoaded) {
        sourceLoaded = true;
        this.sourceLoaded.emit(true);
      }
    });
  }

  get mapLayers(): string[] {
    return layers.filter((layer) => layer.isMapLayer).map((layer) => layer.id);
  }

  handleOwnedBuildingsChanged(entries: BuildingOverviewEntry[]): void {
    this.buildings = {
      type: 'FeatureCollection',
      features: entries.map<Feature<Point>>((building) => ({
        type: 'Feature',
        id: generateUUID(),
        properties: {
          real_estate_id: building.real_estate_id,
          has3d: !!building.external_id || !!building.bim_id,
          active: false,
        },
        geometry: {
          type: 'Point',
          coordinates: [
            building.buildingMetadata.location.lng,
            building.buildingMetadata.location.lat,
          ],
        },
      })),
    };

    if (this.map.getSource('buildings')) {
      // Update the GeoJSON data if the source already exists
      const source = this.map.getSource('buildings') as GeoJSONSource;
      source.setData(this.buildings);
      this.setUnclusteredFilter();
    }

    this.handleBuildingSelected();
  }

  handleBuildingSelected(): void {
    const buildingsFeatures = this.map.querySourceFeatures('buildings');
    buildingsFeatures.forEach((feature) => {
      this.map.setFeatureState(
        { source: 'buildings', id: feature.id },
        { active: feature.properties?.['real_estate_id'] === this.activeRealEstateID }
      );
    });
  }

  handleMouseClick(mapMouseEvent: MapMouseEvent): void {
    this.contextService.hide();
    if (mapMouseEvent.type === 'click' && mapMouseEvent.originalEvent.button === 0)
      // left click
      this.map3dLayer.handleMouseClick(mapMouseEvent);
  }

  handleRightClick(mapMouseEvent: MapMouseEvent): void {
    this.contextService.set(
      mapMouseEvent.originalEvent.clientX,
      mapMouseEvent.originalEvent.clientY,
      [
        {
          name: this.translateService.instant('add-dossier-to-location'),
          icon: 'house',
          action: (): void => {
            this.dossierAtLatLngRequested.emit(mapMouseEvent.lngLat);
          },
        },
      ]
    );
  }

  updateZoom(): void {
    this.check3DLayerVisibility();
    this.setUnclusteredFilter();
    this.updateLatLngZoom();
  }

  check3DLayerVisibility(): void {
    const zoom = this.map.getZoom();
    this.map3dLayer.setVisibility(this.showBag3DLayer && zoom > 15);
  }

  updateLatLngZoom(): void {
    const { lat, lng } = this.map.getCenter();
    const zoom = this.map.getZoom();
    this.newLatLngZoom.emit({ lat, lng, zoom });
  }

  navigateToLocation(latLngZoom: LatLngZoom): void {
    const { lat, lng, zoom } = latLngZoom;
    const [mapLat, mapLng] = this.map.getCenter().toArray();
    if (lat && lng && (Math.abs(lat - mapLat) > 1e-5 || Math.abs(lng - mapLng) > 1e-5))
      this.map.setCenter([lng, lat]);
    if (zoom && Math.abs(zoom - this.map.getZoom()) > 0.2) this.map.setZoom(zoom);
    this.map.triggerRepaint();
  }

  changeNormalLayer = (): void => {
    this.changeSatelliteLayer(false);
    this.changeAHN4Layer(false);
  };

  changeSatelliteLayer = (setToVisible: boolean): void => {
    if (this.showAHN4Layer && setToVisible) {
      this.changeAHN4Layer(false);
    }

    this.showSateliteLayer = setToVisible;
    localStorage.setItem('satellite-layer', setToVisible.toString());
    this.map.setLayoutProperty('satellite-map', 'visibility', setToVisible ? 'visible' : 'none');
  };

  changeAHN4Layer = (setToVisible: boolean): void => {
    if (this.showSateliteLayer && setToVisible) {
      this.changeSatelliteLayer(false);
    }

    this.showAHN4Layer = setToVisible;
    localStorage.setItem('ahn4-layer', setToVisible.toString());
    this.map.setLayoutProperty('pdok-ahn4', 'visibility', setToVisible ? 'visible' : 'none');
  };

  changeCadastralLayer = (): void => {
    this.showCadastralLayer = !this.showCadastralLayer;
    localStorage.setItem('cadastral-layer', this.showCadastralLayer.toString());

    this.map.setLayoutProperty(
      'brk-kadastraal',
      'visibility',
      this.showCadastralLayer ? 'visible' : 'none'
    );
  };

  change3DLayer = (): void => {
    this.showBag3DLayer = !this.showBag3DLayer;
    localStorage.setItem('3d-layer', this.showBag3DLayer.toString());

    this.map.setLayoutProperty(
      this.map3dLayer.id,
      'visibility',
      this.showBag3DLayer ? 'visible' : 'none'
    );

    this.check3DLayerVisibility();
    this.setUnclusteredFilter();
    this.handleBuildingSelected();
  };

  setUnclusteredFilter = (): void => {
    const zoom = this.map.getZoom();
    if (this.showBag3DLayer && zoom > 15) {
      // If showBag3DLayer is true, hide the unclustered points that has a 3D model
      this.map.setFilter('unclustered-point', ['==', ['get', 'has3d'], false]);
    } else {
      // If showBag3DLayer is false, show as normal
      this.map.setFilter('unclustered-point', ['all', ['!', ['has', 'point_count']]]);
    }
  };

  ngOnDestroy(): void {
    this.map?.remove();
  }
}
