import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Observer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadHelper {
  constructor(private readonly toastr: ToastrService) {}

  createUploadProgressToast<T>(
    title: string,
    reload: (body: T | null) => void
  ): Partial<Observer<HttpEvent<T>>> {
    let progress = 50;

    const infoToast = this.toastr.info(title, undefined, {
      extendedTimeOut: 100000000,
      timeOut: 100000000, // The progress bar is displayed only if there is a timeout, so I set a very large timeout to close it manually
      enableHtml: true,
      tapToDismiss: false,
      progressBar: true,
      progressAnimation: 'increasing',
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (<any>infoToast).toastRef.componentInstance.updateProgress = (): void => {
      // override it so that no animation is displayed anymore
    };

    return {
      next: (event): void => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            progress = (event.loaded / (event.total || 0)) * 100;

            // Access the first active toast and update its progress bar
            const toastPortal = this.toastr.toasts[0]?.portal;
            if (toastPortal) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const toastElement = (<any>toastPortal.hostView).rootNodes[0]; // Get the toast DOM element
              const progressBar = toastElement?.querySelector('.toast-progress'); // Find progress bar

              if (toastElement) {
                // Update progress bar width
                if (progressBar) {
                  progressBar.style.width = `${progress}%`;
                }
              }
            }

            break;
          case HttpEventType.Response:
            this.toastr.remove(infoToast.toastId);
            this.toastr.success('File uploaded successfully', undefined, {
              positionClass: 'toast-bottom-right',
            });
            reload(event.body);
            break;
        }
      },
      error: (error): void => {
        console.error(error);
        this.toastr.remove(infoToast.toastId);
        this.toastr.error('Failed to upload file', undefined, {
          positionClass: 'toast-bottom-right',
        });
      },
    };
  }
}
