import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Degradation } from '@api-clients/bim';

@Component({
  selector: 'app-degradation-edit',
  templateUrl: './degradation-edit.component.html',
  styleUrls: ['./degradation-edit.component.scss'],
})
export class DegradationEditComponent {
  private _properties: Degradation | null = null;

  @Input() originalState: Degradation | null = null;

  @Output() emitExpiryDateChangedEvent = new EventEmitter<Event | undefined>();
  @Output() emitDegradationChangedEvent = new EventEmitter<Degradation | null>();

  private EMPTY_WORKING_STATE: Partial<Degradation> = {
    source: 'ManuallyObserved',
    estimated_expiry: undefined,
    description: '',
  };

  _workingState: Partial<Degradation> = structuredClone(this.EMPTY_WORKING_STATE);

  @Input() set properties(properties: Degradation | null) {
    this._properties = properties;

    if (this._properties) {
      this._workingState = { ...properties };
      return;
    }

    this._workingState = { ...this.EMPTY_WORKING_STATE };
  };

  get properties(): Degradation | null {
    return this._properties;
  }

  get description(): string | undefined {
    return this._workingState.description;
  }

  set description(description: string | undefined) {
    this._workingState.description = description;

    if (!this._workingState.estimated_expiry) return;

    this.emitDegradationChangedEvent.emit(this._workingState as Degradation);
  }

  get estimatedExpiry(): string | undefined {
    return this._workingState.estimated_expiry;
  }

  set estimatedExpiry(estimatedExpiry: Date | null) {
    if (!estimatedExpiry) {
      this.emitDegradationChangedEvent.emit(null);
      return;
    }

    this._workingState.estimated_expiry = this.toDateString(estimatedExpiry);

    this.emitDegradationChangedEvent.emit(this._workingState as Degradation);
  }

  get hasDescriptionChanges(): boolean {
    if (this.originalState === undefined) {
      return false;
    }

    if (!this.originalState) {
      return this._workingState.description !== '';
    }

    return this._workingState.description !== this.originalState.description;
  }

  get hasInvalidState(): boolean {
    return this._workingState.description !== '' && !this._workingState.estimated_expiry;
  }

  get hasExpiryDateChanges(): boolean {
    if (this.originalState === undefined) return false;
    if (!this.originalState) return true;

    return (this._workingState.estimated_expiry !== this.originalState.estimated_expiry);
  }

  onDateChangedEvent(): void {
    this.emitExpiryDateChangedEvent.emit();
  }

  private toDateString(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
