import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { lastValueFrom } from 'rxjs';
import { RptInterceptor } from '../auth/rpt.interceptor';

interface Payload {
  group_ids_admin?: Array<string>;
  group_ids_user?: Array<string>;
  sub: string;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserService {
  organizationId!: string;
  userId!: string;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private rptInterceptor: RptInterceptor
  ) {}

  async initialize(): Promise<void> {
    const loginResponse = await lastValueFrom(this.oidcSecurityService.checkAuth());
    if (!loginResponse.isAuthenticated) {
      console.error('User not authenticated', loginResponse);
      return;
    }
    const payload: Payload = await lastValueFrom(
      this.oidcSecurityService.getPayloadFromAccessToken()
    );
    if (!payload.group_ids_user) {
      console.error('User has no groups', payload);
      return;
    }
    this.organizationId = (payload.group_ids_admin || []).concat(payload.group_ids_user)[0];
    this.userId = payload.sub;

    const [token, config] = await Promise.all([
      lastValueFrom(this.oidcSecurityService.getAccessToken()),
      lastValueFrom(this.oidcSecurityService.getConfiguration()),
    ]);
    if (config === null) {
      console.error('OIDC configuration is null');
      return;
    }
    await this.rptInterceptor.setRpt(token, config!);
  }
}
