import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTree, MatTreeNodeDef, MatTreeNode, MatTreeNodePadding, MatTreeNodeToggle } from '@angular/material/tree';
import { Layer } from '../ut-layers';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

// TODO: do not navigate to building when clicking on it

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
export interface Node {
  id: string;
  children?: Node[];
  postGISColumn?: string;
}

/** Flat node with expandable and level information */
export interface FlatNode {
  expandable: boolean;
  id: string;
  level: number;
  expanded: boolean;
  selected?: boolean;
  layer?: string;
  postGISColumn?: string;
}

/**
 * @title Tree with checkboxes
 */
@Component({
    selector: 'legend',
    templateUrl: 'legend.component.html',
    styleUrls: ['legend.component.scss'],
    imports: [MatTree, MatTreeNodeDef, MatTreeNode, MatTreeNodePadding, MatCheckbox, MatTreeNodeToggle, MatIcon, TranslateModule]
})
export class LegendComponent implements OnInit {
  @Input() source: Layer[] = [];
  @Output() setSelected = new EventEmitter<FlatNode[]>();

  checklistSelection!: SelectionModel<FlatNode>;

  private _transformer = (node: Node, level: number): FlatNode => {
    return {
      expandable: !!node.children && node.children.length > 0,
      id: node.id,
      level: level,
      expanded: true,
      postGISColumn: node.postGISColumn,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  ngOnInit(): void {
    this.dataSource.data = this.source.map((n) => ({
      id: n.id,
      name: n.id,
      children: (n.subLayers || []).map((id) => ({
        id,
        layer: n.id,
        postGISColumn: n.postGISColumn,
      })),
    }));

    const allLayers = this.treeControl.dataNodes.map((n) => n.id);
    const selectedLayers =
      JSON.parse(localStorage.getItem('selectedLayers') || 'null') || allLayers;
    const selection = this.treeControl.dataNodes.filter((n) => selectedLayers.includes(n.id));
    this.checklistSelection = new SelectionModel<FlatNode>(true /* multiple */, selection);

    // this.treeControl.expandAll();
  }

  get selection(): string[] {
    return this.checklistSelection.selected.map((s) => s.id);
  }

  _toggleLayer(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    node.selected = this.checklistSelection.isSelected(node);
    this.setSelection(this.checklistSelection.selected);
  }

  hasChild = (_: number, node: FlatNode): boolean => node.expandable;

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FlatNode): void {
    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
    this.setSelection(this.checklistSelection.selected);
  }

  setSelection(selection: FlatNode[]): void {
    localStorage.setItem('selectedLayers', JSON.stringify(selection.map((s) => s.id)));
    this.setSelected.emit(selection);
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: FlatNode): FlatNode | null {
    const currentLevel = node.level;

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
