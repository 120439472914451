import { BufferGeometry, PerspectiveCamera, Raycaster, Vector2 } from 'three';
import { BuildingModel } from '@shared/services/assets/building-model';

export class RayCaster {
  private raycaster = new Raycaster();

  constructor(
    private canvas: HTMLCanvasElement,
    private camera: PerspectiveCamera
  ) {}

  public getFirstVisibleIntersection(
    mouseX: number,
    mouseY: number,
    model: BuildingModel,
    allowedUuids: string[]
  ): string | undefined {
    const pointer = new Vector2(
      (mouseX / this.canvas.clientWidth) * 2 - 1,
      -(mouseY / this.canvas.clientHeight) * 2 + 1
    );
    this.raycaster.setFromCamera(pointer, this.camera);
    const intersects = this.raycaster.intersectObject(model, true);
    let uuid: string | undefined = undefined;
    for (const intersect of intersects) {
      const bufferGeometry = intersect.object['geometry'] as BufferGeometry | undefined;
      if (!bufferGeometry?.hasAttribute('_batch_ids')) continue;
      const attribute = bufferGeometry.getAttribute('_batch_ids');
      if (!intersect.face) continue;
      const batchId = attribute.array[intersect.face.a];
      const batchTable = intersect.object.parent?.userData?.['batch_table'];
      if (!batchTable) continue;
      const foundUuid = batchTable[batchId];
      // If the uuid is an element in the model, return it. This prevents room/level selection.
      if (allowedUuids.includes(foundUuid)) {
        uuid = foundUuid;
        break;
      }
      // intersect.object.visible && intersect.object['name'] !== 'shadowCatcher'
    }
    return uuid;
  }
}

export function glCoordsFromMouseEvent(event: MouseEvent, canvas: HTMLElement): Vector2 {
  return new Vector2(
    (event['layerX'] / canvas.clientWidth) * 2 - 1,
    -(event['layerY'] / canvas.clientHeight) * 2 + 1
  );
}

export function glCoordsFromMouseEventBeforeMotion(
  event: MouseEvent,
  canvas: HTMLElement
): Vector2 {
  return new Vector2(
    ((event['layerX'] - event.movementX) / canvas.clientWidth) * 2 - 1,
    -((event['layerY'] - event.movementY) / canvas.clientHeight) * 2 + 1
  );
}
