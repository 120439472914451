import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import {
  Box3,
  Euler,
  Group,
  Material,
  Mesh,
  Object3D,
  PerspectiveCamera,
  Quaternion,
  Raycaster,
  Scene,
  ShaderMaterial,
  Vector3,
  WebGLRenderer,
} from 'three';

import { CustomLayerInterface, Map as MapBoxMap, MapMouseEvent } from 'maplibre-gl';
import { TilesRenderer } from '3d-tiles-renderer';
import { environment } from '@env/environment';
import { Bag3DShaderMaterial } from './utils/shader';
import {
  AmersfoortToLngLat,
  getCameraPositionInAmersfoortCoordinates,
  getCameraRotationInAmersfoort,
  getProjectionMatrixFromMap,
  LatLngToAmersfoort,
} from './utils/geo';
import {
  initTilesRenderer,
  recenterTiles,
  setBuildingColorByCadastralId as setBuildingColorByCadastralIdOnTile,
  updateBuildingColorsForTile,
} from './utils/tiles';
import { baseColor, highlightColor, ownedColor } from './utils/colors';
import { tileContainsCadastralId } from './utils/indexing';
import { Observable } from 'rxjs';
import { difference, intersection, union } from 'ramda';
import {
  addLightsAround as addLightsAroundScene,
  AmbiguousBuildingId,
  cadastralIdFromRaycasterIntersections,
  controlsToRotate,
  controlsToTranslate,
  initRenderer,
  mapMouseEventToGlMouseCoordinates,
} from './utils/three_utils';
import { ModelLoaderService } from '@shared/services/model-loader.service';
import { HdBuildingManagerComponent } from './assets/hd-building-manager';
import { BuildingOverviewEntry } from '@core/models/building-overview-entry';
import { CadastralId } from '@services/building-overview.service';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { BimService } from '@api-clients/bim';

@Component({
  selector: 'map-3d-layer',
  template: '',
})
export class Map3DLayerComponent implements CustomLayerInterface, OnDestroy {
  readonly id = 'bag-3d-models';
  readonly type = 'custom' as const;
  readonly renderingMode: '3d' | '2d' | undefined = '3d';

  private readonly scene = new Scene();
  private readonly material: ShaderMaterial = new Bag3DShaderMaterial();
  private readonly camera = new PerspectiveCamera();
  private tiles: TilesRenderer = initTilesRenderer(environment.BAG_3D_TILESET);
  private readonly offsetParent = new Group();
  private sceneTransform = new Vector3();
  private show3DTiles = false;

  public setVisibility(visible: boolean): void {
    // Show or hide tiles based on distance to camera (camera zoom is set by map-2d.component)
    if (visible) {
      this.tiles.update();
      if (!this.show3DTiles) {
        this.offsetParent.add(this.tiles.group);
        this.show3DTiles = true;
      }
    } else {
      if (this.show3DTiles) {
        this.offsetParent.remove(this.tiles.group);
        this.show3DTiles = false;
      }
    }
  }

  private map!: MapBoxMap;
  private hdModels = new HdBuildingManagerComponent(this.modelLoader);
  private renderer: WebGLRenderer = new WebGLRenderer();

  @Input() showBimLayer = true;
  @Input({ required: true }) selectedBuilding$!: Observable<BuildingOverviewEntry | undefined>;
  private selectedBuilding: BuildingOverviewEntry | undefined = undefined;
  @Input({ required: true }) ownedBuildings$!: Observable<BuildingOverviewEntry[]>;
  private ownedBuildings: BuildingOverviewEntry[] = [];
  @Output() buildingClicked = new EventEmitter<AmbiguousBuildingId | undefined>();

  constructor(
    private modelLoader: ModelLoaderService,
    private bimService: BimService
  ) {
    this.offsetParent.name = 'offsetParent';
    this.offsetParent.rotation.x = -Math.PI / 2;
    this.scene.add(this.offsetParent);
    this.scene.add(this.camera);
    addLightsAroundScene(this.scene);
  }

  private selectedHdBuilding(): Group | undefined {
    const real_estate_id = this.selectedBuilding?.real_estate_id;
    if (!real_estate_id) return;
    return this.hdModels.get(real_estate_id);
  }

  async onAdd(mapBoxMap: MapBoxMap, glContext: WebGLRenderingContext): Promise<void> {
    this.map = mapBoxMap;
    this.updateProjectionMatrix(mapBoxMap);
    this.initTiles();

    // Reload owned buildings
    this.ownedBuildings$.subscribe(this.ownedBuildingsChanged.bind(this));
    // On building selection, highlight the building
    this.selectedBuilding$.subscribe(this.selectedBuildingChanged.bind(this));
    this.renderer = initRenderer(mapBoxMap.getCanvas(), glContext);

    // Add controls for moving and rotating hd buildings
    const controls = new TransformControls(this.camera, this.renderer.domElement);
    controls.space = 'local';
    controls.addEventListener('objectChange', () => this.map.triggerRepaint());
    controls.addEventListener('mouseDown', () => this.map.dragPan.disable());
    controls.addEventListener('mouseUp', () => {
      this.map.dragPan.enable();
      this.storeSelectedHdBuildingLocation();
    });
    this.scene.add(controls);

    window.addEventListener('keydown', (event) => {
      this.map.triggerRepaint();
      if (!['KeyT', 'KeyR'].includes(event.code)) {
        controls.detach();
        return;
      }
      if (!this.selectedBuilding || !this.selectedBuilding.real_estate_id) return;

      const selectedHdModel = this.hdModels.get(this.selectedBuilding.real_estate_id);
      if (!selectedHdModel) return;

      if (event.code === 'KeyT') controlsToTranslate(controls);
      else if (event.code === 'KeyR') controlsToRotate(controls);

      controls.attach(selectedHdModel);
    });
  }

  private storeSelectedHdBuildingLocation(): void {
    const building = this.selectedHdBuilding();
    if (!building) return;
    const sceneLocation = building.position
      .clone()
      .add(building.parent!.getWorldPosition(new Vector3()));
    const amersfoortLocation = this.sceneToAmersfoortCoordinates(sceneLocation);
    const [lon, lat] = AmersfoortToLngLat(amersfoortLocation.x, amersfoortLocation.y);
    const rotationQuaternion = new Quaternion().setFromEuler(building.rotation);
    const rotation = new Euler().setFromQuaternion(rotationQuaternion, 'YXZ').y;
    const bimId = this.selectedBuilding!.bim_id!;
    this.bimService.bimBimIdPosePut(bimId, { lat, lon, rotation }).subscribe();
  }

  private ownedBuildingsChanged(newOwnedBuildings: BuildingOverviewEntry[]): void {
    this.ownedBuildings = newOwnedBuildings;
    this.updateBuildings();
  }

  private selectedBuildingChanged(newSelectedBuilding: BuildingOverviewEntry | undefined): void {
    const previousSelectedBuilding = this.selectedBuilding;
    this.selectedBuilding = newSelectedBuilding;
    this.selectBuildings([
      previousSelectedBuilding ? [previousSelectedBuilding] : [],
      newSelectedBuilding ? [newSelectedBuilding] : [],
    ]);
  }

  ngOnDestroy(): void {
    this.renderer.dispose();
    this.tiles?.dispose();
    this.material?.dispose();
    this.scene?.clear();
    this.hdModels.clear();
  }

  render(): void {
    const cameraPositionInAmersfoort = getCameraPositionInAmersfoortCoordinates(this.map);
    const cameraRotationInAmersfoort = getCameraRotationInAmersfoort(this.map);

    this.camera.setRotationFromMatrix(cameraRotationInAmersfoort);
    this.camera.position.copy(this.amersfoortToSceneCoordinates(cameraPositionInAmersfoort));

    if (this.show3DTiles) {
      this.tiles.update();

      recenterTiles(this.tiles);
      this.renderer.resetState();
      this.renderer.render(this.scene, this.camera);
    }
  }

  updateProjectionMatrix(map: MapBoxMap): void {
    this.camera.projectionMatrix = getProjectionMatrixFromMap(map, 2, 300000);
    this.camera.projectionMatrixInverse = this.camera.projectionMatrix.clone().invert();
  }

  // Loads potential HD buildings and sets colors for the 3d bag buildings within this tile
  private updateTile(tile: Object3D): void {
    // Potentially add HD buildings to tile
    const potentialHdBuildings = this.ownedBuildings
      .filter((building) => building.bim_id !== undefined)
      .filter((building) => !this.hdModels.has(building.real_estate_id!));

    // Update colors
    updateBuildingColorsForTile(tile, this.ownedCadastralIds, this.selectedCadastralIds);

    for (const building of potentialHdBuildings) {
      if (
        // If the tile contains the cadastralId, add the hd building to this tile
        building.external_id !== undefined &&
        tileContainsCadastralId(tile, building.external_id)
      ) {
        void this.addHdBuildingToTile(tile, building);
      } else if (!building.external_id) {
        // Alternatively, check if it's inside the bounding box of the tile
        const tileBoundingBox: Box3 = tile.children[0]['geometry'].boundingBox;
        const buildingLocation = building.buildingMetadata?.location;
        if (!buildingLocation) continue;
        const { lat, lng } = buildingLocation;
        const sceneCoordinates = this.geoToSceneCoordinates(lat, lng);
        if (tileBoundingBox.containsPoint(sceneCoordinates))
          void this.addHdBuildingToTile(tile, building);
      }
    }

    // Update bag3d visibility
    tile.children[0].visible = this.show3DTiles;

    // Update BIM visibility:
    this.hdModels.setAllBuildingVisiblity(this.showBimLayer);
    const cadastralIds = this.hdModels.getHdCadastralIds();
    if (this.showBimLayer) this.hideBag3DBuilding(cadastralIds);
    else this.resetBag3DBuildingRGBA(cadastralIds);
  }

  private updateBuildings(): void {
    this.tiles.forEachLoadedModel((tile) => {
      // Hide or show the bag 3D layer:
      tile.children[0].visible = this.show3DTiles;
      // Update building colors:
      updateBuildingColorsForTile(tile, this.ownedCadastralIds, this.selectedCadastralIds);
    });

    // Hide or show the hd models layer:
    this.hdModels.setAllBuildingVisiblity(this.showBimLayer);
    const cadastralIds = this.hdModels.getHdCadastralIds();
    if (this.showBimLayer) this.hideBag3DBuilding(cadastralIds);
    else this.resetBag3DBuildingRGBA(cadastralIds);

    this.map.triggerRepaint();
  }

  private async addHdBuildingToTile(
    tile: Object3D,
    building: BuildingOverviewEntry
  ): Promise<void> {
    const fusedModel = await this.hdModels.addHdBuilding(building);
    if (!fusedModel) return;
    const [buildingModel, pose] = fusedModel;

    let lat: number, lng: number, rotation: number;
    if (pose) {
      lat = pose.lat;
      lng = pose.lon;
      rotation = pose.rotation;
    } else {
      lat = building.buildingMetadata!.location!.lat;
      lng = building.buildingMetadata!.location!.lng;
      rotation = 6.283; // 2 PI radians against db0
    }

    const sceneCoordinates = this.geoToSceneCoordinates(lat, lng);
    const tileCoordinates = sceneCoordinates.clone().sub(tile.getWorldPosition(new Vector3()));
    tile.add(buildingModel);

    buildingModel.position.copy(tileCoordinates);
    buildingModel.rotation.y = rotation;
    buildingModel.updateMatrixWorld();

    this.hideBag3DBuilding(building.external_id ? [building.external_id] : []);
    if (this.selectedBuilding?.real_estate_id === building.real_estate_id && building.external_id) {
      this.hdModels.highlightBuildings([building]);
    }
    this.map.triggerRepaint();
  }

  private get selectedCadastralIds(): CadastralId[] {
    const id = this.selectedBuilding?.external_id;
    return id ? [id] : [];
  }

  private get ownedCadastralIds(): CadastralId[] {
    return this.ownedBuildings
      .map((building) => building.external_id)
      .filter((id) => id !== undefined) as CadastralId[];
  }

  private initTiles(): void {
    this.tiles.setCamera(this.camera);
    this.tiles.setResolutionFromRenderer(this.camera, this.renderer);

    this.tiles.onLoadTileSet = (): void => {
      if (!this.tiles.root) return;

      const transform = this.tiles.root['cached'].transform;
      this.sceneTransform = new Vector3(
        transform.elements[12],
        transform.elements[13],
        transform.elements[14]
      );
      this.map.triggerRepaint();
    };

    this.tiles.onLoadModel = (tile: Object3D): void => {
      const mesh = tile.children[0] as Mesh;
      if (!mesh) return;
      if (mesh.geometry) mesh.geometry.computeBoundingBox();
      const material = mesh.material as Material;
      material.dispose();
      mesh.material = this.material;
      mesh.renderOrder = 2;
      this.updateTile(tile);
      this.map.triggerRepaint();
    };

    this.offsetParent.add(this.tiles.group);
  }

  handleMouseClick(mapMouseEvent: MapMouseEvent): void {
    const coord = mapMouseEventToGlMouseCoordinates(mapMouseEvent);
    const raycaster = new Raycaster();
    raycaster.setFromCamera(coord, this.camera);
    const intersections = raycaster.intersectObject(this.tiles.group, true);
    const ambiguousId = cadastralIdFromRaycasterIntersections(intersections);
    this.buildingClicked.emit(ambiguousId);
  }

  private selectBuildings([previousSelectedBuildings, newSelectedBuildings]: [
    BuildingOverviewEntry[],
    BuildingOverviewEntry[],
  ]): void {
    const toBeDeselected = difference(previousSelectedBuildings, newSelectedBuildings);
    const toBeSelected = difference(newSelectedBuildings, previousSelectedBuildings);
    if (toBeDeselected.length) this.unhighlightBuildings(toBeDeselected);
    if (toBeSelected.length) {
      this.highlightBuildings(toBeSelected);
    }
    this.map.triggerRepaint();
  }

  private highlightBuildings(buildings: BuildingOverviewEntry[]): void {
    const { remainingBuildings } = this.hdModels.highlightBuildings(buildings);
    const remainingCadastralIds: string[] = remainingBuildings
      .map((building) => building.external_id!)
      .filter((id) => id !== undefined);
    this.setBag3DBuildingColor(remainingCadastralIds, highlightColor);
  }

  private unhighlightBuildings(cadastralIds: BuildingOverviewEntry[]): void {
    const { remainingBuildings } = this.hdModels.unhighlightBuildings(cadastralIds);
    const remainingCadastralIds: string[] = remainingBuildings
      .map((building) => building.external_id!)
      .filter((id) => id !== undefined);
    this.resetBag3DBuildingRGBA(remainingCadastralIds);
  }

  private hideBag3DBuilding(cadastralIds: CadastralId[]): void {
    this.setBag3DBuildingColor(cadastralIds, [0, 0, 0, 0]);
  }

  private resetBag3DBuildingRGBA(cadastralIds: CadastralId[] = []): void {
    const selected = intersection(this.selectedCadastralIds, cadastralIds);
    const owned = intersection(this.ownedCadastralIds, cadastralIds);
    const unselected = difference(cadastralIds, union(selected, owned));
    this.setBag3DBuildingColor(selected, highlightColor);
    this.setBag3DBuildingColor(owned, ownedColor);
    this.setBag3DBuildingColor(unselected, baseColor);
  }

  private setBag3DBuildingColor(cadastralIds: CadastralId[], color: number[]): void {
    this.tiles.forEachLoadedModel((tile: Object3D) => {
      setBuildingColorByCadastralIdOnTile(tile, cadastralIds, color);
    });
  }

  private amersfoortToSceneCoordinates(amersfoort: Vector3): Vector3 {
    return new Vector3(
      amersfoort.x - this.sceneTransform.x,
      amersfoort.z - this.sceneTransform.z,
      -amersfoort.y + this.sceneTransform.y
    );
  }

  private sceneToAmersfoortCoordinates(scene: Vector3): Vector3 {
    return new Vector3(
      scene.x + this.sceneTransform.x,
      -scene.z + this.sceneTransform.y,
      scene.y + this.sceneTransform.z
    );
  }

  private geoToSceneCoordinates(lat: number, lng: number): Vector3 {
    const amersfoort = LatLngToAmersfoort(lat, lng);
    return this.amersfoortToSceneCoordinates(
      new Vector3(amersfoort[0], amersfoort[1], Number.MIN_VALUE)
    );
  }
}
