import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { PointLockControlsComponent } from './point-lock-controls/point-lock-controls.component';
import { initRenderer } from '../../../../building-module/views/model-viewer/utils/renderer';
import { Box3, Object3D, PerspectiveCamera, Scene, Vector3, WebGLRenderer } from 'three';
import {
  configDirectionalLight,
  createShadowCatcher,
  sceneLights,
} from '../../../../building-module/views/model-viewer/utils/lighting';
import { createOrbitControls } from '../../../../building-module/views/model-viewer/utils/controls';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

@Component({
  selector: 'app-scene',
  templateUrl: './scene.component.html',
  styleUrl: './scene.component.scss',
})
export class SceneComponent implements AfterViewInit, OnDestroy {
  @ViewChild('canvasRef') canvasRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('controls') private lockControls!: PointLockControlsComponent;
  @ViewChild('viewport', { static: true }) viewportRef!: ElementRef;

  @Output() public cameraMoved = new EventEmitter<void>();

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  private get viewport(): HTMLElement {
    return this.viewportRef.nativeElement;
  }

  private boundingBox?: Box3;
  private resizeObserver: ResizeObserver | undefined;
  private renderer!: WebGLRenderer;
  private needsRender = true;
  public camera: PerspectiveCamera = new PerspectiveCamera();
  public scene = new Scene();
  public orbitControls!: OrbitControls;

  ngAfterViewInit(): void {
    // Listen to screen resize
    this.resizeObserver = new ResizeObserver(() => {
      this.onDivResized();
    });
    this.resizeObserver.observe(this.viewportRef!.nativeElement);

    // Init renderer, scene, camera
    this.renderer = initRenderer(this.canvas, this.viewport);
    this.camera = new PerspectiveCamera(30, this.getAspectRatio(), 1, 10000);
    this.scene.add(this.camera);
    this.scene.add(...sceneLights());

    // Init orbit controls
    this.orbitControls = createOrbitControls(this.camera, this.canvas);
    this.orbitControls.listenToKeyEvents(this.canvas);
    this.orbitControls.enableDamping = true;
    this.orbitControls.addEventListener('change', () => {
      this.cameraMoved.emit();
      this.needsRender = true;
    });
    this.render();
  }

  onDivResized(): void {
    if (this.renderer === undefined) return;
    this.camera.aspect = this.getAspectRatio();
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.viewport.clientWidth, this.viewport.clientHeight);
    this.requestRender();
  }

  public shadowNeedsUpdate(): void {
    this.renderer.shadowMap.needsUpdate = true;
  }

  public requestRender(): void {
    this.needsRender = true;
  }

  public addModel(model: Object3D, addShadows: boolean = true, focusCamera: boolean = false): void {
    this.scene.add(model);
    this.boundingBox = new Box3().setFromObject(model);
    if (addShadows) {
      const shadowCatcher = createShadowCatcher(this.boundingBox);
      this.scene.add(shadowCatcher);
    }
    const directionalLight = configDirectionalLight(this.boundingBox);
    this.scene.add(directionalLight);
    this.shadowNeedsUpdate();
    if (focusCamera) {
      // Position camera to look at model
      const center = this.boundingBox.getCenter(new Vector3());
      const max = this.boundingBox.max;
      const cameraPosition = max.clone().multiplyScalar(2).sub(center);
      this.camera.position.set(...cameraPosition.toArray());
      this.camera.lookAt(center);

      // Update orbit controls target
      this.orbitControls.target = this.boundingBox.getCenter(new Vector3());
    }

    this.requestRender();
  }

  private render(): void {
    requestAnimationFrame(this.render.bind(this));
    if (!this.needsRender) return;
    this.needsRender = false;

    this.keepCameraInBounds();
    this.lockControls?.update();
    if (!this.lockControls?.isLocked) this.orbitControls?.update();
    this.renderer.render(this.scene, this.camera);
  }

  private getAspectRatio(): number {
    return this.viewport.clientWidth / this.viewport.clientHeight;
  }

  private keepCameraInBounds(): void {
    if (!this.boundingBox) return;
    const groundHeight = this.boundingBox.min.y;
    if (this.orbitControls.target.y > groundHeight) return; // If not below ground, don't do anything
    this.camera.position.y += groundHeight - this.orbitControls.target.y;
    this.orbitControls.target.y = groundHeight;
  }

  ngOnDestroy(): void {
    this.scene?.clear();
    this.lockControls?.camera?.clear();
    this.camera?.clear();
    this.viewport?.remove();
    this.renderer?.dispose();
    this.orbitControls?.dispose();
    this.resizeObserver?.disconnect();
    this.canvas?.remove();
  }
}
