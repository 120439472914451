<ng-container>
  <div class="button-bar">
    <button
      class="button button--has-icon"
      *ngFor="let location of locations"
      [routerLink]="['.']"
      [queryParams]="location">
      {{ location.name | translate }}
    </button>
    <button (click)="changeView()" class="button-primary">
      <span class="material-symbols-rounded">
        {{ listView ? 'map' : 'list' }}
      </span>
      {{ listView ? 'Map view' : 'List view' }}
    </button>
  </div>
  <viewer *ngIf="!listView"></viewer>
  <list-view *ngIf="listView"></list-view>
</ng-container>
