import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, OrganizationRole, OrganizationService } from '@api-clients/user';
import { UserService } from '@services/user.service';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { lastValueFrom } from 'rxjs';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { Mode } from '../../../auth/authorized-view/mode';
import { PermissionService } from '../../../auth/permission-service';
import { ToastrService } from '@shared/services/toastr.service';
import {
  AddressDetailComponent,
  AddressGroup,
} from '../../components/address-detail/address-detail.component';
import { Resource } from '../../../resources';
import { Scope } from '../../../scopes';

const required = Validators.required;

export interface UserForm {
  id: FormControl<string | undefined>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  organizationId: FormControl<string>;
  organizationRole: FormControl<OrganizationRole>;
  address: FormGroup<AddressGroup | null>; // make optional (null if not needed)
}

@Component({
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  Mode = Mode; // Re-export for template
  OrganizationRole = OrganizationRole; // Re-export for template

  canCreate: boolean = false; // Are we allowed to create the user or only to view?
  canSetOrganization: boolean = false;
  canUpdate: boolean = false; // Are we allowed to edit the user or only to view?
  canDelete: boolean = false; // Are we allowed to delete the user?
  isNew: boolean = false;

  organizations: { id: string; name: string }[] = [];

  @ViewChild(ConfirmationDialogComponent) ConfirmationDialogComponent?: ConfirmationDialogComponent;
  public userDetailForm: FormGroup<UserForm>;
  public addressDetailForm: FormGroup<AddressGroup>;

  breadcrumbTree = [
    {
      translate: 'users-overview-page',
      link: '/users',
    },
    { translate: 'user-detail-page', link: this.router.url },
  ];

  @ViewChild('address')
  private addressComponent?: AddressDetailComponent = undefined;

  constructor(
    private readonly accountService: AccountService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly organizationService: OrganizationService,
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly permissionService: PermissionService,
    private readonly toastrService: ToastrService,
    private readonly formBuilder: FormBuilder
  ) {
    this.addressDetailForm = this.formBuilder.group<AddressGroup>(<AddressGroup>{
      streetName: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      postalCode: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      number: new FormControl('', Validators.required),
    });

    this.userDetailForm = this.formBuilder.group<UserForm>(
      {
        id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
        organizationId: new FormControl<string>(this.userService.organizationId, {
          nonNullable: true,
        }),
        firstName: new FormControl('', {
          nonNullable: true,
          validators: required,
        }),
        lastName: new FormControl('', {
          nonNullable: true,
          validators: required,
        }),
        email: new FormControl('', {
          nonNullable: true,
          validators: [Validators.email, required],
        }),
        organizationRole: new FormControl<OrganizationRole>(OrganizationRole.User, {
          nonNullable: true,
        }),
        address: this.addressDetailForm as FormGroup<AddressGroup | null>,
      },
      { updateOn: 'submit' }
    );
  }

  async ngOnInit(): Promise<void> {
    const canUpdateAllUsers = this.permissionService.userHasPermissionWithScope(
      Resource.AllUsers,
      Scope.UPDATE
    );
    const canUpdateOwnOrganizationUsers = this.permissionService.userHasPermissionWithScope(
      Resource.OwnOrganizationUsers,
      Scope.UPDATE
    );
    const canDeleteAllUsers = this.permissionService.userHasPermissionWithScope(
      Resource.AllUsers,
      Scope.DELETE
    );
    const canDeleteOwnOrganizationUsers = this.permissionService.userHasPermissionWithScope(
      Resource.OwnOrganizationUsers,
      Scope.DELETE
    );
    const id = this.route.snapshot.paramMap.get('id');

    this.isNew = id === null;
    this.canUpdate =
      this.userService.userId === id || canUpdateOwnOrganizationUsers || canUpdateAllUsers;
    if (!this.canUpdate) {
      this.userDetailForm.disable();
    }
    this.canDelete =
      this.userService.userId === id || canDeleteOwnOrganizationUsers || canDeleteAllUsers;
    if (this.userService.userId === id) this.userDetailForm.controls.organizationRole.disable();

    if (id) {
      // If the id is set, we are updating a user
      this.userDetailForm.get('organizationId')!.disable();
      const user = await lastValueFrom(this.accountService.get(id));
      this.userDetailForm.patchValue(user);
    }

    this.canSetOrganization = canUpdateAllUsers;
    if (canUpdateAllUsers) {
      // If the user is allowed to edit all organizations, we will allow choosing the organization on user creation
      this.organizations = (await lastValueFrom(this.organizationService.getAll())).map((org) => ({
        id: org.id!,
        name: org.name!,
      }));
    } else {
      const organizationId = this.userService.organizationId;
      if (!organizationId) throw new Error('No organization id found in user service');
      this.organizations = [{ id: organizationId, name: '' }];
    }
  }

  isSaving: boolean = false;

  async updateUser(): Promise<void> {
    if (!this.userDetailForm.valid) return;
    if (this.isSaving) return;

    this.isSaving = true;

    const user = this.userDetailForm.getRawValue();
    const address = this.addressDetailForm.getRawValue();
    user.address = address;

    // If we have an ID, we are updating a user. If we have no ID, we are creating a new one.

    const putOrPost = user.id
      ? this.accountService.put(user.id, user)
      : this.accountService.post(user);
    putOrPost.subscribe(() => {
      this.toastrService.showSuccess(
        'users-module.user.save-success',
        'users-module.user.save-title'
      );
      this.isSaving = false;
      this.router.navigate(['users']);
    });
  }

  openRemovePopup(): void {
    this.confirmationDialogService.open();
  }

  async removeUser(): Promise<void> {
    const user = this.userDetailForm.value;
    if (!user.id) throw new Error('User id is undefined');
    this.accountService._delete(user.id).subscribe(() => {
      this.router.navigate(['users']);
    });
  }
}
