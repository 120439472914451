import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { BuildingMenuService } from '@shared/services/building-menu.service';
import { VERSION } from '@angular/core';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent {
  protected buildingId?: string;

  constructor(
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly buildingMenuService: BuildingMenuService
  ) {
    console.log('Using Angular ' + VERSION.full);

    // Define the available languages
    const availableLanguages = ['en', 'nl'];

    // Get the browser language
    let browserLang = navigator.language.split('-')[0]; // Extract base language (e.g., "en" from "en-US")

    // Fallback to default if browser language is not available
    if (!availableLanguages.includes(browserLang)) {
      browserLang = 'nl'; // Default language
    }

    // Set the language in ngx-translate
    this.translateService.setDefaultLang(browserLang); // Default language
    this.translateService.use(browserLang); // Use browser language

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.showMenu();
      }
    });

    this.showMenu();
  }

  showMenu(): void {
    this.buildingId = this.router.url.includes('buildings')
      ? this.router.url.split('/')[2]
      : undefined;
    if (this.buildingId) {
      this.buildingMenuService.show();
    } else {
      this.buildingMenuService.hide();
    }
  }
}
