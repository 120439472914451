import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilePreviewComponent } from '../file-preview.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-file-preview-popup',
    templateUrl: './file-preview-popup.component.html',
    styleUrls: ['./file-preview-popup.component.scss'],
    imports: [FilePreviewComponent, TranslateModule],
})
export class FilePreviewPopupComponent {
  @Input() fileUrl: string | undefined = '';
  @Input() fileName: string | undefined = '';
  @Input() fileSize: number = 0;
  @Input() active: boolean = false;
  @Output() close = new EventEmitter<void>();

  constructor() {}

  closePopup(): void {
    this.active = false;
    this.close.emit();
  }

  sanitizeFileName(fileName: string | undefined): string {
    if (!fileName) {
      return '';
    }
    return fileName.replace(/[^a-zA-Z0-9.-]/g, '_');
  }
}
