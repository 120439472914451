import { Component, Input } from '@angular/core';
import { DossierImage, Inspection, InspectionsService, TimeLineType } from '@api-clients/dossier';
import { EnrichedTimeLineDto } from '../../../building-module/views/dossier-detail/EnrichedTimeLineDto';
import {
  isBooleanAnswer,
  isCheckListAnswer,
  isImagesAnswer,
  isNumberAnswer,
  isRadioListAnswer,
  isTextAnswer,
} from '../../../building-module/utils';
import { environment } from '@env/environment';
import { DossierService } from '@services/dossier.service';
import { UsersService } from '@api-clients/user';
import { NgIf, NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { FileSpecsComponent } from '@shared/components/file-specs/file-specs.component';
import { TranslateModule } from '@ngx-translate/core';
import { SecurePipe } from '../../pipes/secure-pipe';

@Component({
  selector: 'app-inspection-result',
  templateUrl: './inspection-result.component.html',
  styleUrl: './inspection-result.component.scss',
  imports: [NgIf, NgFor, FileSpecsComponent, AsyncPipe, DatePipe, TranslateModule, SecurePipe],
})
export class InspectionResultComponent {
  private _item: EnrichedTimeLineDto = {} as EnrichedTimeLineDto;
  protected inspection: Inspection | undefined = undefined;
  protected selectedFile: DossierImage | undefined;
  protected selectedFileUser: string | undefined;
  protected selectedFileUrl: string | undefined;
  protected notFound: boolean = false;

  @Input() user: string | undefined;
  @Input() timeStamp: string | undefined = '';

  @Input()
  set item(value: EnrichedTimeLineDto) {
    this._item = value;
    this.notFound = false;
    // Then get the inspection result for this timeline item
    this.inspectionsService.inspectionIdGet(this._item.item_id).subscribe({
      next: (inspection) => (this.inspection = inspection),
      error: (error) => {
        if (error.status == 404) {
          this.notFound = true;
        }
      },
      complete: () => {},
    });
  }

  constructor(
    private readonly inspectionsService: InspectionsService,
    private readonly dossierService: DossierService,
    private readonly usersApiService: UsersService
  ) {}

  get item(): EnrichedTimeLineDto {
    return this._item;
  }

  protected async openImage(image_id: string): Promise<void> {
    this.selectedFileUrl = await this.dossierService.getImagePresignedUrl(image_id);
    this.selectedFile = await this.dossierService.getImage(image_id);
    this.usersApiService.getNames([this.selectedFile.user_id]).subscribe((users) => {
      this.selectedFileUser = users[this.selectedFile!.user_id];
    });
  }

  protected deselectFile(): void {
    this.selectedFile = undefined;
    this.selectedFileUrl = undefined;
  }

  protected keyDown(e: KeyboardEvent): void {
    if (e.key == 'Escape') {
      this.deselectFile();
    }
  }

  protected readonly environment = environment;
  protected readonly TimeLineType = TimeLineType;
  protected readonly isImagesAnswer = isImagesAnswer;
  protected readonly isTextAnswer = isTextAnswer;
  protected readonly isNumberAnswer = isNumberAnswer;
  protected readonly isBooleanAnswer = isBooleanAnswer;
  protected readonly isCheckListAnswer = isCheckListAnswer;
  protected readonly isRadioListAnswer = isRadioListAnswer;
}
