<div class="variables-popup" [class.active]="variablesPopupService.active">
  <div class="variables-popup__header">
    <h4>{{ 'project-module.measure.add-variables' | translate }}</h4>
    <button class="close-button" (click)="close()">
      <span class="material-symbols-rounded">close</span>
    </button>
  </div>
  <div class="variables-popup__content" *ngIf="variablesForm">
    <form [formGroup]="variablesForm" class="form">
      @for (subject of variablesPopupService.enrichedSubjects; let i = $index; track subject) {
        @let subjectKey = JSON.stringify(subject.subject);

        <div class="unit form-group">
          <app-unit-pin [title]="subject.name"></app-unit-pin>
          @for (projectMeasure of variablesPopupService.projectMeasures; track projectMeasure) {
            @let checked = projectMeasure.subjects.includes(subjectKey);

            @if (checked) {
              @for (variable of projectMeasure.variables; track variable.name) {
                <div class="form-row">
                  @let controlName =
                    hashCode(
                      'field_' + projectMeasure.measure_id + '_' + variable.name + '_' + subjectKey
                    );
                  <label for="{{ controlName }}]}">
                    {{ projectMeasure.description }} - {{ variable.name }}*
                  </label>
                  <input
                    [formControlName]="controlName"
                    id="{{ controlName }}"
                    class="input"
                    type="text"
                    (change)="
                      updateVariableValue(
                        variable,
                        projectMeasure.measureValues,
                        subject.subject,
                        $event
                      )
                    " />
                  <span>{{ variable.unit }}</span>
                </div>
              }
            }
          }
        </div>
      }
    </form>

    <button type="button" class="button-secondary" (click)="close()">
      {{ 'skip' | translate }}
    </button>
    <button
      type="submit"
      class="button-primary"
      (click)="save()"
      [disabled]="variablesForm.invalid">
      {{ 'save' | translate }}
    </button>
  </div>
</div>

<button
  class="variables-popup__background"
  (click)="close()"
  [class.active]="variablesPopupService.active">
  &nbsp;
</button>
