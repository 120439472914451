import { Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PageSliderService } from '@shared/services/page-slider.service';
import { DossierDocument, DossierFile, DossierImage } from '@api-clients/dossier';
import { NgIf, DatePipe } from '@angular/common';
import { FilePreviewComponent } from '../file-preview/file-preview.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-file-specs',
    templateUrl: './file-specs.component.html',
    styleUrls: ['./file-specs.component.scss'],
    imports: [
        NgIf,
        FilePreviewComponent,
        DatePipe,
        TranslateModule,
    ],
})
export class FileSpecsComponent {
  @Input() user: string | undefined;
  @Input() timeStamp: string | undefined = '';
  @Input() file: DossierImage | DossierFile | DossierDocument | undefined;
  @Input() fileUrl: string | undefined = '';
  sliderExpanded: boolean = false;

  constructor(private pageSliderService: PageSliderService) {
    this.pageSliderService.pageSliderStatus$.pipe(takeUntilDestroyed()).subscribe((status) => {
      this.sliderExpanded = status.active;
    });
  }

  get fileType(): string {
    return this.file?.content_type || '-';
  }

  downloadFile(url: string | undefined, name: string | undefined): void {
    if (!url || !name) return;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  humanReadableSize(bytes): string | undefined {
    let size = parseInt(bytes);
    for (const unit of ['b', 'Kb', 'Mb', 'Gb']) {
      if (size < 1024) return `${size.toFixed(2)} ${unit}`;
      size /= 1024.0;
    }
    return '-';
  }
}
